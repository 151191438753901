import { ComboBox, DatePicker, DayOfWeek, DefaultButton, defaultDatePickerStrings, Dialog, DialogFooter, DialogType, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, SpinButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../../app/Hooks";
import { add, dismissMessage, setSelectedAccountItems, resetAccountsSet, reset, setIsFilteredAccountSet, setMessage, getAccounts, setStage1, resetMessage, getTransactionSummary, } from "./AccountStatementFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField, NumberInputField } from "../../../../controls/Controls";
import { inputs } from "../../../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, APIResponse, clearSession, getHeaders, getSession, saveSession } from "../../../../../../app/Api";
import { Message } from "../../../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { currencyFormatter, formatDate, timestampToDate } from "../../../../../../app/Helpers";
import { AuthenticateReply } from "../../../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../../../repository/Accountant/common_pb";
import { TableState } from "../../../../../common/Table/TableSate";
import { GetAccountsRequest } from "../../../../../../repository/Accountant/account_pb";
import { AccountStatus, AuthorizedOperation, Operations, TreasuryStatus } from "../../../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../../../repository/Accountant/currency_pb";
import { ExportStatementRequest, ExportStatementResponse, GetTransactionSummary, GetTransactionSummaryRequest, GetTransactionSummaryResponse } from "../../../../../../repository/Accountant/account_operation_pb";
import { GetTreasuriesRequest, GetTreasuriesResponse } from "../../../../../../repository/Accountant/treasury_pb";
import { List } from "../../../../../common/List/List";
import { Popup } from "../../../../../common/Popup/Popup";
import { AccountsPage } from "../../../../../pages/Accountant/Accounts/AccountsPage";
import { TreasuriesPage } from "../../../../../pages/Accountant/Treasuries/TreasuriesPage";
import moment from "moment";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import statement from "../../../../../../repository/Accountant/account_operation_pb";
import { AuthorizationForm } from "../../../Accounts/AuthorizationForm";


let req: ExportStatementRequest;
let getAccountsReq: GetAccountsRequest;

let promise: any;
let getFilterPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;


export const AccountStatementForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState({
        type: DialogType.normal,
        title: '',
        subText: '',
    });
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const modalPropsStyles = { main: { maxWidth: 450 } };
    const [currentAction, setCurrenctAction] = useState(0)
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const modalProps = useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        [isDraggable],
    );

    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, accounts: TableState, confirmation: any[] } = useAppSelector((state) => {

        return {
            isLoading: state.accountStatementForm.isLoading, message: state.accountStatementForm.message,
            stage1: state.accountStatementForm.stage1,
            accounts: state.accountStatementForm.accounts,
            confirmation: state.accountStatementForm.confirmation,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new ExportStatementRequest();
        getAccountsReq = new GetAccountsRequest();
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                getAccountsReq.setOrganization(wrapper)
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);

        const statt = new Int32Value();
        statt.setValue(TreasuryStatus.ACTIVE);

        getAccountsReq.setNumofresults(state.accounts.numberOfResults)
        getAccountsReq.setOrder(state.accounts.isDescending)
        getAccountsReq.setStatus(stat)




        if (props.renderObject && props.renderObject?.account) {

            dispatch(setSelectedAccountItems(([props.renderObject?.account])))
        }

        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])


    useEffect(() => {
        if (state.confirmation.length > 0) {
            if (state.confirmation.at(0).debtorfeeValue != undefined && state.confirmation.at(0).debtorfeeValue != 0) {
                const formattedFeeCurrency = currencyFormatter(Number(state.confirmation.at(0).debtorfeeValue), { significantDigits: state.confirmation.at(0).currencyDecimalPlaces ?? 9, symbol: state.confirmation.at(0)?.currencySymbol })
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    subText: t("issueStatementConfirmWithFee", { feeAmount: formattedFeeCurrency }),
                })
                toggleHideDialog()
            }
        }
    }, [state.confirmation])

    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getAccountsReq.setSearch(wrapper)
        getAccountsReq.setNextto(undefined)

        dispatch(setIsFilteredAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
        return [];

    }





    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Formik
                enableReinitialize={true}
                initialValues={{

                    account: undefined,
                    numberOfResults: 100,
                    from: moment(fromDate, 'YYYY-MM-DD').startOf('day').toDate(),
                    to: moment(new Date(), 'YYYY-MM-DD').endOf('day').toDate(),
                    exportType: { key: 3, text: t("exportToPDF") },


                }}

                validationSchema={Yup.object({

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let account = state.accounts.selected.at(0);

                        req.setAccount(account.internalId);

                        const wrapper = new Timestamp();
                        wrapper.fromDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate())
                        req.setFrom(wrapper)
                        const wrapperTo = new Timestamp();
                        wrapperTo.fromDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate())
                        req.setTo(wrapperTo)

                        req.setExporttype(values.exportType.key)
                        req.setNumofresults(values.numberOfResults)

                        const labels: statement.Label[] = [];
                        let r = new statement.Label();
                        let value = new StringValue();
                        value.setValue(formatDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate()))
                        r.setLabelvalue(t("from"));
                        r.setValue(value)
                        r.setPropartyname("from")
                        labels.push(r)

                        r = new statement.Label();
                        value = new StringValue();
                        value.setValue(formatDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate()))
                        r.setLabelvalue(t("to"));
                        r.setValue(value)
                        r.setPropartyname("to")
                        labels.push(r)

                        r = new statement.Label();
                        value = new StringValue();
                        value.setValue(values.numberOfResults + "")
                        r.setLabelvalue(t("numberOfResults"));
                        r.setValue(value)
                        r.setPropartyname("numberOfResults")
                        labels.push(r)


                  
                        r = new statement.Label();
                        r.setLabelvalue(t("accountNumberFullForm"));
                        r.setPropartyname("accountId")
                        labels.push(r)
          
                        r = new statement.Label();
                        r.setLabelvalue(t("accountNameFullForm"));
                        r.setPropartyname("accountName")
                        labels.push(r)

                

                        r = new statement.Label();
                        r.setLabelvalue(t("currency"));
                        r.setPropartyname("currency")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("currency"));
                        r.setPropartyname("currencyname")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("client"));
                        r.setPropartyname("client")
                        labels.push(r)
                        

                        r = new statement.Label();
                        r.setLabelvalue(t("totalDebit"));
                        r.setPropartyname("totaldebit")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("totalCredit"));
                        r.setPropartyname("totalCredit")
                        labels.push(r)


                        r = new statement.Label();
                        r.setLabelvalue(t("totalNumberOfDebitOperations"));
                        r.setPropartyname("totalNumberOfDebitOperations")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("totalNumberOfCreditOperations"));
                        r.setPropartyname("totalNumberOfCreditOperations")
                        labels.push(r)
                        
                        
                        r = new statement.Label();
                        r.setLabelvalue(t("openningBalance"));
                        r.setPropartyname("openningBalance")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("closingBalance"));
                        r.setPropartyname("closingBalance")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("generatedDate"));
                        r.setPropartyname("generateddate")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("generatedBy"));
                        r.setPropartyname("generatedby")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("amount"));
                        r.setPropartyname("amount")
                        labels.push(r)

                        r = new statement.Label();
                        value = new StringValue();
                        value.setValue((((new Date()).getTimezoneOffset()) * -1) + "")
                        r.setValue(value);
                        r.setPropartyname("timezone")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("balance"));
                        r.setPropartyname("balance")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("accountStatementTitle"));
                        r.setPropartyname("accountStatementTitle")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("summary"));
                        r.setPropartyname("summary")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("transactionNumberLabel"));
                        r.setPropartyname("id")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("date"));
                        r.setPropartyname("date")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("description"));
                        r.setPropartyname("description")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("credit"));
                        r.setPropartyname("credit")
                        labels.push(r)
                        r = new statement.Label();
                        r.setLabelvalue(t("debit"));
                        r.setPropartyname("debit")
                        labels.push(r)



                        r = new statement.Label();
                        r.setLabelvalue(t("balanceBefore"));
                        r.setPropartyname("balanceBefore")
                        labels.push(r)

                        r = new statement.Label();
                        r.setLabelvalue(t("balanceAfter"));
                        r.setPropartyname("balanceAfter")
                        labels.push(r)



                        req.setLabelsList(labels)


                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: ExportStatementResponse.AsObject) => {
                                actions.resetForm();
                                req.setAuthcode(undefined)

                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({


                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                req.setAuthcode(undefined)
                                actions.setSubmitting(false)
                            })
                    } else {

                    }
                }}

            >
                {formkikProps => (
                    <Form onSubmit={(e) => {
                        e.preventDefault()

                        dispatch(resetMessage())
                        let account = state.accounts.selected.at(0);

                        req.setAuthcode(undefined)
                        if (account.isAuthorizationRequired) {
                            setCurrenctAction(13);
                            return;
                        }

                        var sumary = new GetTransactionSummaryRequest();
                        const set: GetTransactionSummary[] = [];
                        const trans = new GetTransactionSummary();
                        trans.setDebtor(account.internalId)
                        trans.setOperation(Operations.ISSUE_STATEMENT)
                        set.push(trans)
                        sumary.setTransactionsList(set)
                        promise?.abort();
                        promise = dispatch(getTransactionSummary({ body: sumary, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: APIResponse<GetTransactionSummaryResponse.AsObject>) => {
                                if (res) {
                                    var r = Number(res.response?.success?.transactionsummaryList?.at(0)?.debtorfee?.fee?.value);
                                    if (Number.isNaN(r) || r == 0) {
                                        formkikProps.handleSubmit();
                                    }

                                }
                            })
                            .catch((error: APIResponse<ApiMessage>) => {
                                if (error.response?.data == 403) {
                                    formkikProps.handleSubmit();
                                }
                            })



                    }}>
                        <Popup isOpen={(currentAction == 13)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                            <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={
                                {
                                    account: state.accounts.selected.at(0),
                                    operation: AuthorizedOperation.ISSUE_STATEMENT,
                                    parameters: [{
                                        key: "From", value: formatDate(moment(formkikProps.values.from, 'YYYY-MM-DD').startOf('day').toDate(), "YYYY-MM-DD HH:mm:ss Z"),
                                        viewValue: formatDate(moment(formkikProps.values.from, 'YYYY-MM-DD').startOf('day').toDate(), "YYYY-MM-DD HH:mm:ss Z")
                                    },
                                    { key: "To", value: formatDate(moment(formkikProps.values.to, 'YYYY-MM-DD').endOf('day').toDate(), "YYYY-MM-DD HH:mm:ss Z"), viewValue: formatDate(moment(formkikProps.values.to, 'YYYY-MM-DD').endOf('day').toDate(), "YYYY-MM-DD HH:mm:ss Z") },
                                    { key: "NumOfResults", value: formkikProps.values.numberOfResults + "", viewValue: "" },
                                    ],
                                }

                            } onSuccess={(e) => {
                                if (req && e.authCode) {
                                    if (currentAction == 13) {
                                        var r = new StringValue();
                                        r.setValue(e.authCode)
                                        req.setAuthcode(r)
                                        setCurrenctAction(0)

                                        let account = state.accounts.selected.at(0);

                                        var sumary = new GetTransactionSummaryRequest();
                                        const set: GetTransactionSummary[] = [];
                                        const trans = new GetTransactionSummary();
                                        trans.setDebtor(account.internalId)
                                        trans.setOperation(Operations.ISSUE_STATEMENT)
                                        set.push(trans)
                                        sumary.setTransactionsList(set)
                                        promise?.abort();
                                        promise = dispatch(getTransactionSummary({ body: sumary, headers: getHeaders() }))
                                        promise.unwrap()
                                            .then((res: APIResponse<GetTransactionSummaryResponse.AsObject>) => {
                                                if (res) {
                                                    var r = Number(res.response?.success?.transactionsummaryList?.at(0)?.debtorfee?.fee?.value);
                                                    if (Number.isNaN(r) || r == 0) {
                                                        formkikProps.handleSubmit();
                                                    }

                                                }
                                            })
                                            .catch((error: APIResponse<ApiMessage>) => {
                                                if (error.response?.data == 403) {
                                                    formkikProps.handleSubmit();
                                                }
                                            })

                                    }
                                } else {
                                    setCurrenctAction(0)
                                }
                            }} onCancel={() => { setCurrenctAction(0) }} />
                        </Popup>
                        <Dialog
                            hidden={hideDialog}
                            onDismiss={toggleHideDialog}
                            dialogContentProps={showConfirmation}
                            modalProps={modalProps}
                        >
                            <DialogFooter>
                                <PrimaryButton autoFocus onClick={(e: any) => { formkikProps.handleSubmit(e); toggleHideDialog() }} text={t("download")} type="submit" />
                                <DefaultButton onClick={toggleHideDialog} text={t("cancel")} />
                            </DialogFooter>
                        </Dialog>
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("account")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('accounts')}
                                    isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                    isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                    moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                    suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                            getAccountsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
                                        }

                                    }}
                                    inputProps={{ placeholder: t("account"), required: true }}

                                    disabled={state.isLoading || props.renderObject?.account ? true : false}
                                    selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedAccountItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetAccountsSet())
                                        getAccountsReq.setSearch(undefined)
                                        getAccountsReq.setNextto(undefined)
                                        dispatch(setIsFilteredAccountSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))

                                    }}
                                    onFilterChanged={onAccountsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100);

                                            }
                                        }]}
                                />

                            </Stack> : undefined}


                            <DatePicker
                                isRequired
                                firstDayOfWeek={DayOfWeek.Sunday}
                                label={t("from")}
                                placeholder={t("from")}
                                disabled={state.isLoading}
                                value={formkikProps.values.from}
                                ariaLabel={t("from")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').startOf('day').toDate();
                                }}
                                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {

                                    formkikProps.setFieldValue("from", date)
                                }}

                            />

                            <DatePicker
                                isRequired
                                firstDayOfWeek={DayOfWeek.Sunday}
                                label={t("to")}
                                placeholder={t("to")}
                                disabled={state.isLoading}
                                value={formkikProps.values.to}
                                ariaLabel={t("to")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').endOf('day').toDate();
                                }}
                                // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date != undefined)
                                        if (moment(date).isSameOrAfter(formkikProps.values.from)) {
                                            formkikProps.setFieldValue("to", date)
                                        } else {
                                            formkikProps.setFieldValue("to", new Date())
                                        }
                                }}

                            />

                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{
                                    t("numberOfResults")
                                }</Label>
                                <Field name={`numberOfResults`}
                                    placeholder={"100"} component={NumberInputField}
                                    disabled={state.isLoading} max={1000} min={1} required />
                            </Stack> : undefined}

                            <ComboBox
                                label={t("export")}
                                required
                                allowFreeform={true}
                                autoComplete={'on'}
                                disabled={state.isLoading}
                                options={[{ key: 1, text: t("exportToExcel") }, { key: 2, text: t("exportToCsv") }, { key: 3, text: t("exportToPDF") }]}
                                selectedKey={formkikProps.values.exportType.key}
                                onChange={(e, o) => {
                                    formkikProps.setFieldValue("exportType", { key: o?.key, text: o?.text })
                                }} />


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton  type="submit" disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("download") : t("edit")}  >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

