import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks"
import { getPage } from "../../../../../app/Pages"
import { setPage } from "../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../common/Table/Table"
import { ApiMessage, getHeaders, getSession, PROFILE_LOGO_URL } from "../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../../common/Message/Message"
import { ShareBox } from "../../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { deleteItem, dismissMessage, get, reset, resetItem, freeBalance, updateItem, setMessage, getAccount } from "./ReservedBalanceDetailsPageSlice"
import { Popup } from "../../../../common/Popup/Popup"
import { FormType } from "../../../../forms/FormProps"
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields"
import { TextView } from "../../../../common/DataView/TextView"
import { DeleteLimitationRequest, GetLimitationRequest, UpdateLimitationActivityRequest } from "../../../../../repository/Accountant/limitation_pb"
import { LimitationsForm } from "../../../../forms/Accountant/Limitations/LimitationsFrom"
import { ProfileView } from "../../../../common/DataView/ProfileView"
import { ReservedBalancesForm } from "../../../../forms/Accountant/ReservedBalances/ReservedBalancesForm"
import { DeleteReservationRequest, FreeAmountRequest, GetBalanceReservationRequest } from "../../../../../repository/Accountant/balance_reservation_pb"
import { AuthorizedOperation, ReservationStatus } from "../../../../../app/Enums"
import { LinkView } from "../../../../common/DataView/LinkView"
import { currencyFormatter, printComponent, normalizeKey } from "../../../../../app/Helpers"
import { useReactToPrint } from "react-to-print"
import { TransferAmountForm } from "../../../../forms/Accountant/ReservedBalances/TransferAmount/TransferAmountForm"
import { WithdrawAmountForm } from "../../../../forms/Accountant/ReservedBalances/WithdrawAmount/WithdrawAmountForm"
import { GetAccountRequest } from "../../../../../repository/Accountant/account_pb"
import { AuthorizationForm } from "../../../../forms/Accountant/Accounts/AuthorizationForm"
import { StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { ExternalTransferAmountForm } from "../../../../forms/Accountant/ReservedBalances/ExternalTransfareAmount/ExternalTransfareAmountForm"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetBalanceReservationRequest;

let org: number = -1;

let getPromise: any;
let actionPromise: any;


const key = "accountsManagement/reservedBalances/details"
export const ReservedBalanceDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any
        account: any

    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.reservedBalanceDetailsPage.isChangeStateLoading,
            message: state.reservedBalanceDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.reservedBalanceDetailsPage.isLoading,
            item: state.reservedBalanceDetailsPage.item,
            account: state.reservedBalanceDetailsPage.account



        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});

    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetLimitationRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))
        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'edit',
            text: t("edit"),
            title: t('edit'),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                setCurrenctAction(101)

            }

        },
        {
            key: 'releaseAmount',
            text: t("releaseAmount"),
            title: t("releaseAmount"),
            disabled: state.item?.status != ReservationStatus.RESERVED,
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: "Accept", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={(state.isChangeStateLoading && currentAction == 2) || (state.isChangeStateLoading && currentAction == 108) ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 || (state.isChangeStateLoading && currentAction == 108) ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 || (state.isChangeStateLoading && currentAction == 108) ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(108)
                    let req = new GetAccountRequest();
                    req.setId(state.item?.accountId)
                    actionPromise?.abort();
                    actionPromise = dispatch(getAccount({ body: req, headers: getHeaders() }))
                    actionPromise.unwrap().then((e: any) => {
                        setCurrenctAction(2)
                        toggleisActionConfirmationCalloutVisible()

                    })

                }
            }
        },
        {
            key: 'transferMoney',
            text: t("transferMoney"),
            iconProps: { iconName: 'Switch' },
            disabled: state.item?.status != ReservationStatus.RESERVED,
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 105 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 105 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 105 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            title: t("transferMoney"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(105)
                    let req = new GetAccountRequest();
                    req.setId(state.item?.accountId)
                    actionPromise?.abort();
                    actionPromise = dispatch(getAccount({ body: req, headers: getHeaders() }))
                    actionPromise.unwrap().then((e: any) => {
                        setCurrenctAction(5)
                    })

                }
            }
        },
        {
            key: 'externalTransferMoney',
            text: t("externalTransferMoney"),
            iconProps: { iconName: 'SwitcherStartEnd' },
            disabled: state.item?.status != ReservationStatus.RESERVED,
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 109 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 109 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 109 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            title: t("externalTransferMoney"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(109)
                    let req = new GetAccountRequest();
                    req.setId(state.item?.accountId)
                    actionPromise?.abort();
                    actionPromise = dispatch(getAccount({ body: req, headers: getHeaders() }))
                    actionPromise.unwrap().then((e: any) => {
                        setCurrenctAction(9)
                    })

                }
            }
        },
        {
            key: 'withdraw',
            text: t("withdraw"),
            iconProps: { iconName: 'PublishContent' },
            disabled: state.item?.status != ReservationStatus.RESERVED,
            title: t("withdraw"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 107 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 107 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 107 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(107)

                    let req = new GetAccountRequest();
                    req.setId(state.item?.accountId)
                    actionPromise?.abort();
                    actionPromise = dispatch(getAccount({ body: req, headers: getHeaders() }))
                    actionPromise.unwrap().then((e: any) => {
                        setCurrenctAction(7)
                    })
                }
            }
        },
        {
            key: 'delete',
            text: t("delete"),
            title: t("delete"),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Delete", styles: { root: { fontWeight: "bold" } } },
            disabled: state.item?.status == ReservationStatus.RESERVED,
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];



    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <Popup isOpen={(currentAction == 101)} title={t("editBalanceResereservation")} onDismiss={() => { setCurrenctAction(0) }} >

                <ReservedBalancesForm childrenGap={10} maxWidth={480} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 5)} title={t("transfer")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <TransferAmountForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{
                    from: state.account, amount: state.item?.amount, id: state.item?.id
                }} onSuccess={(e) => {
                    //setCurrenctAction(0)
                    dispatch(updateItem({
                        ...state.item, referenceTransactionId: e?.id ?? undefined,
                        referenceTransactionInternalId: e?.internalid ?? "", status: ReservationStatus.TRANSFERRED
                    }))
                    dispatch(setMessage(e.message))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 9)} title={t("externalTransfer")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <ExternalTransferAmountForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{
                    from: state.account, amount: state.item?.amount, id: state.item?.id
                }} onSuccess={(e) => {
                    //setCurrenctAction(0)
                    dispatch(updateItem({
                        ...state.item, referenceTransactionId: e?.id ?? undefined,
                        referenceTransactionInternalId: e?.internalid ?? "", status: ReservationStatus.TRANSFERRED


                    }))
                    dispatch(setMessage(e.message))
                    setCurrenctAction(0)

                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 7)} title={t("withdraw")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <WithdrawAmountForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{
                    account: state.account, amount: state.item?.amount, id: state.item?.id
                }} onSuccess={(e) => {
                    // setCurrenctAction(0)
                    dispatch(updateItem({
                        ...state.item, referenceTransactionId: e?.id ?? undefined,
                        referenceTransactionInternalId: e?.internalid ?? "", status: ReservationStatus.TRANSFERRED
                    }))
                    dispatch(setMessage(e.message))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 14)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                    account: state.account,
                    operation: AuthorizedOperation.FREE_BALANCE,
                    parameters: [{ key: "Amount", value: state.item?.amount + "", viewValue: state.item?.currencySymbol },
                    ],
                }} onSuccess={(e) => {
                    if (state.account && e.authCode) {
                        if (currentAction == 14) {
                            let req = new FreeAmountRequest();
                            req.setId(state.item?.id)
                            var r = new StringValue();
                            r.setValue(e.authCode)
                            req.setAuthcode(r)
                            actionPromise?.abort();
                            setCurrenctAction(2)
                            actionPromise = dispatch(freeBalance({ body: req, headers: getHeaders() }))
                            actionPromise.unwrap().then((e: any) => {
                                setCurrenctAction(0)
                            })

                        }
                    } else {
                        setCurrenctAction(0)
                    }
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareReservedAmount")} value={`https://${window.location.host}/reserved-balances/${state.item.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("delete") : currentAction == 2 ? t("releaseAmount") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("deleteReservedBalanceDesc") : currentAction == 2 ? t("releaseAmountDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 1) {
                                            let req = new DeleteReservationRequest();
                                            req.setId(state.item?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(deleteItem({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                navigate(-1)
                                            })
                                        } else if (currentAction == 2) {
                                            if (state.account?.isAuthorizationRequired) {
                                                setCurrenctAction(14)
                                                return;
                                            }
                                            let req = new FreeAmountRequest();
                                            req.setId(state.item?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(freeBalance({ body: req, headers: getHeaders() }))

                                        }
                                    }
                                }} text={(currentAction == 1 ? t("delete") : currentAction == 2 ? t("release") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                        />
                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("id")} value={state.item?.id} />


                                <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    imageUrl={PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + state.item?.addedById + "/profile.jpg"}

                                    size={PersonaSize.size40} />

                                    : undefined}

                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <LinkView label={t("account")} value={state.item?.accountName == "" ? state.item?.accountId : state.item?.accountName }
                                    url={"/accounts/" + state.item?.accountId + ((org == -1) ? "" : "?org=" + org)} />

                                <TextView label={t("status")} value={state.item?.status == ReservationStatus.FREED ? t("freed") : state.item?.status == ReservationStatus.FORWARDED ? t("forwarded") :
                                    state.item?.status == ReservationStatus.RETURNED ? t("returned") :
                                        state.item?.status == ReservationStatus.RESERVED ? t("reserved") : state.item?.status == ReservationStatus.TRANSFERRED ? t("transferred") :
                                            state.item?.status == ReservationStatus.WITHDRAWN ? t("withdrawn") : state.item?.status == ReservationStatus.LOCKED ? t("locked") : state.item?.status + ""} />


                                <TextView label={t("amount")} value={currencyFormatter(state.item?.amount, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol })} />

                            </Stack>
                            <LinkView label={t("currency")} value={state.item?.currencyName == "" ? state.item?.currencyId : state.item?.currencyName}
                                url={"/currencies/" + state.item?.currencyId + ((org == -1) ? "" : "?org=" + org)} />

                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <LinkView label={t("triggeredbyTransaction")} value={state.item?.triggeredbyTransactionId}
                                    url={"/account-transactions/" + state.item?.triggeredbyTransactionInternalId + ((org == -1) ? "" : "?org=" + org)} />

                                <LinkView label={t("referenceTransaction")} value={state.item?.referenceTransactionId}
                                    url={"/account-transactions/" + state.item?.referenceTransactionInternalId + ((org == -1) ? "" : "?org=" + org)} />
                            </Stack>

                            <TextView label={t("note")} value={state.item?.note} />

                            <Separator alignContent="start"><b>{t("otherInfo")}</b></Separator>

                            {state.item?.extraFields.map((e: DynamicField, index: number) => {
                                return <Stack key={e.key}>
                                    <TextView label={e.label ?? ""} value={e?.value} />

                                </Stack>
                            })}
                        </Stack>



                    </div>


            }


        </Stack >



    );
}

