import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, TooltipHost, DialogFooter, CheckboxVisibility, ConstrainMode, StackItem } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../../../app/Hooks"
import { APIResponse, ApiMessage, ORGANIZATION_LOGO_URL, TRADING_API_URL, getHeaders } from "../../../../../app/Api"
import { currencyFormatter, formatDate, printComponent, normalizeKey, timestampToDate } from "../../../../../app/Helpers"
import { TableState } from "../../../../common/Table/TableSate"
import { useNavigate, useSearchParams } from "react-router-dom"
import { PageProps } from "../../../PageProps"
import {
    widget,
    ChartingLibraryWidgetOptions,
    LanguageCode,
    ResolutionString,
    ChartingLibraryFeatureset,
    IBasicDataFeed,
    CustomTimezoneId,
} from '../../../../../charting_library';
import * as React from 'react';
import { getCurrentLanguage } from '../../../../../app/Helpers';
import { useAppSelector } from '../../../../../app/Hooks';
import { useParams } from 'react-router-dom';
import { setTitle } from "../../../../common/NavHeader/NavHeaderSlice"
import { getPage } from "../../../../../app/Pages"
import { setPage } from "../../../../layouts/Master/MasterLayoutSlice"
import { Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import moment from "moment"
import { dismissMessage, getAccount, getAccounts, getItems, reset } from "./AccountChartSlice"
import { CurrencyForm } from "../../../../../app/Enums"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import { Message } from "../../../../common/Message/Message"
import { LocalStorageSaveLoadAdapter } from "../../../../../app/TVStorage"
import { GetStatementRequest } from "../../../../../repository/Accountant/account_operation_pb"
import { GetAccountRequest, GetAccountsRequest, GetAccountsResponse } from "../../../../../repository/Accountant/account_pb"
import { GetAccountBalanceReportRequest, GetAccountBalanceReportResponse } from "../../../../../repository/Accountant/report_pb"

export interface ChartContainerProps {
    symbol: ChartingLibraryWidgetOptions['symbol'];
    interval: ChartingLibraryWidgetOptions['interval'];

    // BEWARE: no trailing slash is expected in feed URL
    datafeedUrl: string;
    libraryPath: ChartingLibraryWidgetOptions['library_path'];
    chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
    chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
    clientId: ChartingLibraryWidgetOptions['client_id'];
    userId: ChartingLibraryWidgetOptions['user_id'];
    fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
    autosize: ChartingLibraryWidgetOptions['autosize'];
    studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
    container: ChartingLibraryWidgetOptions['container'];
}
let org: number = -1;

var lastTime: any = undefined;
var currentReselution: any = undefined;
var myInterval: any = undefined;

let req: GetAccountBalanceReportRequest;
let reqAcc: GetAccountRequest;
let accountsReq: GetAccountsRequest;


let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

const key = "accountsManagement/accounts/chart"
export const AccountChartPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const disabled_features: ChartingLibraryFeatureset[] = []
    const disabled_features_mobile: ChartingLibraryFeatureset[] = [
        'use_localstorage_for_settings',
        'left_toolbar', 'header_widget', 'timeframes_toolbar', 'edit_buttons_in_legend', 'context_menus', 'control_bar', 'border_around_the_chart',
    ]

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        account: any,
        accounts: TableState,
        organization: any

    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.accountChartPage.isChangeStateLoading,
            set: state.accountChartPage.set,
            message: state.accountChartPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            account: state.accountChartPage.account,
            organization: state.masterLayout.currentOrganization,
            accounts: state.accountChartPage.accounts,

        }
    })


    const defaultProps: Omit<ChartContainerProps, 'container'> = {
        symbol: 'USDLYD',
        interval: 'D' as ResolutionString,
        datafeedUrl: 'https://localhost:7166/api/trading-view/udf',
        libraryPath: '/charting_library/',
        chartsStorageUrl: 'https://saveload.tradingview.com',
        chartsStorageApiVersion: '1.1',
        clientId: 'tradingview.com',
        userId: 'public_user_id',
        fullscreen: false,
        autosize: true,
        studiesOverrides: {},
    };


    const DataFeed: IBasicDataFeed = {

        onReady: (callback: any) => {
            setTimeout(() => callback({
                // Represents the resolutions for bars supported by your datafeed
                supported_resolutions: ["60", "1D", "1M", "1Y"],


                supports_search: true,
                supports_group_request: false,
                supports_marks: true,
                supports_timescale_marks: true,
                supports_time: true,

            }));
        },

        searchSymbols: async (
            userInput: any,
            exchange: any,
            symbolType: any,
            onResultReadyCallback: any,
        ) => {


            if (userInput == state.account?.id) {
                let resulte: any[] = [];
                if (state.account) {

                    resulte.push({
                        symbol: state.account?.id,
                        full_name: (state.account?.name ?? ""), // e.g. BTCE:BTCUSD
                        description: state.account?.name,
                        exchange: state.organization?.name,
                        ticker: state.account.internalId,
                        type: state.account.currencyName



                    });
                }
                onResultReadyCallback(resulte);
            } else {
                let s = new StringValue();
                s.setValue(userInput)

                accountsReq.setSearch(s)
                var request = await dispatch(getAccounts({ body: accountsReq, headers: getHeaders() }))
                const payload = request.payload as GetAccountsResponse.AsObject;

                var bars = (payload?.success?.accountsList.map(val => {

                    let r = {
                        symbol: val?.id,
                        full_name: (val?.name?.value ?? ""), // e.g. BTCE:BTCUSD
                        description: val?.name?.value ?? "",
                        exchange: state.organization?.name,
                        ticker: val.internalid,
                        type: val.currency?.name?.value ?? ""

                    }


                    return r;


                }
                ) as any[])

                onResultReadyCallback(bars);
            }


        },
        resolveSymbol: async (
            symbolName: any,
            onSymbolResolvedCallback: any,
            onResolveErrorCallback: any,
            extension: any
        ) => {

            let found = undefined;
            if (state.account?.internalId == symbolName) {
                found = state.account;

            } else {
                let acc = state.accounts.items.find((element: any) => (element.internalId == symbolName))
                if (acc)
                    found = acc;
                else {
                    reqAcc.setId(symbolName)
                    getItemsPromise = await dispatch(getAccount({ body: reqAcc, headers: getHeaders() }))
                }
            }

            if (found != undefined) {
                const symbolInfo = {
                    ticker: symbolName,
                    name: (found.name),
                    description: found.name,
                    type: found.currencyName
                    ,
                    session: '24x7',
                    exchange: state.organization?.name,
                    minmov: 1,
                    logo_urls: ["" + found.iconSource],
                    pricescale: Number("1".padEnd(found.currencyDecimalPlaces, '0')),
                    has_intraday: true,
                    has_daily: true,
                    visible_plots_set: 'ohlcv',
                    has_empty_bars: false,
                    supported_resolutions: ["60", "1D", "1M", "1Y"],

                    // supported_resolutions: ["1S", "1", "15", "30", "60", "240", "1D", "1W"],
                    volume_precision: found.currencyDecimalPlaces,
                    data_status: 'streaming',
                    delay: 0,
                    exchange_logo: state.organization?.logo?.Name ? ORGANIZATION_LOGO_URL + state.organization?.logo?.Name : undefined,
                };
                onSymbolResolvedCallback(symbolInfo);

            } else {
                onResolveErrorCallback();

            }


        },

        getBars: async (symbolInfo: any, resolution: any, periodParams: any, onHistoryCallback: any, onErrorCallback: any) => {

            try {

                let from = new Date(periodParams.from * 1000);
                let to = new Date(periodParams.to * 1000);

                const wrapper = new Timestamp();
                wrapper.fromDate(from)
                req.setFrom(wrapper)

                const wrapper2 = new Timestamp();
                wrapper2.fromDate(to)
                req.setTo(wrapper2)
                //   options={[{ key: 3, text: t("hourly") }, { key: 0, text: t("daily") }, { key: 1, text: t("monthly") }, { key: 2, text: t("yearly") },]}

                var groupby = -1;
                if (resolution == "60") {
                    groupby = 3;
                }
                else if (resolution == "1D") {
                    groupby = 0;

                }
                else if (resolution == "1M") {
                    groupby = 1;

                }
                else if (resolution == "12M") {
                    groupby = 2;

                }
                else if (resolution == "1") {
                    groupby = 4;
                } else if (resolution == "15") {
                    groupby = 15;
                } else if (resolution == "30") {
                    groupby = 30;
                } else if (resolution == "240") {
                    groupby = 240;
                } else if (resolution == "10080") {
                    groupby = 10080;
                }
                if (groupby != -1) {

                    req.setGroupby(groupby)
                } else {
                    req.setGroupby(0)
                }


                if (symbolInfo.ticker) {
                    req.setAccount(symbolInfo.ticker);
                } else {
                    onHistoryCallback([], {
                        noData: true,
                    });
                    return;
                }
                req.setAccount(symbolInfo.ticker);

                var request = await dispatch(getItems({ body: req, headers: getHeaders() }))
                const payload = request.payload as APIResponse<GetAccountBalanceReportResponse.AsObject>;

                var bars = (payload?.response?.success?.reportList.map(val => {

                    let r = {
                        open: Number(val.openningbalance?.value).valueOf(),
                        high: Number(0).valueOf(),
                        low: Number(0).valueOf(),
                        close: Number(val.closingbalance?.value).valueOf(),
                        time: timestampToDate(val.date?.seconds, val.date?.nanos)?.getTime(),
                        volume: (Number(val.totalcredit?.value) + Number(val.totaldebit?.value)).valueOf(),

                    }
                    if (r.open <= r.close) {

                        r.high = r.close
                        r.low = r.open
                    } else {
                        r.high = r.open
                        r.low = r.close
                    }

                    return r;


                }
                ) as any[])

                if (bars == undefined || bars.length == 0) {
                    if (periodParams.firstDataRequest) {
                        var yesterday = new Date();
                        var nextTime = moment(yesterday).startOf('day').toDate().getTime() - 1000 * 60 * 60 * 24 * 1;   // current date's milliseconds - 1,000 ms * 60 s * 60 mins * 24 hrs * (# of days beyond one to go back)
                        onHistoryCallback([], {
                            noData: true,
                            nextTime: nextTime
                        });
                    } else {
                        onHistoryCallback([], {
                            noData: true,
                        });
                    }

                } else {
                    if (lastTime == undefined) {
                        lastTime = bars[bars.length - 1].time;
                        currentReselution = resolution

                    } else {
                        if (lastTime < bars[bars.length - 1].time) {
                            lastTime = bars[bars.length - 1].time;

                        }
                    }


                    onHistoryCallback(bars, {
                        noData: false,
                    });
                }



            } catch (err) {

                //let message = toApiMessage((err as RpcError).metadata);
                onErrorCallback(err);

            }

        },


        subscribeBars(symbolInfo, resolution, onTick, listenerGuid, onResetCacheNeededCallback) {
            if (myInterval != undefined) {
                clearInterval(myInterval);
            }
            myInterval = setInterval(async () => {
                try {
                    if (lastTime) {



                        let from = new Date(lastTime);
                        const wrapper = new Timestamp();
                        wrapper.fromDate(moment(from, 'YYYY-MM-DD').startOf('day').toDate())
                        req.setFrom(wrapper)


                        let to = new Date();
                        const wrapper2 = new Timestamp();
                        wrapper2.fromDate(moment(to, 'YYYY-MM-DD').endOf('day').toDate())
                        req.setTo(wrapper2)



                        var groupby = -1;
                        if (resolution == "60") {
                            groupby = 3;
                        }
                        else if (resolution == "1D") {
                            groupby = 0;

                        }
                        else if (resolution == "1M") {
                            groupby = 1;

                        }
                        else if (resolution == "12M") {
                            groupby = 2;

                        }
                        else if (resolution == "1") {
                            groupby = 4;
                        } else if (resolution == "15") {
                            groupby = 15;
                        } else if (resolution == "30") {
                            groupby = 30;
                        } else if (resolution == "240") {
                            groupby = 240;
                        } else if (resolution == "10080") {
                            groupby = 10080;
                        }

                        if (groupby != -1) {

                            req.setGroupby(groupby)
                        } else {

                        }

                        if (symbolInfo.ticker) {
                            req.setAccount(symbolInfo.ticker);
                        } else {
                            return;
                        }
                        var request = await dispatch(getItems({ body: req, headers: getHeaders() }))
                        const payload = request.payload as APIResponse<GetAccountBalanceReportResponse.AsObject>;

                        var bars = (payload?.response?.success?.reportList.map(val => {

                            let r = {

                                open: Number(val.openningbalance?.value).valueOf(),
                                high: Number(0).valueOf(),
                                low: Number(0).valueOf(),
                                close: Number(val.closingbalance?.value).valueOf(),
                                time: timestampToDate(val.date?.seconds, val.date?.nanos)?.getTime(),
                                volume: (Number(val.totalcredit?.value) + Number(val.totaldebit?.value)).valueOf(),

                            }
                            if (r.open <= r.close) {

                                r.high = r.close
                                r.low = r.open
                            } else {
                                r.high = r.open
                                r.low = r.close
                            }


                            return r;


                        }
                        ) as any[])


                        if (bars.length != 0) {
                            if (lastTime < bars[bars.length - 1].time) {
                                lastTime = bars[bars.length - 1].time;
                            }
                            onTick(bars[bars.length - 1]);
                        } else {
                        }
                        // onTick(bars[bars.length - 1]);
                    }
                } catch (err) {

                    //throw err
                    //let message = toApiMessage((err as RpcError).metadata);


                }


            }, 60 * 1000);
        },
        unsubscribeBars(listenerGuid) {

        },
    };

    const widgetOptions: ChartingLibraryWidgetOptions = {

        // BEWARE: no trailing slash is expected in feed URL
        // tslint:disable-next-line:no-any
        //datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
        datafeed: DataFeed,
        interval: defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
        container: chartContainerRef.current,
        library_path: defaultProps.libraryPath as string,
        locale: getCurrentLanguage() as LanguageCode,
        charts_storage_url: defaultProps.chartsStorageUrl,
        charts_storage_api_version: defaultProps.chartsStorageApiVersion,
        client_id: defaultProps.clientId,
        user_id: defaultProps.userId,
        fullscreen: defaultProps.fullscreen,
        autosize: defaultProps.autosize,
        studies_overrides: defaultProps.studiesOverrides,
        enabled_features: ["study_templates", "move_logo_to_main_pane", "show_symbol_logos", "show_symbol_logo_in_legend",
            "show_zoom_and_move_buttons_on_touch", "show_exchange_logos", "saveload_separate_drawings_storage"] as ChartingLibraryFeatureset[],
        disabled_features: isMobile ? disabled_features_mobile : disabled_features,
        theme: state?.isDarkModeEnabled ? "dark" : 'light',
        debug: false,
        save_load_adapter: new LocalStorageSaveLoadAdapter(),
        timezone: "Africa/Cairo",


    };


    useEffect(() => {
        req = new GetAccountBalanceReportRequest();
        reqAcc = new GetAccountRequest();
        accountsReq = new GetAccountsRequest();

        var l = searchParams.get('org');

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                accountsReq.setOrganization(wrapper)

            }
        }
        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }
        accountsReq.setNumofresults(1000)
        var symbol = searchParams.get('symbol');
        if (symbol == null) {
            navigate(-1)
            return;
        }
        reqAcc.setId(symbol)
        getItemsPromise = dispatch(getAccount({ body: reqAcc, headers: getHeaders() }))


        return () => {
            // tvWidget.remove();
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
            clearInterval(myInterval);

        };
    }, []);



    useEffect(() => {

        if (state.account) {
            widgetOptions.symbol = state.account.internalId;
            initWedgit();
        }
    }, [state.account])
    const initWedgit = () => {

        const tvWidget = new widget(widgetOptions);
        tvWidget?.onChartReady(() => {
            tvWidget.activeChart().onIntervalChanged().subscribe(null, (interval, timeframeObj) => {
                tvWidget.activeChart().setVisibleRange(
                    { from: Date.UTC(2021, 1, 12, 13, 30) / 1000 },
                    { applyDefaultRightMargin: true }
                )
            });
            tvWidget.activeChart().onSymbolChanged().subscribe(null, () => {
                tvWidget.activeChart().setVisibleRange(
                    { from: Date.UTC(2021, 1, 12, 13, 30) / 1000 },
                    { applyDefaultRightMargin: true }
                )
            });

            tvWidget.activeChart().setResolution('60' as ResolutionString)
            tvWidget.activeChart().setChartType(2);


        });


    }
    return (
        <div ref={chartContainerRef}
            className={'TVChartContainer'}
            style={{ height: props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 100px)" : "calc(100vh - 148px)" }}>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

        </div>

    );
}




