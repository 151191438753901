import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../app/Api';
import { base64ToArrayBuffer, downloadFile, formatDate, getFileExtension, timestampToDate } from '../../../../../app/Helpers';

import { TableState } from '../../../../common/Table/TableSate';
import { number } from 'yup';
import { ReportEndpointClient } from '../../../../../repository/Accountant/ReportServiceClientPb';
import { GetTreasuryBalanceReportRequest, GetTreasuryBalanceReportResponse, GetTreasuryBalanceReportReplay } from '../../../../../repository/Accountant/report_pb';
import { TreasuryEndpointClient } from '../../../../../repository/Accountant/TreasuryServiceClientPb';
import { GetTreasuryRequest, GetTreasuryResponse } from '../../../../../repository/Accountant/treasury_pb';


export interface TreasuryChartPageState {
    set: TableState
    totals: any
    message?: ApiMessage,
    treasury: any
    isChangeStateLoading: boolean,

}

const initialState: TreasuryChartPageState = {

    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    totals: undefined,
    message: undefined,
    treasury: undefined,
    isChangeStateLoading: false

}
const client = new ReportEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientAccount = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getItems = createAsyncThunk<APIResponse<GetTreasuryBalanceReportResponse.AsObject>, APIRequest<GetTreasuryBalanceReportRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/chart/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getTreasuryBalanceReport(req.body, req.headers ?? {});
                return { metadata: { grouping: req.body.getGroupby() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getTreasury = createAsyncThunk<GetTreasuryResponse.AsObject, APIRequest<GetTreasuryRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/treasuryDetails/chart/treasuries/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientAccount.getTreasury(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)




export const treasuryChartPageSlice = createSlice({
    name: 'pages/accountant/treasuryDetails/chart',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.treasury = initialState.treasury;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.totals = initialState.totals;


        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },


        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
    },
    extraReducers: (builder) => {


        builder.addCase(getTreasury.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false

            if (payload) {

                let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    name: payload.success?.name?.value ?? "",
                    code: payload.success?.code?.value ?? "",
                    nature: payload.success?.nature?.id?.value ?? undefined,
                    treasuryTypeId: payload.success?.treasurytype?.id?.value ?? "",
                    treasuryTypeName: payload.success?.treasurytype?.name?.value ?? "",
                    status: payload.success?.status?.value ?? undefined,
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    limitationId: payload.success?.limitation?.id?.value ?? "",
                    limitationName: payload.success?.limitation?.name?.value ?? "",
                    deposits: [] as any
                }



                if (payload?.success?.depositsList) {
                    let count = 0
                    payload?.success?.depositsList?.forEach(element => {
                        r.deposits.push({
                            currencyId: element?.currency?.id?.value ?? "",
                            currencyName: element?.currency?.name?.value ?? "",
                            currencyCode: element?.currency?.code?.value ?? "",
                            currencyDecimalPlaces: element?.currency?.decimalplaces?.value ?? undefined,
                            currencyForm: element?.currency?.form?.value ?? undefined,
                            currencySymbol: element?.currency?.symbol?.value ?? "",
                            isActive: element.isactive?.value ?? undefined
                        })
                        count++
                    });
                }


                state.treasury = r
            }
        })
        builder.addCase(getTreasury.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getTreasury.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(getItems.fulfilled, (state, { payload }) => {

            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })




    }
})

export const { reset, dismissMessage, setMessage, resetSet, } = treasuryChartPageSlice.actions

export default treasuryChartPageSlice.reducer


