import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IStackTokens, DialogFooter, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../common/Table/Table"
import { dismissMessage, getUserTypes, reset, resetMembers, resetUserTypes, setNumberOfResults, setSort, setSearchText, setSelectedMembers, setSelectedUserType, getMembers, setIsFilteredUserTypesSet, updateUser, addUser, setIsFilteredMembersSet, add } from "./GroupMembersFromSlice"
import { ApiMessage, getHeaders, getSession, PROFILE_LOGO_URL } from "../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { SearchField } from "../../../../common/SearchField/SearchField"
import { TableState } from "../../../../common/Table/TableSate"
import { Message } from "../../../../common/Message/Message"
import { ShareBox } from "../../../../common/ShareBox/ShareBox"
import { GetUserTypesRequest } from "../../../../../repository/UserManagement/Managers/usertype_pb"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../../common/Popup/Popup"
import { BaseFormProps, InputFormProps } from "../../../FormProps";
import { formatDate, timestampToDate } from "../../../../../app/Helpers";
import { GetAvailableProfilesRequest } from "../../../../../repository/UserManagement/Managers/group_pb";
import { AddGroupMembershipRequest, AddGroupMembershipResponse } from "../../../../../repository/UserManagement/Managers/groupmembership_pb";
import { UserTypesPage } from "../../../../pages/Management/UserTypes/UserTypesPage";
import { List } from "../../../../common/List/List";

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};
let req: GetAvailableProfilesRequest;
let addReq: AddGroupMembershipRequest;
let userTypeReq: GetUserTypesRequest;

let org: number = -1;

let getManagersPromise: any;
let getUserTypesPromise: any;
let actionPromise: any;

export const GroupMembersForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();


    const [currentAction, setCurrenctAction] = useState(0)



    const state: {
        isLoading: boolean,
        members: TableState, message: ApiMessage | undefined
        appTheme: string, isDarkModeEnabled: boolean, userTypes: TableState, searchText: string | undefined,
        filters: any[],
    } = useAppSelector((state) => {
        return {
            isLoading: state.managersGroupMembersFrom.isLoading,
            members: state.managersGroupMembersFrom.members, message: state.managersGroupMembersFrom.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled, userTypes: state.managersGroupMembersFrom.userTypes,
            searchText: state.managersGroupMembersFrom.searchText,
            filters: state.managersGroupMembersFrom.filters,
        }
    })

    const ref = useRef<HTMLDivElement>(null);

    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const statusActionConfirmationcalloutTargetId = useId('status-action-callout-target');

    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);



    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedMembers(_selection.getSelection()));
            } else {
                dispatch(setSelectedMembers([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedUserType([]))
        req.setUsertype(-1)

    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }

        if (state.userTypes.selected.length > 0) {

            req.setUsertype(Number(state.userTypes.selected.at(0)?.id))
            isFiltered = true;


        } else {
            req.setUsertype(-1)
        }


        req.setNextto(0)
        dispatch(resetMembers())
        dispatch(setIsFilteredMembersSet(isFiltered))
        getManagersPromise?.abort()
        getManagersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetAvailableProfilesRequest();
        addReq = new AddGroupMembershipRequest();
        userTypeReq = new GetUserTypesRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                addReq.setOrganization(wrapper)
                userTypeReq.setOrganization(wrapper)
            }
        }
        req.setNextto(0)
        req.setNumofresults(state.members.numberOfResults)
        req.setOrder(state.members.isDescending)
        req.setId(props.renderObject?.group?.id)
        req.setUsertype(-1)
        userTypeReq.setNumofresults(state.userTypes.numberOfResults)
        userTypeReq.setOrder(state.userTypes.isDescending)

        getManagersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))
        return () => { //clean up
            getManagersPromise?.abort();
            getUserTypesPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [

        {
            key: 'name',
            name: t("user"),
            minWidth: 20,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/management/users/" + item?.internalId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/management/users/" + item?.internalId + ((org == -1) ? "" : "?org=" + org)} ><Persona
                        text={item?.name == "" ? item?.internalId : item?.name}
                        secondaryText={item?.username}
                        size={PersonaSize.size40}
                        imageUrl={PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + item?.internalId + "/profile.jpg"}

                    /></Link>

            },
        },

        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {


                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },
    ];


    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.members.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),

                    canCheck: true,
                    checked: !state.members.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetMembers())
                        req.setOrder(false)
                        req.setNextto(0)
                        getManagersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.members.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetMembers())
                        req.setOrder(true)
                        req.setNextto(0)
                        getManagersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.members.numberOfResults,
            title: t('results') + ': ' + state.members.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.members.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.members.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.members.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];


    const userTypeSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('usersTypes'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.userTypes.hasMore && !state.userTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.userTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedUserType([state.userTypes.items.at(u)]))
            }
        },
        onGetMoreResults: () => {
            if (state.userTypes.items.length > 0 && !state.userTypes.isFetching) {
                userTypeReq.setNextto(state.userTypes.items.at(state.userTypes.items.length - 1).id)
                getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

            }
        },
        suggestions: state.userTypes.items.length > 0 ? state.userTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.userTypes.items.length == 0 ? state.userTypes.isFetching : false,
        isSearching: state.userTypes.items.length > 0 ? state.userTypes.isFetching : false,

    };
    const onUserTypeFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {
        dispatch(resetUserTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        userTypeReq.setSearch(wrapper)
        userTypeReq.setNextto(0)
        dispatch(setIsFilteredUserTypesSet(true))
        getUserTypesPromise?.abort();
        getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

        return [];
    }

    return (
        <Stack >
            <Popup isOpen={(currentAction == 1001)} title={t("usersTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <UserTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserType([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={[]}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />

            <Text block><b>{t("addMembersDesc")}</b></Text>


            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label disabled={state.isLoading}>{t("usertype")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("usertype"), }}
                                disabled={state.isLoading}
                                suggestionsHeaderText={t('usersTypes')}
                                isLoading={state.userTypes.items.length == 0 ? state.userTypes.isFetching : false}
                                isSearching={state.userTypes.items.length > 0 ? state.userTypes.isFetching : false}
                                moreSuggestionsAvailable={state.userTypes.hasMore && !state.userTypes.isFetching}
                                suggestions={state.userTypes.items.length > 0 ? state.userTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                onGetMoreResults={() => {

                                    if (state.userTypes.items.length > 0 && !state.userTypes.isFetching) {
                                        userTypeReq.setNextto(state.userTypes.items.at(state.userTypes.items.length - 1).id)
                                        getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))

                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {

                                    var u = state.userTypes.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedUserType([state.userTypes.items.at(u)]))
                                    }
                                }}
                                isPeoplePicker={false}
                                selectedItems={state.userTypes.selected.length > 0 ? state.userTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedUserType([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetUserTypes())
                                    userTypeReq.setSearch(undefined)
                                    userTypeReq.setNextto(0)
                                    dispatch(setIsFilteredUserTypesSet(false))
                                    getUserTypesPromise?.abort()
                                    getUserTypesPromise = dispatch(getUserTypes({ body: userTypeReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onUserTypeFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1001)

                                        }
                                    }]}
                            />
                        </Stack>


                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {
                                if (e.key == 2) {
                                    dispatch(setSelectedUserType([]))
                                    req.setUsertype(0)
                                }
                            });

                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.members.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="members"
                items={state.members.items}
                columns={c}
                height={"60vh"}
                width={'80vw'}
                checkboxVisibility={ CheckboxVisibility.always }

                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.members.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.members.isFetching && state.members.hasMore) {
                        const wrapper = new StringValue();
                        req.setNextto(state.members.items.at(state.members.items.length - 11)?.internalId)
                        getManagersPromise?.abort();
                        getManagersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))

                    }
                }}
                selection={_selection}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isLoading}
                enableShimmer={state.members.items.length == 0 && state.members.isFetching}
                isColumnSortingEnabled={state.members.selected.length == 0}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.members.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.members.items.length > 0 && !state.members.isFetching && state.members.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    req.setNextto(state.members.items.at(state.members.items.length - 11)?.internalId)
                                    getManagersPromise?.abort();
                                    getManagersPromise = dispatch(getMembers({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />



            <Stack horizontal reversed tokens={{ childrenGap: props.childrenGap }}>
                <PrimaryButton styles={{ root: { width: 120 } }} onClick={() => {
                    if (state.members.selected.length > 0) {
                        let ids: number[] = [];
                        state.members.selected.forEach(e => {
                         
                            if (e?.internalId) {
                                ids.push(e?.internalId)
                            }
                        })
                        addReq.setProfileid(ids[0]);
                        addReq.setGroupid(props.renderObject?.group?.id)
                        actionPromise = dispatch(add({ body: addReq, headers: getHeaders() }))
                        actionPromise.unwrap()
                            .then((res: AddGroupMembershipResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        const selected: any[] = []
                                        state.members.selected.forEach(e => {
                                            selected.push({ ...e, id: res.success?.groupmembershipid, createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)) })
                                        })
                                        props?.onSuccess(selected);
                                    }
                                }

                            })
                    }

                }
                } disabled={state.isLoading} text={state.isLoading ? undefined : t("add")} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton styles={{ root: { width: 120 } }} disabled={state.isLoading} text={t("cancel")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </Stack>
        </Stack>



    );
}
