import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, ProgressIndicator, IComboBoxOption, Separator } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks"
import { getPage } from "../../../../../app/Pages"
import { setPage } from "../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../common/Table/Table"
import { GetUsersRequest, GetUsersResponse, ResetUserPasswordRequest, UpdateUserActivityRequest, UpdateUserConfirmationStateRequest, UserSummaryInfo } from "../../../../../repository/UserManagement/Customers/user_pb"
import { dismissMessage, reset, resetCustomer, updateConfirmationStatus, updateStatus, updateUser, resetPassword, resetNewPassword, getCustomerUser, updateTwoFactor, updateUsername } from "./UserDetailsPageSlice"
import { ApiMessage, getHeaders, getSession, PROFILE_LOGO_URL } from "../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../app/Themes"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../../common/Popup/Popup"
import { UsersForm } from "../../../../forms/Customers/Users/UsersForm"
import { FormType } from "../../../../forms/FormProps"
import { GetUserRequest } from "../../../../../repository/UserManagement/Customers/user_pb"
import { Message } from "../../../../common/Message/Message"
import { ShareBox } from "../../../../common/ShareBox/ShareBox"
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields"
import { countriesAR, countriesEn, nationalitiesAR, nationalitiesEn } from "../../../../../app/Content"
import { formatDate, printComponent, normalizeKey } from "../../../../../app/Helpers"
import { TextView } from "../../../../common/DataView/TextView"
import { LinkView } from "../../../../common/DataView/LinkView"
import { ProfileView } from "../../../../common/DataView/ProfileView"
import { UsernameUpdateForm } from "../../../../forms/Customers/Users/UsernameUpdateForm"
import { AccountSecurityForm } from "../../../../forms/Customers/Users/AccountSecurityForm"
import { useReactToPrint } from "react-to-print"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetUserRequest;

let org: number = -1;

let getCustomersPromise: any;


const key = "customers/users/details"
export const UserDetailsPage: React.FunctionComponent = () => {


    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();

    const state: {
        isChangeStateLoading: boolean,
        item: any,
        isLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        newPassword: string | undefined
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.customersUserDetailsPage.isChangeStateLoading,
            item: state.customersUserDetailsPage.item, message: state.customersUserDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.customersUserDetailsPage.isLoading,
            newPassword: state.customersUserDetailsPage.newPassword
        }
    })

    const [countries, setCountries] = useState([] as IComboBoxOption[])
    const [nationalaties, setNationalaties] = useState([] as IComboBoxOption[])

    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const statusActionConfirmationcalloutTargetId = useId('status-action-callout-target');
    const confirmActionConfirmationcalloutTargetId = useId('confirm-action-callout-target');
    const twoFactorActionConfirmationcalloutTargetId = useId('twof-action-callout-target');
    const resetPasswordActionConfirmationcalloutTargetId = useId('reset-pass-action-callout-target');

    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});
    const getActionId = (action: number): string => {
        if (action == 1)
            return statusActionConfirmationcalloutTargetId;
        else if (action == 2) {
            return confirmActionConfirmationcalloutTargetId;
        } else if (action == 3) {
            return twoFactorActionConfirmationcalloutTargetId;
        } else if (action == 4) {
            return resetPasswordActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    useEffect(() => {

        if (localStorage.getItem("lang") == "en") {
            setCountries(countriesEn)
            setNationalaties(nationalitiesEn)

        } else {
            setCountries(countriesAR)
            setNationalaties(nationalitiesAR)

        }
        req = new GetUserRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                //  const wrapper = new Int32Value();
                //  wrapper.setValue(org);
                //  req.setOrganization(wrapper)
            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getCustomersPromise = dispatch(getCustomerUser({ body: req, headers: getHeaders() }))
        } else {
            navigate(-1)
        }
        return () => { //clean up
            getCustomersPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t("back"),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t("refresh"),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetCustomer())
                getCustomersPromise = dispatch(getCustomerUser({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'edit',
            text: t("edit"),
            title: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            subMenuProps: {
                items: [{
                    key: 'editInfo',
                    text: t('editInfo'),
                    title: t("editInfo"),
                    iconProps: { iconName: 'EditContact', styles: { root: { fontWeight: "bold" } } },

                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            setCurrenctAction(101)
                        }

                    }
                },
                {
                    key: 'editUsername',
                    text: t("editUsername"),
                    title: t("editUsername"),
                    disabled: (state.item?.isProtected),

                    iconProps: { iconName: 'Accounts', styles: { root: { fontWeight: "bold" } } },
                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            setCurrenctAction(102)
                        }
                    }
                },
                {
                    key: 'twoFactor',
                    text: t("twoFactor"),
                    title: t("twoFactor"),
                    disabled: (state.item?.isProtected),

                    iconProps: { iconName: 'Lock', styles: { root: { fontWeight: "bold" } } },
                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            setCurrenctAction(103)
                        }
                    }
                }
                ]
            },

        },
        {
            key: 'status',
            text: (state.item?.isActive) ? t("deactivateAccount") : t("activateAccount"),
            title: (state.item?.isActive) ? t("deactivateAccount") : t("activateAccount"),
            iconProps: { id: statusActionConfirmationcalloutTargetId, iconName: (state.item?.isActive) ? "UserRemove" : "UserFollowed", styles: { root: { fontWeight: (state.item?.isActive) ? undefined : "bold" } } },
            disabled: (state.item?.isProtected),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'confirmation',
            text: (state.item?.isConfirmed) ? t("identityUnconfirm") : t("identityConfirm"),
            title: (state.item?.isConfirmed) ? t("identityUnconfirm") : t("identityConfirm"),
            //disabled: (state.item?.isProtected),

            iconProps: { id: confirmActionConfirmationcalloutTargetId, iconName: (state.item?.isConfirmed) ? "FavoriteStar" : 'FavoriteStarFill' },
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }

        },

        {
            key: 'resetPassword',
            text: t("resetPassword"),
            title: t("resetPassword"),
            disabled: (state.item?.isProtected),

            iconProps: { id: resetPasswordActionConfirmationcalloutTargetId, iconName: "PasswordField", styles: { root: { fontWeight: "bold" } } },
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 4 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 4 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 4 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(4)
                    toggleisActionConfirmationCalloutVisible()
                }
            },
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];






    return (
        <Stack>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareProfile")} value={`https://${window.location.host}/customers/users/${state.item?.internalId}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}

            {state.newPassword ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${resetPasswordActionConfirmationcalloutTargetId}`}
                    onDismiss={() => { dispatch(resetNewPassword()) }}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={() => { dispatch(resetNewPassword()) }} isSecret={true} title={t("newPasswordTitle")} value={state.newPassword}></ShareBox>
                </Callout>
            ) : null}
            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? (state.item?.isActive) ? t("deactivateAccount") : t("activateAccount") : currentAction == 2 ? state.item?.isConfirmed ? t("identityUnconfirm") : t("identityConfirm") : currentAction == 4 ? t("resetPassword") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? (state.item?.isActive) ? t("deactivateAccountDesc") : t("activateAccountDesc") : currentAction == 2 ? state.item?.isConfirmed ? t("identityUnconfirmDesc") : t("identityConfirmDesc") : currentAction == 4 ? t("resetPasswordDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()

                                    if (currentAction == 1) {
                                        let req = new UpdateUserActivityRequest();
                                        req.setProfileid(state.item?.internalId)
                                        req.setIsaccountactive(!state.item?.isActive)

                                        dispatch(updateStatus({ body: req, headers: getHeaders() }))

                                    } else if (currentAction == 2) {
                                        let req = new UpdateUserConfirmationStateRequest();
                                        req.setProfileid(state.item?.internalId)
                                        req.setIsaccountconfirmed(!state.item?.isConfirmed)
                                        dispatch(updateConfirmationStatus({ body: req, headers: getHeaders() }))

                                    } else if (currentAction == 4) {
                                        let req = new ResetUserPasswordRequest();
                                        req.setProfileid(state.item?.internalId)
                                        dispatch(resetPassword({ body: req, headers: getHeaders() }))
                                    }

                                }} text={(currentAction == 1 ? (state.item?.isActive) ? t("deactivateAccount") : t("activateAccount") : currentAction == 2 ? state.item?.isConfirmed ? t("identityUnconfirm") : t("identityConfirm") : currentAction == 4 ? t("reset") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}
            <Popup isOpen={(currentAction == 101)} title={t("editCustomer")} onDismiss={() => { setCurrenctAction(0) }} isBlocking={true}>
                <UsersForm childrenGap={10} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateUser(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 102)} title={t("editCustomer")} onDismiss={() => { setCurrenctAction(0) }} isBlocking={true}>
                <UsernameUpdateForm childrenGap={10} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateUsername(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 103)} title={t("twoFactor")} onDismiss={() => { setCurrenctAction(0) }} isBlocking={true}>
                <AccountSecurityForm childrenGap={10} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateTwoFactor(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null : <div ref={ref}>
                    <CommandBar
                        items={_selectionItems}

                    />
                    <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>

                            <TextView label={t("id")} value={state.item?.internalId} />


                            <TextView label={t("createdDate")} value={state.item?.createdDate} />

                            {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                imageUrl={PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + state.item?.addedById + "/profile.jpg"}

                                size={PersonaSize.size40} />

                                : undefined}

                        </Stack>


                        <Separator alignContent="start"><b>{t("contactInfo")}</b></Separator>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>

                            <TextView label={t("firstname")} value={state.item?.firstname} />
                            <TextView label={t("secondName")} value={state.item?.lastname} />
                            <TextView label={t("identity")} iconName={!state.item?.isConfirmed ? "FavoriteStar" : 'FavoriteStarFill'} value={state.item?.isConfirmed ? t("confirmed") : t("unconfirmed")} />


                        </Stack>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>

                            <TextView label={t("nationality")} value={nationalaties.find(e => e.key === state.item?.nationality?.key)?.text ?? ""} />
                            <TextView label={t("documentId")} value={state.item?.documentId} />
                        </Stack>
                        <TextView label={t("birthdate")} value={state.item?.birthdate} />
                        {state.item?.gender != undefined ? <TextView label={t("gender")} value={state.item?.gender == true ? t("male") : t("female")} /> : undefined}

                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <TextView label={t("addressCountry")} value={countries.find(e => e.key === state.item?.addressCountry.key)?.text ?? ""} />
                            <TextView label={t("addressCity")} value={state.item?.addressCity} />

                        </Stack>
                        <TextView label={t("addressFirstLine")} value={state.item?.addressFirstLine} />
                        <TextView label={t("addressSecondLine")} value={state.item?.addressSecondLine} />
                        <TextView label={t("addressPostalCode")} value={state.item?.addressPostalCode} />



                        <Stack horizontal tokens={{ childrenGap: 10 }}>

                            <Stack>
                                {state.item?.emailAddresses.map((e: DynamicField, index: number) => {
                                    return <TextView key={e.key} label={t("emailAddresses")} value={e?.value} />
                                })}
                            </Stack>

                            <Stack>
                                {state.item?.phoneNumbers.map((e: DynamicField, index: number) => {
                                    return <TextView key={e.key} label={t("phonenumbers")} value={e?.value} />

                                })}
                            </Stack>
                        </Stack>



                        <Separator alignContent="start"><b>{t("accountInfo")}</b></Separator>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <TextView label={t("username")} value={state.item?.username} />
                            <TextView label={t("status")} iconName={state.item?.isActive != undefined ? state.item?.isActive ? "Accept" : "Cancel" : undefined} value={state.item?.isActive != undefined ? state.item?.isActive ? t("active") : t("inactive") : ""} />
                            <TextView label={t("protection")} iconName={state.item?.isProtected != undefined ? state.item?.isProtected == true ? "LockSolid" :
                                "Unlock" : "Unlock"}
                                value={state.item?.isProtected != undefined ? state.item?.isProtected == true ? t("enabled") :
                                    undefined : undefined}
                            />
                        </Stack>

                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <LinkView label={t("subscription")} value={state.item?.role == "" ? state.item?.roleId : state.item?.role}
                                url={"/customers/roles/" + state.item?.roleId + ((org == -1) ? "" : "?org=" + org)} />

                            <LinkView label={t("clientType")} value={state.item?.usertype == "" ? state.item?.usertypeId : state.item?.usertype}
                                url={"/customers/user-types/" + state.item?.usertypeId + ((org == -1) ? "" : "?org=" + org)} />

                        </Stack>

                        <TextView label={t("lastLogin")} value={state.item?.lastlogin} />



                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <TextView label={t("email")} value={state.item?.primaryEmail} />
                            <TextView label={t("phonenumber")} value={state.item?.primaryPhoneNumber} />
                        </Stack>

                        <Separator alignContent="start"><b>{t("otherInfo")}</b></Separator>

                        {state.item?.extraFields.map((e: DynamicField, index: number) => {
                            return <Stack key={e.key}>
                                <TextView label={e.label ?? ""} value={e?.value} />

                            </Stack>
                        })}



                    </Stack>
                </div>


            }

        </Stack>

    );
}




