import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, TooltipHost, DatePicker, DayOfWeek, defaultDatePickerStrings, DialogFooter, CheckboxVisibility, ConstrainMode } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, getItems, getClinetUsers, resetUsers, setFromFilterOption, setIsFilteredOwnersSet, setOrderTypeFilterOption, setSelectedUserItems, setStatusFilterOption, setToFilterOption, setTradeActionFilterOption, setSelectedCurrencyPairItems, getCurrencyPairs, resetCurrencyPairs, setIsFilteredCurrencyPairsSet, cancelOrder } from "./OrdersPageSlice"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../app/Themes"
import { SearchField } from "../../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, printComponent, normalizeKey, printOrder } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { DeleteCurrencyPairRequest, GetCurrencyPairsRequest, UpdateCurrencyPairActivityRequest } from "../../../../repository/Trading/currency_pair_pb"
import { CurrencyPairsForm } from "../../../forms/Trading/CurrencyPairs/CurrencyPairsForm"
import { CancelRequest, GetOrdersRequest } from "../../../../repository/Trading/order_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import moment from "moment"
import { OrderAction, OrderStatus, OrderType } from "../../../../app/Enums"
import { OrdersForm } from "../../../forms/Trading/Orders/OrdersForm"
import { ExternalOrdersForm } from "../../../forms/Trading/Orders/ExternalOrder/ExternalOrdersForm"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"
import { List } from "../../../common/List/List"
import { CurrencyPairsPage } from "../CurrencyPairs/CurrencyPairsPage"
import { UsersPage } from "../../Customers/Users/UsersPage"
import { PlaceOrderForm } from "../../../forms/Trading/Orders/PlaceOrder/PlaceOrderForm"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetOrdersRequest;
let clintsReq: GetUsersRequest;
let currecyPairsReq: GetCurrencyPairsRequest;

let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

const key = "trading/orders"
export const OrdersPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        fromFilterOption: { value: string, text: string }
        filters: any[],
        toFilterOption: { value: string, text: string }
        statusFilterOption: { key: number, text: string }
        clients: TableState,
        currencyPairs: TableState,
        tradeActionFilterOption: { key: number, text: string },
        orderTypeFilterOption: { key: number, text: string },


    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.ordersPage.isChangeStateLoading,
            set: state.ordersPage.set,
            message: state.ordersPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.ordersPage.searchText,
            clients: state.ordersPage.clients,
            currencyPairs: state.ordersPage.currencyPairs,
            fromFilterOption: state.ordersPage.fromFilterOption,
            filters: state.ordersPage.filters,
            toFilterOption: state.ordersPage.toFilterOption,
            orderTypeFilterOption: state.ordersPage.orderTypeFilterOption,
            statusFilterOption: state.ordersPage.statusFilterOption,
            tradeActionFilterOption: state.ordersPage.tradeActionFilterOption


        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeTreasuryActionConfirmationcalloutTargetId = useId('close-treasury-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const tradeActioncalloutTargetId = useId('trade-action-callout-target');

    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});



    const printReceipt = () => {
        return printOrder(state.set.selected?.at(0), t)
    };

    const handlePrint2 = useReactToPrint({});
    const getActionId = (action: number): string => {
        if (action == 1) {
            return closeTreasuryActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedCurrencyPairItems([]))
        req.setCurrencypair(undefined)
        dispatch(setSelectedUserItems([]))
        req.setClient(undefined)
        dispatch(setStatusFilterOption({ key: -1, text: "" }))
        req.setStatus(undefined)
        dispatch(setOrderTypeFilterOption({ key: 0, text: "" }))
        req.setOrdertype(undefined)
        dispatch(setTradeActionFilterOption({ key: 0, text: "" }))
        req.setTradeaction(undefined)
        dispatch(setFromFilterOption({ value: "", text: "" }))
        req.setFrom(undefined)
        dispatch(setToFilterOption({ value: "", text: "" }))
        req.setTo(undefined)
    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }


        if (state.clients.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.clients.selected.at(0)?.internalId));
            req.setClient(wrapper)
            isFiltered = true;
        } else {
            req.setClient(undefined)
        }

        if (state.currencyPairs.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.currencyPairs.selected.at(0)?.id));
            req.setCurrencypair(wrapper)
            isFiltered = true;
        } else {
            req.setCurrencypair(undefined)
        }

        if (state.tradeActionFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.tradeActionFilterOption.key);
            req.setTradeaction(wrapper)
            isFiltered = true;

        } else {
            req.setTradeaction(undefined)
        }

        if (state.orderTypeFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.orderTypeFilterOption.key);
            req.setOrdertype(wrapper)
            isFiltered = true;

        } else {
            req.setOrdertype(undefined)
        }

        if (state.statusFilterOption.key != -1) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.statusFilterOption.key);
            req.setStatus(wrapper)
            isFiltered = true;

        } else {
            req.setStatus(undefined)
        }
        if (state.fromFilterOption && state.fromFilterOption.value.trim().length > 0) {
            const wrapper = new Timestamp();

            wrapper.fromDate(moment(state.fromFilterOption.value, 'YYYY-MM-DD').startOf('day').toDate())
            req.setFrom(wrapper)
            isFiltered = true;

        } else {
            req.setFrom(undefined)
        }

        if (state.toFilterOption && state.toFilterOption.value.trim().length > 0) {
            const wrapper = new Timestamp();
            wrapper.fromDate(moment(state.toFilterOption.value, 'YYYY-MM-DD').endOf('day').toDate())
            req.setTo(wrapper)
            isFiltered = true;

        } else {
            req.setTo(undefined)
        }

        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {

        req = new GetOrdersRequest();
        clintsReq = new GetUsersRequest();
        currecyPairsReq = new GetCurrencyPairsRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                clintsReq.setOrganization(wrapper)
                currecyPairsReq.setOrganization(wrapper)
            }
        }
        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)

        clintsReq.setNumofresults(state.clients.numberOfResults)
        clintsReq.setOrder(state.clients.isDescending)

        currecyPairsReq.setNumofresults(state.currencyPairs.numberOfResults)
        currecyPairsReq.setOrder(state.currencyPairs.isDescending)

        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))


        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [
        {
            key: 'id',
            name: t("id"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/orders/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/orders/" + item?.id + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.id}</Link >;
            },
        },
        {
            key: 'currencyPairId',
            name: t("pair"),
            minWidth: 50,
            maxWidth: 340,
            isRowHeader: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.currencyPairId != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/currency-pairs/" + item?.currencyPairId + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/currency-pairs/" + item?.currencyPairId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}><Persona
                            text={item?.currencyPairName}
                            secondaryText={(item?.currencyPairBaseCurrencyCode == "" ? item?.currencyPairBaseCurrencyId : item?.currencyPairBaseCurrencyCode) + "-" + (item?.currencyPairQuoteCurrencyCode == "" ? item?.currencyPairQuoteCurrencyId : item?.currencyPairQuoteCurrencyCode)}
                            imageInitials={item?.currencyPairBaseCurrencySymbol + "/" + item?.currencyPairQuoteCurrencySymbol}
                            size={PersonaSize.size40}

                        /></Link>;



                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'orderType',
            name: t("orderType"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.orderType != undefined)
                    return <Text>{item?.orderType == OrderType.MARKET_ORDER ? t("marketOrder") : item?.orderType == OrderType.LIMIT_ORDER ? t("limitOrderInternal") : item?.orderType == OrderType.LIMIT_ORDER_External ? t("limitOrderExternal") : item?.orderType == OrderType.ALL_OR_NONE_ORDER ? t("AON") : ""}</Text>;
            },
        },
        {
            key: 'tradeAction',
            name: t("side"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                if (item?.tradeAction != undefined)
                    return <Text styles={{ root: { color: item?.tradeAction == OrderAction.BUY ? "#008000" : "#FF0000" } }}>{item?.tradeAction == OrderAction.BUY ? t("buy") : t("sell")}</Text>;
            },
        },

        {
            key: 'itemPrice',
            name: t("price"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.itemPrice != undefined)
                    return <Text>{currencyFormatter(item?.itemPrice, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;


            },
        },

        {
            key: 'itemQuantity',
            name: t("amount"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.itemPrice != undefined)
                    return <Text>{currencyFormatter(item?.itemQuantity, { significantDigits: item?.currencyPairBaseCurrencyDecimalPlaces ?? 9, symbol: item?.currencyPairBaseCurrencySymbol })}</Text>;



            },
        },

        {
            key: 'total',
            name: t("total"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.total != undefined)
                    return <Text>{currencyFormatter(item?.total, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;


            },
        },
        {
            key: 'filled',
            name: t("filledAmount"),
            minWidth: 50,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.filled != undefined)
                    return <TooltipHost content={"%"+((item?.filled * 100) / item?.total).toFixed(2)}>
                        <Text>{currencyFormatter(item?.filled, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol }) + " / " + currencyFormatter(item?.quantityFilled, { significantDigits: item?.currencyPairBaseCurrencyDecimalPlaces ?? 9, symbol: item?.currencyPairBaseCurrencySymbol })}</Text>
                    </TooltipHost>

            },
        },


        {
            key: 'status',
            name: t("status"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.status != undefined)
                    return <Text>{item?.status == OrderStatus.OPEN ? t("open") : item?.status == OrderStatus.FILLD ? t("filled") : item?.status == OrderStatus.NEW ? t("new") : item?.status == OrderStatus.CANCELED ? t("cancelled") : item?.status == OrderStatus.NEW ? t("new") : ""}</Text>;
            },
        },
        {
            key: 'clientName',
            name: t("client"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/customers/users/" + item?.clientId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/users/" + item?.clientId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined} ><Persona
                        text={item?.clientName == "" ? item?.clientId : item?.clientName}
                        size={PersonaSize.size40}
                    /></Link>

            },
        },
        {
            key: 'createdDate',
            name: t("date"),
            minWidth: 20,
            maxWidth: 160,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItemsOpenOrder: ICommandBarItemProps[] = [
        {
            key: 'trade',
            text: state.set.selected?.at(0)?.tradeAction == OrderAction.SELL ? t("buy") : t("sell"),
            iconProps: { iconName: 'Remote' },
            title: state.set.selected?.at(0)?.tradeAction == OrderAction.SELL ? t("buy") : t("sell"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(102)
                }
            }
        },
        {
            key: 'status',
            text: t("cancelOrder"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: "Cancel", styles: { root: { fontWeight: "bold" } } },
            title: t("cancelOrder"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'editInfo',
            text: t('printReceipt'),
            title: t("printReceipt"),
            iconProps: { iconName: 'M365InvoicingLogo' },

            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint2(printReceipt)
                }

            }
        }


    ];


    const _selectionItems: ICommandBarItemProps[] = [

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'editInfo',
            text: t('printReceipt'),
            title: t("printReceipt"),
            iconProps: { iconName: 'M365InvoicingLogo' },

            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint2(printReceipt)
                }

            }
        }


    ];
    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('add'),
            title: t('add'),
            iconProps: { iconName: 'Add' },
            subMenuProps: {
                items: [
                    {
                        key: 'placeOrder',
                        text: t('placeOrder'),
                        title: t('placeOrder'),
                        iconProps: { iconName: 'Add' },
                        onClick: () => {
                            //  navigate("add")
                            setCurrenctAction(102)
                        }
                    },
                    {
                        key: 'newItem',
                        text: t('internalTradingOrder'),
                        title: t('internalTradingOrder'),
                        iconProps: { iconName: 'Remote' },
                        onClick: () => {
                            //  navigate("add")
                            setCurrenctAction(100)
                        }
                    },

                    {
                        key: 'externalOrder',
                        text: t('externalTradingOrder'),
                        title: t('externalTradingOrder'),
                        iconProps: { iconName: 'Switch' },
                        onClick: () => {
                            //  navigate("add")
                            setCurrenctAction(101)
                        }
                    },
                ]
            }

        },


        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];




    const onPairsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencyPairs())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currecyPairsReq.setSearch(wrapper)
        currecyPairsReq.setNextto(undefined)
        dispatch(setIsFilteredCurrencyPairsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
        return [];

    }



    const onOwnersFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetUsers())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        clintsReq.setSearch(wrapper)
        clintsReq.setNextto(undefined)
        dispatch(setIsFilteredOwnersSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
        return [];

    }



    return (
        <Stack>

            <Popup isOpen={(currentAction == 100)} title={t("internalTradingOrder")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <OrdersForm childrenGap={10} maxWidth={700} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 101)} title={t("externalTradingOrder")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <ExternalOrdersForm childrenGap={10} maxWidth={700} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 102)} title={t("placeOrder")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <PlaceOrderForm childrenGap={10} maxWidth={700} renderObject={{ matchOrder: state.set.selected?.at(0) }} type={FormType.ADD} onSuccess={(e) => {
                    if (e.matchOrder) {
                        /*
                             quantityFilled: val.quantityfilled?.value,
                             remainingFilled: val.remainingfilled?.value,
                             remainingQuantityFilled: val.remainingquantityfilled?.value,
                             filled: val.filled?.value ?? undefined,
     
                        */
                        dispatch(updateItem(e.matchOrder))

                    }

                    setCurrenctAction(0)
                    dispatch(addItem(e.order))
                    _selection.setAllSelected(false)



                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 1000)} title={t("pair")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrencyPairsPage isSelection={true} onItemSelected={(e) => {

                    dispatch(setSelectedCurrencyPairItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>


            <Popup isOpen={(currentAction == 1001)} title={t("clients")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : state.set.selected.at(0)?.status == OrderStatus.OPEN ? _selectionItemsOpenOrder : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareOrder")} value={`https://${window.location.host}/orders/${state.set.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}

            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 2 ? t("cancelOrder") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 2 ? t("cancelOrderDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.set.selected.length > 0) {
                                        if (currentAction == 2) {
                                            let req = new CancelRequest();
                                            req.setId(state.set.selected.at(0)?.id)

                                            actionPromise?.abort();
                                            actionPromise = dispatch(cancelOrder({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })



                                        }
                                    }
                                }} text={(currentAction == 2 ? t("cancelOrder") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack horizontalAlign="space-between" tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("pair")}</Label>

                            <List
                                isPeoplePicker={true}
                                suggestionsHeaderText={t('pair')}
                                styles={{ root: { width: 268 } }}
                                isLoading={state.currencyPairs.items.length == 0 ? state.currencyPairs.isFetching : false}
                                isSearching={state.currencyPairs.items.length > 0 ? state.currencyPairs.isFetching : false}
                                moreSuggestionsAvailable={state.currencyPairs.hasMore && !state.currencyPairs.isFetching}
                                suggestions={state.currencyPairs.items.length > 0 ? state.currencyPairs.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.currencyPairs.items.length > 0 && !state.currencyPairs.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.currencyPairs.items.at(state.currencyPairs.items.length - 1).id);
                                        currecyPairsReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.currencyPairs.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedCurrencyPairItems([state.currencyPairs.items.at(u)]))
                                    }

                                }}
                                inputProps={{ placeholder: t("pair") }}

                                selectedItems={state.currencyPairs.selected.length > 0 ? state.currencyPairs.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedCurrencyPairItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetCurrencyPairs())
                                    currecyPairsReq.setSearch(undefined)
                                    currecyPairsReq.setNextto(undefined)
                                    dispatch(setIsFilteredCurrencyPairsSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onPairsFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000)

                                        }
                                    }]}

                            />

                        </Stack>


                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("client")}</Label>
                            <List
                                isPeoplePicker={true}
                                suggestionsHeaderText={t('clients')}
                                styles={{ root: { width: 268 } }}
                                isLoading={state.clients.items.length == 0 ? state.clients.isFetching : false}
                                isSearching={state.clients.items.length > 0 ? state.clients.isFetching : false}
                                moreSuggestionsAvailable={state.clients.hasMore && !state.clients.isFetching}
                                suggestions={state.clients.items.length > 0 ? state.clients.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource + "" + e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.clients.items.length > 0 && !state.clients.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.clients.items.at(state.clients.items.length - 1).internalId);
                                        clintsReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.clients.items.findIndex(e => e.internalId == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedUserItems([state.clients.items.at(u)]))
                                    }

                                }}
                                inputProps={{ placeholder: t("client") }}

                                selectedItems={state.clients.selected.length > 0 ? state.clients.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource + "" + e?.imagePath : undefined } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedUserItems([]))
                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetUsers())
                                    clintsReq.setSearch(undefined)
                                    clintsReq.setNextto(undefined)
                                    dispatch(setIsFilteredOwnersSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                    return []


                                }}
                                onFilterChanged={onOwnersFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1001)

                                        }
                                    }]}
                            />


                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("orderType")}</Label>
                            <Dropdown
                                selectedKey={state.orderTypeFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 2, text: t("limitOrderInternal") }, { key: 3, text: t("limitOrderExternal") }, { key: 4, text: t("AON") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setOrderTypeFilterOption({ key: Number(item?.key), text: t("orderType") + ":" + item?.text }))

                                })}

                            />
                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("side")}</Label>
                            <Dropdown
                                selectedKey={state.tradeActionFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("buy") }, { key: 2, text: t("sell") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setTradeActionFilterOption({ key: Number(item?.key), text: t("side") + ":" + item?.text }))

                                })}

                            />
                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("status")}</Label>
                            <Dropdown
                                selectedKey={state.statusFilterOption.key}
                                options={[{ key: -1, text: t("all") }, { key: 0, text: t("new") }, { key: 1, text: t("open") }, { key: 2, text: t("filled") }, { key: 3, text: t("cancelled") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setStatusFilterOption({ key: Number(item?.key), text: t("status") + ":" + item?.text }))

                                })}

                            />
                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("from")}</Label>
                            <DatePicker

                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder={t("from")}
                                value={state.fromFilterOption.value != "" ? moment(state.fromFilterOption.value, 'YYYY-MM-DD').toDate() : undefined}
                                styles={{ root: { width: 300 } }}
                                ariaLabel={t("from")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').toDate();
                                }}
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date)
                                        dispatch(setFromFilterOption({ value: formatDate(date, 'YYYY-MM-DD'), text: t("from") + ":" + formatDate(date, 'YYYY-MM-DD') }))

                                }}

                            />

                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("to")}</Label>
                            <DatePicker

                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder={t("to")}
                                value={state.toFilterOption.value != "" ? moment(state.toFilterOption.value, 'YYYY-MM-DD').toDate() : undefined}
                                styles={{ root: { width: 300 } }}
                                ariaLabel={t("to")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').toDate();
                                }}
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date)
                                        dispatch(setToFilterOption({ value: formatDate(date, 'YYYY-MM-DD'), text: t("to") + ":" + formatDate(date, 'YYYY-MM-DD') }))

                                }}

                            />

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {


                                if (e.key == 1) {
                                    dispatch(setSelectedCurrencyPairItems([]))
                                    req.setCurrencypair(undefined)
                                }

                                if (e.key == 2) {
                                    dispatch(setSelectedUserItems([]))
                                    req.setClient(undefined)
                                }

                                if (e.key == 5) {
                                    dispatch(setStatusFilterOption({ key: -1, text: "" }))
                                    req.setStatus(undefined)
                                }
                                if (e.key == 3) {
                                    dispatch(setOrderTypeFilterOption({ key: 0, text: "" }))
                                    req.setOrdertype(undefined)
                                }

                                if (e.key == 4) {
                                    dispatch(setTradeActionFilterOption({ key: 0, text: "" }))
                                    req.setTradeaction(undefined)
                                }

                                if (e.key == 6) {
                                    dispatch(setFromFilterOption({ value: "", text: "" }))
                                    req.setFrom(undefined)
                                }
                                if (e.key == 7) {
                                    dispatch(setToFilterOption({ value: "", text: "" }))
                                    req.setTo(undefined)
                                }

                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>


            <Table
                setKey="orders"
                items={state.set.items}
                columns={c}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                compRef={ref}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 176px)" : "calc(100vh - 224px)"}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                selection={_selection}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                isColumnSortingEnabled={state.set.selected.length == 0}

                constrainMode={ConstrainMode.unconstrained}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.fixedColumns}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />
            {props.isSelection ? <DialogFooter>
                <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                    if (props.onItemSelected && state.set.selected.length > 0) {
                        props.onItemSelected(state.set.selected.at(0))
                    }
                }} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </DialogFooter> : undefined}
        </Stack>



    );
}




