import { ThemeProvider, initializeIcons } from "@fluentui/react"
import { BrowserRouter, Navigate, NavigateFunction, NavigateOptions, Route, Routes, useLocation } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "./app/Hooks"
import { getPage } from "./app/Pages"
import { AppTheme, getMainTheme, ThemeManager } from "./app/Themes"
import { MasterLayout } from "./components/layouts/Master/MasterLayout"
import { Login } from "./components/layouts/Login/LoginLayout"
import { useContext, useEffect } from "react"
import { clearSession, isLoggedIn } from "./app/Api"
import { UsersPage } from "./components/pages/Management/Users/UsersPage"
import { RolesPage } from "./components/pages/Management/Roles/RolesPage"
import { UserTypesPage } from "./components/pages/Management/UserTypes/UserTypesPage"
import { PrivilegesPage } from "./components/pages/Management/Privileges/PrivilegesPage"
import { GroupsPage } from "./components/pages/Management/Groups/GroupsPage"
import { OrganizationsPage } from "./components/pages/Organizations/OrganizationsPage"
import { UsersEntryPage } from "./components/pages/Management/Users/UsersEntryPage/UsersEntryPage"
import { UserTypeDetailsPage } from "./components/pages/Management/UserTypes/UserTypeDetailsPage/UserTypeDetailsPage"
import { UserDetailsPage } from "./components/pages/Management/Users/UserDetailsPage/UserDetailsPage"
import { RoleDetailsPage } from "./components/pages/Management/Roles/RoleDetailsPage/RoleDetailsPage"
import { GroupDetailsPage } from "./components/pages/Management/Groups/GroupDetailsPage/GroupDetailsPage"
import { PrivilegeDetailsPage } from "./components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDetailsPage"
import { OrganizationDetailsPage } from "./components/pages/Organizations/OrganizationDetailsPage/OrganizationDetailsPage"
import { PrivilegeDataDetailsPage } from "./components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/PrivilegeDataDetailsPage"
import { ProfilePage } from "./components/pages/Profile/ProfilePage"


import { UsersPage as CustomerUsersPage } from "./components/pages/Customers/Users/UsersPage"
import { RolesPage as CustomerRolesPage } from "./components/pages/Customers/Roles/RolesPage"
import { UserTypesPage as CustomerUserTypesPage } from "./components/pages/Customers/UserTypes/UserTypesPage"
import { PrivilegesPage as CustomerPrivilegesPage } from "./components/pages/Customers/Privileges/PrivilegesPage"
import { GroupsPage as CustomerGroupsPage } from "./components/pages/Customers/Groups/GroupsPage"
import { UserTypeDetailsPage as CustomerUserTypeDetailsPage } from "./components/pages/Customers/UserTypes/UserTypeDetailsPage/UserTypeDetailsPage"
import { UserDetailsPage as CustomerUserDetailsPage } from "./components/pages/Customers/Users/UserDetailsPage/UserDetailsPage"
import { RoleDetailsPage as CustomerRoleDetailsPage } from "./components/pages/Customers/Roles/RoleDetailsPage/RoleDetailsPage"
import { GroupDetailsPage as CustomerGroupDetailsPage } from "./components/pages/Customers/Groups/GroupDetailsPage/GroupDetailsPage"
import { PrivilegeDetailsPage as CustomerPrivilegeDetailsPage } from "./components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDetailsPage"
import { PrivilegeDataDetailsPage as CustomerPrivilegeDataDetailsPage } from "./components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/PrivilegeDataDetailsPage"
import { GroupMemberDetailsPage as CustomerGroupMemberDetailsPage } from "./components/pages/Customers/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPage"
import { RolePrivilegeDetailsPage as CustomerRolePrivilegeDetailsPage } from "./components/pages/Customers/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegeDetailsPage/RolePrivilegeDetailsPage"
import { DataRestrictionDetailsPage as CustomerDataRestrictionDetailsPage } from "./components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionDetailsPage/DataRestrictionDetailsPage"


import { AccountTypesPage } from "./components/pages/Accountant/AccountTypes/AccountTypesPage"
import { AccountTypeDetailsPage } from "./components/pages/Accountant/AccountTypes/AccountTypeDetailsPage/AccountTypeDetailsPage"
import { CurrenciesPage } from "./components/pages/Accountant/Currencies/CurrenciesPage"
import { CurrencyDetailsPage } from "./components/pages/Accountant/Currencies/CurrencyDetailsPage/CurrencyDetailsPage"
import { FeesPage } from "./components/pages/Accountant/Fees/FeesPage"
import { FeeDetailsPage } from "./components/pages/Accountant/Fees/FeeDetailsPage/FeeDetailsPage"
import { TreasuryTypesPage } from "./components/pages/Accountant/TreasuryTypes/TreasuryTypesPage"
import { TreasuryTypeDetailsPage } from "./components/pages/Accountant/TreasuryTypes/TreasuryTypeDetailsPage/TreasuryTypeDetailsPage"
import { AccountTypeRuleDetailsPage } from "./components/pages/Accountant/AccountTypes/AccountTypeDetailsPage/AccountTypeRulesPage/AccountTypeRuleDetailsPage/AccountTypeRuleDetailsPage"
import { TreasuryTypeRuleDetailsPage } from "./components/pages/Accountant/TreasuryTypes/TreasuryTypeDetailsPage/TreasuryTypeRulesPage/TreasuryTypeRuleDetailsPage/TreasuryTypeRuleDetailsPage"
import { LimitationsPage } from "./components/pages/Accountant/Limitations/LimitationsPage"
import { LimitationDetailsPage } from "./components/pages/Accountant/Limitations/LimitationDetailsPage/LimitationDetailsPage"
import { AccountsPage } from "./components/pages/Accountant/Accounts/AccountsPage"
import { AccountDetailsPage } from "./components/pages/Accountant/Accounts/AccountDetailsPage/AccountDetailsPage"
import { TreasuriesPage } from "./components/pages/Accountant/Treasuries/TreasuriesPage"
import { TreasuryDetailsPage } from "./components/pages/Accountant/Treasuries/TreasuryDetailsPage/TreasuryDetailsPage"
import { TreasuryTransactionDetailsPage } from "./components/pages/Accountant/Treasuries/TreasuryDetailsPage/TreasuryStatementPage/TreasuryStatementDetailsPage/TreasuryStatementDetailsPage"
import { AccountTransactionDetailsPage } from "./components/pages/Accountant/Accounts/AccountDetailsPage/AccountStatementPage/AccountStatementDetailsPage/AccountStatementDetailsPage"
import { ReservedBalancesPage } from "./components/pages/Accountant/ReservedBalances/ReservedBalancesPage"
import { ReservedBalanceDetailsPage } from "./components/pages/Accountant/ReservedBalances/ReservedBalanceDetailsPage/ReservedBalanceDetailsPage"
import { CurrencyPairsPage } from "./components/pages/Trading/CurrencyPairs/CurrencyPairsPage"
import { CurrencyPairDetailsPage } from "./components/pages/Trading/CurrencyPairs/CurrencyPairDetailsPage/CurrencyPairDetailsPage"
import { OrdersPage } from "./components/pages/Trading/Orders/OrdersPage"
import { OrderDetailsPage } from "./components/pages/Trading/Orders/OrderDetailsPage/OrderDetailsPage"
import { OrderTransactionDetailsPage } from "./components/pages/Trading/Orders/OrderDetailsPage/OrderTransactionsPage/OrderTransactionDetailsPage/OrderTransactionDetailsPage"
import { LogsPage } from "./components/pages/Logs/LogsPage"
import { LogDetailsPage } from "./components/pages/Logs/LogDetailsPage/LogDetailsPage"
import GroupMemberDetailsPageSlice from "./components/pages/Management/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPageSlice"
import { GroupMemberDetailsPage } from "./components/pages/Management/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPage"
import { RolePrivilegeDetailsPage } from "./components/pages/Management/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegeDetailsPage/RolePrivilegeDetailsPage"
import { DataRestrictionDetailsPage } from "./components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionDetailsPage/DataRestrictionDetailsPage"
import { TreasuryBalanceReportPage } from "./components/pages/Reports/TreausryBalanceReport/TreasuryBalanceReportPage"
import { AccountBalanceReportPage } from "./components/pages/Reports/AccountBalanceReport/AccountBalanceReportPage"
import { CurrencyExchangeRatesReportPage } from "./components/pages/Reports/CurrencyExchangeRatesReport/CurrencyExchangeRatesReportPage"
import { TradingReportPage } from "./components/pages/Reports/TradingReport/TradingReport"
import { NotFound } from "./components/common/NotFound/NotFound"
import { DashboardPage } from "./components/pages/Dashboard/Dashboard"
import OrganizationClientPortalSettingPageSlice from "./components/pages/Organizations/OrganizationClientPortalSettingPage/OrganizationClientPortalSettingPageSlice"
import { OrganizationClientPortalSettingPage } from "./components/pages/Organizations/OrganizationClientPortalSettingPage/OrganizationClientPortalSettingPage"
import { TransactionsStatementPage } from "./components/pages/Accountant/TransactionsStatement/TransactionsStatementPage"
import { TransactionTotalsReportPage } from "./components/pages/Reports/TransactionTotalsReport/TransactionTotalsReportPage"
import { AccountAuthorizationDetailsPage } from "./components/pages/Accountant/Accounts/AccountDetailsPage/AccountAuthorizationPage/AccountAuthorizationDetailsPage/AccountAuthorizationDetailsPage"
import { AuthorizationDetailsPage } from "./components/pages/Accountant/Accounts/AccountDetailsPage/AuthorizationPage/AuthorizationDetailsPage/AuthorizationDetailsPage"
import { AdvancedChartPage } from "./components/pages/Trading/AdvancedChart/AdvancedChartPage"
import { CurrencyPairPriceChangeLogdDetailsPage } from "./components/pages/Trading/CurrencyPairs/CurrencyPairDetailsPage/CurrencyPairPriceChangeLogPage/CurrencyPairPriceChangeLogDetailsPage/CurrencyPairPriceChangeLogDetailsPage"
import { CategoryDetailsPage } from "./components/pages/Trading/Categories/CategoriesDetailsPage/CategoryDetailsPage"
import { CategoriesPage } from "./components/pages/Trading/Categories/CategoriesPage"
import { AccountChartPage } from "./components/pages/Accountant/Accounts/AccountChart/AccountChartPage"
import { TreasuryChartPage } from "./components/pages/Accountant/Treasuries/TreasuryChart/TreasuryChartPage"



initializeIcons();
export const App: React.FunctionComponent = () => {
    const state: { appTheme: string, isDarkModeEnabled: boolean, currentLang: string } = useAppSelector((state) => {

        return { appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled, currentLang: state.settings.currentLang }
    })
    return (
        <ThemeProvider theme={getMainTheme(state.appTheme, state.isDarkModeEnabled)} style={{ height: '100%', width: '100%' }}  >
            <BrowserRouter>
                <Routes>

                    <Route path="/" element={<RequireAuth redirectTo="/login"> <MasterLayout />  </RequireAuth>}>
                        <Route path='*' element={<NotFound />} />
                        <Route path={getPage("dashboard").path} element={<DashboardPage />} />
                        <Route path={getPage("profile").path} element={<ProfilePage />} />
                        <Route path={getPage("organizations").path} element={<OrganizationsPage />} />
                        <Route path={getPage("organizationClientPortalSetting").path} element={<OrganizationClientPortalSettingPage />} />
                        <Route path={getPage("organizations/details").path} element={<OrganizationDetailsPage />} />
                        <Route path={getPage("management/users").path} element={<UsersPage />} />
                        <Route path={getPage("management/roles").path} element={<RolesPage />} />
                        <Route path={getPage("management/usersTypes").path} element={<UserTypesPage />} />
                        <Route path={getPage("management/privileges").path} element={<PrivilegesPage />} />
                        <Route path={getPage("management/groups").path} element={<GroupsPage />} />
                        <Route path={getPage("management/usersTypes/details").path} element={<UserTypeDetailsPage />} />
                        <Route path={getPage("management/users/details").path} element={<UserDetailsPage />} />
                        <Route path={getPage("management/roles/details").path} element={<RoleDetailsPage />} />
                        <Route path={getPage("management/groups/details").path} element={<GroupDetailsPage />} />
                        <Route path={getPage("management/privileges/details").path} element={<PrivilegeDetailsPage />} />
                        <Route path={getPage("management/privilege-data/details").path} element={<PrivilegeDataDetailsPage />} />
                        <Route path={getPage("management/groupMembership/details").path} element={<GroupMemberDetailsPage />} />
                        <Route path={getPage("management/rolePrivilege/details").path} element={<RolePrivilegeDetailsPage />} />
                        <Route path={getPage("management/dataRestrection/details").path} element={<DataRestrictionDetailsPage />} />

                        <Route path={getPage("customers/users").path} element={<CustomerUsersPage />} />
                        <Route path={getPage("customers/roles").path} element={<CustomerRolesPage />} />
                        <Route path={getPage("customers/usersTypes").path} element={<CustomerUserTypesPage />} />
                        <Route path={getPage("customers/privileges").path} element={<CustomerPrivilegesPage />} />
                        <Route path={getPage("customers/groups").path} element={<CustomerGroupsPage />} />
                        <Route path={getPage("customers/usersTypes/details").path} element={<CustomerUserTypeDetailsPage />} />
                        <Route path={getPage("customers/users/details").path} element={<CustomerUserDetailsPage />} />
                        <Route path={getPage("customers/roles/details").path} element={<CustomerRoleDetailsPage />} />
                        <Route path={getPage("customers/groups/details").path} element={<CustomerGroupDetailsPage />} />
                        <Route path={getPage("customers/privileges/details").path} element={<CustomerPrivilegeDetailsPage />} />
                        <Route path={getPage("customers/privilege-data/details").path} element={<CustomerPrivilegeDataDetailsPage />} />
                        <Route path={getPage("customers/groupMembership/details").path} element={<CustomerGroupMemberDetailsPage />} />
                        <Route path={getPage("customers/rolePrivilege/details").path} element={<CustomerRolePrivilegeDetailsPage />} />
                        <Route path={getPage("customers/dataRestrection/details").path} element={<CustomerDataRestrictionDetailsPage />} />

                        <Route path={getPage("accountsManagement/transactions").path} element={<TransactionsStatementPage />} />
                        <Route path={getPage("accountsManagement/accountTypes").path} element={<AccountTypesPage />} />
                        <Route path={getPage("accountsManagement/accountTypes/details").path} element={<AccountTypeDetailsPage />} />
                        <Route path={getPage("currencies").path} element={<CurrenciesPage />} />
                        <Route path={getPage("currencies/details").path} element={<CurrencyDetailsPage />} />
                        <Route path={getPage("fees").path} element={<FeesPage />} />
                        <Route path={getPage("fees/details").path} element={<FeeDetailsPage />} />
                        <Route path={getPage("treasuriesManagement/treasuryTypes").path} element={<TreasuryTypesPage />} />
                        <Route path={getPage("treasuriesManagement/treasuryTypes/details").path} element={<TreasuryTypeDetailsPage />} />
                        <Route path={getPage("accountsManagement/accountTypeRules/details").path} element={<AccountTypeRuleDetailsPage />} />
                        <Route path={getPage("treasuriesManagement/treasuryTypeRules/details").path} element={<TreasuryTypeRuleDetailsPage />} />
                        <Route path={getPage("limitations").path} element={<LimitationsPage />} />
                        <Route path={getPage("limitations/details").path} element={<LimitationDetailsPage />} />
                        <Route path={getPage("accountsManagement/accounts").path} element={<AccountsPage />} />
                        <Route path={getPage("accountsManagement/accounts/details").path} element={<AccountDetailsPage />} />
                        <Route path={getPage("treasuriesManagement/treasuries").path} element={<TreasuriesPage />} />
                        <Route path={getPage("treasuriesManagement/treasuries/details").path} element={<TreasuryDetailsPage />} />
                        <Route path={getPage("treasuriesManagement/transactions/details").path} element={<TreasuryTransactionDetailsPage />} />
                        <Route path={getPage("accountsManagement/transactions/details").path} element={<AccountTransactionDetailsPage />} />
                        <Route path={getPage("accountsManagement/reservedBalances").path} element={<ReservedBalancesPage />} />
                        <Route path={getPage("accountsManagement/reservedBalances/details").path} element={<ReservedBalanceDetailsPage />} />

                        <Route path={getPage("trading/currencyPairs").path} element={<CurrencyPairsPage />} />
                        <Route path={getPage("trading/currencyPairs/details").path} element={<CurrencyPairDetailsPage />} />
                        <Route path={getPage("trading/orders").path} element={<OrdersPage />} />
                        <Route path={getPage("trading/orders/details").path} element={<OrderDetailsPage />} />
                        <Route path={getPage("trading/orderTransactions/details").path} element={<OrderTransactionDetailsPage />} />
                        <Route path={getPage("trading/chart").path} element={<AdvancedChartPage />} />


                        
                        <Route path={getPage("logs").path} element={<LogsPage />} />
                        <Route path={getPage("logs/details").path} element={<LogDetailsPage />} />

                        <Route path={getPage("reports/treasuryBalanceReport").path} element={<TreasuryBalanceReportPage />} />
                        <Route path={getPage("reports/accountBalanceReport").path} element={<AccountBalanceReportPage />} />
                        <Route path={getPage("reports/currencyExchangeRatesReport").path} element={<CurrencyExchangeRatesReportPage />} />
                        <Route path={getPage("reports/tradingReport").path} element={<TradingReportPage />} />
                        <Route path={getPage("reports/transactionTotalsReport").path} element={<TransactionTotalsReportPage />} />
                        <Route path={getPage("accountsManagement/accountAuthorization/details").path} element={<AccountAuthorizationDetailsPage />} />
                        <Route path={getPage("accountsManagement/authorization/details").path} element={<AuthorizationDetailsPage />} />
                        <Route path={getPage("trading/currencyPairsPriceLog/details").path} element={<CurrencyPairPriceChangeLogdDetailsPage />} />

                        <Route path={getPage("trading/categories/details").path} element={<CategoryDetailsPage />} />
                        <Route path={getPage("trading/categories").path} element={<CategoriesPage />} />
                        <Route path={getPage("accountsManagement/accounts/chart").path} element={<AccountChartPage />} />

                        <Route path={getPage("treasuriesManagement/treasuries/chart").path} element={<TreasuryChartPage />} />

                    </Route>

                    <Route path="/login" element={<BypassAuth><Login /></BypassAuth>} />
                </Routes>
            </BrowserRouter>

        </ThemeProvider >

    );
}


const RequireAuth: React.FunctionComponent<{ redirectTo: string, children: React.ReactNode }> = (props) => {
    let isAuthenticated = isLoggedIn();
    return (isAuthenticated ? props?.children as React.ReactElement : <Navigate to={props.redirectTo} />);
}

const BypassAuth: React.FunctionComponent<{ children: React.ReactNode }> = (props) => {
    let isAuthenticated = isLoggedIn();
    return (isAuthenticated ? <Navigate to="/" /> : props?.children as React.ReactElement);
}
