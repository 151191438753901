import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest } from '../../../../../app/Api';
import { base64ToArrayBuffer, downloadFile, formatDate, getFileExtension, timestampToDate } from '../../../../../app/Helpers';

import { TableState } from '../../../../common/Table/TableSate';
import { number } from 'yup';
import { ReportEndpointClient } from '../../../../../repository/Accountant/ReportServiceClientPb';
import { GetAccountBalanceReportRequest, GetAccountBalanceReportResponse, ExportAccountBalanceReportRequest, ExportAccountBalanceReportResponse } from '../../../../../repository/Accountant/report_pb';
import { AccountEndpointClient } from '../../../../../repository/Accountant/AccountServiceClientPb';
import { GetAccountRequest, GetAccountResponse, GetAccountsRequest, GetAccountsResponse } from '../../../../../repository/Accountant/account_pb';


export interface AccountChartPageState {
    set: TableState,
    accounts: TableState,
    totals: any
    message?: ApiMessage,
    account: any
    isChangeStateLoading: boolean,

}

const initialState: AccountChartPageState = {
    accounts: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    set: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    totals: undefined,
    message: undefined,
    account: undefined,
    isChangeStateLoading: false

}
const client = new ReportEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientAccount = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);

export const getItems = createAsyncThunk<APIResponse<GetAccountBalanceReportResponse.AsObject>, APIRequest<GetAccountBalanceReportRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/chart/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getAccountBalanceReport(req.body, req.headers ?? {});
                return { metadata: { grouping: req.body.getGroupby() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)
export const getAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/chart/accounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientAccount.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getAccount = createAsyncThunk<GetAccountResponse.AsObject, APIRequest<GetAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/chart/account/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientAccount.getAccount(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


 

export const accountChartPageSlice = createSlice({
    name: 'pages/accountant/accountDetails/chart',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.message = initialState.message;
            state.set = initialState.set;
            state.account = initialState.account;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.totals = initialState.totals;
            state.accounts = initialState.accounts;


        },
        resetSet: (state) => {
            state.set.items = [];
            state.set.hasMore = true;
        },

       
        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
    },
    extraReducers: (builder) => {


        builder.addCase(getAccount.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false
            if (payload) {
                let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    name: payload.success?.name?.value ?? "",
                    code: payload.success?.code?.value ?? "",
                    currencyId: payload.success?.currency?.id?.value ?? "",
                    currencyName: payload.success?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.currency?.symbol?.value ?? "",
                    accountTypeId: payload.success?.accounttype?.id?.value ?? "",
                    accountTypeName: payload.success?.accounttype?.name?.value ?? "",
                    status: payload.success?.status?.value ?? undefined,
                    ownerFirstname: payload.success?.owner?.firstname?.value ?? "",
                    ownerLastname: payload.success?.owner?.lastname?.value ?? "",
                    ownerMiddlename: payload.success?.owner?.middlename?.value ?? "",
                    ownerName: (payload.success?.owner?.firstname?.value ?? "") + " " + (payload.success?.owner?.lastname?.value ?? ""),
                    ownerId: payload.success?.owner?.id?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    transferControl: payload.success?.accountsetting?.tansfercontrol?.value ,
                    limitationId: payload.success?.limitation?.id?.value ?? "",
                    limitationName: payload.success?.limitation?.name?.value ?? "",
                    freeBalance: undefined,
                    reservedBalance: undefined,
                    totalBalance: undefined,
                    isAuthorizationRequired: payload.success?.accountsetting?.isauthorizationrequired,
                    isLinkingEnable: payload.success?.accountsetting?.islinkingenable?.value,
                    isLinkingMetadataSet: payload.success?.accountsetting?.islinkingmetadataset?.value,



                }



                state.account = r
            }
        })
        builder.addCase(getAccount.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getAccount.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(getItems.fulfilled, (state, { payload }) => {
     
            state.set.isFetching = false

        })
        builder.addCase(getItems.rejected, (state, action) => {
            state.set.isFetching = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(getItems.pending, (state, action) => {
            state.set.isFetching = true;
            state.message = undefined;
        })

        builder.addCase(getAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                                                isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.accounts.numberOfResults) {
                    state.accounts.hasMore = false;
                }
                state.accounts.items = state.accounts.items.concat(r);
            } else {
                state.accounts.hasMore = false;

            }
            state.accounts.isFetching = false

        })
        builder.addCase(getAccounts.rejected, (state, action) => {
            state.accounts.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccounts.pending, (state, action) => {
            state.accounts.isFetching = true;
            //state.message = undefined;
        })
       

    }
})

export const { reset, dismissMessage, setMessage, resetSet,   } = accountChartPageSlice.actions

export default accountChartPageSlice.reducer


