import { Nav, Stack, Text, Persona, ThemeProvider, PersonaSize, PersonaPresence, IPersonaSharedProps, IImageProps, ImageFit, initializeIcons, INavLinkGroup, IStackStyles, CommandBar, ICommandBarItemProps, IContextualMenuItem, getTheme, Callout, DirectionalHint, Link, Layer, PersonaInitialsColor, IconButton, Separator } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import { calloutStyle, currentOrganization, navHeaderStyle } from './NavHeader.styles';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { AppTheme, getHeaderTheme, getMainTheme, ThemeManager } from '../../../app/Themes';
import { useTranslation } from 'react-i18next';
import { clearSession, getHeaders, getSession, ORGANIZATION_LOGO_URL, PROFILE_LOGO_URL } from '../../../app/Api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { stat } from 'fs/promises';
import { isVisible } from '../Settings/SettingsSlice';
import { Popup } from '../Popup/Popup';
import { ChangePasswordForm } from '../../forms/ChanagePassword/ChangePasswordForm';
import { FormType } from '../../forms/FormProps';
import { LogoutRequest } from '../../../repository/UserManagement/authentication_pb';
import { logout } from './NavHeaderSlice';




let logoutReq: LogoutRequest;

let org: number = -1;
let promise: any;

export const NavHeader: React.FunctionComponent = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [currentAction, setCurrenctAction] = useState(0)

  const state: { title: string, appTheme: string, isDarkModeEnabled: boolean, organization: any } = useAppSelector((state) => {

    return { title: state.navHeader.title, appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled, organization: state.masterLayout.currentOrganization }
  })
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);

  const calloutTargetId = useId('callout-target');
  const labelId = useId('callout-label');
  const descriptionId = useId('callout-description');
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch()
  let current = getSession();
  if (current == null) {
    clearSession();
    navigate("/login");
  }

  const profileImage: IPersonaSharedProps = {
    initialsColor: getMainTheme(state.appTheme, state.isDarkModeEnabled).palette.themePrimary,
    initialsTextColor: "#ffffff",
    text: current.profile?.firstname?.value + " " + current.profile?.lastname?.value,
    secondaryText: current.username?.value,
    coinProps: {
      styles: { initials: { border: '1px solid #ffffff' } }
    }
  };

  useEffect(() => {
    logoutReq = new LogoutRequest();

    let current = getSession();

    if (!current) {
      clearSession();
      navigate("/login");

    }
    logoutReq.setSessionid(Number(current?.accesstoken?.sessionid))

    var l = searchParams.get('org');
    if (l) {
      org = Number(l);
      if (Number.isNaN(org)) {
        org = -1;
      } else {

      }
    }

    return () => { //clean up

      org = -1;
    }
  }, [])


  return (

    <Stack styles={navHeaderStyle} verticalAlign='center' horizontal >
      <Popup isOpen={(currentAction == 101)} title={t("changeProfilePasswordTitle")} onDismiss={() => { setCurrenctAction(0) }}  >
        <ChangePasswordForm childrenGap={10} type={FormType.EDIT} onSuccess={(e) => {

        }} onCancel={() => { setCurrenctAction(0) }} />

      </Popup>
      {isCalloutVisible ? (
        <Callout
          ariaLabelledBy={labelId}
          styles={calloutStyle}
          target={`#${calloutTargetId}`}
          onDismiss={toggleIsCalloutVisible}
          directionalHint={DirectionalHint.bottomRightEdge}
          isBeakVisible={false}
          setInitialFocus
        >
          <CommandBar
            items={[]}
            farItems={[
              {
                key: 'logout',
                text: t('logout'),
                title: t('logout'),
                ariaLabel: t('logout'),
                onClick: () => {
                  dispatch(logout({ body: logoutReq, headers: getHeaders() }))
                  clearSession();
                  window.location.href = "/login";
                }
              }
            ]}
            ariaLabel={t("logout")}

          />
          <Stack horizontal tokens={{ childrenGap: 10, padding: '0px 20px 20px 20px' }} >
            <Persona
              {...profileImage}
              size={PersonaSize.size100}
              hidePersonaDetails={true}
              imageUrl={PROFILE_LOGO_URL + "/" + state.organization?.id + "/" + current.profile?.id?.value + "/profile.jpg"}


            />
            <Stack grow verticalAlign='center' tokens={{ childrenGap: 5 }}   >
              <Text variant={'xLarge'} block >{current.profile?.firstname?.value + " " + current.profile?.lastname?.value}</Text>
              <Text variant={'medium'} nowrap block>{current.username?.value}</Text>
              <Link id={labelId} variant={'small'} onClick={(e) => { e.preventDefault(); toggleIsCalloutVisible(); navigate("/profile") }}>
                {t("myProfile")}
              </Link>
              <Link variant={'small'} onClick={(e) => { e.preventDefault(); setCurrenctAction(101) }}>
                {t("changeProfilePassword")}
              </Link>
            </Stack>


          </Stack>
          <Separator alignContent="start"></Separator>

          {state.organization ? <Stack tokens={{ childrenGap: 10, padding: '0px 20px 20px 20px' }} horizontal wrap styles={currentOrganization}>
            <Stack.Item styles={{ root: { width: (org != -1) ? "80%" : undefined } }}>
              <Link onClick={(e) => {
                if (e) {
                  e.preventDefault();
                  navigate("/organizations/" + state.organization?.id + ((org == -1) ? "" : "?org=" + org));
                  toggleIsCalloutVisible();
                }
              }
              } href={"/organizations/" + state.organization?.id + ((org == -1) ? "" : "?org=" + org)}
              ><Persona
                  text={state.organization?.name == "" ? state.organization?.id : state.organization?.name}
                  secondaryText={state.organization?.domain}
                  size={PersonaSize.size40}
                  imageUrl={state.organization?.logo?.Name ? ORGANIZATION_LOGO_URL + state.organization?.logo?.Name : undefined}
                /></Link>

            </Stack.Item>

            {(org != -1) ? <IconButton iconProps={{ iconName: "SignOut", title: t("backToMain") }} title={t("backToMain")} onClick={(e) => {
              window.location.href = "/";
            }}></IconButton>
              : undefined}

          </Stack> : undefined}
        </Callout>

      ) : null}
      <Stack.Item grow>
        <ThemeProvider theme={getHeaderTheme(state.appTheme, state.isDarkModeEnabled)}>
          <Stack tokens={{ padding: '0px 3% 0px 3%' }} verticalAlign='center' horizontal >

            <Text variant={'xLarge'} nowrap block>{state.title}</Text>

            <Stack verticalAlign='center' grow horizontal reversed id={calloutTargetId} >
              <Persona
                {...profileImage}
                size={PersonaSize.size32}
                presence={PersonaPresence.online}
                hidePersonaDetails={true}
                onClick={toggleIsCalloutVisible}
                styles={{ root: { cursor: "pointer", } }}
              />
              <CommandBar

                items={[]}
                farItems={[ 
                {
                  key: 'calculator',
                  text: t("calculator"),
                  title: t('calculator'),
                  ariaLabel: t("calculator"),
                  iconOnly: true,
                  iconProps: { iconName: 'Calculator' },
                  onClick: (ev, i) => {
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = "Calculator:///";

                    a.click();
                  }
                },
                {
                  key: 'settings',
                  text: t("settings"),
                  title: t('settings'),
                  ariaLabel: t("settings"),
                  iconOnly: true,
                  iconProps: { iconName: 'Settings' },
                  onClick: (ev, i) => {
                    ev?.currentTarget.blur();
                    dispatch(isVisible(true))
                  }
                }]}
                ariaLabel={t("commandBarAria")}
              />

            </Stack>
          </Stack>
        </ThemeProvider>
      </Stack.Item>
    </Stack>

  );
}



