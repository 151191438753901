import { configureStore } from '@reduxjs/toolkit'
import navHeaderReducer from '../components/common/NavHeader/NavHeaderSlice'
import masterLayoutReducer from '../components/layouts/Master/MasterLayoutSlice'
import settingReducer from '../components/common/Settings/SettingsSlice'
import loginFormReducer from '../components/forms/Login/LoginFormSlice'
import managersPageReducer from '../components/pages/Management/Users/UsersPageSlice'
import managersRolesPageReducer from '../components/pages/Management/Roles/RolesPageSlice'
import managersUserTypesPageReducer from '../components/pages/Management/UserTypes/UserTypesSlice'
import managersPrivilegesPageReducer from '../components/pages/Management/Privileges/PrivilegesPageSlice'
import managersGroupsPageReducer from '../components/pages/Management/Groups/GroupsPageSlice'
import organizationsPageReducer from '../components/pages/Organizations/OrganizationsPageSlice'
import managersRolesFormReducer from '../components/forms/Management/Roles/RolesFormSlice'
import managersUserTypesFormReducer from '../components/forms/Management/UserTypes/UserTypesFormSlice'
import managersGroupsFormReducer from '../components/forms/Management/Groups/GroupsFormSlice'
import organizationFormReducer from '../components/forms/Organizations/OrganizationsFormSlice'
import managersUsersReducer from '../components/forms/Management/Users/UsersFormSlice'
import managersPrivilegesReducer from '../components/forms/Management/Privileges/PrivilegesFormSlice'
import changePasswordReducer from '../components/forms/ChanagePassword/ChangePasswordFormSlice'
import managersUserTypeDetailsPageReducer from '../components/pages/Management/UserTypes/UserTypeDetailsPage/UserTypeDetailsPageSlice'
import managersUserDetailsPageReducer from '../components/pages/Management/Users/UserDetailsPage/UserDetailsPageSlice'
import managersRoleDetailsPageReducer from '../components/pages/Management/Roles/RoleDetailsPage/RoleDetailsPageSlice'
import managersGroupDetailsPageReducer from '../components/pages/Management/Groups/GroupDetailsPage/GroupDetailsPageSlice'
import managersPrivilegeDetailsPageReducer from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PriviliegeDetailsPageSlice'
import managersRolePrivilegesDetailsPageReducer from '../components/pages/Management/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegesPageSlice'
import managersRolePrivilegesFormReducer from '../components/forms/Management/Roles/RolePrivilegesForm/RolePrivilegesFormSlice'
import organizationDetailsPageReducer from '../components/pages/Organizations/OrganizationDetailsPage/OrganizationDetailsPageSlice'
import managersPrivilegeDataPageReducer from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataPageSlice'
import managersPrivilegeDataDetailsPageReducer from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/PrivilegeDataDetailsPageSlice'
import managersDataRestrictionsPageReducer from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionsPageSlice'
import managersDataRestrictionsFormReducer from '../components/forms/Management/DataRestriactions/DataRestriactionsFormSlice'
import managersGroupMembersPageReducer from '../components/pages/Management/Groups/GroupDetailsPage/GroupMembersPage/GroupMembersPageSlice'
import managersGroupMembersFromReducer from '../components/forms/Management/Groups/GroupMembers/GroupMembersFromSlice'
import profilePageReducer from '../components/pages/Profile/ProfilePageSlice'
import profileFormReducer from '../components/forms/Profile/ProfileFormSlice'
import managersGroupMembershipDetailsPageReducer from '../components/pages/Management/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPageSlice'
import managersRolePrivilegeDetailsPageReducer from '../components/pages/Management/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegeDetailsPage/RolePrivilegeDetailsPageSlice'
import managersDataRestrictionDetailsPageReducer from '../components/pages/Management/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionDetailsPage/DataRestrictionDetailsPageSlice'
import organizationClientPortalSettingPageReducer from '../components/pages/Organizations/OrganizationClientPortalSettingPage/OrganizationClientPortalSettingPageSlice'
import organizationClientPortalSettingFormReducer from '../components/forms/Organizations/OrganizationClientPortalSetting/OrganizationClientPortalSettingFormSlice'



import customersPageReducer from '../components/pages/Customers/Users/UsersPageSlice'
import customersRolesPageReducer from '../components/pages/Customers/Roles/RolesPageSlice'
import customersUserTypesPageReducer from '../components/pages/Customers/UserTypes/UserTypesSlice'
import customersPrivilegesPageReducer from '../components/pages/Customers/Privileges/PrivilegesPageSlice'
import customersGroupsPageReducer from '../components/pages/Customers/Groups/GroupsPageSlice'
import customersRolesFormReducer from '../components/forms/Customers/Roles/RolesFormSlice'
import customersUserTypesFormReducer from '../components/forms/Customers/UserTypes/UserTypesFormSlice'
import customersGroupsFormReducer from '../components/forms/Customers/Groups/GroupsFormSlice'
import customersUsersReducer from '../components/forms/Customers/Users/UsersFormSlice'
import customersPrivilegesReducer from '../components/forms/Customers/Privileges/PrivilegesFormSlice'
import customersUserTypeDetailsPageReducer from '../components/pages/Customers/UserTypes/UserTypeDetailsPage/UserTypeDetailsPageSlice'
import customersUserDetailsPageReducer from '../components/pages/Customers/Users/UserDetailsPage/UserDetailsPageSlice'
import customersRoleDetailsPageReducer from '../components/pages/Customers/Roles/RoleDetailsPage/RoleDetailsPageSlice'
import customersGroupDetailsPageReducer from '../components/pages/Customers/Groups/GroupDetailsPage/GroupDetailsPageSlice'
import customersPrivilegeDetailsPageReducer from '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PriviliegeDetailsPageSlice'
import customersRolePrivilegesDetailsPageReducer from '../components/pages/Customers/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegesPageSlice'
import customersRolePrivilegesFormReducer from '../components/forms/Customers/Roles/RolePrivilegesForm/RolePrivilegesFormSlice'
import customersPrivilegeDataPageReducer from '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataPageSlice'
import customersPrivilegeDataDetailsPageReducer from '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/PrivilegeDataDetailsPageSlice'
import customersDataRestrictionsPageReducer from '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionsPageSlice'
import customersDataRestrictionsFormReducer from '../components/forms/Customers/DataRestriactions/DataRestriactionsFormSlice'
import customersGroupMembersPageReducer from '../components/pages/Customers/Groups/GroupDetailsPage/GroupMembersPage/GroupMembersPageSlice'
import customersGroupMembersFromReducer from '../components/forms/Customers/Groups/GroupMembers/GroupMembersFromSlice'
import customersGroupMembershipDetailsPageReducer from '../components/pages/Customers/Groups/GroupDetailsPage/GroupMembersPage/GroupMemberDetailsPage/GroupMemberDetailsPageSlice'
import customersRolePrivilegeDetailsPageReducer from '../components/pages/Customers/Roles/RoleDetailsPage/RolePrivilegesPage/RolePrivilegeDetailsPage/RolePrivilegeDetailsPageSlice'
import customersDataRestrictionDetailsPageReducer from '../components/pages/Customers/Privileges/PrivilegeDetailsPage/PrivilegeDataPage/PrivilegeDataDetailsPage/DataRestrictionsPage/DataRestrictionDetailsPage/DataRestrictionDetailsPageSlice'

import transactionsStatementPageReducer from '../components/pages/Accountant/TransactionsStatement/TransactionsStatementPageSlice'
import accountChartPageReducer from '../components/pages/Accountant/Accounts/AccountChart/AccountChartSlice'
import accountTypesPageReducer from '../components/pages/Accountant/AccountTypes/AccountTypesPageSlice'
import accountTypesFormReducer from '../components/forms/Accountant/AccountTypes/AccountTypesFormSlice'
import accountTypeDetailsPageReducer from '../components/pages/Accountant/AccountTypes/AccountTypeDetailsPage/AccountTypeDetailsPageSlice'
import currenciesPageReducer from '../components/pages/Accountant/Currencies/CurrenciesPageSlice'
import currenciesFormReducer from '../components/forms/Accountant/Currencies/CurrenciesFormSlice'
import currencyDetailsPageReducer from '../components/pages/Accountant/Currencies/CurrencyDetailsPage/CurrencyDetailsPageSlice'
import feesPageReducer from '../components/pages/Accountant/Fees/FeesPageSlice'
import feesFormReducer from '../components/forms/Accountant/Fees/FeesFormSlice'
import feeDetailsPageReducer from '../components/pages/Accountant/Fees/FeeDetailsPage/FeeDetailsPageSlice'
import treasuryTypesPageReducer from '../components/pages/Accountant/TreasuryTypes/TreasuryTypesPageSlice'
import treasuryTypesFormReducer from '../components/forms/Accountant/TreasuryTypes/TreasuryTypesFormSlice'
import treasuryTypeDetailsPageReducer from '../components/pages/Accountant/TreasuryTypes/TreasuryTypeDetailsPage/TreasuryTypeDetailsPageSlice'
import accountTypeRulesPageReducer from '../components/pages/Accountant/AccountTypes/AccountTypeDetailsPage/AccountTypeRulesPage/AccountTypeRulesPageSlice'
import accountTypeRulesFormReducer from '../components/forms/Accountant/AccountTypes/AccounetTypeRulesForm/AccounetTypeRulesFormSlice'
import accountTypeRuleDetailsReducer from '../components/pages/Accountant/AccountTypes/AccountTypeDetailsPage/AccountTypeRulesPage/AccountTypeRuleDetailsPage/AccountTypeRuleDetailsPageSlice'
import treasuryTypeRulesPageReducer from '../components/pages/Accountant/TreasuryTypes/TreasuryTypeDetailsPage/TreasuryTypeRulesPage/TreasuryTypeRulesPageSlice'
import treasuryTypeRuleDetailsReducer from '../components/pages/Accountant/TreasuryTypes/TreasuryTypeDetailsPage/TreasuryTypeRulesPage/TreasuryTypeRuleDetailsPage/TreasuryTypeRuleDetailsPageSlice'
import treasuryTypeRulesFormReducer from '../components/forms/Accountant/TreasuryTypes/TreasuryTypeRulesForm/TreasuryTypeRulesFormSlice'
import limitationsPageReducer from '../components/pages/Accountant/Limitations/LimitationsPageSlice'
import limitationsFormReducer from '../components/forms/Accountant/Limitations/LimitationsFromSlice'
import limitationDetailsPageReducer from '../components/pages/Accountant/Limitations/LimitationDetailsPage/LimitationDetailsPageSlice'
import accountsPageReducer from '../components/pages/Accountant/Accounts/AccountsPageSlice'
import accountsFormReducer from '../components/forms/Accountant/Accounts/AccountsFromSlice'
import accountDetailsPageReducer from '../components/pages/Accountant/Accounts/AccountDetailsPage/AccountDetailsPageSlice'
import feeCollectionAccountsFormReducer from '../components/forms/Accountant/Fees/FeeCollectionAccountsForm/FeeCollectionAccountsFormSlice'
import feeCollectionAccountsPageReducer from '../components/pages/Accountant/Fees/FeeDetailsPage/FeeCollectionAccountsPage/FeeCollectionAccountsPageSlice'
import treasuriesPageReducer from '../components/pages/Accountant/Treasuries/TreasuriesPageSlice'
import treasuriesFormReducer from '../components/forms/Accountant/Treasuries/TreasuriesFormSlice'
import treasuryDetailsPageReducer from '../components/pages/Accountant/Treasuries/TreasuryDetailsPage/TreasuryDetailsPageSlice'
import treasuryDepositsPageReducer from '../components/pages/Accountant/Treasuries/TreasuryDetailsPage/TreasuryDepositsPage/TreasuryDepositsPageSlice'
import treasuryDepositsFormReducer from '../components/forms/Accountant/Treasuries/TreasuryDepositsForm/TreasuryDepositsFormSlice'
import treasuryStatementPageReducer from '../components/pages/Accountant/Treasuries/TreasuryDetailsPage/TreasuryStatementPage/TreasuryStatementPageSlice'
import transferFormReducer from '../components/forms/Accountant/Operations/Transfer/TransferFormSlice'
import depositFormReducer from '../components/forms/Accountant/Operations/Deposit/DepositFormSlice'
import withdrawFormReducer from '../components/forms/Accountant/Operations/Withdraw/WithdrawFormSlice'
import treasuryTransactionDetailsPageReducer from '../components/pages/Accountant/Treasuries/TreasuryDetailsPage/TreasuryStatementPage/TreasuryStatementDetailsPage/TreasuryStatementDetailsPageSlice'
import treasuryTransactionsFormReducer from '../components/forms/Accountant/TreasuryTransactions/TreasuryTransactionsFormSlice'
import accountStatementPageReducer from '../components/pages/Accountant/Accounts/AccountDetailsPage/AccountStatementPage/AccountStatementPageSlice'
import accountTransactionDetailsPageReducer from '../components/pages/Accountant/Accounts/AccountDetailsPage/AccountStatementPage/AccountStatementDetailsPage/AccountStatementDetailsPageSlice'
import accountTransactionsFormReducer from '../components/forms/Accountant/AccountTransactions/AccountTransactionsFormSlice'
import reservedBalancePageReducer from '../components/pages/Accountant/ReservedBalances/ReservedBalancesPageSlice'
import reservedBalancesFormReducer from '../components/forms/Accountant/ReservedBalances/ReservedBalancesFormSlice'
import reservedBalanceDetailsPageReducer from '../components/pages/Accountant/ReservedBalances/ReservedBalanceDetailsPage/ReservedBalanceDetailsPageSlice'
import accountStatementFormReducer from '../components/forms/Accountant/Operations/Statement/AccountStatement/AccountStatementFormSlice'
import trasuryStatementFormReducer from '../components/forms/Accountant/Operations/Statement/TreasuryStatement/TreasuryStatementFormSlice'
import withdrawAmountFormReducer from '../components/forms/Accountant/ReservedBalances/WithdrawAmount/WithdrawAmountFormSlice'
import transferAmountFormReducer from '../components/forms/Accountant/ReservedBalances/TransferAmount/TransferAmountFormSlice'
import authorizationFormReducer from '../components/forms/Accountant/Accounts/AuthorizationFormSlice'
import externalTransferFormReducer from '../components/forms/Accountant/Operations/ExternalTransfare/ExternalTransfareFormSlice'
import externalTransferAmountFormReducer from '../components/forms/Accountant/ReservedBalances/ExternalTransfareAmount/ExternalTransfareAmountFormSlice'
import treasuryChartPageReducer from '../components/pages/Accountant/Treasuries/TreasuryChart/TreasuryChartSlice'

import currencyPairsPageReducer from '../components/pages/Trading/CurrencyPairs/CurrencyPairsPageSlice'
import currencyPairDetailsPageReducer from '../components/pages/Trading/CurrencyPairs/CurrencyPairDetailsPage/CurrencyPairDetailsPageSlice'
import currencyPairsFormReducer from '../components/forms/Trading/CurrencyPairs/CurrencyPairsFormSlice'
import ordersPageReducer from '../components/pages/Trading/Orders/OrdersPageSlice'
import ordersFormReducer from '../components/forms/Trading/Orders/OrdersFormSlice'
import placeOrderFormReducer from '../components/forms/Trading/Orders/PlaceOrder/PlaceOrderFormSlice'

import externalOrdersFormReducer from '../components/forms/Trading/Orders/ExternalOrder/ExternalOrdersFormSlice'
import orderDetailsPageReducer from '../components/pages/Trading/Orders/OrderDetailsPage/OrderDetailsPageSlice'
import orderTransactionsPageReducer from '../components/pages/Trading/Orders/OrderDetailsPage/OrderTransactionsPage/OrderTransactionsPageSlice'
import orderTransactionDetailsPageReducer from '../components/pages/Trading/Orders/OrderDetailsPage/OrderTransactionsPage/OrderTransactionDetailsPage/OrderTransactionDetailsPageSlice'

import logsPageReducer from '../components/pages/Logs/LogsPageSlice'
import logDetailsPageReducer from '../components/pages/Logs/LogDetailsPage/LogDetailsPageSlice'

import treasuryBalanceReportPageReducer from '../components/pages/Reports/TreausryBalanceReport/TreasuryBalanceReportPageSlice'
import accountBalanceReportPageReducer from '../components/pages/Reports/AccountBalanceReport/AccountBalanceReportPageSlice'
import currencyExchangeRatesReportPageReducer from '../components/pages/Reports/CurrencyExchangeRatesReport/CurrencyExchangeRatesReportPageSlice'
import tradingReportPageReducer from '../components/pages/Reports/TradingReport/TradingReportSlice'
import transactionTotalsReportPageReducer from '../components/pages/Reports/TransactionTotalsReport/TransactionTotalsReportSlice'

import accountAuthorizationPageReducer from '../components/pages/Accountant/Accounts/AccountDetailsPage/AccountAuthorizationPage/AccountAuthorizationPageSlice'
import accountAuthorizationFormReducer from '../components/forms/Accountant/Accounts/AccountAuthorizationFormSlice'
import accountAuthorizationDetailsPageReducer from '../components/pages/Accountant/Accounts/AccountDetailsPage/AccountAuthorizationPage/AccountAuthorizationDetailsPage/AccountAuthorizationDetailsPageSlice'

import linkAccountPageReducer from '../components/pages/Accountant/Accounts/AccountDetailsPage/LinkAccountPage/LinkAccountPageSlice'
import authorizationDetailsPageReducer from '../components/pages/Accountant/Accounts/AccountDetailsPage/AuthorizationPage/AuthorizationDetailsPage/AuthorizationDetailsPageSlice'


import advancedChartPageReducer from '../components/pages/Trading/AdvancedChart/AdvancedChartPageSlice'
import currencyPairPriceChangeLogPageReducer from '../components/pages/Trading/CurrencyPairs/CurrencyPairDetailsPage/CurrencyPairPriceChangeLogPage/CurrencyPairPriceChangeLogPageSlice'
import currencyPairPriceChangeLogFormReducer from '../components/forms/Trading/CurrencyPairs/CurrencyPairPriceChangeLog/CurrencyPairPriceChangeLogFormSlice'
import currencyPairPriceChangeLogDetailsPageReducer from '../components/pages/Trading/CurrencyPairs/CurrencyPairDetailsPage/CurrencyPairPriceChangeLogPage/CurrencyPairPriceChangeLogDetailsPage/CurrencyPairPriceChangeLogDetailsPageSlice'


import categoriesPageReducer from '../components/pages/Trading/Categories/CategoriesPageSlice'
import categoryDetailsPageReducer from '../components/pages/Trading/Categories/CategoriesDetailsPage/CategoryDetailsPageSlice'
import categoriesFormReducer from '../components/forms/Trading/Categories/CategoriesFormSlice'

import signaturesPageReducer from '../components/pages/Accountant/Accounts/AccountDetailsPage/SignaturesPage/SignaturesPageSlice'

export const store = configureStore({
  reducer: {
    navHeader: navHeaderReducer,
    masterLayout: masterLayoutReducer,
    settings: settingReducer,
    loginForm: loginFormReducer,
    managersPage: managersPageReducer,
    managersRolesPage: managersRolesPageReducer,
    managersUserTypesPage: managersUserTypesPageReducer,
    managersPrivilegesPage: managersPrivilegesPageReducer,
    managersGroupsPage: managersGroupsPageReducer,
    organizationsPage: organizationsPageReducer,
    managersRolesForm: managersRolesFormReducer,
    managersUserTypesForm: managersUserTypesFormReducer,
    managersGroupsForm: managersGroupsFormReducer,
    managersUsersForm: managersUsersReducer,
    managersPrivilegesForm: managersPrivilegesReducer,
    organizationForm: organizationFormReducer,
    changePasswordForm: changePasswordReducer,
    managersUserTypeDetailsPage: managersUserTypeDetailsPageReducer,
    managersUserDetailsPage: managersUserDetailsPageReducer,
    managersRoleDetailsPage: managersRoleDetailsPageReducer,
    managersGroupDetailsPage: managersGroupDetailsPageReducer,
    managersPrivilegeDetailsPage: managersPrivilegeDetailsPageReducer,
    managersRolePrivilegesDetailsPage: managersRolePrivilegesDetailsPageReducer,
    managersRolePrivilegesForm: managersRolePrivilegesFormReducer,
    organizationDetailsPage: organizationDetailsPageReducer,
    managersPrivilegeDataPage: managersPrivilegeDataPageReducer,
    managersPrivilegeDataDetailsPage: managersPrivilegeDataDetailsPageReducer,
    managersDataRestrictionsPage: managersDataRestrictionsPageReducer,
    managersDataRestrictionsForm: managersDataRestrictionsFormReducer,
    managersGroupMembersPage: managersGroupMembersPageReducer,
    profilePage: profilePageReducer,
    profileForm: profileFormReducer,
    managersGroupMembersFrom: managersGroupMembersFromReducer,
    managersGroupMembershipDetailsPage: managersGroupMembershipDetailsPageReducer,
    managersRolePrivilegeDetailsPage: managersRolePrivilegeDetailsPageReducer,
    managersDataRestrictionDetailsPage: managersDataRestrictionDetailsPageReducer,
    organizationClientPortalSettingPage: organizationClientPortalSettingPageReducer,
    organizationClientPortalSettingForm: organizationClientPortalSettingFormReducer,


    customersPage: customersPageReducer,
    customersRolesPage: customersRolesPageReducer,
    customersUserTypesPage: customersUserTypesPageReducer,
    customersPrivilegesPage: customersPrivilegesPageReducer,
    customersRolesForm: customersRolesFormReducer,
    customersUserTypesForm: customersUserTypesFormReducer,
    customersGroupsForm: customersGroupsFormReducer,
    customersUsersForm: customersUsersReducer,
    customersPrivilegesForm: customersPrivilegesReducer,
    customersUserTypeDetailsPage: customersUserTypeDetailsPageReducer,
    customersUserDetailsPage: customersUserDetailsPageReducer,
    customersRoleDetailsPage: customersRoleDetailsPageReducer,
    customersGroupDetailsPage: customersGroupDetailsPageReducer,
    customersPrivilegeDetailsPage: customersPrivilegeDetailsPageReducer,
    customersRolePrivilegesDetailsPage: customersRolePrivilegesDetailsPageReducer,
    customersRolePrivilegesForm: customersRolePrivilegesFormReducer,
    customersPrivilegeDataPage: customersPrivilegeDataPageReducer,
    customersPrivilegeDataDetailsPage: customersPrivilegeDataDetailsPageReducer,
    customersDataRestrictionsPage: customersDataRestrictionsPageReducer,
    customersDataRestrictionsForm: customersDataRestrictionsFormReducer,
    customersGroupMembersPage: customersGroupMembersPageReducer,
    customersGroupMembersFrom: customersGroupMembersFromReducer,
    customersGroupsPage: customersGroupsPageReducer,
    customersGroupMembershipDetailsPage: customersGroupMembershipDetailsPageReducer,
    customersRolePrivilegeDetailsPage: customersRolePrivilegeDetailsPageReducer,
    customersDataRestrictionDetailsPage: customersDataRestrictionDetailsPageReducer,

    accountTypesPage: accountTypesPageReducer,
    accountTypesForm: accountTypesFormReducer,
    accountTypeDetailsPage: accountTypeDetailsPageReducer,
    currenciesPage: currenciesPageReducer,
    currenciesForm: currenciesFormReducer,
    currencyDetailsPage: currencyDetailsPageReducer,
    feesPage: feesPageReducer,
    feesForm: feesFormReducer,
    feeDetailsPage: feeDetailsPageReducer,
    treasuryTypesPage: treasuryTypesPageReducer,
    treasuryTypesForm: treasuryTypesFormReducer,
    treasuryTypeDetailsPage: treasuryTypeDetailsPageReducer,
    accountTypeRulesPage: accountTypeRulesPageReducer,
    accountTypeRulesForm: accountTypeRulesFormReducer,
    accountTypeRuleDetails: accountTypeRuleDetailsReducer,
    treasuryTypeRulesPage: treasuryTypeRulesPageReducer,
    treasuryTypeRuleDetails: treasuryTypeRuleDetailsReducer,
    treasuryTypeRulesForm: treasuryTypeRulesFormReducer,
    limitationsPage: limitationsPageReducer,
    limitationsForm: limitationsFormReducer,
    limitationDetailsPage: limitationDetailsPageReducer,
    accountsPage: accountsPageReducer,
    accountsForm: accountsFormReducer,
    accountDetailsPage: accountDetailsPageReducer,
    feeCollectionAccountsForm: feeCollectionAccountsFormReducer,
    feeCollectionAccountsPage: feeCollectionAccountsPageReducer,
    treasuriesPage: treasuriesPageReducer,
    treasuriesForm: treasuriesFormReducer,
    treasuryDetailsPage: treasuryDetailsPageReducer,
    treasuryDepositsPage: treasuryDepositsPageReducer,
    treasuryDepositsForm: treasuryDepositsFormReducer,
    treasuryStatementPage: treasuryStatementPageReducer,
    transferForm: transferFormReducer,
    externalTransferForm: externalTransferFormReducer,
    depositForm: depositFormReducer,
    withdrawForm: withdrawFormReducer,
    treasuryTransactionDetailsPage: treasuryTransactionDetailsPageReducer,
    treasuryTransactionsForm: treasuryTransactionsFormReducer,
    accountStatementPage: accountStatementPageReducer,
    accountStatementDetailsPage: accountTransactionDetailsPageReducer,
    accountTransactionsForm: accountTransactionsFormReducer,
    reservedBalancePage: reservedBalancePageReducer,
    reservedBalancesForm: reservedBalancesFormReducer,
    reservedBalanceDetailsPage: reservedBalanceDetailsPageReducer,
    accountStatementForm: accountStatementFormReducer,
    trasuryStatementForm: trasuryStatementFormReducer,
    withdrawAmountForm: withdrawAmountFormReducer,
    transferAmountForm: transferAmountFormReducer,
    authorizationForm: authorizationFormReducer,

    currencyPairsPage: currencyPairsPageReducer,
    currencyPairDetailsPage: currencyPairDetailsPageReducer,
    currencyPairsForm: currencyPairsFormReducer,
    ordersPage: ordersPageReducer,
    ordersForm: ordersFormReducer,
    orderDetailsPage: orderDetailsPageReducer,
    orderTransactionsPage: orderTransactionsPageReducer,
    externalOrdersForm: externalOrdersFormReducer,
    orderTransactionDetailsPage: orderTransactionDetailsPageReducer,

    logsPage: logsPageReducer,
    logDetailsPage: logDetailsPageReducer,

    treasuryBalanceReportPage: treasuryBalanceReportPageReducer,
    accountBalanceReportPage: accountBalanceReportPageReducer,
    currencyExchangeRatesReportPage: currencyExchangeRatesReportPageReducer,
    tradingReportPage: tradingReportPageReducer,
    transactionTotalsReportPage: transactionTotalsReportPageReducer,

    transactionsStatementPage: transactionsStatementPageReducer,
    externalTransferAmountForm: externalTransferAmountFormReducer,
    accountAuthorizationPage: accountAuthorizationPageReducer,
    accountAuthorizationForm: accountAuthorizationFormReducer,
    accountAuthorizationDetailsPage: accountAuthorizationDetailsPageReducer,
    linkAccountPage: linkAccountPageReducer,
    authorizationDetailsPage: authorizationDetailsPageReducer,
    advancedChartPage: advancedChartPageReducer,
    currencyPairPriceChangeLogPage: currencyPairPriceChangeLogPageReducer,
    currencyPairPriceChangeLogForm: currencyPairPriceChangeLogFormReducer,
    currencyPairPriceChangeLogDetailsPage: currencyPairPriceChangeLogDetailsPageReducer,
    categoriesPage: categoriesPageReducer,
    categoryDetailsPage: categoryDetailsPageReducer,
    categoriesForm: categoriesFormReducer,
    signaturesPage: signaturesPageReducer ,
    accountChartPage: accountChartPageReducer,
    treasuryChartPage: treasuryChartPageReducer,
    placeOrderForm: placeOrderFormReducer

  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

