import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPersonaProps, NormalPeoplePicker, IPickerItemProps, ValidationState, PeoplePickerItem, DialogFooter, CheckboxVisibility, ConstrainMode } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { dismissMessage, getItems, deleteItem, reset, addItem, freeBalance, getAccounts, resetAccountsSet, resetSet, setIsFilteredAccountSet, setIsFilteredSet, setNumberOfResults, setSearchText, setReasonFilterOption, setSelectedItems, setSelectedAccountItems, setSort, updateItem, setStatusFilterOption, setMessage, getAccount, setReferenceTransactionFilterOption, setTriggeredbyTransactionFilterOption } from "./ReservedBalancesPageSlice"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, Int64Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../app/Themes"
import { SearchField } from "../../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { GetRolesRequest } from "../../../../repository/UserManagement/Customers/role_pb"
import { GetUserTypesRequest } from "../../../../repository/UserManagement/Managers/usertype_pb"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { FreeAmountRequest, FreeAmountResponse, GetBalanceReservationsResponse, GetBalanceReservationsRequest, DeleteReservationRequest, DeleteReservationResponse } from '../../../../repository/Accountant/balance_reservation_pb';
import { GetAccountRequest, GetAccountsRequest } from "../../../../repository/Accountant/account_pb"
import { AccountStatus, AuthorizedOperation, ReservationStatus } from "../../../../app/Enums"
import { ReservedBalancesForm } from "../../../forms/Accountant/ReservedBalances/ReservedBalancesForm"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"
import { List } from "../../../common/List/List"
import { AccountsPage } from "../Accounts/AccountsPage"
import { TransferAmountForm } from "../../../forms/Accountant/ReservedBalances/TransferAmount/TransferAmountForm"
import { WithdrawAmountForm } from "../../../forms/Accountant/ReservedBalances/WithdrawAmount/WithdrawAmountForm"
import { AuthorizationForm } from "../../../forms/Accountant/Accounts/AuthorizationForm"
import { ExternalTransferAmountForm } from "../../../forms/Accountant/ReservedBalances/ExternalTransfareAmount/ExternalTransfareAmountForm"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetBalanceReservationsRequest;
let accountsReq: GetAccountsRequest;

let org: number = -1;

let getItemsPromise: any;
let actionPromise: any;
let getFilterPromise: any;

const key = "accountsManagement/reservedBalances"
export const ReservedBalancesPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        accounts: TableState
        filters: any[],
        reasonFilterOption: { key: number, text: string },
        statusFilterOption: { key: number, text: string },
        account: any,
        triggeredbyTransactionFilterOption: { value: string, text: string },
        referenceTransactionFilterOption: { value: string, text: string },

    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.reservedBalancePage.isChangeStateLoading,
            set: state.reservedBalancePage.set,
            message: state.reservedBalancePage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.reservedBalancePage.searchText,
            accounts: state.reservedBalancePage.accounts,
            filters: state.reservedBalancePage.filters,
            reasonFilterOption: state.reservedBalancePage.reasonFilterOption,
            statusFilterOption: state.reservedBalancePage.statusFilterOption,
            account: state.reservedBalancePage.account,
            triggeredbyTransactionFilterOption: state.reservedBalancePage.triggeredbyTransactionFilterOption,
            referenceTransactionFilterOption: state.reservedBalancePage.referenceTransactionFilterOption

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});

    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setStatusFilterOption({ key: 0, text: "" }))
        req.setStatus(undefined)
        dispatch(setReasonFilterOption({ key: 0, text: "" }))
        req.setReason(undefined)
        dispatch(setSelectedAccountItems([]))
        req.setAccount(undefined)

        dispatch(setTriggeredbyTransactionFilterOption({ text: "", value: "" }))
        req.setTriggeredby(undefined)
        dispatch(setReferenceTransactionFilterOption({ text: "", value: "" }))
        req.setReferencetransaction(undefined)
    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }
        if (state.accounts.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.accounts.selected.at(0)?.internalId));
            req.setAccount(wrapper)
            isFiltered = true;
        } else {
            req.setAccount(undefined)
        }
        if (state.reasonFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.reasonFilterOption.key);
            req.setReason(wrapper)
            isFiltered = true;

        } else {
            req.setReason(undefined)
        }

        if (state.statusFilterOption.key != 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(state.statusFilterOption.key);
            req.setStatus(wrapper)
            isFiltered = true;

        } else {
            req.setStatus(undefined)
        }
        if (state.triggeredbyTransactionFilterOption && state.triggeredbyTransactionFilterOption.value.trim().length > 0) {
            const wrapper = new Int64Value();
            wrapper.setValue(Number(state.triggeredbyTransactionFilterOption.value.trim()));
            req.setTriggeredby(wrapper)
            isFiltered = true;

        } else {
            req.setTriggeredby(undefined)
        }

        if (state.referenceTransactionFilterOption && state.referenceTransactionFilterOption.value.trim().length > 0) {
            const wrapper = new Int64Value();
            wrapper.setValue(Number(state.referenceTransactionFilterOption.value.trim()));
            req.setReferencetransaction(wrapper)
            isFiltered = true;

        } else {
            req.setReferencetransaction(undefined)
        }

        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetBalanceReservationsRequest();
        accountsReq = new GetAccountsRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                accountsReq.setOrganization(wrapper)

            }
        }
        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)

        accountsReq.setNumofresults(state.accounts.numberOfResults)
        accountsReq.setOrder(state.accounts.isDescending)

        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
        return () => { //clean up
            getItemsPromise?.abort();
            actionPromise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [
        {
            key: 'id',
            name: t("id"),
            minWidth: 20,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/reserved-balances/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/reserved-balances/" + item?.id + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined} > {item?.id}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'accountName',
            name: t("account"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/accounts/" + item?.accountId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/accounts/" + item?.accountId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.accountName == "" ? item?.accountId : item?.accountName }</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'amount',
            name: t("reservedAmount"),
            minWidth: 20,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.amount != undefined)
                    return <Text>{currencyFormatter(item?.amount, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },



        {
            key: 'triggeredbyTransactionId',
            name: t("triggeredbyTransaction"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.triggeredbyTransactionId != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/account-transactions/" + item?.triggeredbyTransactionInternalId + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/account-transactions/" + item?.triggeredbyTransactionInternalId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.triggeredbyTransactionId}</Link >;
            },
        },

        {
            key: 'status',
            name: t("status"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.status != undefined) {
                    if (item?.status == ReservationStatus.FREED)
                        return <Text>{t("freed")}</Text>;
                    else if (item?.status == ReservationStatus.FORWARDED)
                        return <Text>{t("forwarded")}</Text>;
                    else if (item?.status == ReservationStatus.RETURNED)
                        return <Text>{t("returned")}</Text>;
                    else if (item?.status == ReservationStatus.RESERVED)
                        return <Text>{t("reserved")}</Text>;
                    else if (item?.status == ReservationStatus.TRANSFERRED)
                        return <Text>{t("transferred")}</Text>;
                    else if (item?.status == ReservationStatus.WITHDRAWN)
                        return <Text>{t("withdrawn")}</Text>;
                    else if (item?.status == ReservationStatus.LOCKED)
                        return <Text>{t("locked")}</Text>;
                }

            },
        },
        {
            key: 'referenceTransactionId',
            name: t("referenceTransaction"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.referenceTransactionId != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/account-transactions/" + item?.referenceTransactionInternalId + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/account-transactions/" + item?.referenceTransactionInternalId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.referenceTransactionId}</Link >;
            },
        },
        {
            key: 'note',
            name: t("note"),
            minWidth: 50,
            maxWidth: 200,
            isMultiline: true,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.note}</Text>;
            },
        },

        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 160,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'edit',
            text: t("edit"),
            title: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(101)
                }
            }
        },
        {
            key: 'releaseAmount',
            text: t("releaseAmount"),
            title: t("releaseAmount"),
            disabled: state.set.selected.at(0)?.status != ReservationStatus.RESERVED,
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: "Accept", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={(state.isChangeStateLoading && currentAction == 2) || (state.isChangeStateLoading && currentAction == 108) ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 || (state.isChangeStateLoading && currentAction == 108) ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 || (state.isChangeStateLoading && currentAction == 108) ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(108)
                    let req = new GetAccountRequest();
                    req.setId(state.set.selected.at(0)?.accountId)
                    actionPromise?.abort();
                    actionPromise = dispatch(getAccount({ body: req, headers: getHeaders() }))
                    actionPromise.unwrap().then((e: any) => {
                        setCurrenctAction(2)
                        toggleisActionConfirmationCalloutVisible()

                    })

                }
            }
        },
        {
            key: 'transferMoney',
            text: t("transferMoney"),
            iconProps: { iconName: 'Switch' },
            disabled: state.set.selected.at(0)?.status != ReservationStatus.RESERVED,
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 105 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 105 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 105 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            title: t("transferMoney"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(105)
                    let req = new GetAccountRequest();
                    req.setId(state.set.selected.at(0)?.accountId)
                    actionPromise?.abort();
                    actionPromise = dispatch(getAccount({ body: req, headers: getHeaders() }))
                    actionPromise.unwrap().then((e: any) => {
                        setCurrenctAction(5)
                    })

                }
            }
        },

        {
            key: 'externalTransferMoney',
            text: t("externalTransferMoney"),
            iconProps: { iconName: 'SwitcherStartEnd' },
            disabled: state.set.selected.at(0)?.status != ReservationStatus.RESERVED,
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 109 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 109 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 109 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            title: t("externalTransferMoney"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(109)
                    let req = new GetAccountRequest();
                    req.setId(state.set.selected.at(0)?.accountId)
                    actionPromise?.abort();
                    actionPromise = dispatch(getAccount({ body: req, headers: getHeaders() }))
                    actionPromise.unwrap().then((e: any) => {
                        setCurrenctAction(9)
                    })

                }
            }
        },
        {
            key: 'withdraw',
            text: t("withdraw"),
            iconProps: { iconName: 'PublishContent' },
            disabled: state.set.selected.at(0)?.status != ReservationStatus.RESERVED,
            title: t("withdraw"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 107 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 107 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 107 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(107)

                    let req = new GetAccountRequest();
                    req.setId(state.set.selected.at(0)?.accountId)
                    actionPromise?.abort();
                    actionPromise = dispatch(getAccount({ body: req, headers: getHeaders() }))
                    actionPromise.unwrap().then((e: any) => {
                        setCurrenctAction(7)
                    })
                }
            }
        },
        {
            key: 'delete',
            text: t("delete"),
            title: t("delete"),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Delete", styles: { root: { fontWeight: "bold" } } },
            disabled: state.set.selected.at(0)?.status == ReservationStatus.RESERVED,
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },

    ];

    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('reserveAmount'),
            title: t("reserveAmount"),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                //  navigate("add")
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t("refresh"),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t("sort"),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t("ascendingDate"),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t("descendingDate"),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];


    const accountsSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accounts.hasMore && !state.accounts.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accounts.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                accountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccounts({ body: accountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.accounts.items.length == 0 ? state.accounts.isFetching : false,
        isSearching: state.accounts.items.length > 0 ? state.accounts.isFetching : false,


    };


    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {


        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        accountsReq.setSearch(wrapper)
        accountsReq.setNextto(undefined)
        dispatch(setIsFilteredAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: accountsReq, headers: getHeaders() }))
        return [];


    }


    return (
        <Stack >
            <Popup isOpen={(currentAction == 100)} title={t("reserveAmount")} isBlocking={true} onDismiss={() => { setCurrenctAction(0) }} >

                <ReservedBalancesForm childrenGap={10} maxWidth={480} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("editBalanceResereservation")} onDismiss={() => { setCurrenctAction(0) }} >

                <ReservedBalancesForm childrenGap={10} maxWidth={480} type={FormType.EDIT} fetchData={true} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 1000)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >
                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 5)} title={t("transfer")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <TransferAmountForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{
                    from: state.account, amount: state.set.selected.at(0)?.amount, id: state.set.selected.at(0)?.id
                }} onSuccess={(e) => {
                    //setCurrenctAction(0)
                    dispatch(updateItem({
                        ...state.set.selected.at(0), referenceTransactionId: e?.id ?? undefined,
                        referenceTransactionInternalId: e?.internalid ?? "", status: ReservationStatus.TRANSFERRED


                    }))
                    dispatch(setMessage(e.message))
                    setCurrenctAction(0)

                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 9)} title={t("externalTransfer")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <ExternalTransferAmountForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{
                    from: state.account, amount: state.set.selected.at(0)?.amount, id: state.set.selected.at(0)?.id
                }} onSuccess={(e) => {
                    //setCurrenctAction(0)
                    dispatch(updateItem({
                        ...state.set.selected.at(0), referenceTransactionId: e?.id ?? undefined,
                        referenceTransactionInternalId: e?.internalid ?? "", status: ReservationStatus.TRANSFERRED


                    }))
                    dispatch(setMessage(e.message))
                    setCurrenctAction(0)

                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 7)} title={t("withdraw")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <WithdrawAmountForm childrenGap={10} maxWidth={320} type={FormType.ADD} renderObject={{
                    account: state.account, amount: state.set.selected.at(0)?.amount, id: state.set.selected.at(0)?.id
                }} onSuccess={(e) => {
                    // setCurrenctAction(0)
                    dispatch(updateItem({
                        ...state.set.selected.at(0), referenceTransactionId: e?.id ?? undefined,
                        referenceTransactionInternalId: e?.internalid ?? "", status: ReservationStatus.WITHDRAWN
                    }))
                    dispatch(setMessage(e.message))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 14)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                    account: state.account,
                    operation: AuthorizedOperation.FREE_BALANCE,
                    parameters: [{ key: "Amount", value: state.set.selected.at(0)?.amount + "", viewValue: state.set.selected.at(0)?.currencySymbol },
                    ],
                }} onSuccess={(e) => {
                    if (state.account && e.authCode) {
                        if (currentAction == 14) {
                            let req = new FreeAmountRequest();
                            req.setId(state.set.selected.at(0)?.id)
                            var r = new StringValue();
                            r.setValue(e.authCode)
                            req.setAuthcode(r)
                            actionPromise?.abort();
                            setCurrenctAction(2)
                            actionPromise = dispatch(freeBalance({ body: req, headers: getHeaders() }))
                            actionPromise.unwrap().then((e: any) => {
                                setCurrenctAction(0)
                            })

                        }
                    } else {
                        setCurrenctAction(0)
                    }
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />
            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareReservedAmount")} value={`https://${window.location.host}/reserved-balances/${state.set.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("delete") : currentAction == 2 ? t("releaseAmount") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("deleteReservedBalanceDesc") : currentAction == 2 ? t("releaseAmountDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.set.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let req = new DeleteReservationRequest();
                                            req.setId(state.set.selected.at(0)?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(deleteItem({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        } else if (currentAction == 2) {
                                            if (state.account?.isAuthorizationRequired) {
                                                setCurrenctAction(14)
                                                return;
                                            }
                                            let req = new FreeAmountRequest();
                                            req.setId(state.set.selected.at(0)?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(freeBalance({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        }
                                    }
                                }} text={(currentAction == 1 ? t("delete") : currentAction == 2 ? t("release") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}

                        </Text>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("account")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("account") }}
                                isPeoplePicker={true}
                                suggestionsHeaderText={t('accounts')}
                                isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                        accountsReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getAccounts({ body: accountsReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
                                    }

                                }}

                                selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedAccountItems([]))
                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetAccountsSet())
                                    accountsReq.setSearch(undefined)
                                    accountsReq.setNextto(undefined)
                                    dispatch(setIsFilteredAccountSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getAccounts({ body: accountsReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onAccountsFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000);

                                        }
                                    }]}
                            />


                        </Stack>
                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("triggeredbyTransaction")}</Label>
                            <SpinButton onChange={(e: any) => {
                                if (e)
                                    dispatch(setTriggeredbyTransactionFilterOption({ value: e?.target?.value, text: t("triggeredbyTransaction") + ":" + e?.target?.value }))

                            }} max={99999999999999999999} min={1} value={state.triggeredbyTransactionFilterOption.value} styles={{ root: { width: 300 } }} />

                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("status")}</Label>
                            <Dropdown
                                selectedKey={state.statusFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("reserved") }, { key: 2, text: t("freed") }, { key: 3, text: t("returned") },
                                { key: 4, text: t("forwarded") }, { key: 5, text: t("transferred") }, { key: 6, text: t("withdrawn") }, { key: 7, text: t("locked") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setStatusFilterOption({ key: Number(item?.key), text: t("status") + ":" + item?.text }))

                                })}


                            />
                        </Stack>
                        <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 20 }} >
                            <Label>{t("referenceTransaction")}</Label>
                            <SpinButton onChange={(e: any) => {
                                if (e)
                                    dispatch(setReferenceTransactionFilterOption({ value: e?.target?.value, text: t("referenceTransaction") + ":" + e?.target?.value }))

                            }} max={99999999999999999999} min={1} step={1} value={state.referenceTransactionFilterOption.value} styles={{ root: { width: 300 } }} />

                        </Stack>

                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null
            }

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {
                                if (e.key == 1) {
                                    dispatch(setSelectedAccountItems([]))
                                    req.setAccount(undefined)
                                }
                                if (e.key == 2) {
                                    dispatch(setStatusFilterOption({ key: 0, text: "" }))
                                    req.setStatus(undefined)
                                }
                                if (e.key == 3) {
                                    dispatch(setReasonFilterOption({ key: 0, text: "" }))
                                    req.setReason(undefined)
                                }
                                if (e.key == 4) {
                                    dispatch(setTriggeredbyTransactionFilterOption({ value: "", text: "" }))
                                    req.setTriggeredby(undefined)
                                }
                                if (e.key == 5) {
                                    dispatch(setReferenceTransactionFilterOption({ value: "", text: "" }))
                                    req.setReferencetransaction(undefined)
                                }
                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="reservedBalances"
                items={state.set.items}
                columns={c}
                compRef={ref}
                constrainMode={ConstrainMode.unconstrained}

                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 176px)" : "calc(100vh - 224px)"} selection={_selection}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.fixedColumns}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {
                props.isSelection ? <DialogFooter>
                    <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                        if (props.onItemSelected && state.set.selected.length > 0) {
                            props.onItemSelected(state.set.selected.at(0))
                        }
                    }} >

                        <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                    </PrimaryButton>
                    <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                        if (props.onCancel) {
                            props.onCancel()
                        }
                    }} />
                </DialogFooter> : undefined
            }
        </Stack >



    );
}




