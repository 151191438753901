import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError, StatusCode } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, APIRequest, APIResponse, refreshSession, getSession, ACCOUNTANT_API_URL } from '../../../../../app/Api';
import { AccountStatus } from '../../../../../app/Enums';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { AccountEndpointClient } from '../../../../../repository/Accountant/AccountServiceClientPb';
import { AccountOperationEndpointClient } from '../../../../../repository/Accountant/Account_operationServiceClientPb';
import { GetBalanceRequest, GetBalanceResponse } from '../../../../../repository/Accountant/account_operation_pb';
import { CloseAccountRequest, CloseAccountResponse, GetAccountRequest, UpdateAccountTypeResponse, UpdateAuthorizationRequirementResponse, GetAccountResponse, TransferAccountOwnershipRequest, TransferAccountOwnershipResponse, UpdateAccountLimitationRequest, UpdateAccountLimitationResponse, UpdateAccountStatusRequest, UpdateAccountStatusResponse, UpdateAccountTypeRequest, UpdateAuthorizationRequirementReplay, UpdateAuthorizationRequirementRequest, LinkResponse, RevokeLinkRequest } from '../../../../../repository/Accountant/account_pb';
import { AccountTypeEndpointClient } from '../../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesRequest, GetAccountTypesResponse } from '../../../../../repository/Accountant/account_type_pb';
import { LimitationEndpointClient } from '../../../../../repository/Accountant/LimitationServiceClientPb';
import { GetLimitationsRequest, GetLimitationsResponse } from '../../../../../repository/Accountant/limitation_pb';
import { UserEndpointClient } from '../../../../../repository/UserManagement/Customers/UserServiceClientPb';
import { GetUsersRequest, GetUsersResponse } from '../../../../../repository/UserManagement/Customers/user_pb';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { TableState } from '../../../../common/Table/TableSate';


export interface AccountDetailsPageState {
    item: any
    balances: any
    isChangeStateLoading: boolean,
    message?: ApiMessage,
    isLoading: boolean,
    owners: TableState,
    limitations: TableState,
    accountTypes: TableState


}

const initialState: AccountDetailsPageState = {
    item: undefined,
    balances: undefined,
    isChangeStateLoading: false,
    message: undefined,
    isLoading: false,
    owners: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    limitations: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    accountTypes: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },

}

const client = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientAccOp = new AccountOperationEndpointClient(ACCOUNTANT_API_URL, null, null);
const clienCustomers = new UserEndpointClient(API_URL, null, null);
const clienLimition = new LimitationEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientAccountType = new AccountTypeEndpointClient(ACCOUNTANT_API_URL, null, null);

export const get = createAsyncThunk<GetAccountResponse.AsObject, APIRequest<GetAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/get',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.getAccount(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const getBalance = createAsyncThunk<GetBalanceResponse.AsObject, APIRequest<GetBalanceRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/getBalance',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await clientAccOp.getBalance(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const getAccountTypes = createAsyncThunk<GetAccountTypesResponse.AsObject, APIRequest<GetAccountTypesRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/accountTypes/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientAccountType.getAccountTypes(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

export const changeOwnership = createAsyncThunk<APIResponse<TransferAccountOwnershipResponse.AsObject>, APIRequest<TransferAccountOwnershipRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/transferAccountOwnership',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.transferOwnership(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), ownerId: req.body.getOwner() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const updateAuthorization = createAsyncThunk<APIResponse<UpdateAuthorizationRequirementResponse.AsObject>, APIRequest<UpdateAuthorizationRequirementRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/updateAuthorization',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateAuthorizationRequirement(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), status: req.body.getIsauthorizationrequired() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const updateLimit = createAsyncThunk<APIResponse<UpdateAccountLimitationResponse.AsObject>, APIRequest<UpdateAccountLimitationRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/updateAccountLimitation',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateLimitation(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), limitationId: req.body.getLimitation() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const updateStatus = createAsyncThunk<APIResponse<UpdateAccountStatusResponse.AsObject>, APIRequest<UpdateAccountStatusRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/updateStatus',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateStatus(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), status: req.body.getStatus() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const updateType = createAsyncThunk<APIResponse<UpdateAccountTypeResponse.AsObject>, APIRequest<UpdateAccountTypeRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/updateType',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.updateType(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId(), type: req.body.getAccounttype() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)



export const closeAccount = createAsyncThunk<APIResponse<CloseAccountResponse.AsObject>, APIRequest<CloseAccountRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/closeAccount',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.close(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId() }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)


export const getClinetUsers = createAsyncThunk<GetUsersResponse.AsObject, APIRequest<GetUsersRequest>, {
    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/clients/fetch',
    async (req, thunkApi) => {

        let callReq = async (): Promise<any> => {
            try {
                var r = await clienCustomers.getUsers(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {
                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const getLimitations = createAsyncThunk<GetLimitationsResponse.AsObject, APIRequest<GetLimitationsRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/limitations/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clienLimition.getLimitations(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const removeLink = createAsyncThunk<APIResponse<LinkResponse.AsObject>, APIRequest<RevokeLinkRequest>, {

    rejectValue: ApiMessage
}>(
    'pages/accountant/accountDetails/removeLink',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {

            try {
                var r = await client.revokeLink(req.body, req.headers ?? {});
                return { metadata: { id: req.body.getId()}, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }

        }
        return await callReq();
    }
)

export const accountDetailsPageSlice = createSlice({
    name: 'pages/accountant/accountDetails',
    initialState,
    reducers: {
        dismissMessage: (state) => {
            state.message = undefined;

        },
        reset: (state) => {
            state.message = initialState.message;
            state.isChangeStateLoading = initialState.isChangeStateLoading;
            state.owners = initialState.owners;
            state.limitations = initialState.limitations;
            state.accountTypes = initialState.accountTypes;
            state.item = initialState.item;
            state.balances = initialState.balances;


        },

        resetItem: (state) => {
            state.item = undefined;
            state.balances = undefined;

        },

        updateItem: (state, action: PayloadAction<any>) => {
            state.item = action.payload;

        },

        resetUsers: (state) => {
            state.owners.items = [];
            state.owners.hasMore = true;
        },
        resetLimitations: (state) => {
            state.limitations.items = [];
            state.limitations.hasMore = true;
        },

        resetAccountTypes: (state) => {
            state.accountTypes.items = [];
            state.accountTypes.hasMore = true;
        },


        setSelectedAccountTypeItems: (state, action: PayloadAction<any[]>) => {
            state.accountTypes.selected = action.payload;
        },

        setIsFilteredAccountTypesSet: (state, action: PayloadAction<boolean>) => {
            state.accountTypes.isFilteredSet = action.payload;
        },

        setSelectedLimitationItems: (state, action: PayloadAction<any[]>) => {
            state.limitations.selected = action.payload;

        },

        setSelectedUserItems: (state, action: PayloadAction<any[]>) => {
            state.owners.selected = action.payload;


        },

        setIsFilteredLimitationsSet: (state, action: PayloadAction<boolean>) => {
            state.limitations.isFilteredSet = action.payload;
        },

        setIsFilteredOwnersSet: (state, action: PayloadAction<boolean>) => {
            state.owners.isFilteredSet = action.payload;
        },


    },
    extraReducers: (builder) => {

        builder.addCase(getAccountTypes.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accounttypesList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])

                if (r.length < state.accountTypes.numberOfResults) {
                    state.accountTypes.hasMore = false;
                }
                state.accountTypes.items = state.accountTypes.items.concat(r);

            } else {
                state.accountTypes.hasMore = false;
            }
            state.accountTypes.isFetching = false

        })
        builder.addCase(getAccountTypes.rejected, (state, action) => {
            state.accountTypes.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getAccountTypes.pending, (state, action) => {
            state.accountTypes.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(getClinetUsers.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.usersList.map(val => {
                    return {
                        internalId: val.profile?.internalid,
                        name: (val.profile?.firstname?.value ?? "") + " " + (val.profile?.lastname?.value ?? ""),
                        firstname: (val.profile?.firstname?.value ?? ""),
                        lastname: (val.profile?.lastname?.value ?? ""),
                        username: val.username?.value ?? "",
                        isActive: val.isactive,
                        isConfirmed: val.isconfirmed,
                        createdDate: formatDate(timestampToDate(val.profile?.createddate?.seconds, val.profile?.createddate?.nanos)),
                        addedBy: (val.profile?.addedby?.firstname?.value ?? "") + " " + (val.profile?.addedby?.lastname?.value ?? ""),
                        addedById: val.profile?.addedby?.id?.value,
                        role: val.profile?.role?.name?.value,
                        roleId: val.profile?.role?.id,
                        usertypeId: val.profile?.usertype?.id,
                        usertype: val.profile?.usertype?.name?.value,
                        lastlogin: formatDate(timestampToDate(val.lastlogin?.seconds, val.lastlogin?.nanos)),
                        imageId: val.profile?.image?.id?.value,
                        imageExtension: val.profile?.image?.extension?.value,
                        imageName: val.profile?.image?.name?.value,
                        imageOriginalName: val.profile?.image?.originalname?.value,
                        imagePath: val.profile?.image?.path?.value,
                        imageSize: val.profile?.image?.size?.value ?? 0,
                        imageSource: val.profile?.image?.source?.value,

                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.owners.numberOfResults) {
                    state.owners.hasMore = false;
                }
                state.owners.items = state.owners.items.concat(r);
                //console.log(state.items)

            } else {
                state.owners.hasMore = false;
            }
            state.owners.isFetching = false

        })
        builder.addCase(getClinetUsers.rejected, (state, action) => {
            state.owners.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getClinetUsers.pending, (state, action) => {
            state.owners.isFetching = true;
            state.message = undefined;
        })



        builder.addCase(getLimitations.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.limitationsList.map(val => {
                    return {
                        id: val.id,
                        name: val.name?.value ?? "",
                        isAvailable: val.isavailable?.value ?? undefined,
                        isActive: val.isactive?.value ?? undefined,
                        type: val.type?.value ?? undefined,
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.limitations.numberOfResults) {
                    state.limitations.hasMore = false;
                }
                state.limitations.items = state.limitations.items.concat(r);
            } else {
                state.limitations.hasMore = false;
            }
            state.limitations.isFetching = false

        })
        builder.addCase(getLimitations.rejected, (state, action) => {
            state.limitations.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getLimitations.pending, (state, action) => {
            state.limitations.isFetching = true;
            // state.message = undefined;
        })
        builder.addCase(updateStatus.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.status = payload?.metadata.status
            }
        })
        builder.addCase(updateStatus.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateStatus.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })
        builder.addCase(updateAuthorization.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.isAuthorizationRequired = payload?.metadata.status
            }
        })
        builder.addCase(updateAuthorization.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateAuthorization.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        
        builder.addCase(removeLink.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response?.success?.message);
                state.item.isLinkingEnable = false;
                state.item.isLinkingMetadataSet = false;
            }
        })
        builder.addCase(removeLink.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(removeLink.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(closeAccount.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.status = AccountStatus.CLOSED

            }

        })
        builder.addCase(closeAccount.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(closeAccount.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(changeOwnership.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.ownerId = payload?.metadata.ownerId
                state.item.ownerName = state.owners.selected.at(0).name;
                state.owners.selected = [];
            }
        })
        builder.addCase(changeOwnership.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(changeOwnership.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateLimit.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.limitationId = payload?.metadata.limitationId
                state.item.limitationName = state.limitations.selected.at(0).name;
                state.limitations.selected = [];

            }
        })
        builder.addCase(updateLimit.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateLimit.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(updateType.fulfilled, (state, { payload }) => {
            state.isChangeStateLoading = false;
            if (payload) {
                state.message = toApiMessage(payload?.response.success?.message);
                state.item.accountTypeId = payload?.metadata.type
                state.item.accountTypeName = state.accountTypes.selected.at(0).name;
                state.accountTypes.selected = [];

            }
        })
        builder.addCase(updateType.rejected, (state, action) => {
            state.isChangeStateLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(updateType.pending, (state, action) => {
            state.isChangeStateLoading = true;
            state.message = undefined;
        })

        builder.addCase(getBalance.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                state.balances = {
                    freeBalance: payload.success?.freebalance?.value ?? undefined,
                    reservedBalance: payload.success?.reservedbalance?.value ?? undefined,
                    totalBalance: payload.success?.totalbalance?.value ?? undefined,
                    currencyId: payload.success?.currency?.id?.value ?? "",
                    currencyName: payload.success?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.currency?.symbol?.value ?? "",

                }
            }
        })
        builder.addCase(getBalance.rejected, (state, action) => {


        })
        builder.addCase(getBalance.pending, (state, action) => {

        })
        builder.addCase(get.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                let r = {
                    id: payload.success?.id,
                    internalId: payload.success?.internalid,
                    name: payload.success?.name?.value ?? "",
                    code: payload.success?.code?.value ?? "",
                    currencyId: payload.success?.currency?.id?.value ?? "",
                    currencyName: payload.success?.currency?.name?.value ?? "",
                    currencyCode: payload.success?.currency?.code?.value ?? "",
                    currencyDecimalPlaces: payload.success?.currency?.decimalplaces?.value ?? undefined,
                    currencyForm: payload.success?.currency?.form?.value ?? undefined,
                    currencySymbol: payload.success?.currency?.symbol?.value ?? "",
                    accountTypeId: payload.success?.accounttype?.id?.value ?? "",
                    accountTypeName: payload.success?.accounttype?.name?.value ?? "",
                    status: payload.success?.status?.value ?? undefined,
                    ownerFirstname: payload.success?.owner?.firstname?.value ?? "",
                    ownerLastname: payload.success?.owner?.lastname?.value ?? "",
                    ownerMiddlename: payload.success?.owner?.middlename?.value ?? "",
                    ownerName: (payload.success?.owner?.firstname?.value ?? "") + " " + (payload.success?.owner?.lastname?.value ?? ""),
                    ownerId: payload.success?.owner?.id?.value ?? "",
                    createdDate: formatDate(timestampToDate(payload.success?.createddate?.seconds, payload.success?.createddate?.nanos)),
                    addedBy: (payload.success?.createdby?.firstname?.value ?? "") + " " + (payload.success?.createdby?.lastname?.value ?? ""),
                    addedById: payload.success?.createdby?.id?.value,
                    transferControl: payload.success?.accountsetting?.tansfercontrol?.value ,
                    limitationId: payload.success?.limitation?.id?.value ?? "",
                    limitationName: payload.success?.limitation?.name?.value ?? "",
                    freeBalance: undefined,
                    reservedBalance: undefined,
                    totalBalance: undefined,
                    extraFields: [] as DynamicField[],
                    isAuthorizationRequired: payload.success?.accountsetting?.isauthorizationrequired,
                    isLinkingEnable: payload.success?.accountsetting?.islinkingenable?.value,
                    isLinkingMetadataSet: payload.success?.accountsetting?.islinkingmetadataset?.value,



                }

                if (payload?.success?.extrafieldsList) {
                    let count = 0
                    payload?.success?.extrafieldsList?.forEach(element => {
                        r.extraFields.push({ key: element.key, label: element.label, type: element.type, value: element.value })
                        count++
                    });
                }


                state.item = r
            }
        })
        builder.addCase(get.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(get.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, resetItem, resetAccountTypes, setIsFilteredAccountTypesSet, setSelectedAccountTypeItems, updateItem, resetLimitations, resetUsers, setIsFilteredLimitationsSet, setIsFilteredOwnersSet, setSelectedUserItems, setSelectedLimitationItems } = accountDetailsPageSlice.actions

export default accountDetailsPageSlice.reducer