import { ChoiceGroup, DefaultButton, Dialog, DialogFooter, DialogType, Icon, IconButton, IDialogContentProps, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, SpinButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, getBuyerFromAccounts, getBuyerToAccounts, getCurrencyPairs, getSellerFromAccounts, getSellerToAccounts, ordersFormSlice, OrdersFormState, resetBuyerFromAccountSet, reset, resetBuyerToAccountSet, resetCurrencyPairs, resetSellerFromAccountSet, resetSellerToAccountSet, setIsFilteredBuyerFromAccountSet, setIsFilteredBuyerToAccountSet, setIsFilteredCurrencyPairsSet, setIsFilteredSellerFromAccountSet, setIsFilteredSellerToAccountSet, setSelectedBuyerFromAccountItems, setSelectedBuyerToAccountItems, setSelectedCurrencyPairItems, setSelectedSellerFromAccountItems, setSelectedSellerToAccountItems, setStage1, resetMessage, getTransactionSummary } from "./OrdersFormSlice";
import * as Yup from 'yup';
import { DynamicInputField, DynamicNumberInputField, InputField, NumberInputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { currencyFormatter, formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { GetAccountsRequest } from "../../../../repository/Accountant/account_pb";
import { AccountStatus, AuthorizedOperation, Operations, OrderAction, OrderStatus, OrderType } from "../../../../app/Enums";
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb";
import { GetCurrencyPairsRequest } from "../../../../repository/Trading/currency_pair_pb";
import { DirectExchangeRequest, DirectExchangeResponse } from "../../../../repository/Trading/order_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { CurrencyPairsPage } from "../../../pages/Trading/CurrencyPairs/CurrencyPairsPage";
import { AccountsPage } from "../../../pages/Accountant/Accounts/AccountsPage";
import { AuthorizationForm } from "../../Accountant/Accounts/AuthorizationForm";
import { GetTransactionSummary, GetTransactionSummaryRequest } from "../../../../repository/Accountant/account_operation_pb";


let req: DirectExchangeRequest;
let getBuyerFromAccountsReq: GetAccountsRequest;
let getBuyerToAccountsReq: GetAccountsRequest;
let getSellerFromAccountsReq: GetAccountsRequest;
let getSellerToAccountsReq: GetAccountsRequest;
let currecyPairsReq: GetCurrencyPairsRequest;

let promise: any;
let getFilterPromise: any;

let org: number = -1;
let current: AuthenticateReply.AsObject;
let currenciesReq: GetCurrenciesRequest;

export const OrdersForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [currentAction, setCurrenctAction] = useState(0)
    const [showConfirmation, setShowConfirmation] = useState({
        type: DialogType.normal,
        title: '',
        subText: '',
        isMultiline: true,


    } as IDialogContentProps);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();

    const modalPropsStyles = { main: { maxWidth: 450 } };



    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
    const modalProps = useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        [isDraggable],
    );

    const state: {
        isLoading: boolean, message: ApiMessage | undefined, stage1: any, currencyPairs: TableState, buyerFromAccount: TableState, buyerToAccount: TableState,
        sellerFromAccount: TableState, sellerToAccount: TableState, confirmation: any[]
    } = useAppSelector((state) => {

        return {
            isLoading: state.ordersForm.isLoading, message: state.ordersForm.message,
            stage1: state.ordersForm.stage1,
            buyerFromAccount: state.ordersForm.buyerFromAccountSet,
            buyerToAccount: state.ordersForm.buyerToAccountSet,
            sellerFromAccount: state.ordersForm.sellerFromAccountSet,
            sellerToAccount: state.ordersForm.sellerToAccountSet,
            currencyPairs: state.ordersForm.currencyPairs,
            confirmation: state.ordersForm.confirmation

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new DirectExchangeRequest();
        currecyPairsReq = new GetCurrencyPairsRequest();
        getBuyerFromAccountsReq = new GetAccountsRequest();
        getBuyerToAccountsReq = new GetAccountsRequest();
        getSellerToAccountsReq = new GetAccountsRequest();
        getSellerFromAccountsReq = new GetAccountsRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                currecyPairsReq.setOrganization(wrapper)
                getBuyerFromAccountsReq.setOrganization(wrapper)
                getBuyerToAccountsReq.setOrganization(wrapper)
                getSellerToAccountsReq.setOrganization(wrapper)
                getSellerFromAccountsReq.setOrganization(wrapper)

            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        const stat = new Int32Value();
        stat.setValue(AccountStatus.ACTIVE);

        currecyPairsReq.setNumofresults(state.currencyPairs.numberOfResults)
        currecyPairsReq.setOrder(state.currencyPairs.isDescending)
        currecyPairsReq.setIsactive(boolv)

        getBuyerFromAccountsReq.setNumofresults(state.buyerFromAccount.numberOfResults)
        getBuyerFromAccountsReq.setOrder(state.buyerFromAccount.isDescending)
        getBuyerFromAccountsReq.setStatus(stat)

        getBuyerToAccountsReq.setNumofresults(state.buyerToAccount.numberOfResults)
        getBuyerToAccountsReq.setOrder(state.buyerToAccount.isDescending)
        getBuyerToAccountsReq.setStatus(stat)

        getSellerToAccountsReq.setNumofresults(state.sellerToAccount.numberOfResults)
        getSellerToAccountsReq.setOrder(state.sellerToAccount.isDescending)
        getSellerToAccountsReq.setStatus(stat)

        getSellerFromAccountsReq.setNumofresults(state.sellerFromAccount.numberOfResults)
        getSellerFromAccountsReq.setOrder(state.sellerFromAccount.isDescending)
        getSellerFromAccountsReq.setStatus(stat)


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            getFilterPromise?.abort();
            dispatch(reset());
        }
    }, [])


    useEffect(() => {
        if (state.confirmation.length > 0) {

            var fees = "";
            if (state.confirmation.at(0)?.debtorfeeValue != undefined && state.confirmation.at(0)?.debtorfeeValue != 0) {
                const fromSellerFeeAmount = currencyFormatter(Number(state.confirmation.at(0).debtorfeeValue ?? 0), { significantDigits: state.currencyPairs?.selected.at(0)?.baseCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.baseCurrencySymbol })
                fees = t("fromSeller", {
                    fromSeller: (state.sellerFromAccount?.selected?.at(0)?.name ?? "") + " (#" + state.sellerFromAccount?.selected?.at(0)?.id + ")",
                    fromSellerFeeAmount: fromSellerFeeAmount,
                })
            }
            if (state.confirmation.at(0)?.creditorfeeValue != undefined && state.confirmation.at(0)?.creditorfeeValue != 0) {
                const toBuyerFeeAmount = currencyFormatter(Number(state.confirmation.at(0).creditorfeeValue ?? 0), { significantDigits: state.currencyPairs?.selected.at(0)?.baseCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.baseCurrencySymbol })
                fees = fees + t("toBuyer", {
                    toBuyer: (state.buyerToAccount?.selected?.at(0)?.name ?? "") + " (#" + state.buyerToAccount?.selected?.at(0)?.id + ")",
                    toBuyerFeeAmount: toBuyerFeeAmount,
                })
            }

            if (state.confirmation.at(1)?.debtorfeeValue != undefined && state.confirmation.at(1)?.debtorfeeValue != 0) {
                const fromBuyerFeeAmount = currencyFormatter(Number(state.confirmation.at(1).debtorfeeValue ?? 0), { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })
                fees = fees + t("fromBuyer", {
                    fromBuyer: (state.buyerFromAccount?.selected?.at(0)?.name ?? "") + " (#" + state.buyerFromAccount?.selected?.at(0)?.id + ")",
                    fromBuyerFeeAmount: fromBuyerFeeAmount,
                })
            }

            if (state.confirmation.at(1)?.creditorfeeValue != undefined && state.confirmation.at(1)?.creditorfeeValue != 0) {
                const toSellerFeeAmount = currencyFormatter(Number(state.confirmation.at(1).creditorfeeValue ?? 0), { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })
                fees = fees + t("toSeller", {
                    toSeller: (state.sellerToAccount?.selected?.at(0)?.name ?? "") + " (#" + state.sellerToAccount?.selected?.at(0)?.id + ")",
                    toSellerFeeAmount: toSellerFeeAmount,
                })
            }
            const formattedAmount = currencyFormatter(state.confirmation.at(0).amount, { significantDigits: state.currencyPairs?.selected.at(0)?.baseCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.baseCurrencySymbol })
            const formattedPrice = currencyFormatter(state.confirmation.at(0).price, { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })
            const formattedTotal = currencyFormatter(state.confirmation.at(1).amount, { significantDigits: state.currencyPairs?.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.currencyPairs?.selected.at(0)?.quoteCurrencySymbol })

            if (fees != "") {
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    styles: { subText: { whiteSpace: 'pre-line' } },
                    subText: t("executeOrderConfirmWithFees", {
                        amount: formattedAmount, price: formattedPrice, total: formattedTotal,
                    }) + fees,
                })
            } else {
                setShowConfirmation({
                    type: DialogType.normal,
                    title: t("confirm"),
                    styles: { subText: { whiteSpace: 'pre-line' } },
                    subText: t("executeOrderConfirm", {
                        amount: formattedAmount, price: formattedPrice, total: formattedTotal,
                    }),
                })
            }


            toggleHideDialog()
        }



    }, [state.confirmation])

    const buyerToSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.buyerToAccount.hasMore && !state.buyerToAccount.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.buyerToAccount.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedBuyerToAccountItems([state.buyerToAccount.items.at(u)]))
            }


        },

        onGetMoreResults: () => {
            if (state.buyerToAccount.items.length > 0 && !state.buyerToAccount.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.buyerToAccount.items.at(state.buyerToAccount.items.length - 1).internalId);
                getBuyerToAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getBuyerToAccounts({ body: getBuyerToAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.buyerToAccount.items.length > 0 ? state.buyerToAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.buyerToAccount.items.length == 0 ? state.buyerToAccount.isFetching : false,
        isSearching: state.buyerToAccount.items.length > 0 ? state.buyerToAccount.isFetching : false,


    };


    const onBuyerToAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetBuyerToAccountSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getBuyerToAccountsReq.setSearch(wrapper)
        getBuyerToAccountsReq.setNextto(undefined)
        if (state.currencyPairs.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.currencyPairs.selected.at(0).baseCurrencyId);
            getBuyerToAccountsReq.setCurrency(r);
        } else {
            getBuyerToAccountsReq.setCurrency(undefined);

        }

        if (state.buyerFromAccount.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.buyerFromAccount.selected.at(0).ownerId);
            getBuyerToAccountsReq.setOwner(r);
        } else {
            getBuyerToAccountsReq.setOwner(undefined);
        }

        dispatch(setIsFilteredBuyerToAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getBuyerToAccounts({ body: getBuyerToAccountsReq, headers: getHeaders() }))
        return [];

    }


    const buyerFromSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.buyerFromAccount.hasMore && !state.buyerFromAccount.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.buyerFromAccount.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedBuyerFromAccountItems([state.buyerFromAccount.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.buyerFromAccount.items.length > 0 && !state.buyerFromAccount.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.buyerFromAccount.items.at(state.buyerFromAccount.items.length - 1).internalId);
                getBuyerFromAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getBuyerFromAccounts({ body: getBuyerFromAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.buyerFromAccount.items.length > 0 ? state.buyerFromAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.buyerFromAccount.items.length == 0 ? state.buyerFromAccount.isFetching : false,
        isSearching: state.buyerFromAccount.items.length > 0 ? state.buyerFromAccount.isFetching : false,


    };


    const onBuyerFromAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetBuyerFromAccountSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getBuyerFromAccountsReq.setSearch(wrapper)
        getBuyerFromAccountsReq.setNextto(undefined)
        if (state.currencyPairs.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.currencyPairs.selected.at(0).quoteCurrencyId);
            getBuyerFromAccountsReq.setCurrency(r);
        } else {
            getBuyerFromAccountsReq.setCurrency(undefined);

        }

        if (state.buyerToAccount.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.buyerToAccount.selected.at(0).ownerId);
            getBuyerFromAccountsReq.setOwner(r);
        } else {
            getBuyerFromAccountsReq.setOwner(undefined);

        }
        dispatch(setIsFilteredBuyerFromAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getBuyerFromAccounts({ body: getBuyerFromAccountsReq, headers: getHeaders() }))
        return [];


    }


    const sellerToSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.sellerToAccount.hasMore && !state.sellerToAccount.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.sellerToAccount.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedSellerToAccountItems([state.sellerToAccount.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.sellerToAccount.items.length > 0 && !state.sellerToAccount.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.sellerToAccount.items.at(state.sellerToAccount.items.length - 1).internalId);
                getSellerToAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getSellerToAccounts({ body: getSellerToAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.sellerToAccount.items.length > 0 ? state.sellerToAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.sellerToAccount.items.length == 0 ? state.sellerToAccount.isFetching : false,
        isSearching: state.sellerToAccount.items.length > 0 ? state.sellerToAccount.isFetching : false,


    };


    const onSellerToAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetSellerToAccountSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getSellerToAccountsReq.setSearch(wrapper)
        getSellerToAccountsReq.setNextto(undefined)
        if (state.currencyPairs.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.currencyPairs.selected.at(0).quoteCurrencyId);
            getSellerToAccountsReq.setCurrency(r);
        } else {
            getSellerToAccountsReq.setCurrency(undefined);
        }
        if (state.sellerFromAccount.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.sellerFromAccount.selected.at(0).ownerId);
            getSellerToAccountsReq.setOwner(r);
        } else {
            getSellerToAccountsReq.setOwner(undefined);
        }
        dispatch(setIsFilteredSellerToAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getSellerToAccounts({ body: getSellerToAccountsReq, headers: getHeaders() }))
        return [];

    }


    const sellerFromSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('accounts'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.sellerFromAccount.hasMore && !state.sellerFromAccount.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.sellerFromAccount.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedSellerFromAccountItems([state.sellerFromAccount.items.at(u)]))
            }
        },

        onGetMoreResults: () => {
            if (state.sellerFromAccount.items.length > 0 && !state.sellerFromAccount.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.sellerFromAccount.items.at(state.sellerFromAccount.items.length - 1).internalId);
                getSellerFromAccountsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getSellerFromAccounts({ body: getSellerFromAccountsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.sellerFromAccount.items.length > 0 ? state.sellerFromAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.sellerFromAccount.items.length == 0 ? state.sellerFromAccount.isFetching : false,
        isSearching: state.sellerFromAccount.items.length > 0 ? state.sellerFromAccount.isFetching : false,


    };


    const onSellerFromAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {
        dispatch(resetSellerFromAccountSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getSellerFromAccountsReq.setSearch(wrapper)
        getSellerFromAccountsReq.setNextto(undefined)
        if (state.currencyPairs.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.currencyPairs.selected.at(0).baseCurrencyId);
            getSellerFromAccountsReq.setCurrency(r);
        } else {
            getSellerFromAccountsReq.setCurrency(undefined);
        }

        if (state.sellerToAccount.selected.length > 0) {
            let r = new StringValue();
            r.setValue(state.sellerToAccount.selected.at(0).ownerId);
            getSellerFromAccountsReq.setOwner(r);
        } else {
            getSellerFromAccountsReq.setOwner(undefined);
        }
        dispatch(setIsFilteredSellerFromAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getSellerFromAccounts({ body: getSellerFromAccountsReq, headers: getHeaders() }))
        return [];

    }





    const pairsSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('pair'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.currencyPairs.hasMore && !state.currencyPairs.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.currencyPairs.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedCurrencyPairItems([state.currencyPairs.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.currencyPairs.items.length > 0 && !state.currencyPairs.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.currencyPairs.items.at(state.currencyPairs.items.length - 1).id);
                currecyPairsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.currencyPairs.items.length > 0 ? state.currencyPairs.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.currencyPairs.items.length == 0 ? state.currencyPairs.isFetching : false,
        isSearching: state.currencyPairs.items.length > 0 ? state.currencyPairs.isFetching : false,


    };
    const onPairsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencyPairs())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currecyPairsReq.setSearch(wrapper)
        currecyPairsReq.setNextto(undefined)
        dispatch(setIsFilteredCurrencyPairsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
        return [];

    }


    return (
        <>
            <Popup isOpen={(currentAction == 100)} title={t("pair")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrencyPairsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyPairItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedSellerFromAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 102)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedSellerToAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 103)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedBuyerFromAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 104)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedBuyerToAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>


            <Formik
                enableReinitialize={true}
                initialValues={{

                    buyerFromAccount: undefined,
                    buyerToAccount: undefined,
                    sellerFromAccount: undefined,
                    sellerToAccount: undefined,
                    currencyPairs: undefined,
                    priceOption: { key: "1", text: t("buy") },
                    price: Number(state.currencyPairs?.selected?.at(0)?.buyPrice).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9) ?? "",
                    amount: "",
                    total: "",


                }}

                validationSchema={Yup.object({
                    price: inputs.price,
                    amount: inputs.amount

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let buyerFromAccount = state.buyerFromAccount.selected.at(0);
                        let buyerToAccount = state.buyerToAccount.selected.at(0);
                        let sellerFromAccount = state.sellerFromAccount.selected.at(0);
                        let sellerToAccount = state.sellerToAccount.selected.at(0);
                        let currencyPairs = state.currencyPairs.selected.at(0);

                        if (buyerFromAccount == undefined || buyerToAccount == undefined || sellerFromAccount == undefined || sellerToAccount == undefined || currencyPairs == undefined) {
                            return;
                        }
                        if (buyerFromAccount.internalId == buyerToAccount.internalId) {
                            dispatch(setSelectedBuyerFromAccountItems([]))
                            dispatch(setSelectedBuyerToAccountItems([]))
                            return;
                        }
                        if (sellerFromAccount.internalId == sellerToAccount.internalId) {
                            dispatch(setSelectedSellerFromAccountItems([]))
                            dispatch(setSelectedSellerToAccountItems([]))

                            return;
                        }

                        if (buyerFromAccount.internalId == sellerToAccount.internalId || buyerFromAccount.internalId == sellerFromAccount.internalId) {
                            dispatch(setSelectedBuyerFromAccountItems([]))
                            dispatch(setSelectedBuyerToAccountItems([]))

                            return;

                        }
                        if (buyerToAccount.internalId == sellerToAccount.internalId || buyerToAccount.internalId == sellerFromAccount.internalId) {
                            dispatch(setSelectedBuyerFromAccountItems([]))
                            dispatch(setSelectedBuyerToAccountItems([]))

                            return;

                        }

                        req.setBuyerfromaccount(buyerFromAccount.internalId);
                        req.setBuyertoaccount(buyerToAccount.internalId)
                        req.setSellerfromaccount(sellerFromAccount.internalId);
                        req.setSellertoaccount(sellerToAccount.internalId)
                        req.setCurrencypair(currencyPairs.id);
                        req.setPrice(values.price?.replace(/,/g, '') + "")
                        req.setQuantity(values.amount?.replace(/,/g, '') + "")
                        req.setPriceoption(Number(values.priceOption.key))

                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: DirectExchangeResponse.AsObject) => {

                                actions.resetForm();
                                if (res) {
                                    if (props?.onSuccess) {


                                        props?.onSuccess({
                                            id: res?.success?.buyerorder,
                                            orderType: OrderType.LIMIT_ORDER,
                                            currencyPairId: currencyPairs.id,
                                            currencyPairName: currencyPairs.name,
                                            currencyPairBaseCurrencyId: currencyPairs.baseCurrencyId,
                                            currencyPairBaseCurrencyName: currencyPairs.baseCurrencyName,
                                            currencyPairBaseCurrencyCode: currencyPairs.baseCurrencyCode,
                                            currencyPairBaseCurrencyDecimalPlaces: currencyPairs.baseCurrencyDecimalPlaces,
                                            currencyPairBaseCurrencyForm: currencyPairs.baseCurrencyForm,
                                            currencyPairBaseCurrencySymbol: currencyPairs.baseCurrencySymbol,
                                            currencyPairQuoteCurrencyId: currencyPairs.quoteCurrencyId,
                                            currencyPairQuoteCurrencyName: currencyPairs.quoteCurrencyName,
                                            currencyPairQuoteCurrencyCode: currencyPairs.quoteCurrencyCode,
                                            currencyPairQuoteCurrencyDecimalPlaces: currencyPairs.quoteCurrencyDecimalPlaces,
                                            currencyPairQuoteCurrencyForm: currencyPairs.quoteCurrencyForm,
                                            currencyPairQuoteCurrencySymbol: currencyPairs.quoteCurrencySymbol,
                                            currencyId: currencyPairs.quoteCurrencyId,
                                            currencyName: currencyPairs.quoteCurrencyName,
                                            currencyCode: currencyPairs.quoteCurrencyCode,
                                            currencyDecimalPlaces: currencyPairs.quoteCurrencyDecimalPlaces,
                                            currencyForm: currencyPairs.quoteCurrencyForm,
                                            currencySymbol: currencyPairs.quoteCurrencySymbol,
                                            itemPrice: values.price?.replace(/,/g, ''),
                                            itemQuantity: values.amount?.replace(/,/g, ''),
                                            clientId: buyerFromAccount.ownerId,
                                            clientName: buyerFromAccount.ownerName,
                                            status: OrderStatus.FILLD,
                                            total: values.total,
                                            tradeAction: OrderAction.BUY,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,

                                        });

                                        props?.onSuccess({
                                            id: res?.success?.sellerorder,
                                            orderType: OrderType.LIMIT_ORDER,
                                            currencyPairId: currencyPairs.id,
                                            currencyPairName: currencyPairs.name,
                                            currencyPairBaseCurrencyId: currencyPairs.baseCurrencyId,
                                            currencyPairBaseCurrencyName: currencyPairs.baseCurrencyName,
                                            currencyPairBaseCurrencyCode: currencyPairs.baseCurrencyCode,
                                            currencyPairBaseCurrencyDecimalPlaces: currencyPairs.baseCurrencyDecimalPlaces,
                                            currencyPairBaseCurrencyForm: currencyPairs.baseCurrencyForm,
                                            currencyPairBaseCurrencySymbol: currencyPairs.baseCurrencySymbol,
                                            currencyPairQuoteCurrencyId: currencyPairs.quoteCurrencyId,
                                            currencyPairQuoteCurrencyName: currencyPairs.quoteCurrencyName,
                                            currencyPairQuoteCurrencyCode: currencyPairs.quoteCurrencyCode,
                                            currencyPairQuoteCurrencyDecimalPlaces: currencyPairs.quoteCurrencyDecimalPlaces,
                                            currencyPairQuoteCurrencyForm: currencyPairs.quoteCurrencyForm,
                                            currencyPairQuoteCurrencySymbol: currencyPairs.quoteCurrencySymbol,
                                            currencyId: currencyPairs.quoteCurrencyId,
                                            currencyName: currencyPairs.quoteCurrencyName,
                                            currencyCode: currencyPairs.quoteCurrencyCode,
                                            currencyDecimalPlaces: currencyPairs.quoteCurrencyDecimalPlaces,
                                            currencyForm: currencyPairs.quoteCurrencyForm,
                                            currencySymbol: currencyPairs.quoteCurrencySymbol,
                                            itemPrice: values.price?.replace(/,/g, ''),
                                            itemQuantity: values.amount?.replace(/,/g, ''),
                                            clientId: sellerFromAccount.ownerId,
                                            clientName: sellerFromAccount.ownerName,
                                            status: OrderStatus.FILLD,
                                            total: values.total,
                                            tradeAction: OrderAction.SELL,
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            quantityFilled: values.amount?.replace(/,/g, ''),
                                            remainingFilled: "0",
                                            remainingQuantityFilled: "0",
                                            filled: values.total

                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {

                    }
                }}

            >
                {formkikProps => (
                    <Form onSubmit={(e) => {
                        e.preventDefault()
                        formkikProps.validateForm();
                        let a = Number(formkikProps.values.amount?.replace(/,/g, ''))
                        let p = Number(formkikProps.values.price?.replace(/,/g, ''))
                        let total = (a * p).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9);
                        if (isNaN(a) || !formkikProps.isValid || a == 0 || isNaN(p) || p == 0) {
                            return;
                        }
                        formkikProps.setFieldValue("total", total)

                        dispatch(resetMessage())
                        let buyerFromAccount = state.buyerFromAccount.selected.at(0);
                        let buyerToAccount = state.buyerToAccount.selected.at(0);
                        let sellerFromAccount = state.sellerFromAccount.selected.at(0);
                        let sellerToAccount = state.sellerToAccount.selected.at(0);
                        let currencyPairs = state.currencyPairs.selected.at(0);
                        if (buyerFromAccount == undefined || buyerToAccount == undefined || sellerFromAccount == undefined || sellerToAccount == undefined || currencyPairs == undefined) {
                            return;
                        }

                        if (buyerFromAccount.internalId == buyerToAccount.internalId) {
                            dispatch(setSelectedBuyerFromAccountItems([]))
                            dispatch(setSelectedBuyerToAccountItems([]))

                            return;
                        }
                        if (sellerFromAccount.internalId == sellerToAccount.internalId) {
                            dispatch(setSelectedSellerFromAccountItems([]))
                            dispatch(setSelectedSellerToAccountItems([]))
                            return;
                        }

                        if (buyerFromAccount.internalId == sellerToAccount.internalId || buyerFromAccount.internalId == sellerFromAccount.internalId) {
                            dispatch(setSelectedBuyerFromAccountItems([]))
                            dispatch(setSelectedBuyerToAccountItems([]))

                            return;

                        }
                        if (buyerToAccount.internalId == sellerToAccount.internalId || buyerToAccount.internalId == sellerFromAccount.internalId) {
                            dispatch(setSelectedBuyerFromAccountItems([]))
                            dispatch(setSelectedBuyerToAccountItems([]))

                            return;

                        }



                        req.setSellerauthcode(undefined)
                        req.setBuyerauthcode(undefined)

                        if (sellerFromAccount.isAuthorizationRequired) {
                            setCurrenctAction(13);
                            return;
                        }
                        if (buyerFromAccount.isAuthorizationRequired) {
                            setCurrenctAction(14);
                            return;
                        }


                        var sumary = new GetTransactionSummaryRequest();
                        const set: GetTransactionSummary[] = [];

                        const trans2 = new GetTransactionSummary();
                        trans2.setAmount(a + "")
                        trans2.setDebtor(sellerFromAccount.internalId)
                        trans2.setCreditor(buyerToAccount.internalId)
                        trans2.setOperation(Operations.TRADING_ORDER_SELL)
                        set.push(trans2)

                        const trans = new GetTransactionSummary();
                        trans.setAmount(total)
                        trans.setDebtor(buyerFromAccount.internalId)
                        trans.setCreditor(sellerToAccount.internalId)
                        trans.setOperation(Operations.TRADING_ORDER_BUY)
                        set.push(trans)


                        sumary.setTransactionsList(set)
                        promise?.abort();
                        promise = dispatch(getTransactionSummary({ body: sumary, headers: getHeaders(), metadata: { price: p, total: a * p } }))


                    }} >

                        <Popup isOpen={(currentAction == 13)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                            <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                                account: state.sellerFromAccount.selected.at(0),
                                operation: AuthorizedOperation.TRADING_ORDER_SELL,
                                parameters: [
                                    { key: "CurrencyPair", value: state.currencyPairs.selected.at(0)?.id + "", viewValue: state.currencyPairs.selected.at(0)?.longName + "" },
                                    { key: "Quantity", value: formkikProps.values.amount?.replace(/,/g, ''), viewValue: state.currencyPairs.selected.at(0)?.baseCurrencySymbol },
                                    { key: "Price", value: formkikProps.values.price?.replace(/,/g, '') + "", viewValue: state.currencyPairs.selected.at(0)?.quoteCurrencySymbol },
                                    { key: "Total", value: formkikProps.values.total?.replace(/,/g, '') + "", viewValue: state.currencyPairs.selected.at(0)?.quoteCurrencySymbol },
                                    { key: "ToAccount", value: state.sellerToAccount.selected.at(0)?.internalId + "", viewValue: state.sellerToAccount.selected.at(0)?.id + "" },
                                ],
                            }} onSuccess={(e) => {
                                if (req && e.authCode) {
                                    if (currentAction == 13) {
                                        var r = new StringValue();
                                        r.setValue(e.authCode)
                                        req.setSellerauthcode(r)
                                        var aacc = state.buyerFromAccount.selected.at(0);
                                        if (aacc.isAuthorizationRequired) {
                                            setCurrenctAction(14);
                                            return;
                                        }
                                        setCurrenctAction(0)

                                        let a = Number(formkikProps.values.amount?.replace(/,/g, ''))
                                        let p = Number(formkikProps.values.price?.replace(/,/g, ''))
                                        if (isNaN(a) || !formkikProps.isValid || a == 0 || isNaN(p) || p == 0) {
                                            return;
                                        }
                                        let buyerFromAccount = state.buyerFromAccount.selected.at(0);
                                        let buyerToAccount = state.buyerToAccount.selected.at(0);
                                        let sellerFromAccount = state.sellerFromAccount.selected.at(0);
                                        let sellerToAccount = state.sellerToAccount.selected.at(0);


                                        var sumary = new GetTransactionSummaryRequest();
                                        const set: GetTransactionSummary[] = [];

                                        const trans2 = new GetTransactionSummary();
                                        trans2.setAmount(formkikProps.values.amount?.replace(/,/g, ''))
                                        trans2.setDebtor(sellerFromAccount.internalId)
                                        trans2.setCreditor(buyerToAccount.internalId)
                                        trans2.setOperation(Operations.TRADING_ORDER_SELL)
                                        set.push(trans2)

                                        const trans = new GetTransactionSummary();
                                        trans.setAmount(formkikProps.values.total?.replace(/,/g, ''))
                                        trans.setDebtor(buyerFromAccount.internalId)
                                        trans.setCreditor(sellerToAccount.internalId)
                                        trans.setOperation(Operations.TRADING_ORDER_BUY)
                                        set.push(trans)

                                        sumary.setTransactionsList(set)
                                        promise?.abort();
                                        promise = dispatch(getTransactionSummary({ body: sumary, headers: getHeaders(), metadata: { price: p, total: a * p } }))
                                    }
                                } else {
                                    setCurrenctAction(0)
                                }
                            }} onCancel={() => { setCurrenctAction(0) }} />
                        </Popup>
                        <Popup isOpen={(currentAction == 14)} title={t("authorization")} onDismiss={() => { setCurrenctAction(0) }} >
                            <AuthorizationForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={{
                                account: state.buyerFromAccount.selected.at(0),
                                operation: AuthorizedOperation.TRADING_ORDER_BUY,
                                parameters: [{ key: "CurrencyPair", value: state.currencyPairs.selected.at(0)?.id + "", viewValue: state.currencyPairs.selected.at(0)?.name + "" },
                                { key: "Quantity", value: formkikProps.values.amount?.replace(/,/g, ''), viewValue: state.currencyPairs.selected.at(0)?.baseCurrencySymbol },
                                { key: "Price", value: formkikProps.values.price?.replace(/,/g, '') + "", viewValue: state.currencyPairs.selected.at(0)?.quoteCurrencySymbol },
                                { key: "Total", value: formkikProps.values.total?.replace(/,/g, '') + "", viewValue: state.currencyPairs.selected.at(0)?.quoteCurrencySymbol },
                                { key: "ToAccount", value: state.buyerToAccount.selected.at(0)?.internalId + "", viewValue: state.buyerToAccount.selected.at(0)?.id + "" },
                                ],

                            }} onSuccess={(e) => {
                                if (req && e.authCode) {
                                    if (currentAction == 14) {
                                        var r = new StringValue();
                                        r.setValue(e.authCode)
                                        req.setBuyerauthcode(r)
                                        setCurrenctAction(0)

                                        let a = Number(formkikProps.values.amount?.replace(/,/g, ''))
                                        let p = Number(formkikProps.values.price?.replace(/,/g, ''))
                                        if (isNaN(a) || !formkikProps.isValid || a == 0 || isNaN(p) || p == 0) {
                                            return;
                                        }

                                        let buyerFromAccount = state.buyerFromAccount.selected.at(0);
                                        let buyerToAccount = state.buyerToAccount.selected.at(0);
                                        let sellerFromAccount = state.sellerFromAccount.selected.at(0);
                                        let sellerToAccount = state.sellerToAccount.selected.at(0);

                                        var sumary = new GetTransactionSummaryRequest();
                                        const set: GetTransactionSummary[] = [];
                                        const trans2 = new GetTransactionSummary();
                                        trans2.setAmount(formkikProps.values.amount?.replace(/,/g, ''))
                                        trans2.setDebtor(sellerFromAccount.internalId)
                                        trans2.setCreditor(buyerToAccount.internalId)
                                        trans2.setOperation(Operations.TRADING_ORDER_SELL)
                                        set.push(trans2)

                                        const trans = new GetTransactionSummary();
                                        trans.setAmount(formkikProps.values.total?.replace(/,/g, ''))
                                        trans.setDebtor(buyerFromAccount.internalId)
                                        trans.setCreditor(sellerToAccount.internalId)
                                        trans.setOperation(Operations.TRADING_ORDER_BUY)
                                        set.push(trans)
                                        sumary.setTransactionsList(set)
                                        promise?.abort();
                                        promise = dispatch(getTransactionSummary({ body: sumary, headers: getHeaders(), metadata: { price: p, total: a * p } }))

                                    }
                                } else {
                                    setCurrenctAction(0)
                                }
                            }} onCancel={() => { setCurrenctAction(0) }} />
                        </Popup>

                        <Dialog
                            hidden={hideDialog}
                            onDismiss={toggleHideDialog}
                            dialogContentProps={showConfirmation}
                            modalProps={modalProps}
                        >
                            <DialogFooter>
                                <PrimaryButton autoFocus onClick={(e: any) => { formkikProps.handleSubmit(e); toggleHideDialog() }} text={t("execute")} type="submit" />
                                <DefaultButton onClick={toggleHideDialog} text={t("cancel")} />
                            </DialogFooter>
                        </Dialog>
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }


                            {props.type == FormType.ADD ? <Stack grow>
                                <Label required disabled={state.isLoading}>{t("pair")}</Label>
                                <List
                                    inputProps={{ placeholder: t("pair"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('pair')}
                                    isPeoplePicker={true}
                                    isLoading={state.currencyPairs.items.length == 0 ? state.currencyPairs.isFetching : false}
                                    isSearching={state.currencyPairs.items.length > 0 ? state.currencyPairs.isFetching : false}
                                    moreSuggestionsAvailable={state.currencyPairs.hasMore && !state.currencyPairs.isFetching}
                                    suggestions={state.currencyPairs.items.length > 0 ? state.currencyPairs.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.currencyPairs.items.length > 0 && !state.currencyPairs.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.currencyPairs.items.at(state.currencyPairs.items.length - 1).id);
                                            currecyPairsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.currencyPairs.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedCurrencyPairItems([state.currencyPairs.items.at(u)]))
                                        }


                                    }}
                                    selectedItems={state.currencyPairs.selected.length > 0 ? state.currencyPairs.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode), imageUrl: e?.iconSource } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedCurrencyPairItems([]))
                                        dispatch(setSelectedBuyerFromAccountItems([]))
                                        dispatch(setSelectedBuyerToAccountItems([]))
                                        dispatch(setSelectedSellerFromAccountItems([]))
                                        dispatch(setSelectedSellerToAccountItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetCurrencyPairs())
                                        currecyPairsReq.setSearch(undefined)
                                        currecyPairsReq.setNextto(undefined)

                                        dispatch(setIsFilteredCurrencyPairsSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getCurrencyPairs({ body: currecyPairsReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onPairsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}

                            <Stack horizontal grow tokens={{ childrenGap: props.childrenGap }} >
                                {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ? <Stack grow>
                                    <Label required disabled={state.isLoading}>{t("sellerFrom", { currency: state.currencyPairs.selected.at(0).baseCurrencyCode })}</Label>
                                    <List
                                        isPeoplePicker={true}
                                        suggestionsHeaderText={t('accounts')}
                                        isLoading={state.sellerFromAccount.items.length == 0 ? state.sellerFromAccount.isFetching : false}
                                        isSearching={state.sellerFromAccount.items.length > 0 ? state.sellerFromAccount.isFetching : false}
                                        moreSuggestionsAvailable={state.sellerFromAccount.hasMore && !state.sellerFromAccount.isFetching}
                                        suggestions={state.sellerFromAccount.items.length > 0 ? state.sellerFromAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.sellerFromAccount.items.length > 0 && !state.sellerFromAccount.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.sellerFromAccount.items.at(state.sellerFromAccount.items.length - 1).internalId);
                                                getSellerFromAccountsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getSellerFromAccounts({ body: getSellerFromAccountsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.sellerFromAccount.items.findIndex(e => e.internalId == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedSellerFromAccountItems([state.sellerFromAccount.items.at(u)]))
                                            }
                                        }}
                                        inputProps={{ placeholder: t("sellerFrom", { currency: state.currencyPairs.selected.at(0).baseCurrencyCode }), required: true }}

                                        disabled={state.isLoading}
                                        selectedItems={state.sellerFromAccount.selected.length > 0 ? state.sellerFromAccount.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedSellerFromAccountItems([]))
                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetSellerFromAccountSet())
                                            getSellerFromAccountsReq.setSearch(undefined)
                                            getSellerFromAccountsReq.setNextto(undefined)
                                            if (state.currencyPairs.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.currencyPairs.selected.at(0).baseCurrencyId);
                                                getSellerFromAccountsReq.setCurrency(r);
                                            } else {
                                                getSellerFromAccountsReq.setCurrency(undefined);
                                            }
                                            if (state.sellerToAccount.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.sellerToAccount.selected.at(0).ownerId);
                                                getSellerFromAccountsReq.setOwner(r);
                                            } else {
                                                getSellerFromAccountsReq.setOwner(undefined);
                                            }
                                            dispatch(setIsFilteredSellerFromAccountSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getSellerFromAccounts({ body: getSellerFromAccountsReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onSellerFromAccountsFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(101)

                                                }
                                            }]}
                                    />

                                </Stack> : undefined}

                                {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ? <Stack grow>
                                    <Label required disabled={state.isLoading}>{t("sellerTo", { currency: state.currencyPairs.selected.at(0).quoteCurrencyCode })}</Label>

                                    <List
                                        isPeoplePicker={true}
                                        suggestionsHeaderText={t('accounts')}
                                        isLoading={state.sellerToAccount.items.length == 0 ? state.sellerToAccount.isFetching : false}
                                        isSearching={state.sellerToAccount.items.length > 0 ? state.sellerToAccount.isFetching : false}
                                        moreSuggestionsAvailable={state.sellerToAccount.hasMore && !state.sellerToAccount.isFetching}
                                        suggestions={state.sellerToAccount.items.length > 0 ? state.sellerToAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.sellerToAccount.items.length > 0 && !state.sellerToAccount.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.sellerToAccount.items.at(state.sellerToAccount.items.length - 1).internalId);
                                                getSellerToAccountsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getSellerToAccounts({ body: getSellerToAccountsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {

                                            var u = state.sellerToAccount.items.findIndex(e => e.internalId == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedSellerToAccountItems([state.sellerToAccount.items.at(u)]))
                                            }
                                        }}
                                        inputProps={{ placeholder: t("sellerTo", { currency: state.currencyPairs.selected.at(0).quoteCurrencyCode }), required: true }}

                                        disabled={state.isLoading}
                                        selectedItems={state.sellerToAccount.selected.length > 0 ? state.sellerToAccount.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedSellerToAccountItems([]))
                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetSellerToAccountSet())
                                            getSellerToAccountsReq.setSearch(undefined)
                                            getSellerToAccountsReq.setNextto(undefined)
                                            if (state.currencyPairs.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.currencyPairs.selected.at(0).quoteCurrencyId);
                                                getSellerToAccountsReq.setCurrency(r);
                                            } else {
                                                getSellerToAccountsReq.setCurrency(undefined);

                                            }
                                            if (state.sellerFromAccount.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.sellerFromAccount.selected.at(0).ownerId);
                                                getSellerToAccountsReq.setOwner(r);
                                            } else {
                                                getSellerToAccountsReq.setOwner(undefined);

                                            }
                                            dispatch(setIsFilteredSellerToAccountSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getSellerToAccounts({ body: getSellerToAccountsReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onSellerToAccountsFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(102)

                                                }
                                            }]}
                                    />

                                </Stack> : undefined}
                            </Stack>
                            <Stack horizontal grow tokens={{ childrenGap: props.childrenGap }} >
                                {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ? <Stack grow>
                                    <Label required disabled={state.isLoading}>{t("buyerFrom", { currency: state.currencyPairs.selected.at(0).quoteCurrencyCode })}</Label>

                                    <List
                                        isPeoplePicker={true}
                                        suggestionsHeaderText={t('accounts')}
                                        isLoading={state.buyerFromAccount.items.length == 0 ? state.buyerFromAccount.isFetching : false}
                                        isSearching={state.buyerFromAccount.items.length > 0 ? state.buyerFromAccount.isFetching : false}
                                        moreSuggestionsAvailable={state.buyerFromAccount.hasMore && !state.buyerFromAccount.isFetching}
                                        suggestions={state.buyerFromAccount.items.length > 0 ? state.buyerFromAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.buyerFromAccount.items.length > 0 && !state.buyerFromAccount.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.buyerFromAccount.items.at(state.buyerFromAccount.items.length - 1).internalId);
                                                getBuyerFromAccountsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getBuyerFromAccounts({ body: getBuyerFromAccountsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.buyerFromAccount.items.findIndex(e => e.internalId == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedBuyerFromAccountItems([state.buyerFromAccount.items.at(u)]))
                                            }


                                        }}
                                        inputProps={{ placeholder: t("buyerFrom", { currency: state.currencyPairs.selected.at(0).quoteCurrencyCode }), required: true }}

                                        disabled={state.isLoading}
                                        selectedItems={state.buyerFromAccount.selected.length > 0 ? state.buyerFromAccount.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedBuyerFromAccountItems([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetBuyerFromAccountSet())
                                            getBuyerFromAccountsReq.setSearch(undefined)
                                            getBuyerFromAccountsReq.setNextto(undefined)
                                            if (state.currencyPairs.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.currencyPairs.selected.at(0).quoteCurrencyId);
                                                getBuyerFromAccountsReq.setCurrency(r);
                                            } else {
                                                getBuyerFromAccountsReq.setCurrency(undefined);

                                            }
                                            if (state.buyerToAccount.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.buyerToAccount.selected.at(0).ownerId);
                                                getBuyerFromAccountsReq.setOwner(r);
                                            } else {
                                                getBuyerFromAccountsReq.setOwner(undefined);
                                            }

                                            dispatch(setIsFilteredBuyerFromAccountSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getBuyerFromAccounts({ body: getBuyerFromAccountsReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onBuyerFromAccountsFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(103)

                                                }
                                            }]}
                                    />


                                </Stack> : undefined}

                                {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ? <Stack grow>
                                    <Label required disabled={state.isLoading}>{t("buyerTo", { currency: state.currencyPairs.selected.at(0).baseCurrencyCode })}</Label>
                                    <List
                                        isPeoplePicker={true}
                                        suggestionsHeaderText={t('accounts')}
                                        isLoading={state.buyerToAccount.items.length == 0 ? state.buyerToAccount.isFetching : false}
                                        isSearching={state.buyerToAccount.items.length > 0 ? state.buyerToAccount.isFetching : false}
                                        moreSuggestionsAvailable={state.buyerToAccount.hasMore && !state.buyerToAccount.isFetching}
                                        suggestions={state.buyerToAccount.items.length > 0 ? state.buyerToAccount.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.buyerToAccount.items.length > 0 && !state.buyerToAccount.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.buyerToAccount.items.at(state.buyerToAccount.items.length - 1).internalId);
                                                getBuyerToAccountsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getBuyerToAccounts({ body: getBuyerToAccountsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.buyerToAccount.items.findIndex(e => e.internalId == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedBuyerToAccountItems([state.buyerToAccount.items.at(u)]))
                                            }



                                        }}
                                        inputProps={{ placeholder: t("buyerTo", { currency: state.currencyPairs.selected.at(0).baseCurrencyCode }), required: true }}

                                        disabled={state.isLoading}
                                        selectedItems={state.buyerToAccount.selected.length > 0 ? state.buyerToAccount.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedBuyerToAccountItems([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetBuyerToAccountSet())
                                            getBuyerToAccountsReq.setSearch(undefined)
                                            getBuyerToAccountsReq.setNextto(undefined)
                                            if (state.currencyPairs.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.currencyPairs.selected.at(0).baseCurrencyId);
                                                getBuyerToAccountsReq.setCurrency(r);
                                            } else {
                                                getBuyerToAccountsReq.setCurrency(undefined);

                                            }

                                            if (state.buyerFromAccount.selected.length > 0) {
                                                let r = new StringValue();
                                                r.setValue(state.buyerFromAccount.selected.at(0).ownerId);
                                                getBuyerToAccountsReq.setOwner(r);
                                            } else {
                                                getBuyerToAccountsReq.setOwner(undefined);
                                            }

                                            dispatch(setIsFilteredBuyerToAccountSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getBuyerToAccounts({ body: getBuyerToAccountsReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onBuyerToAccountsFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(104)

                                                }
                                            }]}
                                    />

                                </Stack> : undefined}
                            </Stack>



                            {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ?
                                <Stack horizontal grow tokens={{ childrenGap: props.childrenGap }}>

                                    <ChoiceGroup styles={{ flexContainer: { display: "flex" } }} label={t("priceOption")}

                                        disabled={state.isLoading}
                                        name="priceOption"
                                        //selectedKey={formkikProps.values.gender.key}
                                        selectedKey={formkikProps.values.priceOption.key}
                                        options={[{ key: "1", text: t("buy") + "\u00A0\u00A0" },
                                        { key: "2", text: t("sell") + "\u00A0\u00A0" }, { key: "3", text: t("custom") }]}
                                        onChange={(e, o) => {
                                            let price;
                                            if (o?.key == "1") {
                                                price = state.currencyPairs.selected.at(0).buyPrice;
                                                formkikProps.setFieldValue("price", Number(price).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                            }

                                            if (o?.key == "2") {
                                                price = state.currencyPairs.selected.at(0).sellPrice;
                                                formkikProps.setFieldValue("price", Number(price).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                            }

                                            if (o?.key == "3") {
                                                price = state.currencyPairs.selected.at(0).tradingPrice;
                                                formkikProps.setFieldValue("price", Number(price).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                            }

                                            formkikProps.setFieldValue("priceOption", { key: o?.key, text: o?.text })
                                            if (formkikProps.values.amount) {
                                                formkikProps.setFieldValue("total", (Number(price) * Number(formkikProps.values.amount?.replace(/,/g, ''))).toFixed(state.currencyPairs.selected.at(0)?.quoteCurrencyDecimalPlaces ?? 9))
                                            }
                                        }} required={true} />
                                    <Stack.Item grow >
                                        <Field name="price" label={t("price")} placeholder={t("price")}
                                            component={InputField} disabled={state.isLoading} maxLength={15} readOnly={formkikProps.values.priceOption.key != "3"}
                                            required={formkikProps.values.priceOption.key == "3"} suffix={state.currencyPairs.selected.at(0).quoteCurrencyCode} onBlur={(event: any) => {
                                                if (isNaN(Number(formkikProps.values.price?.replace(/,/g, '')))) {
                                                    formkikProps.handleBlur(event);
                                                    return;
                                                }
                                                let account = state.currencyPairs?.selected?.at(0);
                                                let price;
                                                if (account)
                                                    price = Number(formkikProps.values.price?.replace(/,/g, '')).toFixed(account?.quoteCurrencyDecimalPlaces ?? 9);
                                                else
                                                    price = Number(formkikProps.values.price?.replace(/,/g, '')).toFixed(9)

                                                formkikProps.setFieldValue("price", price)
                                                if (formkikProps.values.amount) {
                                                    formkikProps.setFieldValue("total", (Number(price) * Number(formkikProps.values.amount?.replace(/,/g, ''))).toFixed(account?.quoteCurrencyDecimalPlaces ?? 9))
                                                }

                                                formkikProps.handleBlur(event);
                                            }} />
                                    </Stack.Item>
                                </Stack> : undefined}
                            {props.type == FormType.ADD && state.currencyPairs.selected.length > 0 ?
                                <Stack horizontal grow tokens={{ childrenGap: props.childrenGap }} >
                                    <Stack.Item grow >
                                        <Field name="amount" label={t("amount")} placeholder={t("amount")}
                                            component={InputField} disabled={state.isLoading} maxLength={15}
                                            required suffix={state.currencyPairs.selected.at(0).baseCurrencyCode} onBlur={(event: any) => {
                                                if (isNaN(Number(formkikProps.values.amount?.replace(/,/g, '')))) {
                                                    formkikProps.handleBlur(event);
                                                    return;
                                                }
                                                let account = state.currencyPairs?.selected?.at(0);
                                                let amount;
                                                if (account)
                                                    amount = Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(account?.baseCurrencyDecimalPlaces ?? 9);
                                                else
                                                    amount = Number(formkikProps.values.amount?.replace(/,/g, '')).toFixed(9)

                                                formkikProps.setFieldValue("amount", amount)
                                                if (formkikProps.values.price) {
                                                    formkikProps.setFieldValue("total", (Number(amount) * Number(formkikProps.values.price?.replace(/,/g, ''))).toFixed(account?.quoteCurrencyDecimalPlaces ?? 9))
                                                }

                                                formkikProps.handleBlur(event);
                                            }} />
                                    </Stack.Item>
                                    <Stack.Item grow >
                                        <Field name="total" label={t("total")}
                                            component={InputField} disabled={state.isLoading} readOnly
                                            suffix={state.currencyPairs.selected.at(0).quoteCurrencyCode} />
                                    </Stack.Item>
                                </Stack> : undefined}


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton type="submit" disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("execute") : t("edit")}
                            >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>

                    </Form>
                )}
            </Formik>
        </>
    );

}

