// source: authorization.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT! asdsa
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.authorization_endpoint.AddAuthorizationReplay', null, global);
goog.exportSymbol('proto.authorization_endpoint.AddAuthorizationRequest', null, global);
goog.exportSymbol('proto.authorization_endpoint.AddAuthorizationResponse', null, global);
goog.exportSymbol('proto.authorization_endpoint.AddAuthorizationResponse.ResultCase', null, global);
goog.exportSymbol('proto.authorization_endpoint.Authorization', null, global);
goog.exportSymbol('proto.authorization_endpoint.AuthorizationState', null, global);
goog.exportSymbol('proto.authorization_endpoint.GenerateSignaturesReplay', null, global);
goog.exportSymbol('proto.authorization_endpoint.GenerateSignaturesRequest', null, global);
goog.exportSymbol('proto.authorization_endpoint.GenerateSignaturesResponse', null, global);
goog.exportSymbol('proto.authorization_endpoint.GenerateSignaturesResponse.ResultCase', null, global);
goog.exportSymbol('proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay', null, global);
goog.exportSymbol('proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest', null, global);
goog.exportSymbol('proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse', null, global);
goog.exportSymbol('proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.ResultCase', null, global);
goog.exportSymbol('proto.authorization_endpoint.GetAuthorizationReplay', null, global);
goog.exportSymbol('proto.authorization_endpoint.GetAuthorizationRequest', null, global);
goog.exportSymbol('proto.authorization_endpoint.GetAuthorizationResponse', null, global);
goog.exportSymbol('proto.authorization_endpoint.GetAuthorizationResponse.ResultCase', null, global);
goog.exportSymbol('proto.authorization_endpoint.GetAuthorizationsReplay', null, global);
goog.exportSymbol('proto.authorization_endpoint.GetAuthorizationsRequest', null, global);
goog.exportSymbol('proto.authorization_endpoint.GetAuthorizationsResponse', null, global);
goog.exportSymbol('proto.authorization_endpoint.GetAuthorizationsResponse.ResultCase', null, global);
goog.exportSymbol('proto.authorization_endpoint.KeyValuePair', null, global);
goog.exportSymbol('proto.authorization_endpoint.RevokeAuthorizationReplay', null, global);
goog.exportSymbol('proto.authorization_endpoint.RevokeAuthorizationRequest', null, global);
goog.exportSymbol('proto.authorization_endpoint.RevokeAuthorizationResponse', null, global);
goog.exportSymbol('proto.authorization_endpoint.RevokeAuthorizationResponse.ResultCase', null, global);
goog.exportSymbol('proto.authorization_endpoint.UpdateAuthorizationReplay', null, global);
goog.exportSymbol('proto.authorization_endpoint.UpdateAuthorizationRequest', null, global);
goog.exportSymbol('proto.authorization_endpoint.UpdateAuthorizationResponse', null, global);
goog.exportSymbol('proto.authorization_endpoint.UpdateAuthorizationResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.AddAuthorizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authorization_endpoint.AddAuthorizationRequest.repeatedFields_, null);
};
goog.inherits(proto.authorization_endpoint.AddAuthorizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.AddAuthorizationRequest.displayName = 'proto.authorization_endpoint.AddAuthorizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.repeatedFields_, null);
};
goog.inherits(proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.displayName = 'proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GenerateSignaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authorization_endpoint.GenerateSignaturesRequest.repeatedFields_, null);
};
goog.inherits(proto.authorization_endpoint.GenerateSignaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GenerateSignaturesRequest.displayName = 'proto.authorization_endpoint.GenerateSignaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.UpdateAuthorizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authorization_endpoint.UpdateAuthorizationRequest.repeatedFields_, null);
};
goog.inherits(proto.authorization_endpoint.UpdateAuthorizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.UpdateAuthorizationRequest.displayName = 'proto.authorization_endpoint.UpdateAuthorizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.KeyValuePair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.KeyValuePair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.KeyValuePair.displayName = 'proto.authorization_endpoint.KeyValuePair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.RevokeAuthorizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.RevokeAuthorizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.RevokeAuthorizationRequest.displayName = 'proto.authorization_endpoint.RevokeAuthorizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GetAuthorizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.GetAuthorizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GetAuthorizationRequest.displayName = 'proto.authorization_endpoint.GetAuthorizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GetAuthorizationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.GetAuthorizationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GetAuthorizationsRequest.displayName = 'proto.authorization_endpoint.GetAuthorizationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.AddAuthorizationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.AddAuthorizationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.AddAuthorizationReplay.displayName = 'proto.authorization_endpoint.AddAuthorizationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.displayName = 'proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GenerateSignaturesReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.GenerateSignaturesReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GenerateSignaturesReplay.displayName = 'proto.authorization_endpoint.GenerateSignaturesReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.UpdateAuthorizationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.UpdateAuthorizationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.UpdateAuthorizationReplay.displayName = 'proto.authorization_endpoint.UpdateAuthorizationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.RevokeAuthorizationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.RevokeAuthorizationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.RevokeAuthorizationReplay.displayName = 'proto.authorization_endpoint.RevokeAuthorizationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.AuthorizationState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.authorization_endpoint.AuthorizationState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.AuthorizationState.displayName = 'proto.authorization_endpoint.AuthorizationState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GetAuthorizationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authorization_endpoint.GetAuthorizationReplay.repeatedFields_, null);
};
goog.inherits(proto.authorization_endpoint.GetAuthorizationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GetAuthorizationReplay.displayName = 'proto.authorization_endpoint.GetAuthorizationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.Authorization = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authorization_endpoint.Authorization.repeatedFields_, null);
};
goog.inherits(proto.authorization_endpoint.Authorization, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.Authorization.displayName = 'proto.authorization_endpoint.Authorization';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GetAuthorizationsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.authorization_endpoint.GetAuthorizationsReplay.repeatedFields_, null);
};
goog.inherits(proto.authorization_endpoint.GetAuthorizationsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GetAuthorizationsReplay.displayName = 'proto.authorization_endpoint.GetAuthorizationsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.AddAuthorizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authorization_endpoint.AddAuthorizationResponse.oneofGroups_);
};
goog.inherits(proto.authorization_endpoint.AddAuthorizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.AddAuthorizationResponse.displayName = 'proto.authorization_endpoint.AddAuthorizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.UpdateAuthorizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authorization_endpoint.UpdateAuthorizationResponse.oneofGroups_);
};
goog.inherits(proto.authorization_endpoint.UpdateAuthorizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.UpdateAuthorizationResponse.displayName = 'proto.authorization_endpoint.UpdateAuthorizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.oneofGroups_);
};
goog.inherits(proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.displayName = 'proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.RevokeAuthorizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authorization_endpoint.RevokeAuthorizationResponse.oneofGroups_);
};
goog.inherits(proto.authorization_endpoint.RevokeAuthorizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.RevokeAuthorizationResponse.displayName = 'proto.authorization_endpoint.RevokeAuthorizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GetAuthorizationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authorization_endpoint.GetAuthorizationResponse.oneofGroups_);
};
goog.inherits(proto.authorization_endpoint.GetAuthorizationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GetAuthorizationResponse.displayName = 'proto.authorization_endpoint.GetAuthorizationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GetAuthorizationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authorization_endpoint.GetAuthorizationsResponse.oneofGroups_);
};
goog.inherits(proto.authorization_endpoint.GetAuthorizationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GetAuthorizationsResponse.displayName = 'proto.authorization_endpoint.GetAuthorizationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.authorization_endpoint.GenerateSignaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.authorization_endpoint.GenerateSignaturesResponse.oneofGroups_);
};
goog.inherits(proto.authorization_endpoint.GenerateSignaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.authorization_endpoint.GenerateSignaturesResponse.displayName = 'proto.authorization_endpoint.GenerateSignaturesResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authorization_endpoint.AddAuthorizationRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.AddAuthorizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.AddAuthorizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.AddAuthorizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.AddAuthorizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operation: jspb.Message.getFieldWithDefault(msg, 2, 0),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.authorization_endpoint.KeyValuePair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.AddAuthorizationRequest}
 */
proto.authorization_endpoint.AddAuthorizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.AddAuthorizationRequest;
  return proto.authorization_endpoint.AddAuthorizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.AddAuthorizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.AddAuthorizationRequest}
 */
proto.authorization_endpoint.AddAuthorizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOperation(value);
      break;
    case 3:
      var value = new proto.authorization_endpoint.KeyValuePair;
      reader.readMessage(value,proto.authorization_endpoint.KeyValuePair.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.AddAuthorizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.AddAuthorizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.AddAuthorizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.AddAuthorizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperation();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.authorization_endpoint.KeyValuePair.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.authorization_endpoint.AddAuthorizationRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.AddAuthorizationRequest} returns this
 */
proto.authorization_endpoint.AddAuthorizationRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 operation = 2;
 * @return {number}
 */
proto.authorization_endpoint.AddAuthorizationRequest.prototype.getOperation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.authorization_endpoint.AddAuthorizationRequest} returns this
 */
proto.authorization_endpoint.AddAuthorizationRequest.prototype.setOperation = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated KeyValuePair parameters = 3;
 * @return {!Array<!proto.authorization_endpoint.KeyValuePair>}
 */
proto.authorization_endpoint.AddAuthorizationRequest.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.authorization_endpoint.KeyValuePair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authorization_endpoint.KeyValuePair, 3));
};


/**
 * @param {!Array<!proto.authorization_endpoint.KeyValuePair>} value
 * @return {!proto.authorization_endpoint.AddAuthorizationRequest} returns this
*/
proto.authorization_endpoint.AddAuthorizationRequest.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.authorization_endpoint.KeyValuePair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authorization_endpoint.KeyValuePair}
 */
proto.authorization_endpoint.AddAuthorizationRequest.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.authorization_endpoint.KeyValuePair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.AddAuthorizationRequest} returns this
 */
proto.authorization_endpoint.AddAuthorizationRequest.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.repeatedFields_ = [3,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.authorization_endpoint.KeyValuePair.toObject, includeInstance),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest;
  return proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 3:
      var value = new proto.authorization_endpoint.KeyValuePair;
      reader.readMessage(value,proto.authorization_endpoint.KeyValuePair.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    case 6:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.authorization_endpoint.KeyValuePair.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest} returns this
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated KeyValuePair parameters = 3;
 * @return {!Array<!proto.authorization_endpoint.KeyValuePair>}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.authorization_endpoint.KeyValuePair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authorization_endpoint.KeyValuePair, 3));
};


/**
 * @param {!Array<!proto.authorization_endpoint.KeyValuePair>} value
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest} returns this
*/
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.authorization_endpoint.KeyValuePair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authorization_endpoint.KeyValuePair}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.authorization_endpoint.KeyValuePair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest} returns this
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};


/**
 * repeated common.ExtraField extraFields = 6;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 6));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest} returns this
*/
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest} returns this
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authorization_endpoint.GenerateSignaturesRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GenerateSignaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GenerateSignaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateSignaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expirationdate: (f = msg.getExpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.authorization_endpoint.KeyValuePair.toObject, includeInstance),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GenerateSignaturesRequest;
  return proto.authorization_endpoint.GenerateSignaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GenerateSignaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationdate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 4:
      var value = new proto.authorization_endpoint.KeyValuePair;
      reader.readMessage(value,proto.authorization_endpoint.KeyValuePair.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    case 5:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GenerateSignaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GenerateSignaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateSignaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpirationdate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.authorization_endpoint.KeyValuePair.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account = 1;
 * @return {string}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest} returns this
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp expirationDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.getExpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest} returns this
*/
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.setExpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest} returns this
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.clearExpirationdate = function() {
  return this.setExpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.hasExpirationdate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 count = 3;
 * @return {number}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest} returns this
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated KeyValuePair parameters = 4;
 * @return {!Array<!proto.authorization_endpoint.KeyValuePair>}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.authorization_endpoint.KeyValuePair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authorization_endpoint.KeyValuePair, 4));
};


/**
 * @param {!Array<!proto.authorization_endpoint.KeyValuePair>} value
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest} returns this
*/
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.authorization_endpoint.KeyValuePair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authorization_endpoint.KeyValuePair}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.authorization_endpoint.KeyValuePair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest} returns this
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};


/**
 * repeated common.ExtraField extraFields = 5;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 5));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest} returns this
*/
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.GenerateSignaturesRequest} returns this
 */
proto.authorization_endpoint.GenerateSignaturesRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.UpdateAuthorizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.UpdateAuthorizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationRequest}
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.UpdateAuthorizationRequest;
  return proto.authorization_endpoint.UpdateAuthorizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.UpdateAuthorizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationRequest}
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.UpdateAuthorizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.UpdateAuthorizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.UpdateAuthorizationRequest} returns this
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated common.ExtraField extraFields = 4;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 4));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.authorization_endpoint.UpdateAuthorizationRequest} returns this
*/
proto.authorization_endpoint.UpdateAuthorizationRequest.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationRequest} returns this
 */
proto.authorization_endpoint.UpdateAuthorizationRequest.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.KeyValuePair.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.KeyValuePair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.KeyValuePair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.KeyValuePair.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    viewvalue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.KeyValuePair}
 */
proto.authorization_endpoint.KeyValuePair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.KeyValuePair;
  return proto.authorization_endpoint.KeyValuePair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.KeyValuePair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.KeyValuePair}
 */
proto.authorization_endpoint.KeyValuePair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setViewvalue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.KeyValuePair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.KeyValuePair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.KeyValuePair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.KeyValuePair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getViewvalue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string key = 1;
 * @return {string}
 */
proto.authorization_endpoint.KeyValuePair.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.KeyValuePair} returns this
 */
proto.authorization_endpoint.KeyValuePair.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.authorization_endpoint.KeyValuePair.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.KeyValuePair} returns this
 */
proto.authorization_endpoint.KeyValuePair.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string viewValue = 3;
 * @return {string}
 */
proto.authorization_endpoint.KeyValuePair.prototype.getViewvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.KeyValuePair} returns this
 */
proto.authorization_endpoint.KeyValuePair.prototype.setViewvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.RevokeAuthorizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.RevokeAuthorizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.RevokeAuthorizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.RevokeAuthorizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.RevokeAuthorizationRequest}
 */
proto.authorization_endpoint.RevokeAuthorizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.RevokeAuthorizationRequest;
  return proto.authorization_endpoint.RevokeAuthorizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.RevokeAuthorizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.RevokeAuthorizationRequest}
 */
proto.authorization_endpoint.RevokeAuthorizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.RevokeAuthorizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.RevokeAuthorizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.RevokeAuthorizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.RevokeAuthorizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.authorization_endpoint.RevokeAuthorizationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.RevokeAuthorizationRequest} returns this
 */
proto.authorization_endpoint.RevokeAuthorizationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GetAuthorizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GetAuthorizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GetAuthorizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isview: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GetAuthorizationRequest}
 */
proto.authorization_endpoint.GetAuthorizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GetAuthorizationRequest;
  return proto.authorization_endpoint.GetAuthorizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GetAuthorizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GetAuthorizationRequest}
 */
proto.authorization_endpoint.GetAuthorizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsview(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GetAuthorizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GetAuthorizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GetAuthorizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsview();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.authorization_endpoint.GetAuthorizationRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.GetAuthorizationRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isView = 2;
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationRequest.prototype.getIsview = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authorization_endpoint.GetAuthorizationRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationRequest.prototype.setIsview = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GetAuthorizationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GetAuthorizationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isexpired: (f = msg.getIsexpired()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    isrequest: (f = msg.getIsrequest()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 11, 0),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GetAuthorizationsRequest;
  return proto.authorization_endpoint.GetAuthorizationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GetAuthorizationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsexpired(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsrequest(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GetAuthorizationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GetAuthorizationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsexpired();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getIsrequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue account = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getAccount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue code = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasCode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isExpired = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getIsexpired = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setIsexpired = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearIsexpired = function() {
  return this.setIsexpired(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasIsexpired = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value status = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue isRequest = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getIsrequest = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setIsrequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearIsrequest = function() {
  return this.setIsrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasIsrequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue search = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool order = 9;
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 numOfResults = 11;
 * @return {number}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional google.protobuf.StringValue token = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
*/
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsRequest} returns this
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsRequest.prototype.hasToken = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.AddAuthorizationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.AddAuthorizationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.AddAuthorizationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.AddAuthorizationReplay}
 */
proto.authorization_endpoint.AddAuthorizationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.AddAuthorizationReplay;
  return proto.authorization_endpoint.AddAuthorizationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.AddAuthorizationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.AddAuthorizationReplay}
 */
proto.authorization_endpoint.AddAuthorizationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 3:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.AddAuthorizationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.AddAuthorizationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.AddAuthorizationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.AddAuthorizationReplay} returns this
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.AddAuthorizationReplay} returns this
*/
proto.authorization_endpoint.AddAuthorizationReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.AddAuthorizationReplay} returns this
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.MessageResponse message = 3;
 * @return {?proto.common.MessageResponse}
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 3));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.authorization_endpoint.AddAuthorizationReplay} returns this
*/
proto.authorization_endpoint.AddAuthorizationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.AddAuthorizationReplay} returns this
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.AddAuthorizationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay;
  return proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} returns this
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} returns this
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} returns this
*/
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} returns this
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.MessageResponse message = 4;
 * @return {?proto.common.MessageResponse}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 4));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} returns this
*/
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} returns this
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GenerateSignaturesReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GenerateSignaturesReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateSignaturesReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GenerateSignaturesReplay}
 */
proto.authorization_endpoint.GenerateSignaturesReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GenerateSignaturesReplay;
  return proto.authorization_endpoint.GenerateSignaturesReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GenerateSignaturesReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GenerateSignaturesReplay}
 */
proto.authorization_endpoint.GenerateSignaturesReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 4:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GenerateSignaturesReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GenerateSignaturesReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateSignaturesReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.GenerateSignaturesReplay} returns this
*/
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GenerateSignaturesReplay} returns this
 */
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.MessageResponse message = 4;
 * @return {?proto.common.MessageResponse}
 */
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 4));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.authorization_endpoint.GenerateSignaturesReplay} returns this
*/
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GenerateSignaturesReplay} returns this
 */
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GenerateSignaturesReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.UpdateAuthorizationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.UpdateAuthorizationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationReplay}
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.UpdateAuthorizationReplay;
  return proto.authorization_endpoint.UpdateAuthorizationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.UpdateAuthorizationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationReplay}
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.UpdateAuthorizationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.UpdateAuthorizationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.UpdateAuthorizationReplay} returns this
*/
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationReplay} returns this
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.authorization_endpoint.UpdateAuthorizationReplay} returns this
*/
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationReplay} returns this
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.UpdateAuthorizationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.RevokeAuthorizationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.RevokeAuthorizationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.RevokeAuthorizationReplay}
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.RevokeAuthorizationReplay;
  return proto.authorization_endpoint.RevokeAuthorizationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.RevokeAuthorizationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.RevokeAuthorizationReplay}
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.RevokeAuthorizationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.RevokeAuthorizationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.RevokeAuthorizationReplay} returns this
*/
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.RevokeAuthorizationReplay} returns this
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.authorization_endpoint.RevokeAuthorizationReplay} returns this
*/
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.RevokeAuthorizationReplay} returns this
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.RevokeAuthorizationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.AuthorizationState.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.AuthorizationState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.AuthorizationState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.AuthorizationState.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.AuthorizationState}
 */
proto.authorization_endpoint.AuthorizationState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.AuthorizationState;
  return proto.authorization_endpoint.AuthorizationState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.AuthorizationState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.AuthorizationState}
 */
proto.authorization_endpoint.AuthorizationState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.AuthorizationState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.AuthorizationState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.AuthorizationState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.AuthorizationState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.authorization_endpoint.AuthorizationState.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.authorization_endpoint.AuthorizationState} returns this
*/
proto.authorization_endpoint.AuthorizationState.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.AuthorizationState} returns this
 */
proto.authorization_endpoint.AuthorizationState.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.AuthorizationState.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.AuthorizationState.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.AuthorizationState} returns this
*/
proto.authorization_endpoint.AuthorizationState.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.AuthorizationState} returns this
 */
proto.authorization_endpoint.AuthorizationState.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.AuthorizationState.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authorization_endpoint.GetAuthorizationReplay.repeatedFields_ = [10,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GetAuthorizationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GetAuthorizationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    account: (f = msg.getAccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    expirationdate: (f = msg.getExpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.authorization_endpoint.AuthorizationState.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    isrequest: (f = msg.getIsrequest()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.authorization_endpoint.KeyValuePair.toObject, includeInstance),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay}
 */
proto.authorization_endpoint.GetAuthorizationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GetAuthorizationReplay;
  return proto.authorization_endpoint.GetAuthorizationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GetAuthorizationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay}
 */
proto.authorization_endpoint.GetAuthorizationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationdate(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 5:
      var value = new proto.authorization_endpoint.AuthorizationState;
      reader.readMessage(value,proto.authorization_endpoint.AuthorizationState.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsrequest(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 10:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 11:
      var value = new proto.authorization_endpoint.KeyValuePair;
      reader.readMessage(value,proto.authorization_endpoint.KeyValuePair.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GetAuthorizationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GetAuthorizationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getExpirationdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.authorization_endpoint.AuthorizationState.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsrequest();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.authorization_endpoint.KeyValuePair.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.AccountResponse account = 2;
 * @return {?proto.common.AccountResponse}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getAccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 2));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp expirationDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getExpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setExpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearExpirationdate = function() {
  return this.setExpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.hasExpirationdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AuthorizationState status = 5;
 * @return {?proto.authorization_endpoint.AuthorizationState}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getStatus = function() {
  return /** @type{?proto.authorization_endpoint.AuthorizationState} */ (
    jspb.Message.getWrapperField(this, proto.authorization_endpoint.AuthorizationState, 5));
};


/**
 * @param {?proto.authorization_endpoint.AuthorizationState|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.ProfileResponse createdBy = 7;
 * @return {?proto.common.ProfileResponse}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 7));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue isRequest = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getIsrequest = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setIsrequest = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearIsrequest = function() {
  return this.setIsrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.hasIsrequest = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue token = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.hasToken = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated common.ExtraField extraFields = 10;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 10));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * repeated KeyValuePair parameters = 11;
 * @return {!Array<!proto.authorization_endpoint.KeyValuePair>}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.authorization_endpoint.KeyValuePair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authorization_endpoint.KeyValuePair, 11));
};


/**
 * @param {!Array<!proto.authorization_endpoint.KeyValuePair>} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.authorization_endpoint.KeyValuePair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authorization_endpoint.KeyValuePair}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.authorization_endpoint.KeyValuePair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};


/**
 * optional google.protobuf.StringValue code = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationReplay.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationReplay.prototype.hasCode = function() {
  return jspb.Message.getField(this, 12) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authorization_endpoint.Authorization.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.Authorization.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.Authorization.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.Authorization} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.Authorization.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    token: (f = msg.getToken()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    expirationdate: (f = msg.getExpirationdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    operation: (f = msg.getOperation()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && proto.authorization_endpoint.AuthorizationState.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    isrequest: (f = msg.getIsrequest()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    parametersList: jspb.Message.toObjectList(msg.getParametersList(),
    proto.authorization_endpoint.KeyValuePair.toObject, includeInstance),
    code: (f = msg.getCode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.Authorization}
 */
proto.authorization_endpoint.Authorization.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.Authorization;
  return proto.authorization_endpoint.Authorization.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.Authorization} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.Authorization}
 */
proto.authorization_endpoint.Authorization.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpirationdate(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOperation(value);
      break;
    case 5:
      var value = new proto.authorization_endpoint.AuthorizationState;
      reader.readMessage(value,proto.authorization_endpoint.AuthorizationState.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsrequest(value);
      break;
    case 9:
      var value = new proto.authorization_endpoint.KeyValuePair;
      reader.readMessage(value,proto.authorization_endpoint.KeyValuePair.deserializeBinaryFromReader);
      msg.addParameters(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.Authorization.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.Authorization.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.Authorization} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.Authorization.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExpirationdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOperation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.authorization_endpoint.AuthorizationState.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getIsrequest();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getParametersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.authorization_endpoint.KeyValuePair.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.authorization_endpoint.Authorization.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue token = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authorization_endpoint.Authorization.prototype.getToken = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
*/
proto.authorization_endpoint.Authorization.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.Authorization.prototype.hasToken = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp expirationDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.Authorization.prototype.getExpirationdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
*/
proto.authorization_endpoint.Authorization.prototype.setExpirationdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.clearExpirationdate = function() {
  return this.setExpirationdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.Authorization.prototype.hasExpirationdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value operation = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.authorization_endpoint.Authorization.prototype.getOperation = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
*/
proto.authorization_endpoint.Authorization.prototype.setOperation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.clearOperation = function() {
  return this.setOperation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.Authorization.prototype.hasOperation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AuthorizationState status = 5;
 * @return {?proto.authorization_endpoint.AuthorizationState}
 */
proto.authorization_endpoint.Authorization.prototype.getStatus = function() {
  return /** @type{?proto.authorization_endpoint.AuthorizationState} */ (
    jspb.Message.getWrapperField(this, proto.authorization_endpoint.AuthorizationState, 5));
};


/**
 * @param {?proto.authorization_endpoint.AuthorizationState|undefined} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
*/
proto.authorization_endpoint.Authorization.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.Authorization.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.authorization_endpoint.Authorization.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
*/
proto.authorization_endpoint.Authorization.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.Authorization.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.ProfileResponse createdBy = 7;
 * @return {?proto.common.ProfileResponse}
 */
proto.authorization_endpoint.Authorization.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 7));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
*/
proto.authorization_endpoint.Authorization.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.Authorization.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue isRequest = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.authorization_endpoint.Authorization.prototype.getIsrequest = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
*/
proto.authorization_endpoint.Authorization.prototype.setIsrequest = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.clearIsrequest = function() {
  return this.setIsrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.Authorization.prototype.hasIsrequest = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated KeyValuePair parameters = 9;
 * @return {!Array<!proto.authorization_endpoint.KeyValuePair>}
 */
proto.authorization_endpoint.Authorization.prototype.getParametersList = function() {
  return /** @type{!Array<!proto.authorization_endpoint.KeyValuePair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authorization_endpoint.KeyValuePair, 9));
};


/**
 * @param {!Array<!proto.authorization_endpoint.KeyValuePair>} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
*/
proto.authorization_endpoint.Authorization.prototype.setParametersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.authorization_endpoint.KeyValuePair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authorization_endpoint.KeyValuePair}
 */
proto.authorization_endpoint.Authorization.prototype.addParameters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.authorization_endpoint.KeyValuePair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.clearParametersList = function() {
  return this.setParametersList([]);
};


/**
 * optional google.protobuf.StringValue code = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.authorization_endpoint.Authorization.prototype.getCode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.authorization_endpoint.Authorization} returns this
*/
proto.authorization_endpoint.Authorization.prototype.setCode = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.Authorization} returns this
 */
proto.authorization_endpoint.Authorization.prototype.clearCode = function() {
  return this.setCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.Authorization.prototype.hasCode = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.authorization_endpoint.GetAuthorizationsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GetAuthorizationsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GetAuthorizationsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GetAuthorizationsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    authorizationsList: jspb.Message.toObjectList(msg.getAuthorizationsList(),
    proto.authorization_endpoint.Authorization.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GetAuthorizationsReplay}
 */
proto.authorization_endpoint.GetAuthorizationsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GetAuthorizationsReplay;
  return proto.authorization_endpoint.GetAuthorizationsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GetAuthorizationsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GetAuthorizationsReplay}
 */
proto.authorization_endpoint.GetAuthorizationsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authorization_endpoint.Authorization;
      reader.readMessage(value,proto.authorization_endpoint.Authorization.deserializeBinaryFromReader);
      msg.addAuthorizations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GetAuthorizationsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GetAuthorizationsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GetAuthorizationsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthorizationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.authorization_endpoint.Authorization.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Authorization authorizations = 1;
 * @return {!Array<!proto.authorization_endpoint.Authorization>}
 */
proto.authorization_endpoint.GetAuthorizationsReplay.prototype.getAuthorizationsList = function() {
  return /** @type{!Array<!proto.authorization_endpoint.Authorization>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.authorization_endpoint.Authorization, 1));
};


/**
 * @param {!Array<!proto.authorization_endpoint.Authorization>} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsReplay} returns this
*/
proto.authorization_endpoint.GetAuthorizationsReplay.prototype.setAuthorizationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.authorization_endpoint.Authorization=} opt_value
 * @param {number=} opt_index
 * @return {!proto.authorization_endpoint.Authorization}
 */
proto.authorization_endpoint.GetAuthorizationsReplay.prototype.addAuthorizations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.authorization_endpoint.Authorization, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.authorization_endpoint.GetAuthorizationsReplay} returns this
 */
proto.authorization_endpoint.GetAuthorizationsReplay.prototype.clearAuthorizationsList = function() {
  return this.setAuthorizationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authorization_endpoint.AddAuthorizationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.authorization_endpoint.AddAuthorizationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.authorization_endpoint.AddAuthorizationResponse.ResultCase}
 */
proto.authorization_endpoint.AddAuthorizationResponse.prototype.getResultCase = function() {
  return /** @type {proto.authorization_endpoint.AddAuthorizationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authorization_endpoint.AddAuthorizationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.AddAuthorizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.AddAuthorizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.AddAuthorizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.AddAuthorizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.authorization_endpoint.AddAuthorizationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.AddAuthorizationResponse}
 */
proto.authorization_endpoint.AddAuthorizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.AddAuthorizationResponse;
  return proto.authorization_endpoint.AddAuthorizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.AddAuthorizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.AddAuthorizationResponse}
 */
proto.authorization_endpoint.AddAuthorizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authorization_endpoint.AddAuthorizationReplay;
      reader.readMessage(value,proto.authorization_endpoint.AddAuthorizationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.AddAuthorizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.AddAuthorizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.AddAuthorizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.AddAuthorizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authorization_endpoint.AddAuthorizationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional AddAuthorizationReplay success = 1;
 * @return {?proto.authorization_endpoint.AddAuthorizationReplay}
 */
proto.authorization_endpoint.AddAuthorizationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.authorization_endpoint.AddAuthorizationReplay} */ (
    jspb.Message.getWrapperField(this, proto.authorization_endpoint.AddAuthorizationReplay, 1));
};


/**
 * @param {?proto.authorization_endpoint.AddAuthorizationReplay|undefined} value
 * @return {!proto.authorization_endpoint.AddAuthorizationResponse} returns this
*/
proto.authorization_endpoint.AddAuthorizationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authorization_endpoint.AddAuthorizationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.AddAuthorizationResponse} returns this
 */
proto.authorization_endpoint.AddAuthorizationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.AddAuthorizationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.authorization_endpoint.UpdateAuthorizationResponse.ResultCase}
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.prototype.getResultCase = function() {
  return /** @type {proto.authorization_endpoint.UpdateAuthorizationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authorization_endpoint.UpdateAuthorizationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.UpdateAuthorizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.UpdateAuthorizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.authorization_endpoint.UpdateAuthorizationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationResponse}
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.UpdateAuthorizationResponse;
  return proto.authorization_endpoint.UpdateAuthorizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.UpdateAuthorizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationResponse}
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authorization_endpoint.UpdateAuthorizationReplay;
      reader.readMessage(value,proto.authorization_endpoint.UpdateAuthorizationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.UpdateAuthorizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.UpdateAuthorizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authorization_endpoint.UpdateAuthorizationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAuthorizationReplay success = 1;
 * @return {?proto.authorization_endpoint.UpdateAuthorizationReplay}
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.authorization_endpoint.UpdateAuthorizationReplay} */ (
    jspb.Message.getWrapperField(this, proto.authorization_endpoint.UpdateAuthorizationReplay, 1));
};


/**
 * @param {?proto.authorization_endpoint.UpdateAuthorizationReplay|undefined} value
 * @return {!proto.authorization_endpoint.UpdateAuthorizationResponse} returns this
*/
proto.authorization_endpoint.UpdateAuthorizationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authorization_endpoint.UpdateAuthorizationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.UpdateAuthorizationResponse} returns this
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.UpdateAuthorizationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.ResultCase}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.prototype.getResultCase = function() {
  return /** @type {proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse;
  return proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay;
      reader.readMessage(value,proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GenerateTriggeredTransferTokenReplay success = 1;
 * @return {?proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.prototype.getSuccess = function() {
  return /** @type{?proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay} */ (
    jspb.Message.getWrapperField(this, proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay, 1));
};


/**
 * @param {?proto.authorization_endpoint.GenerateTriggeredTransferTokenReplay|undefined} value
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse} returns this
*/
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse} returns this
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GenerateTriggeredTransferTokenResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.authorization_endpoint.RevokeAuthorizationResponse.ResultCase}
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.prototype.getResultCase = function() {
  return /** @type {proto.authorization_endpoint.RevokeAuthorizationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authorization_endpoint.RevokeAuthorizationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.RevokeAuthorizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.RevokeAuthorizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.authorization_endpoint.RevokeAuthorizationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.RevokeAuthorizationResponse}
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.RevokeAuthorizationResponse;
  return proto.authorization_endpoint.RevokeAuthorizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.RevokeAuthorizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.RevokeAuthorizationResponse}
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authorization_endpoint.RevokeAuthorizationReplay;
      reader.readMessage(value,proto.authorization_endpoint.RevokeAuthorizationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.RevokeAuthorizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.RevokeAuthorizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authorization_endpoint.RevokeAuthorizationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional RevokeAuthorizationReplay success = 1;
 * @return {?proto.authorization_endpoint.RevokeAuthorizationReplay}
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.authorization_endpoint.RevokeAuthorizationReplay} */ (
    jspb.Message.getWrapperField(this, proto.authorization_endpoint.RevokeAuthorizationReplay, 1));
};


/**
 * @param {?proto.authorization_endpoint.RevokeAuthorizationReplay|undefined} value
 * @return {!proto.authorization_endpoint.RevokeAuthorizationResponse} returns this
*/
proto.authorization_endpoint.RevokeAuthorizationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authorization_endpoint.RevokeAuthorizationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.RevokeAuthorizationResponse} returns this
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.RevokeAuthorizationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authorization_endpoint.GetAuthorizationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.authorization_endpoint.GetAuthorizationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.authorization_endpoint.GetAuthorizationResponse.ResultCase}
 */
proto.authorization_endpoint.GetAuthorizationResponse.prototype.getResultCase = function() {
  return /** @type {proto.authorization_endpoint.GetAuthorizationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authorization_endpoint.GetAuthorizationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GetAuthorizationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GetAuthorizationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GetAuthorizationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.authorization_endpoint.GetAuthorizationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GetAuthorizationResponse}
 */
proto.authorization_endpoint.GetAuthorizationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GetAuthorizationResponse;
  return proto.authorization_endpoint.GetAuthorizationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GetAuthorizationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GetAuthorizationResponse}
 */
proto.authorization_endpoint.GetAuthorizationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authorization_endpoint.GetAuthorizationReplay;
      reader.readMessage(value,proto.authorization_endpoint.GetAuthorizationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GetAuthorizationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GetAuthorizationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GetAuthorizationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authorization_endpoint.GetAuthorizationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAuthorizationReplay success = 1;
 * @return {?proto.authorization_endpoint.GetAuthorizationReplay}
 */
proto.authorization_endpoint.GetAuthorizationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.authorization_endpoint.GetAuthorizationReplay} */ (
    jspb.Message.getWrapperField(this, proto.authorization_endpoint.GetAuthorizationReplay, 1));
};


/**
 * @param {?proto.authorization_endpoint.GetAuthorizationReplay|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationResponse} returns this
*/
proto.authorization_endpoint.GetAuthorizationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authorization_endpoint.GetAuthorizationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationResponse} returns this
 */
proto.authorization_endpoint.GetAuthorizationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authorization_endpoint.GetAuthorizationsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.authorization_endpoint.GetAuthorizationsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.authorization_endpoint.GetAuthorizationsResponse.ResultCase}
 */
proto.authorization_endpoint.GetAuthorizationsResponse.prototype.getResultCase = function() {
  return /** @type {proto.authorization_endpoint.GetAuthorizationsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authorization_endpoint.GetAuthorizationsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GetAuthorizationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GetAuthorizationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GetAuthorizationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.authorization_endpoint.GetAuthorizationsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GetAuthorizationsResponse}
 */
proto.authorization_endpoint.GetAuthorizationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GetAuthorizationsResponse;
  return proto.authorization_endpoint.GetAuthorizationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GetAuthorizationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GetAuthorizationsResponse}
 */
proto.authorization_endpoint.GetAuthorizationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authorization_endpoint.GetAuthorizationsReplay;
      reader.readMessage(value,proto.authorization_endpoint.GetAuthorizationsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GetAuthorizationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GetAuthorizationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GetAuthorizationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GetAuthorizationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authorization_endpoint.GetAuthorizationsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAuthorizationsReplay success = 1;
 * @return {?proto.authorization_endpoint.GetAuthorizationsReplay}
 */
proto.authorization_endpoint.GetAuthorizationsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.authorization_endpoint.GetAuthorizationsReplay} */ (
    jspb.Message.getWrapperField(this, proto.authorization_endpoint.GetAuthorizationsReplay, 1));
};


/**
 * @param {?proto.authorization_endpoint.GetAuthorizationsReplay|undefined} value
 * @return {!proto.authorization_endpoint.GetAuthorizationsResponse} returns this
*/
proto.authorization_endpoint.GetAuthorizationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authorization_endpoint.GetAuthorizationsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GetAuthorizationsResponse} returns this
 */
proto.authorization_endpoint.GetAuthorizationsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GetAuthorizationsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.authorization_endpoint.GenerateSignaturesResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.authorization_endpoint.GenerateSignaturesResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.authorization_endpoint.GenerateSignaturesResponse.ResultCase}
 */
proto.authorization_endpoint.GenerateSignaturesResponse.prototype.getResultCase = function() {
  return /** @type {proto.authorization_endpoint.GenerateSignaturesResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.authorization_endpoint.GenerateSignaturesResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.authorization_endpoint.GenerateSignaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.authorization_endpoint.GenerateSignaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.authorization_endpoint.GenerateSignaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateSignaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.authorization_endpoint.GenerateSignaturesReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.authorization_endpoint.GenerateSignaturesResponse}
 */
proto.authorization_endpoint.GenerateSignaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.authorization_endpoint.GenerateSignaturesResponse;
  return proto.authorization_endpoint.GenerateSignaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.authorization_endpoint.GenerateSignaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.authorization_endpoint.GenerateSignaturesResponse}
 */
proto.authorization_endpoint.GenerateSignaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.authorization_endpoint.GenerateSignaturesReplay;
      reader.readMessage(value,proto.authorization_endpoint.GenerateSignaturesReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.authorization_endpoint.GenerateSignaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.authorization_endpoint.GenerateSignaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.authorization_endpoint.GenerateSignaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.authorization_endpoint.GenerateSignaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.authorization_endpoint.GenerateSignaturesReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GenerateSignaturesReplay success = 1;
 * @return {?proto.authorization_endpoint.GenerateSignaturesReplay}
 */
proto.authorization_endpoint.GenerateSignaturesResponse.prototype.getSuccess = function() {
  return /** @type{?proto.authorization_endpoint.GenerateSignaturesReplay} */ (
    jspb.Message.getWrapperField(this, proto.authorization_endpoint.GenerateSignaturesReplay, 1));
};


/**
 * @param {?proto.authorization_endpoint.GenerateSignaturesReplay|undefined} value
 * @return {!proto.authorization_endpoint.GenerateSignaturesResponse} returns this
*/
proto.authorization_endpoint.GenerateSignaturesResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.authorization_endpoint.GenerateSignaturesResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.authorization_endpoint.GenerateSignaturesResponse} returns this
 */
proto.authorization_endpoint.GenerateSignaturesResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.authorization_endpoint.GenerateSignaturesResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.authorization_endpoint);
