
export interface Page {
    key: string,
    title: string,
    path: string,
    parentKey?: string,
    nav: string
}

const appPages: Page[] = [
    {
        key: "dashboard",
        title: "dashboard",
        path: "",
        nav: "dashboard"
        //parentKey: "users"
    },
    {
        key: "organizations",
        title: "organizations",
        path: "organizations",
        nav: "organizations"
        //parentKey: "users"

    },

    {
        key: "organizationClientPortalSetting",
        title: "organizationClientPortalSetting",
        path: "client-portal-settings",
        nav: "organizationClientPortalSetting"
        //parentKey: "users"

    },
    {
        key: "reports",
        title: "reports",
        path: "reports",
        nav: "reports"
        //parentKey: "users"

    },
    {
        key: "reports/treasuryBalanceReport",
        title: "treasuryBalanceReport",
        path: "reports/treasuryBalanceReport",
        parentKey: "reports",
        nav: "reports/treasuryBalanceReport"

    },
    {
        key: "reports/accountBalanceReport",
        title: "accountBalanceReport",
        path: "reports/accountBalanceReport",
        parentKey: "reports",
        nav: "reports/accountBalanceReport"
    },
    {
        key: "reports/transactionTotalsReport",
        title: "transactionTotalsReport",
        path: "reports/transactionTotalsReport",
        parentKey: "reports",
        nav: "reports/transactionTotalsReport"
    },
    {
        key: "reports/currencyExchangeRatesReport",
        title: "currencyExchangeRatesReport",
        path: "reports/currencyExchangeRatesReport",
        parentKey: "reports",
        nav: "reports/currencyExchangeRatesReport"
    },
    {
        key: "reports/tradingReport",
        title: "tradingReport",
        path: "reports/tradingReport",
        parentKey: "reports",
        nav: "reports/tradingReport"
    },
    {
        key: "login",
        title: "sarafa",
        path: "login",
        nav: ""
        //parentKey: "users"
    },
    {
        key: "logs",
        title: "logs",
        path: "logs",
        nav: "logs"
        //parentKey: "users"
    },
    {
        key: "logs/details",
        title: "logDetails",
        path: "logs/:id",
        parentKey: "logs",
        nav: "logs"
    },

    {
        key: "profile",
        title: "myProfilePage",
        path: "profile",
        nav: ""
        //parentKey: "users"
    },

    {
        key: "organizations/details",
        title: "organizationDetails",
        path: "organizations/:id",
        parentKey: "organizations",
        nav: "organizations"
    },


    {
        key: "management",
        title: "management",
        path: "management/users",
        nav: "management"

    },
    {
        key: "management/users",
        title: "users",
        path: "management/users",
        parentKey: "management",
        nav: "management/users"

    },
    {
        key: "management/users/add",
        title: "addManager",
        path: "management/users/add",
        parentKey: "management/users",
        nav: "management/users"

    },
    {
        key: "management/users/details",
        title: "managerDetails",
        path: "management/users/:id",
        parentKey: "management/users",
        nav: "management/users"
    },
    {
        key: "management/usersTypes",
        title: "usersTypes",
        path: "management/user-types",
        parentKey: "management",
        nav: "management/usersTypes"


    },
    {
        key: "management/usersTypes/details",
        title: "userTypeDetails",
        path: "management/user-types/:id",
        parentKey: "management/usersTypes",
        nav: "management/usersTypes"
    },

    {
        key: "management/privileges",
        title: "privileges",
        path: "management/privileges",
        parentKey: "management",
        nav: "management/privileges"


    },
    {
        key: "management/privileges/details",
        title: "privilegeDetails",
        path: "management/privileges/:id",
        parentKey: "management/privileges",
        nav: "management/privileges"
    },
    {
        key: "management/privilege-data/details",
        title: "privilegeDataDetails",
        path: "management/privilege-data/:id",
        nav: "management/privileges",
        parentKey: "management",

    },
    {
        key: "management/roles",
        title: "roles",
        path: "management/roles",
        parentKey: "management",
        nav: "management/roles"


    },
    {
        key: "management/roles/details",
        title: "roleDetails",
        path: "management/roles/:id",
        parentKey: "management/roles",
        nav: "management/roles"
    },
    {
        key: "management/groups",
        title: "groups",
        path: "management/groups",
        parentKey: "management",
        nav: "management/groups"


    },
    {
        key: "management/groups/details",
        title: "groupDetails",
        path: "management/groups/:id",
        parentKey: "management/groups",
        nav: "management/groups"
    },

    {
        key: "management/groupMembership/details",
        title: "groupMembershipDetails",
        path: "management/group-memberships/:id",
        nav: "management/groups"
    },
    {
        key: "customers/groupMembership/details",
        title: "groupMembershipDetails",
        path: "customers/group-memberships/:id",
        nav: "customers/groups"
    },


    {
        key: "customers/dataRestrection/details",
        title: "dataRestrectionDetails",
        path: "customers/data-restrictions/:id",
        nav: "customers/privileges"
    },


    {
        key: "management/dataRestrection/details",
        title: "dataRestrectionDetails",
        path: "management/data-restrictions/:id",
        nav: "management/privileges"
    },


    {
        key: "customers/rolePrivilege/details",
        title: "subscriptionServiceDetails",
        path: "customers/role-privileges/:id",
        nav: "customers/roles"
    },


    {
        key: "management/rolePrivilege/details",
        title: "rolePrivilegeDetails",
        path: "management/role-privileges/:id",
        nav: "management/roles"
    },

    {
        key: "customers",
        title: "customers",
        path: "customers/users",
        nav: "customers"

    },
    {
        key: "customers/users",
        title: "profiles",
        path: "customers/users",
        parentKey: "customers",
        nav: "customers/users"

    },
    {
        key: "customers/users/add",
        title: "addCustomer",
        path: "customers/users/add",
        parentKey: "customers/users",
        nav: "customers/users"

    },
    {
        key: "customers/users/details",
        title: "customerDetails",
        path: "customers/users/:id",
        parentKey: "customers/users",
        nav: "customers/users"
    },
    {
        key: "customers/usersTypes",
        title: "clientTypes",
        path: "customers/user-types",
        parentKey: "customers",
        nav: "customers/usersTypes"


    },
    {
        key: "customers/usersTypes/details",
        title: "clientTypeDetails",
        path: "customers/user-types/:id",
        parentKey: "customers/usersTypes",
        nav: "customers/usersTypes"
    },

    {
        key: "customers/privileges",
        title: "services",
        path: "customers/privileges",
        parentKey: "customers",
        nav: "customers/privileges"


    },
    {
        key: "customers/privileges/details",
        title: "serviceDetails",
        path: "customers/privileges/:id",
        parentKey: "customers/privileges",
        nav: "customers/privileges"
    },
    {
        key: "customers/privilege-data/details",
        title: "serviceDataDetails",
        path: "customers/privilege-data/:id",
        nav: "customers/privileges",
        parentKey: "customers",
    },
    {
        key: "customers/roles",
        title: "subscriptions",
        path: "customers/roles",
        parentKey: "customers",
        nav: "customers/roles"


    },
    {
        key: "customers/roles/details",
        title: "subscriptionDetails",
        path: "customers/roles/:id",
        parentKey: "customers/roles",
        nav: "customers/roles"
    },
    {
        key: "customers/groups",
        title: "groups",
        path: "customers/groups",
        parentKey: "customers",
        nav: "customers/groups"


    },
    {
        key: "customers/groups/details",
        title: "groupDetails",
        path: "customers/groups/:id",
        parentKey: "customers/groups",
        nav: "customers/groups"
    },

    {
        key: "customers/groupMembership/details",
        title: "groupMembershipDetails",
        path: "customers/group-memberships/:id",
        nav: "customers/groups"
    },

    {
        key: "accountsManagement",
        title: "accountsManagement",
        path: "accounts",
        nav: "accountsManagement"
    },
    {
        key: "accountsManagement/accounts",
        title: "accounts",
        path: "accounts",
        nav: "accountsManagement/accounts"
    },
    {
        key: "accountsManagement/accounts/details",
        title: "accountDetails",
        path: "accounts/:id",
        parentKey: "accountsManagement/accounts",
        nav: "accountsManagement/accounts"
    },
    {
        key: "accountsManagement/accounts/chart",
        title: "chart",
        path: "accounts/chart",
        nav: "accountsManagement/accounts",
        parentKey: "accountsManagement/accounts",

    },
    {
        key: "accountsManagement/accountTypes",
        title: "accountTypes",
        path: "account-types",
        parentKey: "accountsManagement",
        nav: "accountsManagement/accountTypes"
    },

    {
        key: "accountsManagement/transactions",
        title: "transactionsStatement",
        path: "transactions",
        parentKey: "accountsManagement",
        nav: "accountsManagement/transactions"
    },
    {
        key: "accountsManagement/reservedBalances",
        title: "reservedBalances",
        path: "reserved-balances",
        parentKey: "accountsManagement",
        nav: "accountsManagement/reservedBalances"
    },

    {
        key: "accountsManagement/reservedBalances/details",
        title: "reservedBalanceDetails",
        path: "reserved-balances/:id",
        parentKey: "accountsManagement/reservedBalances",
        nav: "accountsManagement/reservedBalances"
    },

    {
        key: "accountsManagement/accountTypes/details",
        title: "accountTypeDetails",
        path: "account-types/:id",
        parentKey: "accountsManagement/accountTypes",
        nav: "accountsManagement/accountTypes"
    },
    {
        key: "accountsManagement/accountTypeRules/details",
        title: "accountTypeRuleDetails",
        path: "account-type-rules/:id",
        nav: "accountsManagement/accountTypes"
    },
    {
        key: "accountsManagement/transactions/details",
        title: "transactionDetails",
        path: "account-transactions/:id",
        parentKey: "accountsManagement",
        nav: "accountsManagement/accounts"
    },
    {
        key: "accountsManagement/accountAuthorization/details",
        title: "accountAuthorizationDetails",
        path: "account-authorizations/:id",
        parentKey: "accountsManagement",
        nav: "accountsManagement/accounts"
    },
    {
        key: "accountsManagement/authorization/details",
        title: "authorization",
        path: "authorizations/:id",
        parentKey: "accountsManagement",
        nav: "accountsManagement/accounts"
    },


    {
        key: "currencies",
        title: "currencies",
        path: "currencies",
        nav: "currencies"
    },
    {
        key: "currencies/details",
        title: "currencyDetails",
        path: "currencies/:id",
        parentKey: "currencies",
        nav: "currencies"
    },

    {
        key: "fees",
        title: "fees",
        path: "fees",
        nav: "fees"
    },

    {
        key: "fees/details",
        title: "feeDetails",
        path: "fees/:id",
        parentKey: "fees",
        nav: "fees"
    },

    {
        key: "limitations",
        title: "limitations",
        path: "limitations",
        nav: "limitations"
    },
    {
        key: "limitations/details",
        title: "limitationDetails",
        path: "limitations/:id",
        parentKey: "limitations",
        nav: "limitations"
    },
    {
        key: "treasuriesManagement",
        title: "treasuriesManagement",
        path: "treasuries",
        nav: "treasuriesManagement"
    },
    {
        key: "treasuriesManagement/treasuries",
        title: "treasuries",
        path: "treasuries",
        parentKey: "treasuriesManagement",
        nav: "treasuriesManagement/treasuries"
    },


    {
        key: "treasuriesManagement/treasuries/details",
        title: "treasuryDetails",
        path: "treasuries/:id",
        parentKey: "treasuriesManagement/treasuries",
        nav: "treasuriesManagement/treasuries"
    },

    {
        key: "treasuriesManagement/treasuries/chart",
        title: "chart",
        path: "treasuries/chart",
        nav: "treasuriesManagement/treasuries",
        parentKey: "treasuriesManagement/treasuries",

    },
    {
        key: "treasuriesManagement/transactions/details",
        title: "transactionDetails",
        path: "treasury-transactions/:id",
        parentKey: "treasuriesManagement",
        nav: "treasuriesManagement/treasuries"
    },
    {
        key: "treasuriesManagement/treasuryTypes",
        title: "treasuryTypes",
        path: "treasury-types",
        parentKey: "treasuriesManagement",
        nav: "treasuriesManagement/treasuryTypes"
    },

    {
        key: "treasuriesManagement/treasuryTypes/details",
        title: "treasuryTypeDetails",
        path: "treasury-types/:id",
        parentKey: "treasuriesManagement/treasuryTypes",
        nav: "treasuriesManagement/treasuryTypes"
    },

    {
        key: "treasuriesManagement/treasuryTypeRules/details",
        title: "treasuryTypeRuleDetails",
        path: "treasury-type-rules/:id",
        parentKey: "treasuriesManagement/treasuryTypeRules",
        nav: "treasuriesManagement/treasuryTypes"
    },


    {
        key: "trading",
        title: "trading",
        path: "currency-pairs",
        nav: "trading"
    },
    {
        key: "trading/currencyPairs",
        title: "currencyPairs",
        path: "currency-pairs",
        nav: "trading/currencyPairs",
        parentKey: "trading",

    },
    {
        key: "trading/chart",
        title: "tradingScreen",
        path: "chart",
        nav: "trading/chart",
        parentKey: "trading",

    },
    {
        key: "trading/currencyPairs/details",
        title: "currencyPairDetails",
        path: "currency-pairs/:id",
        parentKey: "trading/currencyPairs",
        nav: "trading/currencyPairs"
    },

    {
        key: "trading/currencyPairsPriceLog/details",
        title: "exchangeRates",
        path: "currency-pairs-price-log/:id",
        parentKey: "trading/currencyPairs",
        nav: "trading/currencyPairs"
    },

    {
        key: "trading/categories",
        title: "categories",
        path: "categories",
        parentKey: "trading",
        nav: "trading/categories"
    },

    {
        key: "trading/categories/details",
        title: "categoryDetails",
        path: "categories/:id",
        parentKey: "trading/categories",
        nav: "trading/categories"
    },


    {
        key: "trading/orders",
        title: "orders",
        path: "orders",
        nav: "trading/orders",
        parentKey: "trading",

    },

    {
        key: "trading/orders/details",
        title: "orderDetails",
        path: "orders/:id",
        nav: "trading/orders",
        parentKey: "trading/orders",

    },

    {
        key: "trading/orderTransactions/details",
        title: "orderTransactionsDetails",
        path: "orderTransactions/:id",
        nav: "trading/orders",
        parentKey: "trading",


    },

];


export const getPage = (key: string) => appPages.filter(e => e.key == key)[0];

