import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DialogFooter, DatePicker, DayOfWeek, defaultDatePickerStrings, Label, CheckboxVisibility, Separator, ConstrainMode } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { reset, dismissMessage, getItems, resetSet, getAccounts, resetAccountsSet, getCurrencies, resetCurrenciesSet, setIsFilteredCurrencySet, setOperation, setSelectedCurrencyItems, setMessage, setIsFilteredAccountSet, setSelectedAccountItems, exportReport } from "./TransactionTotalsReportSlice"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { currencyFormatter, formatDate, printComponent, normalizeKey, getOperations } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../common/Popup/Popup"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"
import { List } from "../../../common/List/List"
import { ExportAccountBalanceReportReplay, ExportAccountBalanceReportRequest, ExportTransactionTotalsReportRequest, GetAccountBalanceReportRequest, GetTransactionTotalsReportRequest, GetTreasuryBalanceReportRequest } from "../../../../repository/Accountant/report_pb"
import report from "../../../../repository/Accountant/report_pb"

import { GetTreasuriesRequest } from "../../../../repository/Accountant/treasury_pb"
import { TreasuriesPage } from "../../Accountant/Treasuries/TreasuriesPage"
import { CurrenciesPage } from "../../Accountant/Currencies/CurrenciesPage"
import moment from "moment"
import { Form, Formik } from "formik"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import { AccountStatus, TreasuryStatus } from "../../../../app/Enums"
import { inputs } from "../../../../app/Validation"
import * as Yup from 'yup';
import { GetAccountsRequest } from "../../../../repository/Accountant/account_pb"
import { AccountsPage } from "../../Accountant/Accounts/AccountsPage"
import { TextView } from "../../../common/DataView/TextView"

let req: GetTransactionTotalsReportRequest;
let getAccountsReq: GetAccountsRequest;
let currenciesReq: GetCurrenciesRequest;


let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;
let exportReq: ExportTransactionTotalsReportRequest;

const key = "reports/transactionTotalsReport"
export const TransactionTotalsReportPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        accounts: TableState,
        currencies: TableState,
        operations: { key: number, text: string },
        isChangeStateLoading: boolean
        totals: any

    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.transactionTotalsReportPage.isChangeStateLoading,
            set: state.transactionTotalsReportPage.set,
            message: state.transactionTotalsReportPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            accounts: state.transactionTotalsReportPage.accounts,
            totals: state.transactionTotalsReportPage.totals,
            currencies: state.transactionTotalsReportPage.currencies,
            operations: state.transactionTotalsReportPage.operations,
        }
    })

    const [currentAction, setCurrenctAction] = useState(0)
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});


    useEffect(() => {
        req = new GetTransactionTotalsReportRequest();
        getAccountsReq = new GetAccountsRequest();
        currenciesReq = new GetCurrenciesRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                console.log(org)
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                getAccountsReq.setOrganization(wrapper)
                currenciesReq.setOrganization(wrapper)
            }
        }

        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        const boolv = new BoolValue();
        boolv.setValue(true);

        const statt = new Int32Value();
        statt.setValue(AccountStatus.ACTIVE);

        getAccountsReq.setNumofresults(state.accounts.numberOfResults)
        getAccountsReq.setOrder(state.accounts.isDescending)
        getAccountsReq.setStatus(statt)

        currenciesReq.setNumofresults(state.currencies.numberOfResults)
        currenciesReq.setOrder(state.currencies.isDescending)


        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const c: IColumn[] = [
        {
            key: 'currencyId',
            name: t("currency"),
            minWidth: 50,
            maxWidth: 340,
            isRowHeader: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.currencyId != undefined)
                    return <Text>{(item?.currencyName)}</Text>; //{item.createdDate}




                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'operation',
            name: t("operation"),
            minWidth: 20,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.operation) {
                    return <Text>{t(getOperations().find(x => x.key == item?.operation)?.text ?? item?.operation)}</Text>;;

                }
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'date',
            name: t("date"),
            minWidth: 50,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.date != undefined)
                    return <Text>{(item?.date)}</Text>; //{item.createdDate}
            },
        },

        {
            key: 'totalamount',
            name: t("total"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalAmount != undefined)
                    return <Text>{currencyFormatter(item?.totalAmount, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;
            },
        },
        {
            key: 'totalNumberOfOperations',
            name: t("numberOfOperations"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalNumberOfOperations != undefined)
                    return <Text>{item?.totalNumberOfOperations}</Text>;
            },
        },


    ];







    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getAccountsReq.setSearch(wrapper)
        getAccountsReq.setNextto(undefined)

        dispatch(setIsFilteredAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
        return [];

    }
    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrenciesSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrencySet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }
    return (
        <Stack >

            <Popup isOpen={(currentAction == 1000)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 1003)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >
                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }


            <Formik
                enableReinitialize={true}
                initialValues={{
                    from: moment(fromDate, 'YYYY-MM-DD').startOf('day').toDate(),
                    to: moment(new Date(), 'YYYY-MM-DD').endOf('day').toDate(),
                    group: { key: 0, text: t("daily") },
                    operation: { key: 0, text: t("all") },

                }}


                onSubmit={(values, actions) => {

                    let account = state.accounts.selected.at(0);
                    let currency = state.currencies.selected.at(0);
                    exportReq = new ExportTransactionTotalsReportRequest();

                    if (account) {
                        let s = new StringValue();
                        s.setValue(account.internalId)
                        req.setAccount(s);
                        exportReq.setAccount(s);

                    } else {
                        req.setAccount(undefined);
                        exportReq.setAccount(undefined);
                    }
                    if (currency) {
                        let s = new StringValue();
                        s.setValue(currency.id)
                        req.setCurrency(s);
                        exportReq.setCurrency(s)

                    } else {
                        req.setCurrency(undefined);
                        exportReq.setCurrency(undefined)


                    }
                    if (values.operation?.key != 0) {
                        let s = new Int32Value();
                        s.setValue(values.operation?.key)
                        req.setOperation(s);
                        exportReq.setOperation(s)

                    } else {
                        req.setOperation(undefined);
                        exportReq.setOperation(undefined)

                    }

                    const wrapper = new Timestamp();
                    wrapper.fromDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate())
                    req.setFrom(wrapper)

                    const wrapperTo = new Timestamp();
                    wrapperTo.fromDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate())
                    req.setTo(wrapperTo)
                    req.setGroupby(Number(values.group.key))

                    exportReq.setOrganization(req.getOrganization())
                    exportReq.setFrom(req.getFrom())
                    exportReq.setTo(req.getTo())
                    exportReq.setGroupby(req.getGroupby())


                    const labels: report.Label[] = [];
                    let r = new report.Label();
                    let value = new StringValue();
                    value.setValue(formatDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate()))
                    r.setLabelvalue(t("from"));
                    r.setValue(value)
                    r.setPropartyname("from")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(formatDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate()))
                    r.setLabelvalue(t("to"));
                    r.setValue(value)
                    r.setPropartyname("to")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(values.group.text)
                    r.setLabelvalue(t("grouping"));
                    r.setValue(value)
                    r.setPropartyname("groupby")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(account ? (account.name + " - " + account.id) : "")
                    r.setLabelvalue(t("account"));
                    r.setValue(value)
                    r.setPropartyname("account")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(currency?.name ?? "")
                    r.setLabelvalue(t("currency"));
                    r.setValue(value)
                    r.setPropartyname("currency")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(values?.operation?.text ?? "")
                    r.setLabelvalue(t("operation"));
                    r.setValue(value)
                    r.setPropartyname("operation")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("generatedDate"));
                    r.setPropartyname("generateddate")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("generatedBy"));
                    r.setPropartyname("generatedby")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("date"));
                    r.setPropartyname("date")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("total"));
                    r.setPropartyname("totalAmount")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("numberOfOperations"));
                    r.setPropartyname("totalNumberOfOperations")
                    labels.push(r)



                    r = new report.Label();
                    value = new StringValue();
                    value.setValue((((new Date()).getTimezoneOffset()) * -1) + "")
                    r.setValue(value);
                    r.setPropartyname("timezone")
                    labels.push(r)

                    exportReq.setLabelsList(labels)
                    getItemsPromise?.abort()
                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))


                }}

            >
                {formkikProps => (
                    <Form >

                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                <Stack.Item grow>
                                    <DatePicker
                                        isRequired
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        label={t("from")}
                                        placeholder={t("from")}
                                        disabled={state.set.isFetching}
                                        value={formkikProps.values.from}
                                        ariaLabel={t("from")}
                                        formatDate={(e) => {
                                            return formatDate(e, 'YYYY-MM-DD')
                                        }}
                                        parseDateFromString={(date) => {
                                            return moment(date, 'YYYY-MM-DD').startOf('day').toDate();
                                        }}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        onSelectDate={(date: Date | null | undefined) => {

                                            formkikProps.setFieldValue("from", date)
                                        }}

                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <DatePicker
                                        isRequired
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        label={t("to")}
                                        placeholder={t("to")}
                                        disabled={state.set.isFetching}
                                        value={formkikProps.values.to}
                                        ariaLabel={t("to")}
                                        formatDate={(e) => {
                                            return formatDate(e, 'YYYY-MM-DD')
                                        }}
                                        parseDateFromString={(date) => {
                                            return moment(date, 'YYYY-MM-DD').endOf('day').toDate();
                                        }}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        onSelectDate={(date: Date | null | undefined) => {
                                            if (date != undefined)
                                                if (moment(date).isSameOrAfter(formkikProps.values.from)) {
                                                    formkikProps.setFieldValue("to", date)
                                                } else {
                                                    formkikProps.setFieldValue("to", new Date())
                                                }
                                        }}

                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <ComboBox
                                        label={t("grouping")}
                                        required
                                        allowFreeform={true}
                                        autoComplete={'on'}
                                        disabled={state.set.isFetching}
                                        options={[{ key: 3, text: t("hourly") }, { key: 0, text: t("daily") }, { key: 1, text: t("monthly") }, { key: 2, text: t("yearly") },]}
                                        selectedKey={formkikProps.values.group.key}
                                        onChange={(e, o) => {
                                            formkikProps.setFieldValue("group", { key: o?.key, text: o?.text })
                                        }} />
                                </Stack.Item>
                            </Stack>


                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>

                                <Stack.Item grow>
                                    <Label>{t("operation")}</Label>
                                    <Dropdown
                                        selectedKey={state.operations.key}
                                        options={[{ key: 0, text: t("all") }].concat(getOperations(true).map(o => {
                                            return { key: o.key, text: t(normalizeKey(o.text)) }
                                        }))}
                                        disabled={state.set.isFetching}

                                        onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {

                                            dispatch(setOperation({ key: Number(item?.key), text: t("operation") + ":" + item?.text }))

                                        })}

                                    />
                                </Stack.Item>


                                <Stack.Item grow>
                                    <Label disabled={state.set.isFetching}>{t("account")}</Label>
                                    <List
                                        isPeoplePicker={true}
                                        suggestionsHeaderText={t('accounts')}
                                        isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                        isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                        moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                        suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                                getAccountsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
                                            }

                                        }}
                                        inputProps={{ placeholder: t("account"), }}

                                        disabled={state.set.isFetching}
                                        selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name + " (#" + e.id + ")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedAccountItems([]))
                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetAccountsSet())
                                            getAccountsReq.setSearch(undefined)
                                            getAccountsReq.setNextto(undefined)
                                            dispatch(setIsFilteredAccountSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))

                                        }}
                                        onFilterChanged={onAccountsFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(1000);

                                                }
                                            }]}
                                    />

                                </Stack.Item>

                                <Stack grow >
                                    <Label >{t("currency")}</Label>
                                    <List
                                        disabled={state.set.isFetching}

                                        inputProps={{ placeholder: t("currency"), }}
                                        suggestionsHeaderText={t('currencies')}
                                        isLoading={state.currencies.items.length == 0 ? state.currencies.isFetching : false}
                                        isSearching={state.currencies.items.length > 0 ? state.currencies.isFetching : false}
                                        moreSuggestionsAvailable={state.currencies.hasMore && !state.currencies.isFetching}
                                        suggestions={state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                                                currenciesReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.currencies.items.findIndex(e => e.id == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                                            }
                                        }}
                                        isPeoplePicker={true}
                                        selectedItems={state.currencies.selected.length > 0 ? state.currencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedCurrencyItems([]))

                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetCurrenciesSet())
                                            currenciesReq.setSearch(undefined)
                                            currenciesReq.setNextto(undefined)
                                            dispatch(setIsFilteredCurrencySet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                            return []
                                        }}
                                        onFilterChanged={onCurrenciesFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(1003)

                                                }
                                            }]}
                                    />

                                </Stack>
                            </Stack>


                            <DialogFooter>
                                <PrimaryButton disabled={state.set.isFetching} text={state.set.isFetching ? undefined : t("generateReport")} type="submit" >

                                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                                </PrimaryButton>

                                <DefaultButton disabled={state.set.isFetching} text={t("clearFilter")} onClick={() => {
                                    formkikProps.resetForm()
                                    req.setAccount(undefined)
                                    req.setCurrency(undefined)
                                    req.setOperation(undefined)
                                    req.setGroupby(0)
                                    req.setFrom(undefined)
                                    req.setTo(undefined)
                                    dispatch(setSelectedAccountItems([]))
                                    dispatch(resetSet())
                                }} />
                            </DialogFooter>
                        </Stack>
                        {state.set.items.length > 0 ? <CommandBar
                            items={[
                                {
                                    key: 'export',
                                    text: t("export"),
                                    iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },
                                    title: t("export"),
                                    disabled: state.set.isFetching,
                                    commandBarButtonAs: (e) => {
                                        return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                                            <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                                        </CommandBarButton>;
                                    },
                                    subMenuProps: {
                                        items: [{
                                            key: 'exportToExcel',
                                            text: t('exportToExcel'),
                                            title: t("exportToExcel"),
                                            iconProps: { iconName: 'ExcelDocument' },

                                            onClick: () => {
                                                if (!state.isChangeStateLoading && exportReq) {
                                                    exportReq.setExporttype(1);
                                                    setCurrenctAction(1)
                                                    getItemsPromise = dispatch(exportReport({
                                                        body: exportReq, headers: getHeaders()
                                                    }))

                                                }

                                            }
                                        },
                                        {
                                            key: 'exportToCsv',
                                            text: t("exportToCsv"),
                                            title: t("exportToCsv"),

                                            iconProps: { iconName: 'ReportDocument' },
                                            onClick: () => {
                                                if (!state.isChangeStateLoading && exportReq) {
                                                    exportReq.setExporttype(2);
                                                    setCurrenctAction(1)
                                                    getItemsPromise = dispatch(exportReport({
                                                        body: exportReq, headers: getHeaders()
                                                    }))

                                                }

                                            }
                                        },

                                        ]
                                    },


                                },
                                {
                                    key: 'print',
                                    text: t("print"),
                                    iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
                                    title: t("print"),
                                    onClick: () => {
                                        handlePrint(reactToPrintContent);
                                    }
                                },

                            ]}

                        /> : undefined}

                    </Form>
                )}


            </Formik>

            <Table
                setKey="transactionTotalsReport"
                items={state.set.items}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                columns={c}
                selectionMode={SelectionMode.none}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.fixedColumns}
                compRef={ref}
                height={state.message == undefined ? "calc(100vh - 435px)" : "calc(100vh - 484px)"}

            />
            {state.totals ? <>
                <Separator alignContent="start"><b>{t("totalPeriod")}</b></Separator>

                <Stack tokens={{ childrenGap: 10 }} horizontal>

                    {(state.accounts?.selected?.length > 0 || state.currencies?.selected?.length > 0) ? <TextView label={t("total")} value={state.totals?.totalAmount != undefined ? currencyFormatter(state.totals?.totalAmount, { significantDigits: state.totals?.currencyDecimalPlaces ?? 9, symbol: state.totals?.currencySymbol }) : ""} />
                        : undefined}

                    <TextView label={t("numberOfOperations")} value={state.totals?.totalNumberOfOperations != undefined ? state.totals?.totalNumberOfOperations + "" : ""} />


                </Stack>
            </> : undefined
            }

        </Stack >



    );
}




