// source: order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.order_endpoint.CancelReplay', null, global);
goog.exportSymbol('proto.order_endpoint.CancelRequest', null, global);
goog.exportSymbol('proto.order_endpoint.CancelResponse', null, global);
goog.exportSymbol('proto.order_endpoint.CancelResponse.ResultCase', null, global);
goog.exportSymbol('proto.order_endpoint.DirectExchangeReplay', null, global);
goog.exportSymbol('proto.order_endpoint.DirectExchangeRequest', null, global);
goog.exportSymbol('proto.order_endpoint.DirectExchangeResponse', null, global);
goog.exportSymbol('proto.order_endpoint.DirectExchangeResponse.ResultCase', null, global);
goog.exportSymbol('proto.order_endpoint.ExportOrderReplay', null, global);
goog.exportSymbol('proto.order_endpoint.ExportOrderRequest', null, global);
goog.exportSymbol('proto.order_endpoint.ExportOrderResponse', null, global);
goog.exportSymbol('proto.order_endpoint.ExportOrderResponse.ResultCase', null, global);
goog.exportSymbol('proto.order_endpoint.ExternalExchangeReplay', null, global);
goog.exportSymbol('proto.order_endpoint.ExternalExchangeRequest', null, global);
goog.exportSymbol('proto.order_endpoint.ExternalExchangeResponse', null, global);
goog.exportSymbol('proto.order_endpoint.ExternalExchangeResponse.ResultCase', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderReplay', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderRequest', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderResponse', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderResponse.ResultCase', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderTransactionReplay', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderTransactionRequest', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderTransactionResponse', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderTransactionResponse.ResultCase', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderTransactionsReplay', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderTransactionsRequest', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderTransactionsResponse', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrderTransactionsResponse.ResultCase', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrdersReplay', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrdersRequest', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrdersResponse', null, global);
goog.exportSymbol('proto.order_endpoint.GetOrdersResponse.ResultCase', null, global);
goog.exportSymbol('proto.order_endpoint.Label', null, global);
goog.exportSymbol('proto.order_endpoint.Order', null, global);
goog.exportSymbol('proto.order_endpoint.OrderTransaction', null, global);
goog.exportSymbol('proto.order_endpoint.PlaceOrderReplay', null, global);
goog.exportSymbol('proto.order_endpoint.PlaceOrderRequest', null, global);
goog.exportSymbol('proto.order_endpoint.PlaceOrderResponse', null, global);
goog.exportSymbol('proto.order_endpoint.PlaceOrderResponse.ResultCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.PlaceOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.PlaceOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.PlaceOrderRequest.displayName = 'proto.order_endpoint.PlaceOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.DirectExchangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.DirectExchangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.DirectExchangeRequest.displayName = 'proto.order_endpoint.DirectExchangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.ExternalExchangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.ExternalExchangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.ExternalExchangeRequest.displayName = 'proto.order_endpoint.ExternalExchangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.CancelRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.CancelRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.CancelRequest.displayName = 'proto.order_endpoint.CancelRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.GetOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrderRequest.displayName = 'proto.order_endpoint.GetOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.Label = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.Label, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.Label.displayName = 'proto.order_endpoint.Label';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.ExportOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_endpoint.ExportOrderRequest.repeatedFields_, null);
};
goog.inherits(proto.order_endpoint.ExportOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.ExportOrderRequest.displayName = 'proto.order_endpoint.ExportOrderRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrderTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.GetOrderTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrderTransactionRequest.displayName = 'proto.order_endpoint.GetOrderTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.GetOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrdersRequest.displayName = 'proto.order_endpoint.GetOrdersRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrderTransactionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.GetOrderTransactionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrderTransactionsRequest.displayName = 'proto.order_endpoint.GetOrderTransactionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.DirectExchangeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.DirectExchangeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.DirectExchangeReplay.displayName = 'proto.order_endpoint.DirectExchangeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.ExternalExchangeReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.ExternalExchangeReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.ExternalExchangeReplay.displayName = 'proto.order_endpoint.ExternalExchangeReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.CancelReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.CancelReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.CancelReplay.displayName = 'proto.order_endpoint.CancelReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrderReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.GetOrderReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrderReplay.displayName = 'proto.order_endpoint.GetOrderReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.Order.displayName = 'proto.order_endpoint.Order';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.ExportOrderReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.ExportOrderReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.ExportOrderReplay.displayName = 'proto.order_endpoint.ExportOrderReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrdersReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_endpoint.GetOrdersReplay.repeatedFields_, null);
};
goog.inherits(proto.order_endpoint.GetOrdersReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrdersReplay.displayName = 'proto.order_endpoint.GetOrdersReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrderTransactionReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.GetOrderTransactionReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrderTransactionReplay.displayName = 'proto.order_endpoint.GetOrderTransactionReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.OrderTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.OrderTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.OrderTransaction.displayName = 'proto.order_endpoint.OrderTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.PlaceOrderReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.order_endpoint.PlaceOrderReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.PlaceOrderReplay.displayName = 'proto.order_endpoint.PlaceOrderReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrderTransactionsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.order_endpoint.GetOrderTransactionsReplay.repeatedFields_, null);
};
goog.inherits(proto.order_endpoint.GetOrderTransactionsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrderTransactionsReplay.displayName = 'proto.order_endpoint.GetOrderTransactionsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.DirectExchangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.order_endpoint.DirectExchangeResponse.oneofGroups_);
};
goog.inherits(proto.order_endpoint.DirectExchangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.DirectExchangeResponse.displayName = 'proto.order_endpoint.DirectExchangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.ExternalExchangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.order_endpoint.ExternalExchangeResponse.oneofGroups_);
};
goog.inherits(proto.order_endpoint.ExternalExchangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.ExternalExchangeResponse.displayName = 'proto.order_endpoint.ExternalExchangeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.CancelResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.order_endpoint.CancelResponse.oneofGroups_);
};
goog.inherits(proto.order_endpoint.CancelResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.CancelResponse.displayName = 'proto.order_endpoint.CancelResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.order_endpoint.GetOrdersResponse.oneofGroups_);
};
goog.inherits(proto.order_endpoint.GetOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrdersResponse.displayName = 'proto.order_endpoint.GetOrdersResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrderTransactionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.order_endpoint.GetOrderTransactionsResponse.oneofGroups_);
};
goog.inherits(proto.order_endpoint.GetOrderTransactionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrderTransactionsResponse.displayName = 'proto.order_endpoint.GetOrderTransactionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.order_endpoint.GetOrderResponse.oneofGroups_);
};
goog.inherits(proto.order_endpoint.GetOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrderResponse.displayName = 'proto.order_endpoint.GetOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.GetOrderTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.order_endpoint.GetOrderTransactionResponse.oneofGroups_);
};
goog.inherits(proto.order_endpoint.GetOrderTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.GetOrderTransactionResponse.displayName = 'proto.order_endpoint.GetOrderTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.ExportOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.order_endpoint.ExportOrderResponse.oneofGroups_);
};
goog.inherits(proto.order_endpoint.ExportOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.ExportOrderResponse.displayName = 'proto.order_endpoint.ExportOrderResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.order_endpoint.PlaceOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.order_endpoint.PlaceOrderResponse.oneofGroups_);
};
goog.inherits(proto.order_endpoint.PlaceOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.order_endpoint.PlaceOrderResponse.displayName = 'proto.order_endpoint.PlaceOrderResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.PlaceOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.PlaceOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.PlaceOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypair: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fromaccount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toaccount: jspb.Message.getFieldWithDefault(msg, 5, ""),
    matchorder: jspb.Message.getFieldWithDefault(msg, 6, ""),
    side: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    type: jspb.Message.getFieldWithDefault(msg, 9, 0),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.PlaceOrderRequest}
 */
proto.order_endpoint.PlaceOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.PlaceOrderRequest;
  return proto.order_endpoint.PlaceOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.PlaceOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.PlaceOrderRequest}
 */
proto.order_endpoint.PlaceOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencypair(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromaccount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToaccount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchorder(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSide(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.PlaceOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.PlaceOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.PlaceOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypair();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromaccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToaccount();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMatchorder();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSide();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string currencyPair = 1;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getCurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string quantity = 2;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string price = 3;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fromAccount = 4;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getFromaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.setFromaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string toAccount = 5;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getToaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.setToaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string matchOrder = 6;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getMatchorder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.setMatchorder = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool side = 7;
 * @return {boolean}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getSide = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.setSide = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 type = 9;
 * @return {number}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.Int32Value organization = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
*/
proto.order_endpoint.PlaceOrderRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue authCode = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
*/
proto.order_endpoint.PlaceOrderRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.PlaceOrderRequest} returns this
 */
proto.order_endpoint.PlaceOrderRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.PlaceOrderRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.DirectExchangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.DirectExchangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.DirectExchangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypair: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sellerfromaccount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sellertoaccount: jspb.Message.getFieldWithDefault(msg, 5, ""),
    buyerfromaccount: jspb.Message.getFieldWithDefault(msg, 6, ""),
    buyertoaccount: jspb.Message.getFieldWithDefault(msg, 7, ""),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    priceoption: jspb.Message.getFieldWithDefault(msg, 9, 0),
    buyerauthcode: (f = msg.getBuyerauthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sellerauthcode: (f = msg.getSellerauthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.DirectExchangeRequest}
 */
proto.order_endpoint.DirectExchangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.DirectExchangeRequest;
  return proto.order_endpoint.DirectExchangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.DirectExchangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.DirectExchangeRequest}
 */
proto.order_endpoint.DirectExchangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencypair(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellerfromaccount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellertoaccount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerfromaccount(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyertoaccount(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceoption(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setBuyerauthcode(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSellerauthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.DirectExchangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.DirectExchangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.DirectExchangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypair();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSellerfromaccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSellertoaccount();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBuyerfromaccount();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBuyertoaccount();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPriceoption();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBuyerauthcode();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSellerauthcode();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string currencyPair = 1;
 * @return {string}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getCurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string quantity = 2;
 * @return {string}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string price = 3;
 * @return {string}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sellerFromAccount = 4;
 * @return {string}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getSellerfromaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.setSellerfromaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string sellerToAccount = 5;
 * @return {string}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getSellertoaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.setSellertoaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string buyerFromAccount = 6;
 * @return {string}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getBuyerfromaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.setBuyerfromaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string buyerToAccount = 7;
 * @return {string}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getBuyertoaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.setBuyertoaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Int32Value organization = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
*/
proto.order_endpoint.DirectExchangeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 priceOption = 9;
 * @return {number}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getPriceoption = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.setPriceoption = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.StringValue buyerAuthCode = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getBuyerauthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
*/
proto.order_endpoint.DirectExchangeRequest.prototype.setBuyerauthcode = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.clearBuyerauthcode = function() {
  return this.setBuyerauthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.hasBuyerauthcode = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue sellerAuthCode = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.getSellerauthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
*/
proto.order_endpoint.DirectExchangeRequest.prototype.setSellerauthcode = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.DirectExchangeRequest} returns this
 */
proto.order_endpoint.DirectExchangeRequest.prototype.clearSellerauthcode = function() {
  return this.setSellerauthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.DirectExchangeRequest.prototype.hasSellerauthcode = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.ExternalExchangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.ExternalExchangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExternalExchangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypair: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, ""),
    price: jspb.Message.getFieldWithDefault(msg, 3, ""),
    fromaccount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    toaccount: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fromtreasury: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totreasury: jspb.Message.getFieldWithDefault(msg, 7, ""),
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    priceoption: jspb.Message.getFieldWithDefault(msg, 9, 0),
    side: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    authcode: (f = msg.getAuthcode()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.ExternalExchangeRequest}
 */
proto.order_endpoint.ExternalExchangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.ExternalExchangeRequest;
  return proto.order_endpoint.ExternalExchangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.ExternalExchangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.ExternalExchangeRequest}
 */
proto.order_endpoint.ExternalExchangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencypair(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromaccount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToaccount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromtreasury(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotreasury(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceoption(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSide(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuthcode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.ExternalExchangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.ExternalExchangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExternalExchangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypair();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getFromaccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getToaccount();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFromtreasury();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotreasury();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPriceoption();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getSide();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getAuthcode();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string currencyPair = 1;
 * @return {string}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getCurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string quantity = 2;
 * @return {string}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string price = 3;
 * @return {string}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string fromAccount = 4;
 * @return {string}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getFromaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.setFromaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string toAccount = 5;
 * @return {string}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getToaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.setToaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fromTreasury = 6;
 * @return {string}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getFromtreasury = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.setFromtreasury = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string toTreasury = 7;
 * @return {string}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getTotreasury = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.setTotreasury = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Int32Value organization = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
*/
proto.order_endpoint.ExternalExchangeRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 priceOption = 9;
 * @return {number}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getPriceoption = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.setPriceoption = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool side = 10;
 * @return {boolean}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getSide = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.setSide = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.StringValue authCode = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.getAuthcode = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
*/
proto.order_endpoint.ExternalExchangeRequest.prototype.setAuthcode = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.ExternalExchangeRequest} returns this
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.clearAuthcode = function() {
  return this.setAuthcode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.ExternalExchangeRequest.prototype.hasAuthcode = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.CancelRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.CancelRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.CancelRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.CancelRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.CancelRequest}
 */
proto.order_endpoint.CancelRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.CancelRequest;
  return proto.order_endpoint.CancelRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.CancelRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.CancelRequest}
 */
proto.order_endpoint.CancelRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.CancelRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.CancelRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.CancelRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.CancelRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_endpoint.CancelRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.CancelRequest} returns this
 */
proto.order_endpoint.CancelRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrderRequest}
 */
proto.order_endpoint.GetOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrderRequest;
  return proto.order_endpoint.GetOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrderRequest}
 */
proto.order_endpoint.GetOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_endpoint.GetOrderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.GetOrderRequest} returns this
 */
proto.order_endpoint.GetOrderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.Label.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.Label.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.Label} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.Label.toObject = function(includeInstance, msg) {
  var f, obj = {
    propartyname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    labelvalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: (f = msg.getValue()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.Label}
 */
proto.order_endpoint.Label.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.Label;
  return proto.order_endpoint.Label.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.Label} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.Label}
 */
proto.order_endpoint.Label.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropartyname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabelvalue(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.Label.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.Label.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.Label} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.Label.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropartyname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabelvalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string propartyName = 1;
 * @return {string}
 */
proto.order_endpoint.Label.prototype.getPropartyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.Label} returns this
 */
proto.order_endpoint.Label.prototype.setPropartyname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string labelValue = 2;
 * @return {string}
 */
proto.order_endpoint.Label.prototype.getLabelvalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.Label} returns this
 */
proto.order_endpoint.Label.prototype.setLabelvalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue value = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.Label.prototype.getValue = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.Label} returns this
*/
proto.order_endpoint.Label.prototype.setValue = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Label} returns this
 */
proto.order_endpoint.Label.prototype.clearValue = function() {
  return this.setValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Label.prototype.hasValue = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_endpoint.ExportOrderRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.ExportOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.ExportOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.ExportOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExportOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    proto.order_endpoint.Label.toObject, includeInstance),
    exporttype: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.ExportOrderRequest}
 */
proto.order_endpoint.ExportOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.ExportOrderRequest;
  return proto.order_endpoint.ExportOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.ExportOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.ExportOrderRequest}
 */
proto.order_endpoint.ExportOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.order_endpoint.Label;
      reader.readMessage(value,proto.order_endpoint.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setExporttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.ExportOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.ExportOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.ExportOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExportOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.order_endpoint.Label.serializeBinaryToWriter
    );
  }
  f = message.getExporttype();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_endpoint.ExportOrderRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.ExportOrderRequest} returns this
 */
proto.order_endpoint.ExportOrderRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Label labels = 2;
 * @return {!Array<!proto.order_endpoint.Label>}
 */
proto.order_endpoint.ExportOrderRequest.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.order_endpoint.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order_endpoint.Label, 2));
};


/**
 * @param {!Array<!proto.order_endpoint.Label>} value
 * @return {!proto.order_endpoint.ExportOrderRequest} returns this
*/
proto.order_endpoint.ExportOrderRequest.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.order_endpoint.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order_endpoint.Label}
 */
proto.order_endpoint.ExportOrderRequest.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.order_endpoint.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_endpoint.ExportOrderRequest} returns this
 */
proto.order_endpoint.ExportOrderRequest.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * optional int32 exportType = 3;
 * @return {number}
 */
proto.order_endpoint.ExportOrderRequest.prototype.getExporttype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.order_endpoint.ExportOrderRequest} returns this
 */
proto.order_endpoint.ExportOrderRequest.prototype.setExporttype = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrderTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrderTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrderTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrderTransactionRequest}
 */
proto.order_endpoint.GetOrderTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrderTransactionRequest;
  return proto.order_endpoint.GetOrderTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrderTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrderTransactionRequest}
 */
proto.order_endpoint.GetOrderTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrderTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrderTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrderTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_endpoint.GetOrderTransactionRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.GetOrderTransactionRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    client: (f = msg.getClient()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currencypair: (f = msg.getCurrencypair()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    tradeaction: (f = msg.getTradeaction()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    ordertype: (f = msg.getOrdertype()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrdersRequest}
 */
proto.order_endpoint.GetOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrdersRequest;
  return proto.order_endpoint.GetOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrdersRequest}
 */
proto.order_endpoint.GetOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCurrencypair(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTradeaction(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrdertype(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrencypair();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTradeaction();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOrdertype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue client = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getClient = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasClient = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue currencyPair = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getCurrencypair = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearCurrencypair = function() {
  return this.setCurrencypair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasCurrencypair = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp from = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp to = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value status = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value tradeAction = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getTradeaction = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setTradeaction = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearTradeaction = function() {
  return this.setTradeaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasTradeaction = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value orderType = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getOrdertype = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setOrdertype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearOrdertype = function() {
  return this.setOrdertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasOrdertype = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue search = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool order = 10;
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
*/
proto.order_endpoint.GetOrdersRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 numOfResults = 12;
 * @return {number}
 */
proto.order_endpoint.GetOrdersRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.order_endpoint.GetOrdersRequest} returns this
 */
proto.order_endpoint.GetOrdersRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrderTransactionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrderTransactionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organization: (f = msg.getOrganization()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    orderid: (f = msg.getOrderid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    from: (f = msg.getFrom()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    referenceorder: (f = msg.getReferenceorder()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    client: (f = msg.getClient()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    order: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    nextto: (f = msg.getNextto()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    numofresults: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest}
 */
proto.order_endpoint.GetOrderTransactionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrderTransactionsRequest;
  return proto.order_endpoint.GetOrderTransactionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrderTransactionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest}
 */
proto.order_endpoint.GetOrderTransactionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOrderid(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReferenceorder(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrder(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNextto(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumofresults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrderTransactionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrderTransactionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOrderid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReferenceorder();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getNextto();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNumofresults();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional google.protobuf.Int32Value organization = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getOrganization = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
*/
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue orderId = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getOrderid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
*/
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setOrderid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.clearOrderid = function() {
  return this.setOrderid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.hasOrderid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp from = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getFrom = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
*/
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp to = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getTo = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
*/
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.hasTo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue referenceOrder = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getReferenceorder = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
*/
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setReferenceorder = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.clearReferenceorder = function() {
  return this.setReferenceorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.hasReferenceorder = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue client = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getClient = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
*/
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.hasClient = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue search = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
*/
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool order = 8;
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getOrder = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.StringValue nextTo = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getNextto = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
*/
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setNextto = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.clearNextto = function() {
  return this.setNextto(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.hasNextto = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 numOfResults = 10;
 * @return {number}
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.getNumofresults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.order_endpoint.GetOrderTransactionsRequest} returns this
 */
proto.order_endpoint.GetOrderTransactionsRequest.prototype.setNumofresults = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.DirectExchangeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.DirectExchangeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.DirectExchangeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.DirectExchangeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    sellerorder: jspb.Message.getFieldWithDefault(msg, 2, ""),
    buyerorder: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.DirectExchangeReplay}
 */
proto.order_endpoint.DirectExchangeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.DirectExchangeReplay;
  return proto.order_endpoint.DirectExchangeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.DirectExchangeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.DirectExchangeReplay}
 */
proto.order_endpoint.DirectExchangeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSellerorder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyerorder(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.DirectExchangeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.DirectExchangeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.DirectExchangeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.DirectExchangeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getSellerorder();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBuyerorder();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.order_endpoint.DirectExchangeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.order_endpoint.DirectExchangeReplay} returns this
*/
proto.order_endpoint.DirectExchangeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.DirectExchangeReplay} returns this
 */
proto.order_endpoint.DirectExchangeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.DirectExchangeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string SellerOrder = 2;
 * @return {string}
 */
proto.order_endpoint.DirectExchangeReplay.prototype.getSellerorder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.DirectExchangeReplay} returns this
 */
proto.order_endpoint.DirectExchangeReplay.prototype.setSellerorder = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string BuyerOrder = 3;
 * @return {string}
 */
proto.order_endpoint.DirectExchangeReplay.prototype.getBuyerorder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.DirectExchangeReplay} returns this
 */
proto.order_endpoint.DirectExchangeReplay.prototype.setBuyerorder = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.DirectExchangeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.DirectExchangeReplay} returns this
*/
proto.order_endpoint.DirectExchangeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.DirectExchangeReplay} returns this
 */
proto.order_endpoint.DirectExchangeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.DirectExchangeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.ExternalExchangeReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.ExternalExchangeReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExternalExchangeReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.ExternalExchangeReplay}
 */
proto.order_endpoint.ExternalExchangeReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.ExternalExchangeReplay;
  return proto.order_endpoint.ExternalExchangeReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.ExternalExchangeReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.ExternalExchangeReplay}
 */
proto.order_endpoint.ExternalExchangeReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.ExternalExchangeReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.ExternalExchangeReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExternalExchangeReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.order_endpoint.ExternalExchangeReplay} returns this
*/
proto.order_endpoint.ExternalExchangeReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.ExternalExchangeReplay} returns this
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string order = 2;
 * @return {string}
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.ExternalExchangeReplay} returns this
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.ExternalExchangeReplay} returns this
*/
proto.order_endpoint.ExternalExchangeReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.ExternalExchangeReplay} returns this
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.ExternalExchangeReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.CancelReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.CancelReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.CancelReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.CancelReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.CancelReplay}
 */
proto.order_endpoint.CancelReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.CancelReplay;
  return proto.order_endpoint.CancelReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.CancelReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.CancelReplay}
 */
proto.order_endpoint.CancelReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.CancelReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.CancelReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.CancelReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.CancelReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.order_endpoint.CancelReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.order_endpoint.CancelReplay} returns this
*/
proto.order_endpoint.CancelReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.CancelReplay} returns this
 */
proto.order_endpoint.CancelReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.CancelReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updatedDate = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.CancelReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.CancelReplay} returns this
*/
proto.order_endpoint.CancelReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.CancelReplay} returns this
 */
proto.order_endpoint.CancelReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.CancelReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrderReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrderReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrderReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    total: (f = msg.getTotal()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    filled: (f = msg.getFilled()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    reference: (f = msg.getReference()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    ordertype: (f = msg.getOrdertype()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    tradeaction: (f = msg.getTradeaction()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    client: (f = msg.getClient()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    orderitem: (f = msg.getOrderitem()) && Protos_common_pb.OrderItemResponse.toObject(includeInstance, f),
    fromaccount: (f = msg.getFromaccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    toaccount: (f = msg.getToaccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    quantityfilled: (f = msg.getQuantityfilled()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    remainingfilled: (f = msg.getRemainingfilled()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    remainingquantityfilled: (f = msg.getRemainingquantityfilled()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrderReplay}
 */
proto.order_endpoint.GetOrderReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrderReplay;
  return proto.order_endpoint.GetOrderReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrderReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrderReplay}
 */
proto.order_endpoint.GetOrderReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFilled(value);
      break;
    case 5:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReference(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrdertype(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTradeaction(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 10:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 11:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 12:
      var value = new Protos_common_pb.OrderItemResponse;
      reader.readMessage(value,Protos_common_pb.OrderItemResponse.deserializeBinaryFromReader);
      msg.setOrderitem(value);
      break;
    case 13:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setFromaccount(value);
      break;
    case 14:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setToaccount(value);
      break;
    case 15:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setQuantityfilled(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRemainingfilled(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRemainingquantityfilled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrderReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrderReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrderReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFilled();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getReference();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOrdertype();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTradeaction();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getOrderitem();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Protos_common_pb.OrderItemResponse.serializeBinaryToWriter
    );
  }
  f = message.getFromaccount();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getToaccount();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuantityfilled();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRemainingfilled();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRemainingquantityfilled();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_endpoint.GetOrderReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value status = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.GetOrderReplay.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue total = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderReplay.prototype.getTotal = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue filled = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderReplay.prototype.getFilled = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setFilled = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearFilled = function() {
  return this.setFilled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasFilled = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.CurrencyResponse currency = 5;
 * @return {?proto.common.CurrencyResponse}
 */
proto.order_endpoint.GetOrderReplay.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 5));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue reference = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderReplay.prototype.getReference = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setReference = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearReference = function() {
  return this.setReference(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasReference = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value orderType = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.GetOrderReplay.prototype.getOrdertype = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setOrdertype = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearOrdertype = function() {
  return this.setOrdertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasOrdertype = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value tradeAction = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.GetOrderReplay.prototype.getTradeaction = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setTradeaction = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearTradeaction = function() {
  return this.setTradeaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasTradeaction = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.GetOrderReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.ProfileResponse createdBy = 10;
 * @return {?proto.common.ProfileResponse}
 */
proto.order_endpoint.GetOrderReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 10));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.ProfileResponse client = 11;
 * @return {?proto.common.ProfileResponse}
 */
proto.order_endpoint.GetOrderReplay.prototype.getClient = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 11));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasClient = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.OrderItemResponse orderItem = 12;
 * @return {?proto.common.OrderItemResponse}
 */
proto.order_endpoint.GetOrderReplay.prototype.getOrderitem = function() {
  return /** @type{?proto.common.OrderItemResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.OrderItemResponse, 12));
};


/**
 * @param {?proto.common.OrderItemResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setOrderitem = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearOrderitem = function() {
  return this.setOrderitem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasOrderitem = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional common.AccountResponse fromAccount = 13;
 * @return {?proto.common.AccountResponse}
 */
proto.order_endpoint.GetOrderReplay.prototype.getFromaccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 13));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setFromaccount = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearFromaccount = function() {
  return this.setFromaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasFromaccount = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional common.AccountResponse toAccount = 14;
 * @return {?proto.common.AccountResponse}
 */
proto.order_endpoint.GetOrderReplay.prototype.getToaccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 14));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setToaccount = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearToaccount = function() {
  return this.setToaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasToaccount = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.StringValue quantityFilled = 15;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderReplay.prototype.getQuantityfilled = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 15));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setQuantityfilled = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearQuantityfilled = function() {
  return this.setQuantityfilled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasQuantityfilled = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.protobuf.StringValue remainingFilled = 16;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderReplay.prototype.getRemainingfilled = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 16));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setRemainingfilled = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearRemainingfilled = function() {
  return this.setRemainingfilled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasRemainingfilled = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.StringValue remainingQuantityFilled = 17;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderReplay.prototype.getRemainingquantityfilled = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 17));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
*/
proto.order_endpoint.GetOrderReplay.prototype.setRemainingquantityfilled = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderReplay} returns this
 */
proto.order_endpoint.GetOrderReplay.prototype.clearRemainingquantityfilled = function() {
  return this.setRemainingquantityfilled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderReplay.prototype.hasRemainingquantityfilled = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    total: (f = msg.getTotal()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    filled: (f = msg.getFilled()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    ordertype: (f = msg.getOrdertype()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    tradeaction: (f = msg.getTradeaction()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    client: (f = msg.getClient()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    orderitem: (f = msg.getOrderitem()) && Protos_common_pb.OrderItemResponse.toObject(includeInstance, f),
    quantityfilled: (f = msg.getQuantityfilled()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    remainingfilled: (f = msg.getRemainingfilled()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    remainingquantityfilled: (f = msg.getRemainingquantityfilled()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.Order}
 */
proto.order_endpoint.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.Order;
  return proto.order_endpoint.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.Order}
 */
proto.order_endpoint.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTotal(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFilled(value);
      break;
    case 5:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrdertype(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTradeaction(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 9:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 10:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 11:
      var value = new Protos_common_pb.OrderItemResponse;
      reader.readMessage(value,Protos_common_pb.OrderItemResponse.deserializeBinaryFromReader);
      msg.setOrderitem(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setQuantityfilled(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRemainingfilled(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRemainingquantityfilled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFilled();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getOrdertype();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTradeaction();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getOrderitem();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Protos_common_pb.OrderItemResponse.serializeBinaryToWriter
    );
  }
  f = message.getQuantityfilled();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRemainingfilled();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRemainingquantityfilled();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_endpoint.Order.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Int32Value status = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.Order.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue total = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.Order.prototype.getTotal = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setTotal = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearTotal = function() {
  return this.setTotal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasTotal = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue filled = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.Order.prototype.getFilled = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setFilled = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearFilled = function() {
  return this.setFilled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasFilled = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.CurrencyResponse currency = 5;
 * @return {?proto.common.CurrencyResponse}
 */
proto.order_endpoint.Order.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 5));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value orderType = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.Order.prototype.getOrdertype = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setOrdertype = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearOrdertype = function() {
  return this.setOrdertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasOrdertype = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Int32Value tradeAction = 7;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.order_endpoint.Order.prototype.getTradeaction = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 7));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setTradeaction = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearTradeaction = function() {
  return this.setTradeaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasTradeaction = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.Order.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.ProfileResponse createdBy = 9;
 * @return {?proto.common.ProfileResponse}
 */
proto.order_endpoint.Order.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 9));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.ProfileResponse client = 10;
 * @return {?proto.common.ProfileResponse}
 */
proto.order_endpoint.Order.prototype.getClient = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 10));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasClient = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.OrderItemResponse orderItem = 11;
 * @return {?proto.common.OrderItemResponse}
 */
proto.order_endpoint.Order.prototype.getOrderitem = function() {
  return /** @type{?proto.common.OrderItemResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.OrderItemResponse, 11));
};


/**
 * @param {?proto.common.OrderItemResponse|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setOrderitem = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearOrderitem = function() {
  return this.setOrderitem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasOrderitem = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue quantityFilled = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.Order.prototype.getQuantityfilled = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setQuantityfilled = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearQuantityfilled = function() {
  return this.setQuantityfilled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasQuantityfilled = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue remainingFilled = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.Order.prototype.getRemainingfilled = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setRemainingfilled = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearRemainingfilled = function() {
  return this.setRemainingfilled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasRemainingfilled = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue remainingQuantityFilled = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.Order.prototype.getRemainingquantityfilled = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.Order} returns this
*/
proto.order_endpoint.Order.prototype.setRemainingquantityfilled = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.Order} returns this
 */
proto.order_endpoint.Order.prototype.clearRemainingquantityfilled = function() {
  return this.setRemainingquantityfilled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.Order.prototype.hasRemainingquantityfilled = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.ExportOrderReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.ExportOrderReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.ExportOrderReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExportOrderReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: msg.getOrder_asB64(),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.ExportOrderReplay}
 */
proto.order_endpoint.ExportOrderReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.ExportOrderReplay;
  return proto.order_endpoint.ExportOrderReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.ExportOrderReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.ExportOrderReplay}
 */
proto.order_endpoint.ExportOrderReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setOrder(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.ExportOrderReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.ExportOrderReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.ExportOrderReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExportOrderReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes order = 1;
 * @return {!(string|Uint8Array)}
 */
proto.order_endpoint.ExportOrderReplay.prototype.getOrder = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes order = 1;
 * This is a type-conversion wrapper around `getOrder()`
 * @return {string}
 */
proto.order_endpoint.ExportOrderReplay.prototype.getOrder_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getOrder()));
};


/**
 * optional bytes order = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getOrder()`
 * @return {!Uint8Array}
 */
proto.order_endpoint.ExportOrderReplay.prototype.getOrder_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getOrder()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.order_endpoint.ExportOrderReplay} returns this
 */
proto.order_endpoint.ExportOrderReplay.prototype.setOrder = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.ExportOrderReplay.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.ExportOrderReplay} returns this
*/
proto.order_endpoint.ExportOrderReplay.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.ExportOrderReplay} returns this
 */
proto.order_endpoint.ExportOrderReplay.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.ExportOrderReplay.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_endpoint.GetOrdersReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrdersReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrdersReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrdersReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrdersReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto.order_endpoint.Order.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrdersReplay}
 */
proto.order_endpoint.GetOrdersReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrdersReplay;
  return proto.order_endpoint.GetOrdersReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrdersReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrdersReplay}
 */
proto.order_endpoint.GetOrdersReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.Order;
      reader.readMessage(value,proto.order_endpoint.Order.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrdersReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrdersReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrdersReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrdersReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.order_endpoint.Order.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Order orders = 1;
 * @return {!Array<!proto.order_endpoint.Order>}
 */
proto.order_endpoint.GetOrdersReplay.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.order_endpoint.Order>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order_endpoint.Order, 1));
};


/**
 * @param {!Array<!proto.order_endpoint.Order>} value
 * @return {!proto.order_endpoint.GetOrdersReplay} returns this
*/
proto.order_endpoint.GetOrdersReplay.prototype.setOrdersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.order_endpoint.Order=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order_endpoint.Order}
 */
proto.order_endpoint.GetOrdersReplay.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.order_endpoint.Order, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_endpoint.GetOrdersReplay} returns this
 */
proto.order_endpoint.GetOrdersReplay.prototype.clearOrdersList = function() {
  return this.setOrdersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrderTransactionReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrderTransactionReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: (f = msg.getOrder()) && Protos_common_pb.OrderResponse.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    fromaccount: (f = msg.getFromaccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    toaccount: (f = msg.getToaccount()) && Protos_common_pb.AccountResponse.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f),
    referenceorder: (f = msg.getReferenceorder()) && Protos_common_pb.OrderResponse.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    client: (f = msg.getClient()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay}
 */
proto.order_endpoint.GetOrderTransactionReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrderTransactionReplay;
  return proto.order_endpoint.GetOrderTransactionReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrderTransactionReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay}
 */
proto.order_endpoint.GetOrderTransactionReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.OrderResponse;
      reader.readMessage(value,Protos_common_pb.OrderResponse.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 4:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setFromaccount(value);
      break;
    case 5:
      var value = new Protos_common_pb.AccountResponse;
      reader.readMessage(value,Protos_common_pb.AccountResponse.deserializeBinaryFromReader);
      msg.setToaccount(value);
      break;
    case 6:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    case 7:
      var value = new Protos_common_pb.OrderResponse;
      reader.readMessage(value,Protos_common_pb.OrderResponse.deserializeBinaryFromReader);
      msg.setReferenceorder(value);
      break;
    case 8:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 10:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 11:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrderTransactionReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrderTransactionReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.OrderResponse.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFromaccount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getToaccount();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.AccountResponse.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getReferenceorder();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_common_pb.OrderResponse.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.OrderResponse order = 2;
 * @return {?proto.common.OrderResponse}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getOrder = function() {
  return /** @type{?proto.common.OrderResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.OrderResponse, 2));
};


/**
 * @param {?proto.common.OrderResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue amount = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.AccountResponse fromAccount = 4;
 * @return {?proto.common.AccountResponse}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getFromaccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 4));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setFromaccount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearFromaccount = function() {
  return this.setFromaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasFromaccount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.AccountResponse toAccount = 5;
 * @return {?proto.common.AccountResponse}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getToaccount = function() {
  return /** @type{?proto.common.AccountResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.AccountResponse, 5));
};


/**
 * @param {?proto.common.AccountResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setToaccount = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearToaccount = function() {
  return this.setToaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasToaccount = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.TransactionResponse referenceTransaction = 6;
 * @return {?proto.common.TransactionResponse}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getReferencetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 6));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.OrderResponse referenceOrder = 7;
 * @return {?proto.common.OrderResponse}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getReferenceorder = function() {
  return /** @type{?proto.common.OrderResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.OrderResponse, 7));
};


/**
 * @param {?proto.common.OrderResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setReferenceorder = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearReferenceorder = function() {
  return this.setReferenceorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasReferenceorder = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.CurrencyResponse currency = 8;
 * @return {?proto.common.CurrencyResponse}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 8));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.ProfileResponse createdBy = 10;
 * @return {?proto.common.ProfileResponse}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 10));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.ProfileResponse client = 11;
 * @return {?proto.common.ProfileResponse}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.getClient = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 11));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionReplay.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionReplay.prototype.hasClient = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.OrderTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.OrderTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.OrderTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.OrderTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    order: (f = msg.getOrder()) && Protos_common_pb.OrderResponse.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    referencetransaction: (f = msg.getReferencetransaction()) && Protos_common_pb.TransactionResponse.toObject(includeInstance, f),
    currency: (f = msg.getCurrency()) && Protos_common_pb.CurrencyResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    client: (f = msg.getClient()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    referenceorder: (f = msg.getReferenceorder()) && Protos_common_pb.OrderResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.OrderTransaction}
 */
proto.order_endpoint.OrderTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.OrderTransaction;
  return proto.order_endpoint.OrderTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.OrderTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.OrderTransaction}
 */
proto.order_endpoint.OrderTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new Protos_common_pb.OrderResponse;
      reader.readMessage(value,Protos_common_pb.OrderResponse.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 4:
      var value = new Protos_common_pb.TransactionResponse;
      reader.readMessage(value,Protos_common_pb.TransactionResponse.deserializeBinaryFromReader);
      msg.setReferencetransaction(value);
      break;
    case 5:
      var value = new Protos_common_pb.CurrencyResponse;
      reader.readMessage(value,Protos_common_pb.CurrencyResponse.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 8:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setClient(value);
      break;
    case 9:
      var value = new Protos_common_pb.OrderResponse;
      reader.readMessage(value,Protos_common_pb.OrderResponse.deserializeBinaryFromReader);
      msg.setReferenceorder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.OrderTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.OrderTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.OrderTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.OrderTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.OrderResponse.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getReferencetransaction();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Protos_common_pb.TransactionResponse.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Protos_common_pb.CurrencyResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getClient();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getReferenceorder();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Protos_common_pb.OrderResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.order_endpoint.OrderTransaction.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.OrderTransaction} returns this
 */
proto.order_endpoint.OrderTransaction.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.OrderResponse order = 2;
 * @return {?proto.common.OrderResponse}
 */
proto.order_endpoint.OrderTransaction.prototype.getOrder = function() {
  return /** @type{?proto.common.OrderResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.OrderResponse, 2));
};


/**
 * @param {?proto.common.OrderResponse|undefined} value
 * @return {!proto.order_endpoint.OrderTransaction} returns this
*/
proto.order_endpoint.OrderTransaction.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.OrderTransaction} returns this
 */
proto.order_endpoint.OrderTransaction.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.OrderTransaction.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue amount = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.order_endpoint.OrderTransaction.prototype.getAmount = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.order_endpoint.OrderTransaction} returns this
*/
proto.order_endpoint.OrderTransaction.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.OrderTransaction} returns this
 */
proto.order_endpoint.OrderTransaction.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.OrderTransaction.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.TransactionResponse referenceTransaction = 4;
 * @return {?proto.common.TransactionResponse}
 */
proto.order_endpoint.OrderTransaction.prototype.getReferencetransaction = function() {
  return /** @type{?proto.common.TransactionResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.TransactionResponse, 4));
};


/**
 * @param {?proto.common.TransactionResponse|undefined} value
 * @return {!proto.order_endpoint.OrderTransaction} returns this
*/
proto.order_endpoint.OrderTransaction.prototype.setReferencetransaction = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.OrderTransaction} returns this
 */
proto.order_endpoint.OrderTransaction.prototype.clearReferencetransaction = function() {
  return this.setReferencetransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.OrderTransaction.prototype.hasReferencetransaction = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.CurrencyResponse currency = 5;
 * @return {?proto.common.CurrencyResponse}
 */
proto.order_endpoint.OrderTransaction.prototype.getCurrency = function() {
  return /** @type{?proto.common.CurrencyResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.CurrencyResponse, 5));
};


/**
 * @param {?proto.common.CurrencyResponse|undefined} value
 * @return {!proto.order_endpoint.OrderTransaction} returns this
*/
proto.order_endpoint.OrderTransaction.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.OrderTransaction} returns this
 */
proto.order_endpoint.OrderTransaction.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.OrderTransaction.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.OrderTransaction.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.OrderTransaction} returns this
*/
proto.order_endpoint.OrderTransaction.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.OrderTransaction} returns this
 */
proto.order_endpoint.OrderTransaction.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.OrderTransaction.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.ProfileResponse createdBy = 7;
 * @return {?proto.common.ProfileResponse}
 */
proto.order_endpoint.OrderTransaction.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 7));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.order_endpoint.OrderTransaction} returns this
*/
proto.order_endpoint.OrderTransaction.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.OrderTransaction} returns this
 */
proto.order_endpoint.OrderTransaction.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.OrderTransaction.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.ProfileResponse client = 8;
 * @return {?proto.common.ProfileResponse}
 */
proto.order_endpoint.OrderTransaction.prototype.getClient = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 8));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.order_endpoint.OrderTransaction} returns this
*/
proto.order_endpoint.OrderTransaction.prototype.setClient = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.OrderTransaction} returns this
 */
proto.order_endpoint.OrderTransaction.prototype.clearClient = function() {
  return this.setClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.OrderTransaction.prototype.hasClient = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.OrderResponse referenceOrder = 9;
 * @return {?proto.common.OrderResponse}
 */
proto.order_endpoint.OrderTransaction.prototype.getReferenceorder = function() {
  return /** @type{?proto.common.OrderResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.OrderResponse, 9));
};


/**
 * @param {?proto.common.OrderResponse|undefined} value
 * @return {!proto.order_endpoint.OrderTransaction} returns this
*/
proto.order_endpoint.OrderTransaction.prototype.setReferenceorder = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.OrderTransaction} returns this
 */
proto.order_endpoint.OrderTransaction.prototype.clearReferenceorder = function() {
  return this.setReferenceorder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.OrderTransaction.prototype.hasReferenceorder = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.PlaceOrderReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.PlaceOrderReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.PlaceOrderReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f),
    order: jspb.Message.getFieldWithDefault(msg, 2, ""),
    filled: jspb.Message.getFieldWithDefault(msg, 3, ""),
    quantityfilled: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    remainingfilled: jspb.Message.getFieldWithDefault(msg, 7, ""),
    remainingquantityfilled: jspb.Message.getFieldWithDefault(msg, 8, ""),
    matchorderfilled: jspb.Message.getFieldWithDefault(msg, 9, ""),
    matchorderquantityfilled: jspb.Message.getFieldWithDefault(msg, 10, ""),
    matchorderremainingfilled: jspb.Message.getFieldWithDefault(msg, 11, ""),
    matchorderremainingquantityfilled: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.PlaceOrderReplay}
 */
proto.order_endpoint.PlaceOrderReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.PlaceOrderReplay;
  return proto.order_endpoint.PlaceOrderReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.PlaceOrderReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.PlaceOrderReplay}
 */
proto.order_endpoint.PlaceOrderReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrder(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilled(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuantityfilled(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemainingfilled(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRemainingquantityfilled(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchorderfilled(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchorderquantityfilled(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchorderremainingfilled(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMatchorderremainingquantityfilled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.PlaceOrderReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.PlaceOrderReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.PlaceOrderReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFilled();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQuantityfilled();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRemainingfilled();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRemainingquantityfilled();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMatchorderfilled();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMatchorderquantityfilled();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMatchorderremainingfilled();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMatchorderremainingquantityfilled();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional common.MessageResponse message = 1;
 * @return {?proto.common.MessageResponse}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 1));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
*/
proto.order_endpoint.PlaceOrderReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string order = 2;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getOrder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.setOrder = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string filled = 3;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getFilled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.setFilled = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string quantityFilled = 4;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getQuantityfilled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.setQuantityfilled = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp createdDate = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
*/
proto.order_endpoint.PlaceOrderReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string remainingFilled = 7;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getRemainingfilled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.setRemainingfilled = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string remainingQuantityFilled = 8;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getRemainingquantityfilled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.setRemainingquantityfilled = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string matchOrderFilled = 9;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getMatchorderfilled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.setMatchorderfilled = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string matchOrderQuantityFilled = 10;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getMatchorderquantityfilled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.setMatchorderquantityfilled = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string matchOrderRemainingFilled = 11;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getMatchorderremainingfilled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.setMatchorderremainingfilled = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string matchOrderRemainingQuantityFilled = 12;
 * @return {string}
 */
proto.order_endpoint.PlaceOrderReplay.prototype.getMatchorderremainingquantityfilled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.order_endpoint.PlaceOrderReplay} returns this
 */
proto.order_endpoint.PlaceOrderReplay.prototype.setMatchorderremainingquantityfilled = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.order_endpoint.GetOrderTransactionsReplay.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrderTransactionsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrderTransactionsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrderTransactionsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordertransactionsList: jspb.Message.toObjectList(msg.getOrdertransactionsList(),
    proto.order_endpoint.OrderTransaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrderTransactionsReplay}
 */
proto.order_endpoint.GetOrderTransactionsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrderTransactionsReplay;
  return proto.order_endpoint.GetOrderTransactionsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrderTransactionsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrderTransactionsReplay}
 */
proto.order_endpoint.GetOrderTransactionsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.OrderTransaction;
      reader.readMessage(value,proto.order_endpoint.OrderTransaction.deserializeBinaryFromReader);
      msg.addOrdertransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrderTransactionsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrderTransactionsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrderTransactionsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdertransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.order_endpoint.OrderTransaction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrderTransaction orderTransactions = 1;
 * @return {!Array<!proto.order_endpoint.OrderTransaction>}
 */
proto.order_endpoint.GetOrderTransactionsReplay.prototype.getOrdertransactionsList = function() {
  return /** @type{!Array<!proto.order_endpoint.OrderTransaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.order_endpoint.OrderTransaction, 1));
};


/**
 * @param {!Array<!proto.order_endpoint.OrderTransaction>} value
 * @return {!proto.order_endpoint.GetOrderTransactionsReplay} returns this
*/
proto.order_endpoint.GetOrderTransactionsReplay.prototype.setOrdertransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.order_endpoint.OrderTransaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.order_endpoint.OrderTransaction}
 */
proto.order_endpoint.GetOrderTransactionsReplay.prototype.addOrdertransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.order_endpoint.OrderTransaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.order_endpoint.GetOrderTransactionsReplay} returns this
 */
proto.order_endpoint.GetOrderTransactionsReplay.prototype.clearOrdertransactionsList = function() {
  return this.setOrdertransactionsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.order_endpoint.DirectExchangeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.order_endpoint.DirectExchangeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.order_endpoint.DirectExchangeResponse.ResultCase}
 */
proto.order_endpoint.DirectExchangeResponse.prototype.getResultCase = function() {
  return /** @type {proto.order_endpoint.DirectExchangeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.order_endpoint.DirectExchangeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.DirectExchangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.DirectExchangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.DirectExchangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.DirectExchangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.order_endpoint.DirectExchangeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.DirectExchangeResponse}
 */
proto.order_endpoint.DirectExchangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.DirectExchangeResponse;
  return proto.order_endpoint.DirectExchangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.DirectExchangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.DirectExchangeResponse}
 */
proto.order_endpoint.DirectExchangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.DirectExchangeReplay;
      reader.readMessage(value,proto.order_endpoint.DirectExchangeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.DirectExchangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.DirectExchangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.DirectExchangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.DirectExchangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order_endpoint.DirectExchangeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional DirectExchangeReplay success = 1;
 * @return {?proto.order_endpoint.DirectExchangeReplay}
 */
proto.order_endpoint.DirectExchangeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.order_endpoint.DirectExchangeReplay} */ (
    jspb.Message.getWrapperField(this, proto.order_endpoint.DirectExchangeReplay, 1));
};


/**
 * @param {?proto.order_endpoint.DirectExchangeReplay|undefined} value
 * @return {!proto.order_endpoint.DirectExchangeResponse} returns this
*/
proto.order_endpoint.DirectExchangeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.order_endpoint.DirectExchangeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.DirectExchangeResponse} returns this
 */
proto.order_endpoint.DirectExchangeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.DirectExchangeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.order_endpoint.ExternalExchangeResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.order_endpoint.ExternalExchangeResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.order_endpoint.ExternalExchangeResponse.ResultCase}
 */
proto.order_endpoint.ExternalExchangeResponse.prototype.getResultCase = function() {
  return /** @type {proto.order_endpoint.ExternalExchangeResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.order_endpoint.ExternalExchangeResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.ExternalExchangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.ExternalExchangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.ExternalExchangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExternalExchangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.order_endpoint.ExternalExchangeReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.ExternalExchangeResponse}
 */
proto.order_endpoint.ExternalExchangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.ExternalExchangeResponse;
  return proto.order_endpoint.ExternalExchangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.ExternalExchangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.ExternalExchangeResponse}
 */
proto.order_endpoint.ExternalExchangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.ExternalExchangeReplay;
      reader.readMessage(value,proto.order_endpoint.ExternalExchangeReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.ExternalExchangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.ExternalExchangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.ExternalExchangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExternalExchangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order_endpoint.ExternalExchangeReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExternalExchangeReplay success = 1;
 * @return {?proto.order_endpoint.ExternalExchangeReplay}
 */
proto.order_endpoint.ExternalExchangeResponse.prototype.getSuccess = function() {
  return /** @type{?proto.order_endpoint.ExternalExchangeReplay} */ (
    jspb.Message.getWrapperField(this, proto.order_endpoint.ExternalExchangeReplay, 1));
};


/**
 * @param {?proto.order_endpoint.ExternalExchangeReplay|undefined} value
 * @return {!proto.order_endpoint.ExternalExchangeResponse} returns this
*/
proto.order_endpoint.ExternalExchangeResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.order_endpoint.ExternalExchangeResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.ExternalExchangeResponse} returns this
 */
proto.order_endpoint.ExternalExchangeResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.ExternalExchangeResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.order_endpoint.CancelResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.order_endpoint.CancelResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.order_endpoint.CancelResponse.ResultCase}
 */
proto.order_endpoint.CancelResponse.prototype.getResultCase = function() {
  return /** @type {proto.order_endpoint.CancelResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.order_endpoint.CancelResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.CancelResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.CancelResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.CancelResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.CancelResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.order_endpoint.CancelReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.CancelResponse}
 */
proto.order_endpoint.CancelResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.CancelResponse;
  return proto.order_endpoint.CancelResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.CancelResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.CancelResponse}
 */
proto.order_endpoint.CancelResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.CancelReplay;
      reader.readMessage(value,proto.order_endpoint.CancelReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.CancelResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.CancelResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.CancelResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.CancelResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order_endpoint.CancelReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional CancelReplay success = 1;
 * @return {?proto.order_endpoint.CancelReplay}
 */
proto.order_endpoint.CancelResponse.prototype.getSuccess = function() {
  return /** @type{?proto.order_endpoint.CancelReplay} */ (
    jspb.Message.getWrapperField(this, proto.order_endpoint.CancelReplay, 1));
};


/**
 * @param {?proto.order_endpoint.CancelReplay|undefined} value
 * @return {!proto.order_endpoint.CancelResponse} returns this
*/
proto.order_endpoint.CancelResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.order_endpoint.CancelResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.CancelResponse} returns this
 */
proto.order_endpoint.CancelResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.CancelResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.order_endpoint.GetOrdersResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.order_endpoint.GetOrdersResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.order_endpoint.GetOrdersResponse.ResultCase}
 */
proto.order_endpoint.GetOrdersResponse.prototype.getResultCase = function() {
  return /** @type {proto.order_endpoint.GetOrdersResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.order_endpoint.GetOrdersResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.order_endpoint.GetOrdersReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrdersResponse}
 */
proto.order_endpoint.GetOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrdersResponse;
  return proto.order_endpoint.GetOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrdersResponse}
 */
proto.order_endpoint.GetOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.GetOrdersReplay;
      reader.readMessage(value,proto.order_endpoint.GetOrdersReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order_endpoint.GetOrdersReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetOrdersReplay success = 1;
 * @return {?proto.order_endpoint.GetOrdersReplay}
 */
proto.order_endpoint.GetOrdersResponse.prototype.getSuccess = function() {
  return /** @type{?proto.order_endpoint.GetOrdersReplay} */ (
    jspb.Message.getWrapperField(this, proto.order_endpoint.GetOrdersReplay, 1));
};


/**
 * @param {?proto.order_endpoint.GetOrdersReplay|undefined} value
 * @return {!proto.order_endpoint.GetOrdersResponse} returns this
*/
proto.order_endpoint.GetOrdersResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.order_endpoint.GetOrdersResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrdersResponse} returns this
 */
proto.order_endpoint.GetOrdersResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrdersResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.order_endpoint.GetOrderTransactionsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.order_endpoint.GetOrderTransactionsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.order_endpoint.GetOrderTransactionsResponse.ResultCase}
 */
proto.order_endpoint.GetOrderTransactionsResponse.prototype.getResultCase = function() {
  return /** @type {proto.order_endpoint.GetOrderTransactionsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.order_endpoint.GetOrderTransactionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrderTransactionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrderTransactionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrderTransactionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.order_endpoint.GetOrderTransactionsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrderTransactionsResponse}
 */
proto.order_endpoint.GetOrderTransactionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrderTransactionsResponse;
  return proto.order_endpoint.GetOrderTransactionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrderTransactionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrderTransactionsResponse}
 */
proto.order_endpoint.GetOrderTransactionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.GetOrderTransactionsReplay;
      reader.readMessage(value,proto.order_endpoint.GetOrderTransactionsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrderTransactionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrderTransactionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrderTransactionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order_endpoint.GetOrderTransactionsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetOrderTransactionsReplay success = 1;
 * @return {?proto.order_endpoint.GetOrderTransactionsReplay}
 */
proto.order_endpoint.GetOrderTransactionsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.order_endpoint.GetOrderTransactionsReplay} */ (
    jspb.Message.getWrapperField(this, proto.order_endpoint.GetOrderTransactionsReplay, 1));
};


/**
 * @param {?proto.order_endpoint.GetOrderTransactionsReplay|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionsResponse} returns this
*/
proto.order_endpoint.GetOrderTransactionsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.order_endpoint.GetOrderTransactionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionsResponse} returns this
 */
proto.order_endpoint.GetOrderTransactionsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.order_endpoint.GetOrderResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.order_endpoint.GetOrderResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.order_endpoint.GetOrderResponse.ResultCase}
 */
proto.order_endpoint.GetOrderResponse.prototype.getResultCase = function() {
  return /** @type {proto.order_endpoint.GetOrderResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.order_endpoint.GetOrderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.order_endpoint.GetOrderReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrderResponse}
 */
proto.order_endpoint.GetOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrderResponse;
  return proto.order_endpoint.GetOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrderResponse}
 */
proto.order_endpoint.GetOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.GetOrderReplay;
      reader.readMessage(value,proto.order_endpoint.GetOrderReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order_endpoint.GetOrderReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetOrderReplay success = 1;
 * @return {?proto.order_endpoint.GetOrderReplay}
 */
proto.order_endpoint.GetOrderResponse.prototype.getSuccess = function() {
  return /** @type{?proto.order_endpoint.GetOrderReplay} */ (
    jspb.Message.getWrapperField(this, proto.order_endpoint.GetOrderReplay, 1));
};


/**
 * @param {?proto.order_endpoint.GetOrderReplay|undefined} value
 * @return {!proto.order_endpoint.GetOrderResponse} returns this
*/
proto.order_endpoint.GetOrderResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.order_endpoint.GetOrderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderResponse} returns this
 */
proto.order_endpoint.GetOrderResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.order_endpoint.GetOrderTransactionResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.order_endpoint.GetOrderTransactionResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.order_endpoint.GetOrderTransactionResponse.ResultCase}
 */
proto.order_endpoint.GetOrderTransactionResponse.prototype.getResultCase = function() {
  return /** @type {proto.order_endpoint.GetOrderTransactionResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.order_endpoint.GetOrderTransactionResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.GetOrderTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.GetOrderTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.GetOrderTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.order_endpoint.GetOrderTransactionReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.GetOrderTransactionResponse}
 */
proto.order_endpoint.GetOrderTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.GetOrderTransactionResponse;
  return proto.order_endpoint.GetOrderTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.GetOrderTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.GetOrderTransactionResponse}
 */
proto.order_endpoint.GetOrderTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.GetOrderTransactionReplay;
      reader.readMessage(value,proto.order_endpoint.GetOrderTransactionReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.GetOrderTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.GetOrderTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.GetOrderTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.GetOrderTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order_endpoint.GetOrderTransactionReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetOrderTransactionReplay success = 1;
 * @return {?proto.order_endpoint.GetOrderTransactionReplay}
 */
proto.order_endpoint.GetOrderTransactionResponse.prototype.getSuccess = function() {
  return /** @type{?proto.order_endpoint.GetOrderTransactionReplay} */ (
    jspb.Message.getWrapperField(this, proto.order_endpoint.GetOrderTransactionReplay, 1));
};


/**
 * @param {?proto.order_endpoint.GetOrderTransactionReplay|undefined} value
 * @return {!proto.order_endpoint.GetOrderTransactionResponse} returns this
*/
proto.order_endpoint.GetOrderTransactionResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.order_endpoint.GetOrderTransactionResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.GetOrderTransactionResponse} returns this
 */
proto.order_endpoint.GetOrderTransactionResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.GetOrderTransactionResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.order_endpoint.ExportOrderResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.order_endpoint.ExportOrderResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.order_endpoint.ExportOrderResponse.ResultCase}
 */
proto.order_endpoint.ExportOrderResponse.prototype.getResultCase = function() {
  return /** @type {proto.order_endpoint.ExportOrderResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.order_endpoint.ExportOrderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.ExportOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.ExportOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.ExportOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExportOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.order_endpoint.ExportOrderReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.ExportOrderResponse}
 */
proto.order_endpoint.ExportOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.ExportOrderResponse;
  return proto.order_endpoint.ExportOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.ExportOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.ExportOrderResponse}
 */
proto.order_endpoint.ExportOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.ExportOrderReplay;
      reader.readMessage(value,proto.order_endpoint.ExportOrderReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.ExportOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.ExportOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.ExportOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.ExportOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order_endpoint.ExportOrderReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional ExportOrderReplay success = 1;
 * @return {?proto.order_endpoint.ExportOrderReplay}
 */
proto.order_endpoint.ExportOrderResponse.prototype.getSuccess = function() {
  return /** @type{?proto.order_endpoint.ExportOrderReplay} */ (
    jspb.Message.getWrapperField(this, proto.order_endpoint.ExportOrderReplay, 1));
};


/**
 * @param {?proto.order_endpoint.ExportOrderReplay|undefined} value
 * @return {!proto.order_endpoint.ExportOrderResponse} returns this
*/
proto.order_endpoint.ExportOrderResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.order_endpoint.ExportOrderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.ExportOrderResponse} returns this
 */
proto.order_endpoint.ExportOrderResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.ExportOrderResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.order_endpoint.PlaceOrderResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.order_endpoint.PlaceOrderResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.order_endpoint.PlaceOrderResponse.ResultCase}
 */
proto.order_endpoint.PlaceOrderResponse.prototype.getResultCase = function() {
  return /** @type {proto.order_endpoint.PlaceOrderResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.order_endpoint.PlaceOrderResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.order_endpoint.PlaceOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.order_endpoint.PlaceOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.order_endpoint.PlaceOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.PlaceOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.order_endpoint.PlaceOrderReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.order_endpoint.PlaceOrderResponse}
 */
proto.order_endpoint.PlaceOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.order_endpoint.PlaceOrderResponse;
  return proto.order_endpoint.PlaceOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.order_endpoint.PlaceOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.order_endpoint.PlaceOrderResponse}
 */
proto.order_endpoint.PlaceOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.order_endpoint.PlaceOrderReplay;
      reader.readMessage(value,proto.order_endpoint.PlaceOrderReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.order_endpoint.PlaceOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.order_endpoint.PlaceOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.order_endpoint.PlaceOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.order_endpoint.PlaceOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.order_endpoint.PlaceOrderReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional PlaceOrderReplay success = 1;
 * @return {?proto.order_endpoint.PlaceOrderReplay}
 */
proto.order_endpoint.PlaceOrderResponse.prototype.getSuccess = function() {
  return /** @type{?proto.order_endpoint.PlaceOrderReplay} */ (
    jspb.Message.getWrapperField(this, proto.order_endpoint.PlaceOrderReplay, 1));
};


/**
 * @param {?proto.order_endpoint.PlaceOrderReplay|undefined} value
 * @return {!proto.order_endpoint.PlaceOrderResponse} returns this
*/
proto.order_endpoint.PlaceOrderResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.order_endpoint.PlaceOrderResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.order_endpoint.PlaceOrderResponse} returns this
 */
proto.order_endpoint.PlaceOrderResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.order_endpoint.PlaceOrderResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.order_endpoint);
