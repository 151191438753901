import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, TooltipHost, DialogFooter, CheckboxVisibility, ConstrainMode } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, getCategories, getMainPairsItems, resetCategories, resetMainPair, setIsFilteredCategoriesSet, setIsFilteredMainPairsSet, setSelecteMainPairItems, setSelectedCategoryItems, setSearchText, setIsFilteredSet, setSyncPricesFilterOption, addItem, updateItem, getItems, deleteItem, getBaseCurrencies, getQuoteCurrencies, resetBaseCurrencies, resetQuoteCurrencies, setIsFilteredBaseCurrenciesSet, setSelectedQuoteCurrencyItems, setSelectedBaseCurrencyItems, setIsFilteredQuoteCurrenciesSet, setStatusFilterOption, updateStatus } from "./CurrencyPairsPageSlice"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../app/Themes"
import { SearchField } from "../../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { DeleteCurrencyPairRequest, GetCurrencyPairsRequest, UpdateCurrencyPairActivityRequest } from "../../../../repository/Trading/currency_pair_pb"
import { CurrencyPairsForm } from "../../../forms/Trading/CurrencyPairs/CurrencyPairsForm"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"
import { List } from "../../../common/List/List"
import { CurrenciesPage } from "../../Accountant/Currencies/CurrenciesPage"
import { CurrencyPairsPriceForm } from "../../../forms/Trading/CurrencyPairs/CurrencyPairsPriceForm"
import { GetCategoriesRequest } from "../../../../repository/Trading/category_pb"
import { CategoriesPage } from "../Categories/CategoriesPage"
import { CurrencyPairsPriceSettingsForm } from "../../../forms/Trading/CurrencyPairs/CurrencyPairsPriceSettingsForm"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetCurrencyPairsRequest;
let baseCurrenciesReq: GetCurrenciesRequest;
let quoteCurrenciesReq: GetCurrenciesRequest;
let categoriesReq: GetCategoriesRequest;
let mainPairsReq: GetCurrencyPairsRequest;

let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

const key = "trading/currencyPairs"
export const CurrencyPairsPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        filters: any[],
        statusFilterOption: { key: number, text: string },
        syncPricesFilterOption: { key: number, text: string },

        baseCurrencies: TableState,
        quoteCurrencies: TableState,
        categories: TableState,
        mainPairs: TableState


    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.currencyPairsPage.isChangeStateLoading,
            set: state.currencyPairsPage.set,
            message: state.currencyPairsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.currencyPairsPage.searchText,
            baseCurrencies: state.currencyPairsPage.baseCurrencies,
            quoteCurrencies: state.currencyPairsPage.quoteCurrencies,
            filters: state.currencyPairsPage.filters,
            statusFilterOption: state.currencyPairsPage.statusFilterOption,
            syncPricesFilterOption: state.currencyPairsPage.syncPricesFilterOption,
            categories: state.currencyPairsPage.categories,
            mainPairs: state.currencyPairsPage.mainPairs

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});

    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedBaseCurrencyItems([]))
        req.setBase(undefined)
        dispatch(setSelectedQuoteCurrencyItems([]))
        req.setQuote(undefined)
        dispatch(setStatusFilterOption({ key: 0, text: "" }))
        req.setIsactive(undefined)
        dispatch(setSelecteMainPairItems([]))
        req.setMaincurrencypair(undefined)
        dispatch(setSelectedCategoryItems([]))
        req.setCategory(undefined)
    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }
        if (state.categories.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.categories.selected.at(0)?.id));
            req.setCategory(wrapper)
            isFiltered = true;
        } else {
            req.setCategory(undefined)
        }

        if (state.mainPairs.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.mainPairs.selected.at(0)?.id));
            req.setMaincurrencypair(wrapper)
            isFiltered = true;
        } else {
            req.setMaincurrencypair(undefined)
        }

        if (state.baseCurrencies.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.baseCurrencies.selected.at(0)?.id));
            req.setBase(wrapper)
            isFiltered = true;
        } else {
            req.setBase(undefined)
        }

        if (state.quoteCurrencies.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.quoteCurrencies.selected.at(0)?.id));
            req.setQuote(wrapper)
            isFiltered = true;
        } else {
            req.setQuote(undefined)
        }

        if (state.statusFilterOption.key != 0) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.statusFilterOption.key == 1);
            req.setIsactive(wrapper)
            isFiltered = true;

        } else {
            req.setIsactive(undefined)
        }


        if (state.syncPricesFilterOption.key != 0) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.syncPricesFilterOption.key == 1);
            req.setSyncprices(wrapper)
            isFiltered = true;

        } else {
            req.setSyncprices(undefined)
        }
        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetCurrencyPairsRequest();
        baseCurrenciesReq = new GetCurrenciesRequest();
        quoteCurrenciesReq = new GetCurrenciesRequest();
        categoriesReq = new GetCategoriesRequest();
        mainPairsReq = new GetCurrencyPairsRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                baseCurrenciesReq.setOrganization(wrapper)
                quoteCurrenciesReq.setOrganization(wrapper)
                categoriesReq.setOrganization(wrapper)
                mainPairsReq.setOrganization(wrapper)



            }
        }
        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)

        const boolv = new BoolValue();
        boolv.setValue(true);
        
        categoriesReq.setOnlysub(boolv)
        baseCurrenciesReq.setNumofresults(state.baseCurrencies.numberOfResults)
        baseCurrenciesReq.setOrder(state.baseCurrencies.isDescending)
        //   baseCurrenciesReq.setIsavailable(boolv)

        quoteCurrenciesReq.setNumofresults(state.quoteCurrencies.numberOfResults)
        quoteCurrenciesReq.setOrder(state.quoteCurrencies.isDescending)

        categoriesReq.setNumofresults(state.categories.numberOfResults)
        categoriesReq.setOrder(state.categories.isDescending)

        mainPairsReq.setNumofresults(state.mainPairs.numberOfResults)
        mainPairsReq.setOrder(state.mainPairs.isDescending)


        //  quoteCurrenciesReq.setIsavailable(boolv)

        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [



        {
            key: 'id',
            name: t("pair"),
            minWidth: 50,
            maxWidth: 340,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/currency-pairs/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/currency-pairs/" + item?.id + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined} ><Persona
                            text={item?.name}
                            secondaryText={(item?.baseCurrencyCode == "" ? item?.baseCurrencyId : item?.baseCurrencyCode) + "-" + (item?.quoteCurrencyCode == "" ? item?.quoteCurrencyId : item?.quoteCurrencyCode)}
                            imageInitials={item?.baseCurrencySymbol + "/" + item?.quoteCurrencySymbol}
                            imageUrl={item?.iconSource}
                            tertiaryText={item?.categoryNameAr + " | " + item?.categoryNameEn}
                            size={PersonaSize.size40}

                        /></Link>;



            },
        },

        {
            key: 'categoryId',
            name: t("category"),
            minWidth: 20,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isMultiline: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/categories/" + item?.categoryId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/categories/" + item?.categoryId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.categoryNameAr == "" ? item?.categoryId : item?.categoryNameAr + " | " + item?.categoryNameEn}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'buyPrice',
            name: t("buyPrice"),
            minWidth: 20,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.buyPrice != undefined)
                    return <Text>{currencyFormatter(item?.buyPrice, { significantDigits: item?.quoteCurrencyDecimalPlaces ?? 9, symbol: item?.quoteCurrencySymbol })}</Text>

                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'sellPrice',
            name: t("sellPrice"),
            minWidth: 20,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                if (item?.sellPrice != undefined)
                    return <Text>{currencyFormatter(item?.sellPrice, { significantDigits: item?.quoteCurrencyDecimalPlaces ?? 9, symbol: item?.quoteCurrencySymbol })}</Text>


                //return <Link href="/" underline >{item.name}</Link>;
            },
        },

        {
            key: 'tradingPrice',
            name: t("tradingPrice"),
            minWidth: 20,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                if (item?.tradingPrice != undefined)
                    return <TooltipHost
                        content={item?.priceUpdate ? t("latestUpdateDate") + ": " + item?.priceUpdate : undefined}
                    >
                        <Text>{currencyFormatter(item?.tradingPrice, { significantDigits: item?.quoteCurrencyDecimalPlaces ?? 9, symbol: item?.quoteCurrencySymbol })}</Text>
                    </TooltipHost>;

                //return <Link href="/" underline >{item.name}</Link>;
            },
        },




        {
            key: 'isActive',
            name: t("status"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.isActive != undefined)
                    return <Text>{item?.isActive ? t("active") : t("inactive")}</Text>;
            },
        },

        {
            key: 'syncPrices',
            name: t("syncPrices"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.syncPrices != undefined)
                    return <Text>{item?.syncPrices ? t("active") : t("inactive")}</Text>;
            },
        },
        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 160,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



        {

            key: 'tradingScreen',
            name: t("tradingScreen"),
            minWidth: 20,
            maxWidth: 70,
            isRowHeader: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined) {
                    return <IconButton iconProps={{ iconName: "chart" }} title={t("tradingScreen")} onClick={() => {
                        navigate("/chart/?symbol=" + item?.id + ((org == -1) ? "" : "&org=" + org));
                    }} />
                }


            },
        }


    ];

    const _selectionItems: ICommandBarItemProps[] = [


        {
            key: 'edit',
            text: t("edit"),
            title: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            subMenuProps: {
                items: [{
                    key: 'editInfo',
                    text: t('edit'),
                    title: t("edit"),
                    iconProps: { iconName: 'EditContact', styles: { root: { fontWeight: "bold" } } },

                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            setCurrenctAction(101)
                        }

                    }
                },
                {
                    key: 'editPrice',
                    text: t("editPrices"),
                    title: t("editPrices"),
                    iconProps: { iconName: 'Money', styles: { root: { fontWeight: "bold" } } },
                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            setCurrenctAction(102)
                        }
                    }
                },
                {
                    key: 'linkPair',
                    text: t("linkPair"),
                    title: t("linkPair"),
                    iconProps: { iconName: 'Link', styles: { root: { fontWeight: "bold" } } },
                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            setCurrenctAction(103)
                        }
                    }
                }

                ]
            },

        },
        {
            key: 'status',
            text: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate"),
            title: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? "Cancel" : "Accept", styles: { root: { fontWeight: (state.set.selected.length > 0 && state.set.selected.at(0)?.isActive) ? undefined : "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'delete',
            text: t("delete"),
            title: t("delete"),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Delete", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },

    ];

    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('add'),
            title: t('add'),
            iconProps: { iconName: 'Add' },
            onClick: () => {
                //  navigate("add")
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];



    const baseCurrenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.baseCurrencies.hasMore && !state.baseCurrencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.baseCurrencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedBaseCurrencyItems([state.baseCurrencies.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.baseCurrencies.items.length > 0 && !state.baseCurrencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.baseCurrencies.items.at(state.baseCurrencies.items.length - 1).id);
                baseCurrenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.baseCurrencies.items.length > 0 ? state.baseCurrencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.baseCurrencies.items.length == 0 ? state.baseCurrencies.isFetching : false,
        isSearching: state.baseCurrencies.items.length > 0 ? state.baseCurrencies.isFetching : false,


    };
    const onBaseCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetBaseCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        baseCurrenciesReq.setSearch(wrapper)
        baseCurrenciesReq.setNextto(undefined)
        dispatch(setIsFilteredBaseCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
        return [];

    }

    const quoteCurrenciesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('currencies'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.quoteCurrencies.hasMore && !state.quoteCurrencies.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.quoteCurrencies.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedQuoteCurrencyItems([state.quoteCurrencies.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.quoteCurrencies.items.length > 0 && !state.quoteCurrencies.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.quoteCurrencies.items.at(state.quoteCurrencies.items.length - 1).id);
                quoteCurrenciesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.quoteCurrencies.items.length == 0 ? state.quoteCurrencies.isFetching : false,
        isSearching: state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.isFetching : false,


    };
    const onQuoteCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetQuoteCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        quoteCurrenciesReq.setSearch(wrapper)
        quoteCurrenciesReq.setNextto(undefined)
        dispatch(setIsFilteredQuoteCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
        return [];

    }
    const onCategoriesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCategories())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        categoriesReq.setSearch(wrapper)
        categoriesReq.setNextto(undefined)
        dispatch(setIsFilteredCategoriesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
        return [];

    }

    const onPairsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetMainPair())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        mainPairsReq.setSearch(wrapper)
        mainPairsReq.setNextto(undefined)
        dispatch(setIsFilteredMainPairsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getMainPairsItems({ body: mainPairsReq, headers: getHeaders() }))
        return [];


    }
    return (
        <Stack >
            <Popup isOpen={(currentAction == 100)} title={t("addCurrencyPair")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <CurrencyPairsForm childrenGap={10} maxWidth={280} type={FormType.ADD} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addItem(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("edit")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <CurrencyPairsForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 102)} title={t("editPrices")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <CurrencyPairsPriceForm childrenGap={10} maxWidth={480} type={FormType.EDIT} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            <Popup isOpen={(currentAction == 103)} title={t("linkPair")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <CurrencyPairsPriceSettingsForm childrenGap={10} maxWidth={480} type={FormType.EDIT} renderObject={state.set.selected.at(0)} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>
            <Popup isOpen={(currentAction == 1000)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedBaseCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 1001)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedQuoteCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            <Popup isOpen={(currentAction == 1002)} title={t("categories")} onDismiss={() => { setCurrenctAction(0) }} >

                <CategoriesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCategoryItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>


            <Popup isOpen={(currentAction == 1003)} title={t("currencyPairs")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrencyPairsPage key={"d"} isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelecteMainPairItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareCurrencyPair")} value={`https://${window.location.host}/currency-pairs/${state.set.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("delete") : currentAction == 2 ? (state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("deleteFeeDesc") : currentAction == 2 ? (state.set.selected.at(0)?.isActive) ? t("deactivateCurrencyPairDesc") : t("activateCurrencyPairDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.set.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let req = new DeleteCurrencyPairRequest();
                                            req.setId(state.set.selected.at(0)?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(deleteItem({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        } else if (currentAction == 2) {
                                            let req = new UpdateCurrencyPairActivityRequest();
                                            req.setId(state.set.selected.at(0)?.id)
                                            req.setIsactive(!state.set.selected.at(0)?.isActive)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(updateStatus({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })

                                        }
                                    }
                                }} text={(currentAction == 1 ? t("delete") : currentAction == 2 ? (state.set.selected.at(0)?.isActive) ? t("deactivate") : t("activate") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("baseCurrency")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("baseCurrency"), }}
                                suggestionsHeaderText={t('currencies')}
                                isLoading={state.baseCurrencies.items.length == 0 ? state.baseCurrencies.isFetching : false}
                                isSearching={state.baseCurrencies.items.length > 0 ? state.baseCurrencies.isFetching : false}
                                moreSuggestionsAvailable={state.baseCurrencies.hasMore && !state.baseCurrencies.isFetching}
                                suggestions={state.baseCurrencies.items.length > 0 ? state.baseCurrencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.baseCurrencies.items.length > 0 && !state.baseCurrencies.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.baseCurrencies.items.at(state.baseCurrencies.items.length - 1).id);
                                        baseCurrenciesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {

                                    var u = state.baseCurrencies.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedBaseCurrencyItems([state.baseCurrencies.items.at(u)]))
                                    }

                                }}
                                isPeoplePicker={true}
                                selectedItems={state.baseCurrencies.selected.length > 0 ? state.baseCurrencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedBaseCurrencyItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetBaseCurrencies())
                                    baseCurrenciesReq.setSearch(undefined)
                                    baseCurrenciesReq.setNextto(undefined)
                                    dispatch(setIsFilteredBaseCurrenciesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getBaseCurrencies({ body: baseCurrenciesReq, headers: getHeaders() }))
                                    return []

                                }}
                                onFilterChanged={onBaseCurrenciesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000)

                                        }
                                    }]}
                            />


                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("quoteCurrency")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("quoteCurrency"), }}
                                suggestionsHeaderText={t('currencies')}
                                isLoading={state.quoteCurrencies.items.length == 0 ? state.quoteCurrencies.isFetching : false}
                                isSearching={state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.isFetching : false}
                                moreSuggestionsAvailable={state.quoteCurrencies.hasMore && !state.quoteCurrencies.isFetching}
                                suggestions={state.quoteCurrencies.items.length > 0 ? state.quoteCurrencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.quoteCurrencies.items.length > 0 && !state.quoteCurrencies.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.quoteCurrencies.items.at(state.quoteCurrencies.items.length - 1).id);
                                        quoteCurrenciesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {

                                    var u = state.quoteCurrencies.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedQuoteCurrencyItems([state.quoteCurrencies.items.at(u)]))
                                    }

                                }}
                                isPeoplePicker={true}
                                selectedItems={state.quoteCurrencies.selected.length > 0 ? state.quoteCurrencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}

                                onChange={(e) => {
                                    dispatch(setSelectedQuoteCurrencyItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetQuoteCurrencies())
                                    quoteCurrenciesReq.setSearch(undefined)
                                    quoteCurrenciesReq.setNextto(undefined)
                                    dispatch(setIsFilteredQuoteCurrenciesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getQuoteCurrencies({ body: quoteCurrenciesReq, headers: getHeaders() }))
                                    return []

                                }}
                                onFilterChanged={onQuoteCurrenciesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1001)

                                        }
                                    }]}
                            />

                        </Stack>


                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("categories")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("categories"), }}
                                suggestionsHeaderText={t('categories')}
                                isLoading={state.categories.items.length == 0 ? state.categories.isFetching : false}
                                isSearching={state.categories.items.length > 0 ? state.categories.isFetching : false}
                                moreSuggestionsAvailable={state.categories.hasMore && !state.categories.isFetching}
                                suggestions={state.categories.items.length > 0 ? state.categories.items.map(e => { return { item: { key: e.id, text: e.nameAr + " | " + e.nameEn, imageInitials: (e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn), secondaryText: e.parentCategoryId == undefined ? undefined : e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn , imageUrl: e?.iconSource } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.categories.items.length > 0 && !state.categories.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.categories.items.at(state.categories.items.length - 1).id);
                                        categoriesReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {

                                    var u = state.categories.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedCategoryItems([state.categories.items.at(u)]))
                                    }

                                }}
                                isPeoplePicker={true}
                                selectedItems={state.categories.selected.length > 0 ? state.categories.selected.map(e => { return { key: e.id, text: e.nameAr + " | " + e.nameEn, imageInitials: (e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined : e.parentCategoryNameAr + " | " + e.parentCategoryNameEn), secondaryText: e.parentCategoryId == undefined ? undefined : e.parentCategoryNameAr == "" && e.parentCategoryNameEn == "" ? undefined: e.parentCategoryNameAr + " | " + e.parentCategoryNameEn , imageUrl: e?.iconSource } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedCategoryItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetCategories())
                                    categoriesReq.setSearch(undefined)
                                    categoriesReq.setNextto(undefined)
                                    dispatch(setIsFilteredCategoriesSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getCategories({ body: categoriesReq, headers: getHeaders() }))
                                    return []

                                }}
                                onFilterChanged={onCategoriesFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1002)

                                        }
                                    }]}
                            />

                        </Stack>


                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("mainPair")}</Label>
                            <List
                                styles={{ root: { width: 268 } }}
                                inputProps={{ placeholder: t("mainPair"), }}
                                suggestionsHeaderText={t('pair')}
                                isPeoplePicker={true}
                                isLoading={state.mainPairs.items.length == 0 ? state.mainPairs.isFetching : false}
                                isSearching={state.mainPairs.items.length > 0 ? state.mainPairs.isFetching : false}
                                moreSuggestionsAvailable={state.mainPairs.hasMore && !state.mainPairs.isFetching}

                                suggestions={state.mainPairs.items.length > 0 ? state.mainPairs.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode) } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.mainPairs.items.length > 0 && !state.mainPairs.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.mainPairs.items.at(state.mainPairs.items.length - 1).id);
                                        mainPairsReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getMainPairsItems({ body: mainPairsReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.mainPairs.items.findIndex(e => e.id == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelecteMainPairItems([state.mainPairs.items.at(u)]))
                                    }


                                }}
                                selectedItems={state.mainPairs.selected.length > 0 ? state.mainPairs.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.baseCurrencySymbol + "/" + e?.quoteCurrencySymbol, secondaryText: (e?.baseCurrencyCode == "" ? e?.baseCurrencyId : e?.baseCurrencyCode) + "-" + (e?.quoteCurrencyCode == "" ? e?.quoteCurrencyId : e?.quoteCurrencyCode) } as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelecteMainPairItems([]))

                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetMainPair())
                                    mainPairsReq.setSearch(undefined)
                                    mainPairsReq.setNextto(undefined)

                                    dispatch(setIsFilteredMainPairsSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getMainPairsItems({ body: mainPairsReq, headers: getHeaders() }))
                                    return []
                                }}
                                onFilterChanged={onPairsFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            // setCurrenctAction(1003)

                                        }
                                    }]}


                            />

                        </Stack>



                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("status")}</Label>
                            <Dropdown
                                selectedKey={state.statusFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("active") }, { key: 2, text: t("inactive") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setStatusFilterOption({ key: Number(item?.key), text: t("status") + ":" + item?.text }))

                                })}

                            />
                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("syncPrices")}</Label>
                            <Dropdown
                                selectedKey={state.syncPricesFilterOption.key}
                                options={[{ key: 0, text: t("all") }, { key: 1, text: t("active") }, { key: 2, text: t("inactive") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setSyncPricesFilterOption({ key: Number(item?.key), text: t("syncPrices") + ":" + item?.text  }))

                                })}

                            />
                        </Stack>
                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {

                                if (e.key == 1) {
                                    dispatch(setSelectedBaseCurrencyItems([]))
                                    req.setBase(undefined)
                                }

                                if (e.key == 2) {
                                    dispatch(setSelectedQuoteCurrencyItems([]))
                                    req.setQuote(undefined)
                                }
                                if (e.key == 3) {
                                    dispatch(setSelectedCategoryItems([]))
                                    req.setCategory(undefined)
                                }
                                if (e.key == 4) {
                                    dispatch(setSelecteMainPairItems([]))
                                    req.setMaincurrencypair(undefined)
                                }
                                if (e.key == 5) {
                                    dispatch(setStatusFilterOption({ key: 0, text: "" }))
                                    req.setIsactive(undefined)
                                }
                                if (e.key == 6) {
                                    dispatch(setSyncPricesFilterOption({ key: 0, text: "" }))
                                    req.setSyncprices(undefined)
                                }

                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="currencyPairs"
                items={state.set.items}
                columns={c}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 176px)" : "calc(100vh - 224px)"}
                selection={_selection}
                selectionMode={SelectionMode.single}

                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.fixedColumns}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {props.isSelection ? <DialogFooter>
                <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                    if (props.onItemSelected && state.set.selected.length > 0) {
                        props.onItemSelected(state.set.selected.at(0))
                    }
                }} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </DialogFooter> : undefined}
        </Stack>



    );
}




