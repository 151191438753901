// source: organizationclientportalsetting.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('./common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.AccountType', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.Currency', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.CurrencyPairResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.EntityMetadata', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.Limitation', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.Role', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateURLsReplay', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateURLsRequest', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateURLsResponse', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.ResultCase', null, global);
goog.exportSymbol('proto.organization_client_portal_setting_endpoint.UserType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.displayName = 'proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.displayName = 'proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.displayName = 'proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateURLsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateURLsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.displayName = 'proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.displayName = 'proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.displayName = 'proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.displayName = 'proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateURLsReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateURLsReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.Role.displayName = 'proto.organization_client_portal_setting_endpoint.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UserType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UserType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UserType.displayName = 'proto.organization_client_portal_setting_endpoint.UserType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.Currency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.Currency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.Currency.displayName = 'proto.organization_client_portal_setting_endpoint.Currency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.CurrencyPairResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.displayName = 'proto.organization_client_portal_setting_endpoint.CurrencyPairResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.AccountType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.AccountType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.AccountType.displayName = 'proto.organization_client_portal_setting_endpoint.AccountType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.Limitation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.Limitation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.Limitation.displayName = 'proto.organization_client_portal_setting_endpoint.Limitation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.EntityMetadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.EntityMetadata.displayName = 'proto.organization_client_portal_setting_endpoint.EntityMetadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.repeatedFields_, null);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.displayName = 'proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateURLsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateURLsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.displayName = 'proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.oneofGroups_);
};
goog.inherits(proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.displayName = 'proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accounttype: jspb.Message.getFieldWithDefault(msg, 3, ""),
    limitation: jspb.Message.getFieldWithDefault(msg, 4, ""),
    maxnumberofaccountscanbeopend: jspb.Message.getFieldWithDefault(msg, 5, 0),
    openbydefault: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    rank: jspb.Message.getFieldWithDefault(msg, 7, 0),
    canupdatename: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    canupdatecode: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest;
  return proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccounttype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLimitation(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxnumberofaccountscanbeopend(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpenbydefault(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanupdatename(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanupdatecode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccounttype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLimitation();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMaxnumberofaccountscanbeopend();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOpenbydefault();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getCanupdatename();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getCanupdatecode();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string accountType = 3;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.getAccounttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string limitation = 4;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.getLimitation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.setLimitation = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 maxNumberOfAccountsCanBeOpend = 5;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.getMaxnumberofaccountscanbeopend = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.setMaxnumberofaccountscanbeopend = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool openByDefault = 6;
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.getOpenbydefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.setOpenbydefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 rank = 7;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool canUpdateName = 8;
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.getCanupdatename = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.setCanupdatename = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool canUpdateCode = 9;
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.getCanupdatecode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationRequest.prototype.setCanupdatecode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gwaccount: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest;
  return proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGwaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGwaccount();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string gwAccount = 3;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.prototype.getGwaccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationRequest.prototype.setGwaccount = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    gwaccoint: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest;
  return proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGwaccoint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGwaccoint();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string gwAccoint = 2;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.prototype.getGwaccoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationRequest.prototype.setGwaccoint = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currencypair: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rank: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest;
  return proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencypair(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrencypair();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string currencyPair = 2;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.prototype.getCurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 rank = 3;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationRequest.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currencypair: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest;
  return proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrencypair(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrencypair();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string currencyPair = 2;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.prototype.getCurrencypair = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationRequest.prototype.setCurrencypair = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accounttype: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest;
  return proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccounttype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccounttype();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string currency = 2;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string accountType = 3;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.prototype.getAccounttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationRequest.prototype.setAccounttype = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    role: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usertype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    businessusertype: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest;
  return proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRole(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsertype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBusinessusertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUsertype();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBusinessusertype();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 role = 2;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 userType = 3;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.getUsertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.setUsertype = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 businessUserType = 4;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.getBusinessusertype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationRequest.prototype.setBusinessusertype = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isaccountlockedbydefault: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest;
  return proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsaccountlockedbydefault(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIsaccountlockedbydefault();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool isAccountLockedByDefault = 2;
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.prototype.getIsaccountlockedbydefault = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultRequest.prototype.setIsaccountlockedbydefault = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cansignup: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest;
  return proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCansignup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCansignup();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool canSignUp = 2;
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.prototype.getCansignup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpRequest.prototype.setCansignup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    canopenaccount: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest;
  return proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanopenaccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCanopenaccount();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool canOpenAccount = 2;
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.prototype.getCanopenaccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountRequest.prototype.setCanopenaccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateURLsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    terms: jspb.Message.getFieldWithDefault(msg, 2, ""),
    privacypolicy: jspb.Message.getFieldWithDefault(msg, 3, ""),
    support: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateURLsRequest;
  return proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateURLsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsRequest}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTerms(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrivacypolicy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateURLsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTerms();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrivacypolicy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSupport();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string terms = 2;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.getTerms = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.setTerms = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string privacyPolicy = 3;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.getPrivacypolicy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.setPrivacypolicy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string support = 4;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.getSupport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsRequest.prototype.setSupport = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest;
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay;
  return proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay;
  return proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay;
  return proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay;
  return proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay;
  return proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay;
  return proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay;
  return proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay;
  return proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay;
  return proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay;
  return proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateURLsReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateURLsReplay;
  return proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateURLsReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateURLsReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.Role}
 */
proto.organization_client_portal_setting_endpoint.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.Role;
  return proto.organization_client_portal_setting_endpoint.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.Role}
 */
proto.organization_client_portal_setting_endpoint.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.Role} returns this
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.Role} returns this
*/
proto.organization_client_portal_setting_endpoint.Role.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.Role} returns this
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.Role.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UserType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UserType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UserType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UserType}
 */
proto.organization_client_portal_setting_endpoint.UserType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UserType;
  return proto.organization_client_portal_setting_endpoint.UserType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UserType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UserType}
 */
proto.organization_client_portal_setting_endpoint.UserType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UserType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UserType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UserType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.UserType} returns this
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UserType} returns this
*/
proto.organization_client_portal_setting_endpoint.UserType.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UserType} returns this
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UserType.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.Currency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.Currency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Currency.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.Currency}
 */
proto.organization_client_portal_setting_endpoint.Currency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.Currency;
  return proto.organization_client_portal_setting_endpoint.Currency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.Currency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.Currency}
 */
proto.organization_client_portal_setting_endpoint.Currency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.Currency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.Currency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Currency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.Currency} returns this
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.Currency} returns this
*/
proto.organization_client_portal_setting_endpoint.Currency.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.Currency} returns this
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.Currency.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    base: (f = msg.getBase()) && proto.organization_client_portal_setting_endpoint.Currency.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && proto.organization_client_portal_setting_endpoint.Currency.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.CurrencyPairResponse;
  return proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new proto.organization_client_portal_setting_endpoint.Currency;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.Currency.deserializeBinaryFromReader);
      msg.setBase(value);
      break;
    case 4:
      var value = new proto.organization_client_portal_setting_endpoint.Currency;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.Currency.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBase();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.organization_client_portal_setting_endpoint.Currency.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.organization_client_portal_setting_endpoint.Currency.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Currency base = 3;
 * @return {?proto.organization_client_portal_setting_endpoint.Currency}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.getBase = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.Currency} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.Currency, 3));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.Currency|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.setBase = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.clearBase = function() {
  return this.setBase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.hasBase = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Currency quote = 4;
 * @return {?proto.organization_client_portal_setting_endpoint.Currency}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.getQuote = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.Currency} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.Currency, 4));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.Currency|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.AccountType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.AccountType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.AccountType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType}
 */
proto.organization_client_portal_setting_endpoint.AccountType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.AccountType;
  return proto.organization_client_portal_setting_endpoint.AccountType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.AccountType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType}
 */
proto.organization_client_portal_setting_endpoint.AccountType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.AccountType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.AccountType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.AccountType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType} returns this
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType} returns this
*/
proto.organization_client_portal_setting_endpoint.AccountType.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.AccountType} returns this
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.AccountType.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.Limitation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.Limitation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Limitation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation}
 */
proto.organization_client_portal_setting_endpoint.Limitation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.Limitation;
  return proto.organization_client_portal_setting_endpoint.Limitation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.Limitation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation}
 */
proto.organization_client_portal_setting_endpoint.Limitation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.Limitation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.Limitation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.Limitation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation} returns this
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation} returns this
*/
proto.organization_client_portal_setting_endpoint.Limitation.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.Limitation} returns this
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.Limitation.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.EntityMetadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.EntityMetadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    currency: (f = msg.getCurrency()) && proto.organization_client_portal_setting_endpoint.Currency.toObject(includeInstance, f),
    internalid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    id: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.EntityMetadata}
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.EntityMetadata;
  return proto.organization_client_portal_setting_endpoint.EntityMetadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.EntityMetadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.EntityMetadata}
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.Currency;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.Currency.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.EntityMetadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.EntityMetadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.Currency.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional Currency currency = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.Currency}
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.getCurrency = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.Currency} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.Currency, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.Currency|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.EntityMetadata} returns this
*/
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.EntityMetadata} returns this
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string internalId = 2;
 * @return {string}
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.organization_client_portal_setting_endpoint.EntityMetadata} returns this
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 id = 3;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.EntityMetadata} returns this
 */
proto.organization_client_portal_setting_endpoint.EntityMetadata.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    role: (f = msg.getRole()) && proto.organization_client_portal_setting_endpoint.Role.toObject(includeInstance, f),
    usertype: (f = msg.getUsertype()) && proto.organization_client_portal_setting_endpoint.UserType.toObject(includeInstance, f),
    businessusertype: (f = msg.getBusinessusertype()) && proto.organization_client_portal_setting_endpoint.UserType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.Role;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 2:
      var value = new proto.organization_client_portal_setting_endpoint.UserType;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UserType.deserializeBinaryFromReader);
      msg.setUsertype(value);
      break;
    case 3:
      var value = new proto.organization_client_portal_setting_endpoint.UserType;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UserType.deserializeBinaryFromReader);
      msg.setBusinessusertype(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.Role.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.organization_client_portal_setting_endpoint.UserType.serializeBinaryToWriter
    );
  }
  f = message.getBusinessusertype();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.organization_client_portal_setting_endpoint.UserType.serializeBinaryToWriter
    );
  }
};


/**
 * optional Role role = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.Role}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.getRole = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.Role} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.Role, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.Role|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.hasRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserType userType = 2;
 * @return {?proto.organization_client_portal_setting_endpoint.UserType}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.getUsertype = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UserType} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UserType, 2));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UserType|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.setUsertype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.clearUsertype = function() {
  return this.setUsertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.hasUsertype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserType businessUserType = 3;
 * @return {?proto.organization_client_portal_setting_endpoint.UserType}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.getBusinessusertype = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UserType} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UserType, 3));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UserType|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.setBusinessusertype = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.clearBusinessusertype = function() {
  return this.setBusinessusertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.prototype.hasBusinessusertype = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currency: (f = msg.getCurrency()) && proto.organization_client_portal_setting_endpoint.Currency.toObject(includeInstance, f),
    accounttype: (f = msg.getAccounttype()) && proto.organization_client_portal_setting_endpoint.AccountType.toObject(includeInstance, f),
    limitation: (f = msg.getLimitation()) && proto.organization_client_portal_setting_endpoint.Limitation.toObject(includeInstance, f),
    maxnumberofaccountscanbeopend: (f = msg.getMaxnumberofaccountscanbeopend()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    openbydefault: (f = msg.getOpenbydefault()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    rank: (f = msg.getRank()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    canupdatename: (f = msg.getCanupdatename()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    canupdatecode: (f = msg.getCanupdatecode()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.Currency;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.Currency.deserializeBinaryFromReader);
      msg.setCurrency(value);
      break;
    case 2:
      var value = new proto.organization_client_portal_setting_endpoint.AccountType;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.AccountType.deserializeBinaryFromReader);
      msg.setAccounttype(value);
      break;
    case 3:
      var value = new proto.organization_client_portal_setting_endpoint.Limitation;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.Limitation.deserializeBinaryFromReader);
      msg.setLimitation(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setMaxnumberofaccountscanbeopend(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setOpenbydefault(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setRank(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setCanupdatename(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setCanupdatecode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrency();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.Currency.serializeBinaryToWriter
    );
  }
  f = message.getAccounttype();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.organization_client_portal_setting_endpoint.AccountType.serializeBinaryToWriter
    );
  }
  f = message.getLimitation();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.organization_client_portal_setting_endpoint.Limitation.serializeBinaryToWriter
    );
  }
  f = message.getMaxnumberofaccountscanbeopend();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOpenbydefault();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCanupdatename();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCanupdatecode();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional Currency currency = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.Currency}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getCurrency = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.Currency} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.Currency, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.Currency|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setCurrency = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearCurrency = function() {
  return this.setCurrency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasCurrency = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AccountType accountType = 2;
 * @return {?proto.organization_client_portal_setting_endpoint.AccountType}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getAccounttype = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.AccountType} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.AccountType, 2));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.AccountType|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setAccounttype = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearAccounttype = function() {
  return this.setAccounttype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasAccounttype = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Limitation limitation = 3;
 * @return {?proto.organization_client_portal_setting_endpoint.Limitation}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getLimitation = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.Limitation} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.Limitation, 3));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.Limitation|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setLimitation = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearLimitation = function() {
  return this.setLimitation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasLimitation = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value maxNumberOfAccountsCanBeOpend = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getMaxnumberofaccountscanbeopend = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setMaxnumberofaccountscanbeopend = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearMaxnumberofaccountscanbeopend = function() {
  return this.setMaxnumberofaccountscanbeopend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasMaxnumberofaccountscanbeopend = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue openByDefault = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getOpenbydefault = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setOpenbydefault = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearOpenbydefault = function() {
  return this.setOpenbydefault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasOpenbydefault = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value rank = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getRank = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setRank = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearRank = function() {
  return this.setRank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasRank = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue canUpdateName = 7;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getCanupdatename = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 7));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setCanupdatename = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearCanupdatename = function() {
  return this.setCanupdatename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasCanupdatename = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue canUpdateCode = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.getCanupdatecode = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.setCanupdatecode = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.clearCanupdatecode = function() {
  return this.setCanupdatecode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.prototype.hasCanupdatecode = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gwaccount: (f = msg.getGwaccount()) && proto.organization_client_portal_setting_endpoint.EntityMetadata.toObject(includeInstance, f),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.EntityMetadata;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.EntityMetadata.deserializeBinaryFromReader);
      msg.setGwaccount(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGwaccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.EntityMetadata.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional EntityMetadata gwAccount = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.EntityMetadata}
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.getGwaccount = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.EntityMetadata} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.EntityMetadata, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.EntityMetadata|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.setGwaccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.clearGwaccount = function() {
  return this.setGwaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.hasGwaccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currencypair: (f = msg.getCurrencypair()) && proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.toObject(includeInstance, f),
    rank: (f = msg.getRank()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.CurrencyPairResponse;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.deserializeBinaryFromReader);
      msg.setCurrencypair(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setRank(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrencypair();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.CurrencyPairResponse.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional CurrencyPairResponse currencyPair = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.CurrencyPairResponse}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.getCurrencypair = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.CurrencyPairResponse} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.CurrencyPairResponse, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.CurrencyPairResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.setCurrencypair = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.clearCurrencypair = function() {
  return this.setCurrencypair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.hasCurrencypair = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value rank = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.getRank = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.setRank = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.clearRank = function() {
  return this.setRank(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.prototype.hasRank = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.repeatedFields_ = [10,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    canopenaccount: (f = msg.getCanopenaccount()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    cansignup: (f = msg.getCansignup()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    privacypolicy: (f = msg.getPrivacypolicy()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    terms: (f = msg.getTerms()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isaccountlockedbydefault: (f = msg.getIsaccountlockedbydefault()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    createdby: (f = msg.getCreatedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    clientdefaultconfiguration: (f = msg.getClientdefaultconfiguration()) && proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.toObject(includeInstance, f),
    openaccountdefaultconfigurationsList: jspb.Message.toObjectList(msg.getOpenaccountdefaultconfigurationsList(),
    proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.toObject, includeInstance),
    support: (f = msg.getSupport()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currencypairsconfigurationsList: jspb.Message.toObjectList(msg.getCurrencypairsconfigurationsList(),
    proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.toObject, includeInstance),
    externaltransfersentitiesconfigurationsList: jspb.Message.toObjectList(msg.getExternaltransfersentitiesconfigurationsList(),
    proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay;
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setCanopenaccount(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setCansignup(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrivacypolicy(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTerms(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsaccountlockedbydefault(value);
      break;
    case 7:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setCreatedby(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 9:
      var value = new proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.deserializeBinaryFromReader);
      msg.setClientdefaultconfiguration(value);
      break;
    case 10:
      var value = new proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader);
      msg.addOpenaccountdefaultconfigurations(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSupport(value);
      break;
    case 12:
      var value = new proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.deserializeBinaryFromReader);
      msg.addCurrencypairsconfigurations(value);
      break;
    case 13:
      var value = new proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.deserializeBinaryFromReader);
      msg.addExternaltransfersentitiesconfigurations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCanopenaccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCansignup();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPrivacypolicy();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTerms();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsaccountlockedbydefault();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getCreatedby();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getClientdefaultconfiguration();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse.serializeBinaryToWriter
    );
  }
  f = message.getOpenaccountdefaultconfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse.serializeBinaryToWriter
    );
  }
  f = message.getSupport();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrencypairsconfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse.serializeBinaryToWriter
    );
  }
  f = message.getExternaltransfersentitiesconfigurationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue canOpenAccount = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getCanopenaccount = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setCanopenaccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearCanopenaccount = function() {
  return this.setCanopenaccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasCanopenaccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue canSignUp = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getCansignup = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setCansignup = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearCansignup = function() {
  return this.setCansignup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasCansignup = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue privacyPolicy = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getPrivacypolicy = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setPrivacypolicy = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearPrivacypolicy = function() {
  return this.setPrivacypolicy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasPrivacypolicy = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue terms = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getTerms = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setTerms = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearTerms = function() {
  return this.setTerms(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasTerms = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.BoolValue isAccountLockedByDefault = 14;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getIsaccountlockedbydefault = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 14));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setIsaccountlockedbydefault = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearIsaccountlockedbydefault = function() {
  return this.setIsaccountlockedbydefault(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasIsaccountlockedbydefault = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional common.ProfileResponse createdBy = 7;
 * @return {?proto.common.ProfileResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getCreatedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 7));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setCreatedby = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearCreatedby = function() {
  return this.setCreatedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasCreatedby = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional ClientDefaultConfigurationResponse clientDefaultConfiguration = 9;
 * @return {?proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getClientdefaultconfiguration = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse, 9));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.ClientDefaultConfigurationResponse|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setClientdefaultconfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearClientdefaultconfiguration = function() {
  return this.setClientdefaultconfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasClientdefaultconfiguration = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated OpenAccountDefaultConfigurationResponse openAccountDefaultConfigurations = 10;
 * @return {!Array<!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse>}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getOpenaccountdefaultconfigurationsList = function() {
  return /** @type{!Array<!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse, 10));
};


/**
 * @param {!Array<!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse>} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setOpenaccountdefaultconfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.addOpenaccountdefaultconfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.organization_client_portal_setting_endpoint.OpenAccountDefaultConfigurationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearOpenaccountdefaultconfigurationsList = function() {
  return this.setOpenaccountdefaultconfigurationsList([]);
};


/**
 * optional google.protobuf.StringValue support = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getSupport = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setSupport = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearSupport = function() {
  return this.setSupport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.hasSupport = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated CurrencyPairsConfigurationResponse currencyPairsConfigurations = 12;
 * @return {!Array<!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse>}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getCurrencypairsconfigurationsList = function() {
  return /** @type{!Array<!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse, 12));
};


/**
 * @param {!Array<!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse>} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setCurrencypairsconfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.addCurrencypairsconfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.organization_client_portal_setting_endpoint.CurrencyPairsConfigurationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearCurrencypairsconfigurationsList = function() {
  return this.setCurrencypairsconfigurationsList([]);
};


/**
 * repeated ExternalTransfersEntitiesConfigurationResponse externalTransfersEntitiesConfigurations = 13;
 * @return {!Array<!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse>}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.getExternaltransfersentitiesconfigurationsList = function() {
  return /** @type{!Array<!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse, 13));
};


/**
 * @param {!Array<!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse>} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.setExternaltransfersentitiesconfigurationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.addExternaltransfersentitiesconfigurations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.organization_client_portal_setting_endpoint.ExternalTransfersEntitiesConfigurationResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.prototype.clearExternaltransfersentitiesconfigurationsList = function() {
  return this.setExternaltransfersentitiesconfigurationsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateOpenAccountDefaultConfigurationReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateOpenAccountDefaultConfigurationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional RemoveOpenAccountDefaultConfigurationReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.RemoveOpenAccountDefaultConfigurationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateClientDefaultConfigurationReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateClientDefaultConfigurationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse;
  return proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateCanSignUpReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UpdateCanSignUpReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanSignUpResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse;
  return proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateCanOpenAccountReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCanOpenAccountResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateURLsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateURLsResponse;
  return proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateURLsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.UpdateURLsReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateURLsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.UpdateURLsReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateURLsReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.UpdateURLsReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UpdateURLsReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UpdateURLsReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UpdateURLsReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateURLsResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateURLsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse;
  return proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetOrganizationClientPortalSettingReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.GetOrganizationClientPortalSettingResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateCurrencyPairsConfigurationReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateCurrencyPairsConfigurationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse;
  return proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateIsAccountLockedByDefaultReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateIsAccountLockedByDefaultResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional RemoveCurrencyPairsConfigurationReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.RemoveCurrencyPairsConfigurationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateExternalTransfersEntitiesConfigurationReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.UpdateExternalTransfersEntitiesConfigurationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.ResultCase}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.prototype.getResultCase = function() {
  return /** @type {proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse;
  return proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay;
      reader.readMessage(value,proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional RemoveExternalTransfersEntitiesConfigurationReplay success = 1;
 * @return {?proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay} */ (
    jspb.Message.getWrapperField(this, proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay, 1));
};


/**
 * @param {?proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationReplay|undefined} value
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse} returns this
*/
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse} returns this
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.organization_client_portal_setting_endpoint.RemoveExternalTransfersEntitiesConfigurationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.organization_client_portal_setting_endpoint);
