import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, DialogFooter, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../app/Themes"
import { SearchField } from "../../../common/SearchField/SearchField"
import { formatDate, printComponent, normalizeKey } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool } from "yup"
import { removePrivilege, dismissMessage, getPrivileges, reset, setSelectedPrivilege, setNumberOfResults, setSearchText, resetPrivileges, setSort, setIsFilteredSet, addPrivileges } from "./PrivilegesPageSlice"
import { GetPrivilegesRequest, RemovePrivilegeRequest } from "../../../../repository/UserManagement/Customers/privilege_pb"
import { Popup } from "../../../common/Popup/Popup"
import { PrivilegesForm } from "../../../forms/Customers/Privileges/PrivilegesForm"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetPrivilegesRequest;
var org: number = -1;
let getPrivilegesPromise: any;
let actionPromise: any;

interface PrivilegesPageProps {
    showAdded?: boolean,
}

const key = "customers/privileges"
export const PrivilegesPage: React.FunctionComponent<PrivilegesPageProps & PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();





    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined, privileges: TableState,
        appTheme: string, isDarkModeEnabled: boolean, searchText: string | undefined,
        filters: any[],
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.customersPrivilegesPage.isChangeStateLoading,
            message: state.customersPrivilegesPage.message, privileges: state.customersPrivilegesPage.privileges,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.customersPrivilegesPage.searchText,
            filters: state.customersPrivilegesPage.filters
        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});
    const getActionId = (action: number): string => {
        if (action == 1)
            return deleteActionConfirmationcalloutTargetId;
        else {
            return ""
        }
    }
    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedPrivilege(_selection.getSelection()));
            } else {
                dispatch(setSelectedPrivilege([]));
            }
        },

    }));


    const clearFilter = () => {

    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }
        req.setNextto(0)
        dispatch(resetPrivileges())
        dispatch(setIsFilteredSet(isFiltered))
        getPrivilegesPromise?.abort()
        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {

        req = new GetPrivilegesRequest()
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
            }
        }
        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }
        req.setNextto(0)
        req.setNumofresults(state.privileges.numberOfResults)
        req.setOrder(state.privileges.isDescending)
        if (props.showAdded) {
            req.setIsadded(props.showAdded)
        } else {
            req.setIsadded(true)
        }
        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))
        return () => { //clean up
            getPrivilegesPromise?.abort();
            actionPromise?.abort();
            org = -1;
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [
        {
            key: 'keyName',
            name: t("id"),
            minWidth: 20,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{item?.keyName}</Text>;

                //return <Link href="/" underline >{item.name}</Link>;
            },
        },
        {
            key: 'name',
            name: t("service"),
            minWidth: 20,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {


                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/customers/privileges/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/privileges/" + item?.id + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined} > {item.name == "" ? item.id : item?.name}</Link >;
                //return <Link href="/" underline >{item.name}</Link>;
            },
        },


        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {


                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },




    ];

    const _selectionItems: ICommandBarItemProps[] = [

        {
            key: 'remove',
            text: t("removeService"),
            title: t("removeService"),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Remove", styles: { root: { fontWeight: "bold" } } },
            disabled: (org == -1),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.privileges.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
    ];

    const mainOptions: ICommandBarItemProps[] = [
        {
            key: 'newItem',
            text: t('add'),
            title: t("add"),
            iconProps: { iconName: 'Add' },
            disabled: (org == -1),
            onClick: () => {
                setCurrenctAction(100)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t("refresh"),
            iconProps: { iconName: 'refresh' },
            disabled: state.privileges.isFetching,
            onClick: () => {
                dispatch(resetPrivileges())
                req.setNextto(0)
                getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t("sort"),
            iconProps: { iconName: 'sort' },
            disabled: state.privileges.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t("ascendingDate"),
                    canCheck: true,
                    checked: !state.privileges.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetPrivileges())
                        req.setOrder(false)
                        req.setNextto(0)
                        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))

                    }

                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t("descendingDate"),
                    canCheck: true,
                    checked: state.privileges.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetPrivileges())
                        req.setOrder(true)
                        req.setNextto(0)
                        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.privileges.numberOfResults,
            title: t('results') + ': ' + state.privileges.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.privileges.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.privileges.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.privileges.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];




    return (
        <Stack >
            <Popup isOpen={(currentAction == 100)} title={t("addService")} onDismiss={() => { setCurrenctAction(0) }} isBlocking={true} >
                <PrivilegesForm childrenGap={10} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(addPrivileges(e))

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.privileges.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}

            />
            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareService")} value={`https://${window.location.host}/customers/privileges/${state.privileges.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("removeService") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("deleteServiceDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.privileges.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let req = new RemovePrivilegeRequest();
                                            req.setId(state.privileges.selected.at(0)?.id)
                                            if ((org != -1)) {
                                                req.setOrganization(org)
                                            }

                                            actionPromise?.abort();
                                            actionPromise = dispatch(removePrivilege({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })

                                        }
                                    }
                                }} text={(currentAction == 1 ? t("remove") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}



            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {

                            });

                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.privileges.isFilteredSet) {
                                search(undefined);
                            }
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="privileges"
                items={state.privileges.items}
                columns={c}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 176px)" : "calc(100vh - 224px)"}
                selection={_selection}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.privileges.isFetching && state.privileges.hasMore) {
                        req.setNextto(state.privileges.items.at(state.privileges.items.length - 11)?.id)
                        getPrivilegesPromise?.abort();
                        getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))

                    }
                }}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.privileges.items.length == 0 && state.privileges.isFetching}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.privileges.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                isColumnSortingEnabled={state.privileges.selected.length == 0}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.privileges.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.privileges.items.length > 0 && !state.privileges.isFetching && state.privileges.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    req.setNextto(state.privileges.items.at(state.privileges.items.length - 11)?.id)
                                    getPrivilegesPromise?.abort();
                                    getPrivilegesPromise = dispatch(getPrivileges({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {props.isSelection ? <DialogFooter>
                <PrimaryButton disabled={state.privileges.isFetching || state.privileges.selected.length == 0} text={state.privileges.isFetching ? undefined : t("select")} onClick={() => {
                    if (props.onItemSelected && state.privileges.selected.length > 0) {
                        props.onItemSelected(state.privileges.selected.at(0))
                    }
                }} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.privileges.isFetching ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton disabled={state.privileges.isFetching} text={t("discard")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </DialogFooter> : undefined}
        </Stack>



    );
}




