import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator, TooltipHost } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../../app/Hooks"
import { getPage } from "../../../../../../../app/Pages"
import { setPage } from "../../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../../common/NavHeader/NavHeaderSlice"

import { ApiMessage, getHeaders } from "../../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../../../../common/Message/Message"
import { ShareBox } from "../../../../../../common/ShareBox/ShareBox"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { deleteItem, dismissMessage, get, reset, resetItem, updateItem } from "./CurrencyPairPriceChangeLogDetailsPageSlice"
import { Popup } from "../../../../../../common/Popup/Popup"
import { FormType } from "../../../../../../forms/FormProps"
import { TextView } from "../../../../../../common/DataView/TextView"
import { ProfileView } from "../../../../../../common/DataView/ProfileView"
import { LinkView } from "../../../../../../common/DataView/LinkView"
import { currencyFormatter, printComponent, normalizeKey } from "../../../../../../../app/Helpers"
import { useReactToPrint } from "react-to-print"
import { CurrencyPairsPriceForm } from "../../../../../../forms/Trading/CurrencyPairs/CurrencyPairsPriceForm"
import { CurrencyPairPriceChangeLogForm } from "../../../../../../forms/Trading/CurrencyPairs/CurrencyPairPriceChangeLog/CurrencyPairPriceChangeLogForm"

import { GetCurrencyPairPriceLogResponse, GetCurrencyPairPriceLogRequest, GetCurrencyPairPriceLogsRequest, DeleteCurrencyPairPriceLogResponse, DeleteCurrencyPairPriceLogRequest } from '../../../../../../../repository/Trading/currency_pair_price_log_pb';

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetCurrencyPairPriceLogRequest;

let org: number = -1;

let getPromise: any;
let actionPromise: any;

const key = "trading/currencyPairsPriceLog/details"

export const CurrencyPairPriceChangeLogdDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const [selectedKey, setSelectedKey] = useState("0");

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.currencyPairPriceChangeLogDetailsPage.isChangeStateLoading,
            message: state.currencyPairPriceChangeLogDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.currencyPairPriceChangeLogDetailsPage.isLoading,
            item: state.currencyPairPriceChangeLogDetailsPage.item,

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const { hash } = useLocation();

    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});
    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetCurrencyPairPriceLogRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))


        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            }
        },


        {
            key: 'edit',
            text: t("edit"),
            title: t("edit"),
            iconProps: { iconName: 'EditSolid12' },
            onClick: () => {
                setCurrenctAction(101)

            }

        },

        {
            key: 'delete',
            text: t("delete"),
            title: t("delete"),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Delete", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },


        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];



    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

            <Popup isOpen={(currentAction == 101)} title={t("edit")} onDismiss={() => { setCurrenctAction(0) }} >
                <CurrencyPairPriceChangeLogForm childrenGap={10} maxWidth={280} type={FormType.EDIT} renderObject={state.item} onSuccess={(e) => {
                    setCurrenctAction(0)
                    dispatch(updateItem(e))
                }} onCancel={() => { setCurrenctAction(0) }} />
            </Popup>

            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareRate")} value={`https://${window.location.host}/currency-pair-price-log/${state.item?.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}




            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? t("delete") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? t("deleteExchangeRatesDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 1) {
                                            let req = new DeleteCurrencyPairPriceLogRequest();
                                            req.setId(state.item?.id)
                                            actionPromise?.abort();
                                            actionPromise = dispatch(deleteItem({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                navigate(-1)
                                            })


                                        }
                                    }
                                }} text={(currentAction == 1 ? t("delete") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                        />
                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("id")} value={state.item?.id} />


                                <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}

                                    size={PersonaSize.size40} />

                                    : undefined}

                            </Stack>

                            <LinkView label={t("pair")} value={state.item?.currencyPairName == "" ? state.item?.currencyPairId : state.item?.currencyPairName}
                                url={"/currency-pairs/" + state.item?.currencyPairId + ((org == -1) ? "" : "?org=" + org)} />

                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <LinkView label={t("baseCurrency")} value={state.item?.baseCurrencyName == "" ? state.item?.baseCurrencyId : state.item?.baseCurrencyName}
                                    url={"/currencies/" + state.item?.baseCurrencyId + ((org == -1) ? "" : "?org=" + org)} />

                                <LinkView label={t("quoteCurrency")} value={state.item?.quoteCurrencyName == "" ? state.item?.quoteCurrencyId : state.item?.quoteCurrencyName}
                                    url={"/currencies/" + state.item?.quoteCurrencyId + ((org == -1) ? "" : "?org=" + org)} />

                                <TextView label={t("status")} iconName={state.item?.isActive != undefined ? state.item?.isActive ? "Accept" : "Cancel" : undefined} value={state.item?.isActive != undefined ? state.item?.isActive ? t("active") : t("inactive") : ""} />

                                <TextView label={t("syncPrices")} iconName={state.item?.syncPrices != undefined ? state.item?.syncPrices ? "Accept" : "Cancel" : undefined} value={state.item?.syncPrices != undefined ? state.item?.syncPrices ? t("active") : t("inactive") : ""} />

                            </Stack>


                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("buyPrice")} value={state.item?.buyPrice ? currencyFormatter(state.item?.buyPrice, { significantDigits: state.item?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.item?.quoteCurrencySymbol }) : ""} />

                                <TextView label={t("sellPrice")} value={state.item?.sellPrice ? currencyFormatter(state.item?.sellPrice, { significantDigits: state.item?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.item?.quoteCurrencySymbol }) : ""} />

                                <TextView label={t("tradingPrice")} value={state.item?.tradingPrice ? currencyFormatter(state.item?.tradingPrice, { significantDigits: state.item?.quoteCurrencyDecimalPlaces ?? 9, symbol: state.item?.quoteCurrencySymbol }) : ""} />


                            </Stack>


                        </Stack>


                    </div>


            }



        </Stack>



    );
}




