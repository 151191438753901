import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RpcError } from 'grpc-web';
import { API_URL, getCurrentLng, getHeaders, ApiMessage, toApiMessage, refreshSession, getSession, ACCOUNTANT_API_URL, APIRequest, APIResponse, TRADING_API_URL } from '../../../../../app/Api';
import { AccountTypeEndpointClient } from '../../../../../repository/Accountant/Account_typeServiceClientPb';
import { GetAccountTypesResponse, GetAccountTypesRequest } from '../../../../../repository/Accountant/account_type_pb';
import { formatDate, timestampToDate } from '../../../../../app/Helpers';
import { DynamicField } from '../../../../common/DynamicFields/DynamicFields';
import { AccountTypeRuleEndpointClient } from '../../../../../repository/Accountant/Account_type_ruleServiceClientPb';
import { FeeEndpointClient } from '../../../../../repository/Accountant/FeeServiceClientPb';
import { GetFeesRequest, GetFeesResponse } from '../../../../../repository/Accountant/fee_pb';
import { TableState } from '../../../../common/Table/TableSate';
import { CurrencyEndpointClient } from '../../../../../repository/Accountant/CurrencyServiceClientPb';
import { GetCurrenciesRequest, GetCurrenciesResponse } from '../../../../../repository/Accountant/currency_pb';
import { GetCurrencyPairsResponse, GetCurrencyPairsRequest } from '../../../../../repository/Trading/currency_pair_pb';
import { CurrencyPairEndpointClient } from '../../../../../repository/Trading/Currency_pairServiceClientPb';
import { OrderEndpointClient } from '../../../../../repository/Trading/OrderServiceClientPb';
import { AccountEndpointClient } from '../../../../../repository/Accountant/AccountServiceClientPb';
import { DirectExchangeRequest, DirectExchangeResponse, ExternalExchangeRequest, ExternalExchangeResponse, PlaceOrderRequest, PlaceOrderResponse } from '../../../../../repository/Trading/order_pb';
import { GetAccountsRequest, GetAccountsResponse } from '../../../../../repository/Accountant/account_pb';
import { TreasuryEndpointClient } from '../../../../../repository/Accountant/TreasuryServiceClientPb';
import { GetTreasuriesRequest, GetTreasuriesResponse } from '../../../../../repository/Accountant/treasury_pb';
import { AccountOperationEndpointClient } from '../../../../../repository/Accountant/Account_operationServiceClientPb';
import { GetTransactionSummaryRequest, GetTransactionSummaryResponse } from '../../../../../repository/Accountant/account_operation_pb';


export interface ExternalOrdersFormState {
    isLoading: boolean
    message?: ApiMessage
    stage1: any,
    currencyPairs: TableState
    fromAccountSet: TableState
    toAccountSet: TableState
    confirmation: any

}

const initialState: ExternalOrdersFormState = {
    isLoading: false,
    message: undefined,
    stage1: undefined,
    currencyPairs: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    fromAccountSet: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    toAccountSet: { items: [], hasMore: true, isDescending: true, isFetching: false, numberOfResults: 10, selected: [], isFilteredSet: false },
    confirmation: []

}

const client = new OrderEndpointClient(TRADING_API_URL, null, null);
const clientAccount = new AccountEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientTreasury = new TreasuryEndpointClient(ACCOUNTANT_API_URL, null, null);
const clientCurrencyPairs = new CurrencyPairEndpointClient(TRADING_API_URL, null, null);
const transactionClient = new AccountOperationEndpointClient(ACCOUNTANT_API_URL, null, null);

export const add = createAsyncThunk<PlaceOrderResponse.AsObject, APIRequest<PlaceOrderRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/orders/placeOrder/add',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {
                var r = await client.placeOrder(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }
)



export const getTransactionSummary = createAsyncThunk<APIResponse<GetTransactionSummaryResponse.AsObject>, APIRequest<GetTransactionSummaryRequest>, {

    rejectValue: APIResponse<ApiMessage>
}>(
    'forms/trading/orders/placeOrder/getTransactionSummary',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await transactionClient.getTransactionSummary(req.body, req.headers ?? {});
                return { metadata: { price: req.metadata?.price, total: req.metadata?.total }, response: r.toObject() };
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue({
                        response: message, metadata: {
                            transactions: req.body.getTransactionsList()?.map(v => {
                                return { amount: v.getAmount(), price: req.metadata?.price, total: req.metadata?.total }
                            })
                        }
                    })
                } catch (err) {

                    return thunkApi.rejectWithValue({
                        response: { body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage, metadata: {
                            transactions: {
                                amount: req.body.getTransactionsList()?.map(v => {
                                    return { amount: v.getAmount(), price: req.metadata?.price, total: req.metadata?.total }
                                })
                            }
                        }
                    })
                }
            }
        }
        return await callReq();
    }
)

export const getCurrencyPairs = createAsyncThunk<GetCurrencyPairsResponse.AsObject, APIRequest<GetCurrencyPairsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/orders/placeOrder/currencyPairs/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientCurrencyPairs.getCurrencyPairs(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)



export const getFromAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/orders/placeOrder/fromAccounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientAccount.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)


export const getToAccounts = createAsyncThunk<GetAccountsResponse.AsObject, APIRequest<GetAccountsRequest>, {

    rejectValue: ApiMessage
}>(
    'forms/trading/orders/placeOrder/toAccounts/fetch',
    async (req, thunkApi) => {
        let callReq = async (): Promise<any> => {
            try {

                var r = await clientAccount.getAccounts(req.body, req.headers ?? {});
                return r.toObject();
            } catch (err) {
                try {
                    let message = toApiMessage((err as RpcError).metadata);
                    if (message.data == 401) {
                        var isSessionRefreshed = await refreshSession();
                        if (isSessionRefreshed) {
                            req.headers = {
                                ...req.headers,
                                "Authorization": 'Bearer ' + getSession().accesstoken?.token?.value,
                            }
                            return await callReq();
                        }
                    }
                    return thunkApi.rejectWithValue(message)
                } catch (err) {

                    return thunkApi.rejectWithValue({ body: "genericErrorBody", data: "", title: "genericErrorTitle", type: -2 } as ApiMessage)
                }
            }
        }
        return await callReq();
    }

)

 
export const placeOrderFormSlice = createSlice({
    name: 'forms/trading/placeOrder',
    initialState,
    reducers: {

        dismissMessage: (state) => {
            state.message = undefined;
        },
        reset: (state) => {
            state.isLoading = false;
            state.message = undefined;
            state.stage1 = initialState.stage1;
            state.fromAccountSet = initialState.fromAccountSet;
            state.toAccountSet = initialState.toAccountSet;
            state.currencyPairs = initialState.currencyPairs;
            state.confirmation = initialState.confirmation;


        },
        setStage1: (state, action: PayloadAction<any>) => {
            let stage1 = {

            }
            state.stage1 = stage1;
        },
        resetCurrencyPairs: (state) => {
            state.currencyPairs.items = [];
            state.currencyPairs.hasMore = true;
        },

        setSelectedCurrencyPairItems: (state, action: PayloadAction<any[]>) => {
            state.currencyPairs.selected = action.payload;
        },

        setIsFilteredCurrencyPairsSet: (state, action: PayloadAction<boolean>) => {
            state.currencyPairs.isFilteredSet = action.payload;
        },

        resetFromAccountSet: (state) => {
            state.fromAccountSet.items = [];
            state.fromAccountSet.hasMore = true;
        },

        resetToAccountSet: (state) => {
            state.toAccountSet.items = [];
            state.toAccountSet.hasMore = true;
        },

        setSelectedFromAccountItems: (state, action: PayloadAction<any[]>) => {
            state.fromAccountSet.selected = action.payload;
        },

        setIsFilteredFromAccountSet: (state, action: PayloadAction<boolean>) => {
            state.fromAccountSet.isFilteredSet = action.payload;
        },

        setSelectedToAccountItems: (state, action: PayloadAction<any[]>) => {
            state.toAccountSet.selected = action.payload;
        },

        setIsFilteredToAccountSet: (state, action: PayloadAction<boolean>) => {
            state.toAccountSet.isFilteredSet = action.payload;
        },


      

    

        setMessage: (state, action: PayloadAction<ApiMessage>) => {
            state.message = action.payload;
        },
        resetMessage: (state) => {
            state.message = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(add.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            if (payload)
                state.message = toApiMessage(payload.success?.message);

        })
        builder.addCase(add.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload) {
                state.message = action.payload;
            }
        })
        builder.addCase(add.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })

        builder.addCase(getFromAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.fromAccountSet.numberOfResults) {
                    state.fromAccountSet.hasMore = false;
                }
                state.fromAccountSet.items = state.fromAccountSet.items.concat(r);
            } else {
                state.fromAccountSet.hasMore = false;

            }
            state.fromAccountSet.isFetching = false

        })
        builder.addCase(getFromAccounts.rejected, (state, action) => {
            state.fromAccountSet.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getFromAccounts.pending, (state, action) => {
            state.fromAccountSet.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(getToAccounts.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.accountsList.map(val => {

                    return {
                        id: val.id,
                        internalId: val.internalid,
                        name: val.name?.value ?? "",
                        code: val.code?.value ?? "",
                        currencyId: val.currency?.id?.value ?? "",
                        currencyName: val.currency?.name?.value ?? "",
                        currencyCode: val.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val.currency?.form?.value ?? undefined,
                        currencySymbol: val.currency?.symbol?.value ?? "",
                        accountTypeId: val.accounttype?.id?.value ?? "",
                        accountTypeName: val.accounttype?.name?.value ?? "",
                        mirrorBalance: val.mirrorbalance?.value ?? undefined,
                        status: val.status?.value ?? undefined,
                        ownerFirstname: val.owner?.firstname?.value ?? "",
                        ownerLastname: val.owner?.lastname?.value ?? "",
                        ownerMiddlename: val.owner?.middlename?.value ?? "",
                        ownerName: (val.owner?.firstname?.value ?? "") + " " + (val.owner?.lastname?.value ?? ""),
                        ownerId: val.owner?.id?.value ?? "",
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                        isAuthorizationRequired: val?.accountsetting?.isauthorizationrequired,
                        isLinkingEnable: val?.accountsetting?.islinkingenable?.value,
                        isLinkingMetadataSet: val?.accountsetting?.islinkingmetadataset?.value,
                        transferControl: val?.accountsetting?.tansfercontrol?.value ,


                    }
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.toAccountSet.numberOfResults) {
                    state.toAccountSet.hasMore = false;
                }
                state.toAccountSet.items = state.toAccountSet.items.concat(r);
            } else {
                state.toAccountSet.hasMore = false;

            }
            state.toAccountSet.isFetching = false

        })
        builder.addCase(getToAccounts.rejected, (state, action) => {
            state.toAccountSet.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getToAccounts.pending, (state, action) => {
            state.toAccountSet.isFetching = true;
            // state.message = undefined;
        })


    

        builder.addCase(getCurrencyPairs.fulfilled, (state, { payload }) => {
            if (payload) {
                var r = (payload?.success?.currencypairsList.map(val => {
                    let rc = {
                        id: val.id,
                        name: val.name?.value,
                        longName: "",
                        iconSource: val.iconsource?.value ?? "",
                        categoryId: val.category?.id?.value ?? "",
                        categoryNameAr: val.category?.namear?.value ?? "",
                        categoryNameEn: val.category?.nameen?.value ?? "",
                        buyPriceEquation: val.pricetrigger?.buypriceequation?.value ?? "",
                        sellPriceEquation: val.pricetrigger?.sellpriceequation?.value ?? "",
                        tradingPriceEquation: val.pricetrigger?.tradingpriceequation?.value ?? "",
                        isPriceTriggerActive: val.pricetrigger?.isactive?.value ,
                        mainCurrencyPair: val.pricetrigger?.main?.id?.value ?? "",
                        mainCurrencyPairName: val.pricetrigger?.main?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyId: val.pricetrigger?.main?.base?.id?.value ?? "",
                        mainCurrencyPairBaseCurrencyName: val.pricetrigger?.main?.base?.name?.value ?? "",
                        mainCurrencyPairBaseCurrencyCode: val.pricetrigger?.main?.base?.code?.value ?? "",
                        mainCurrencyPairBaseCurrencyDecimalPlaces: val.pricetrigger?.main?.base?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairBaseCurrencyForm: val.pricetrigger?.main?.base?.form?.value ?? undefined,
                        mainCurrencyPairBaseCurrencySymbol: val.pricetrigger?.main?.base?.symbol?.value ?? "",
                        mainCurrencyPairQuoteCurrencyId: val.pricetrigger?.main?.quote?.id?.value ?? "",
                        mainCurrencyPairQuoteCurrencyName: val.pricetrigger?.main?.quote?.name?.value ?? "",
                        mainCurrencyPairQuoteCurrencyCode: val.pricetrigger?.main?.quote?.code?.value ?? "",
                        mainCurrencyPairQuoteCurrencyDecimalPlaces: val.pricetrigger?.main?.quote?.decimalplaces?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencyForm: val.pricetrigger?.main?.quote?.form?.value ?? undefined,
                        mainCurrencyPairQuoteCurrencySymbol: val.pricetrigger?.main?.quote?.symbol?.value ?? "",

                        buyPrice: val.buyprice?.value,
                        sellPrice: val.sellprice?.value,
                        tradingPrice: val.tradingprice?.value,
                        isActive: val.isactive?.value,
                        baseCurrencyId: val.base?.id?.value ?? "",
                        baseCurrencyName: val.base?.name?.value ?? "",
                        baseCurrencyCode: val.base?.code?.value ?? "",
                        baseCurrencyDecimalPlaces: val.base?.decimalplaces?.value ?? undefined,
                        baseCurrencyForm: val.base?.form?.value ?? undefined,
                        baseCurrencySymbol: val.base?.symbol?.value ?? "",
                        quoteCurrencyId: val.quote?.id?.value ?? "",
                        quoteCurrencyName: val.quote?.name?.value ?? "",
                        quoteCurrencyCode: val.quote?.code?.value ?? "",
                        quoteCurrencyDecimalPlaces: val.quote?.decimalplaces?.value ?? undefined,
                        quoteCurrencyForm: val.quote?.form?.value ?? undefined,
                        quoteCurrencySymbol: val.quote?.symbol?.value ?? "",
                        priceUpdate: formatDate(timestampToDate(val.priceupdate?.seconds, val.priceupdate?.nanos)),
                        createdDate: formatDate(timestampToDate(val.createddate?.seconds, val.createddate?.nanos)),
                        addedBy: (val.createdby?.firstname?.value ?? "") + " " + (val.createdby?.lastname?.value ?? ""),
                        addedById: val.createdby?.id?.value,
                    }

                    if (rc.name == undefined) {
                        rc.name = (rc?.baseCurrencyName == "" ? rc?.baseCurrencyId : rc?.baseCurrencyName + " (" + rc?.baseCurrencyCode + ")") + " / " + (rc?.quoteCurrencyName == "" ? rc?.quoteCurrencyId : rc?.quoteCurrencyName + " (" + rc?.quoteCurrencyCode + ")")
                    }
                    rc.longName = rc.name;
                    return rc;
                }
                ) as any[])
                //r = (r.concat(...Array(9).fill(r)));
                if (r.length < state.currencyPairs.numberOfResults) {
                    state.currencyPairs.hasMore = false;
                }
                state.currencyPairs.items = state.currencyPairs.items.concat(r);
                //console.log(state.items)

            } else {
                state.currencyPairs.hasMore = false;
            }
            state.currencyPairs.isFetching = false

        })
        builder.addCase(getCurrencyPairs.rejected, (state, action) => {
            state.currencyPairs.isFetching = false;
            if (action.payload) {
                //state.message = action.payload;
            }
        })
        builder.addCase(getCurrencyPairs.pending, (state, action) => {
            state.currencyPairs.isFetching = true;
            // state.message = undefined;
        })

        builder.addCase(getTransactionSummary.fulfilled, (state, { payload }) => {
            state.isLoading = false
            if (payload) {
                var r = (payload.response?.success?.transactionsummaryList.map(val => {

                    return {
                        amount: val?.amount?.value,
                        creditorfeeValue: val?.creditorfee?.fee?.value,
                        creditorfeeId: val?.creditorfee?.id?.value,
                        creditorfeeName: val?.creditorfee?.name?.value,
                        debtorfeeValue: val?.debtorfee?.fee?.value,
                        debtorfeeId: val?.debtorfee?.id?.value,
                        debtorfeeName: val?.debtorfee?.name?.value,
                        currencyId: val?.currency?.id?.value ?? "",
                        currencyName: val?.currency?.name?.value ?? "",
                        currencyCode: val?.currency?.code?.value ?? "",
                        currencyDecimalPlaces: val?.currency?.decimalplaces?.value ?? undefined,
                        currencyForm: val?.currency?.form?.value ?? undefined,
                        currencySymbol: val?.currency?.symbol?.value ?? "",
                        price: payload?.metadata?.price,
                        total: payload?.metadata?.total,
                    }
                }
                ) as any[])

                //r = (r.concat(...Array(9).fill(r)));
                state.confirmation = r;

            }

        })
        builder.addCase(getTransactionSummary.rejected, (state, action) => {
            state.isLoading = false;
            if (action.payload?.response.data == 403) {
                var r = (action.payload?.metadata?.transactions?.map((val: any) => {
                    return {
                        amount: val?.amount,
                        price: val?.price,
                        total: val?.total,

                    }
                }
                ) as any[])
                state.confirmation = r;
                return;
            }
            if (action.payload) {
                state.message = action.payload.response;
            }
        })
        builder.addCase(getTransactionSummary.pending, (state, action) => {
            state.isLoading = true;
            state.message = undefined;
        })
    }
})

export const { reset, dismissMessage, setStage1, setMessage, resetMessage, resetFromAccountSet,  resetToAccountSet, setIsFilteredFromAccountSet, setIsFilteredToAccountSet, setSelectedFromAccountItems, setSelectedToAccountItems, resetCurrencyPairs, setIsFilteredCurrencyPairsSet, setSelectedCurrencyPairItems } = placeOrderFormSlice.actions

export default placeOrderFormSlice.reducer


