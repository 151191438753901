import { ComboBox, DefaultButton, DialogFooter, Icon, IconButton, IPersonaProps, IPickerItemProps, IStackTokens, ISuggestionItemProps, ISuggestionModel, ISuggestionsProps, ITag, Label, MessageBar, MessageBarType, Modal, NormalPeoplePicker, PeoplePickerItem, Persona, PersonaSize, PrimaryButton, Spinner, SpinnerSize, Stack, TagItemSuggestion, TagPicker, Text, TextField, Toggle, TooltipHost, ValidationState } from "@fluentui/react";
import { useId, useBoolean } from '@fluentui/react-hooks';
import { Field, FieldArray, FieldProps, Form, Formik } from "formik";
import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks";
import { add, dismissMessage, get, getAccountTypes, getClinetUsers, getCurrencies, getLimitations, reset, resetCurrencies, resetLimitations, resetAccountTypes, setIsFilteredAccountTypesSet, resetUsers, setIsFilteredCurrenciesSet, setIsFilteredOwnersSet, setSelectedAccountTypeItems, setSelectedCurrencyItems, setIsFilteredLimitationsSet, setSelectedLimitationItems, setStage1, setSelectedUserItems, update } from "./AccountsFromSlice";
import * as Yup from 'yup';
import { DynamicInputField, InputField } from "../../controls/Controls";
import { inputs } from "../../../../app/Validation";

import { ClientReadableStream, RpcError, Status } from "grpc-web";
import { PayloadAction } from "@reduxjs/toolkit";
import { ApiMessage, APIRequest, clearSession, getHeaders, getSession, saveSession } from "../../../../app/Api";
import { Message } from "../../../common/Message/Message";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseFormProps, FormType, InputFormProps } from "../../FormProps";
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb";
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog";
import { formatDate, timestampToDate } from "../../../../app/Helpers";
import { AuthenticateReply } from "../../../../repository/UserManagement/authentication_pb";
import { DynamicField } from "../../../common/DynamicFields/DynamicFields";
import { ExtraField } from "../../../../repository/Accountant/common_pb";
import { TableState } from "../../../common/Table/TableSate";
import { AccountStatus, LimitationType, Operations } from "../../../../app/Enums";
import { GetAccountTypesRequest } from "../../../../repository/Accountant/account_type_pb"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { GetUsersRequest } from "../../../../repository/UserManagement/Customers/user_pb"
import { GetLimitationsRequest } from "../../../../repository/Accountant/limitation_pb"
import { GetAccountRequest, GetAccountResponse, OpenAccountRequest, UpdateAccountResponse, OpenAccountResponse, UpdateAccountRequest } from "../../../../repository/Accountant/account_pb";
import { List } from "../../../common/List/List";
import { Popup } from "../../../common/Popup/Popup";
import { UsersPage } from "../../../pages/Customers/Users/UsersPage";
import { LimitationsPage } from "../../../pages/Accountant/Limitations/LimitationsPage";
import { AccountTypesPage } from "../../../pages/Accountant/AccountTypes/AccountTypesPage";
import { CurrenciesPage } from "../../../pages/Accountant/Currencies/CurrenciesPage";

let req: OpenAccountRequest;
let updateReq: UpdateAccountRequest;
let limitationReq: GetLimitationsRequest;
let accountTypesReq: GetAccountTypesRequest;
let currenciesReq: GetCurrenciesRequest;
let clintsReq: GetUsersRequest;

let promise: any;
let getFilterPromise: any;
let org: number = -1;
let current: AuthenticateReply.AsObject;

export const AccountsForm: React.FunctionComponent<BaseFormProps & IStackTokens & InputFormProps> = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipId = useId('tooltipId');
    const [searchParams] = useSearchParams();
    const [currentAction, setCurrenctAction] = useState(0)


    const state: { isLoading: boolean, message: ApiMessage | undefined, stage1: any, owners: TableState, limitations: TableState, currencies: TableState, accountTypes: TableState } = useAppSelector((state) => {

        return {
            isLoading: state.accountsForm.isLoading, message: state.accountsForm.message, stage1: state.accountsForm.stage1,
            accountTypes: state.accountsForm.accountTypes,
            currencies: state.accountsForm.currencies,
            limitations: state.accountsForm.limitations,
            owners: state.accountsForm.owners,

        }
    })

    useEffect(() => {
        var l = searchParams.get('org');
        req = new OpenAccountRequest();
        updateReq = new UpdateAccountRequest()
        accountTypesReq = new GetAccountTypesRequest();
        currenciesReq = new GetCurrenciesRequest();
        clintsReq = new GetUsersRequest();
        limitationReq = new GetLimitationsRequest();

        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                accountTypesReq.setOrganization(wrapper)
                currenciesReq.setOrganization(wrapper)
                clintsReq.setOrganization(wrapper)
                limitationReq.setOrganization(wrapper)

            }
        }

        if (props.type == FormType.EDIT) {
            if (props.fetchData) {
                let r = new GetAccountRequest()
                r.setId(props?.renderObject?.internalId);
                promise?.abort()
                promise = dispatch(get({ body: r, headers: getHeaders() }))
            } else {
                dispatch(setStage1(props?.renderObject))
            }
        }
        const boolv = new BoolValue();
        boolv.setValue(true);
        accountTypesReq.setNumofresults(state.accountTypes.numberOfResults)
        accountTypesReq.setOrder(state.accountTypes.isDescending)
        accountTypesReq.setIsavailable(boolv)
        currenciesReq.setNumofresults(state.currencies.numberOfResults)
        currenciesReq.setOrder(state.currencies.isDescending)
        currenciesReq.setIsavailable(boolv)
        limitationReq.setNumofresults(state.limitations.numberOfResults)
        limitationReq.setOrder(state.limitations.isDescending)
        const type = new Int32Value();
        type.setValue(LimitationType.ACCOUNT_LIMITATION);
        limitationReq.setType(type)
        limitationReq.setIsavailable(boolv)
        clintsReq.setNumofresults(state.owners.numberOfResults)
        clintsReq.setOrder(state.owners.isDescending)

        if (props.renderObject?.currencyId)
            dispatch(setSelectedCurrencyItems(([{ id: props.renderObject.currencyId, name: props.renderObject.currencyName }])))

        if (props.renderObject?.accountTypeId)
            dispatch(setSelectedAccountTypeItems(([{ id: props.renderObject.accountTypeId, name: props.renderObject.accountTypeName }])))

        if (props.renderObject?.ownerId)
            dispatch(setSelectedUserItems(([{ id: props.renderObject.ownerId, name: props.renderObject.ownerName }])))

        if (props.renderObject?.limitationId)
            dispatch(setSelectedLimitationItems(([{ id: props.renderObject.limitationId, name: props.renderObject.limitationName }])))


        current = getSession();
        if (!current) {
            clearSession();
            navigate("/login");
        }
        return () => { //clean up
            promise?.abort();
            dispatch(reset());
        }
    }, [])

    const accountTypesSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('accountTypes'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.accountTypes.hasMore && !state.accountTypes.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.accountTypes.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedAccountTypeItems([state.accountTypes.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                accountTypesReq.setNextto(wrapper)
                getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
            }
        },
        suggestions: state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false,
        isSearching: state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false,


    };
    const onAccountTypesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetAccountTypes())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        accountTypesReq.setSearch(wrapper)
        accountTypesReq.setNextto(undefined)
        dispatch(setIsFilteredAccountTypesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
        return [];

    }


    const ownersSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('clients'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.owners.hasMore && !state.owners.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.owners.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedUserItems([state.owners.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.owners.items.length > 0 && !state.owners.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.owners.items.at(state.owners.items.length - 1).internalId);
                clintsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.owners.items.length > 0 ? state.owners.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.owners.items.length == 0 ? state.owners.isFetching : false,
        isSearching: state.owners.items.length > 0 ? state.owners.isFetching : false,


    };


    const onOwnersFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetUsers())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        clintsReq.setSearch(wrapper)
        clintsReq.setNextto(undefined)
        dispatch(setIsFilteredOwnersSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
        return [];

    }


    const onCurrenciesFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetCurrencies())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        currenciesReq.setSearch(wrapper)
        currenciesReq.setNextto(undefined)
        dispatch(setIsFilteredCurrenciesSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
        return [];

    }


    const limitationsSuggestionsProps: ISuggestionsProps<ITag> = {
        suggestionsHeaderText: t('limitations'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.limitations.hasMore && !state.limitations.isFetching,
        onRenderSuggestion: (props: ITag, suggestionItemProps: ISuggestionItemProps<ITag>) => {
            return <TagItemSuggestion>
                {props?.name ?? props?.key}
            </TagItemSuggestion>
        },
        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.limitations.items.findIndex(e => e.id == item.key)
            if (u >= 0) {
                dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                limitationReq.setNextto(wrapper)
                getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
            }
        },
        suggestions: state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : [],
        isLoading: state.limitations.items.length == 0 ? state.limitations.isFetching : false,
        isSearching: state.limitations.items.length > 0 ? state.limitations.isFetching : false,


    };
    const onLimitationsFilterChanged = (filterText: string, selectedItems?: ITag[]): ITag[] => {

        dispatch(resetLimitations())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        limitationReq.setSearch(wrapper)
        limitationReq.setNextto(undefined)
        dispatch(setIsFilteredLimitationsSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
        return [];

    }


    return (
        <>
            <Popup isOpen={(currentAction == 100)}  title={t("clients")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 101)} title={t("limitations")} onDismiss={() => { setCurrenctAction(0) }} >

                <LimitationsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedLimitationItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 102)} title={t("accountTypes")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountTypesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountTypeItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Popup isOpen={(currentAction == 103)} title={t("currencies")} onDismiss={() => { setCurrenctAction(0) }} >

                <CurrenciesPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedCurrencyItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: (props.renderObject?.name) ? props.renderObject?.name : "",
                    code: (props.renderObject?.code) ? props.renderObject?.code : "",
                    currency: undefined,
                    accountType: undefined,
                    limitation: undefined,
                    owner: undefined,
                    extraFields: (state.stage1?.extraFields) ? state.stage1?.extraFields as DynamicField[] : [] as DynamicField[],

                }}

                validationSchema={Yup.object({
                    name: inputs.accountName,
                    code: inputs.accountCode,

                    extraFields: inputs.extraFields,

                })}

                onSubmit={(values, actions) => {
                    if (props.type == FormType.ADD) {

                        let accountTypes = state.accountTypes.selected.at(0);
                        let owners = state.owners.selected.at(0);
                        let currencies = state.currencies.selected.at(0);
                        let limitations = state.limitations.selected.at(0);

                        req.setAccounttype(accountTypes?.id);
                        req.setOwner(owners?.internalId);
                        req.setCurrency(currencies?.id);
                        req.setLimitation(limitations?.id);

                        req.setCode(values.code?.trim())
                        req.setName(values.name?.trim())

                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        req.setExtrafieldsList(extraField)
                        promise = dispatch(add({ body: req, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: OpenAccountResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            id: res.success?.id,
                                            internalId: res.success?.internalid,
                                            name: values.name,
                                            code: values.code,
                                            currencyId: currencies?.id,
                                            currencyName: currencies?.name,
                                            currencyCode: currencies?.code,
                                            currencyDecimalPlaces: currencies?.decimalPlaces,
                                            currencyForm: currencies?.form,
                                            currencySymbol: currencies?.symbol,
                                            accountTypeId: accountTypes.id,
                                            accountTypeName: accountTypes.name,
                                            status: AccountStatus.ACTIVE,
                                            ownerFirstname: owners?.firstname,
                                            ownerLastname: owners?.lastname,
                                            ownerMiddlename: owners?.middlename,
                                            ownerName: owners?.name,
                                            ownerId: owners?.internalId,
                                            limitationId: limitations?.id,
                                            limitationName: limitations?.name,
                                            mirrorBalance: "0",
                                            createdDate: formatDate(timestampToDate(res.success?.createddate?.seconds, res.success?.createddate?.nanos)),
                                            addedBy: (current.profile?.firstname?.value ?? "") + " " + (current.profile?.lastname?.value ?? ""),
                                            addedById: current.profile?.id?.value,
                                            isAuthorizationRequired: false,
                                            isLinkingEnable: false,
                                            isLinkingMetadataSet: false
                    
                                        });
                                    }
                                }
                                actions.setSubmitting(false)


                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    } else {
                        updateReq.setId(props.renderObject?.internalId);
                        updateReq.setCode(values.code)
                        updateReq.setName(values.name)
                        const extraField: ExtraField[] = [];
                        const count: Number = 1;
                        values.extraFields.forEach((e: DynamicField) => {
                            const r = new ExtraField();
                            r.setKey(e.key);
                            r.setLabel(e.label?.trim() ?? "")
                            r.setType(1)
                            r.setValue(e.value.trim())
                            extraField.push(r)
                        })
                        updateReq.setExtrafieldsList(extraField)
                        promise = dispatch(update({ body: updateReq, headers: getHeaders() }))
                        promise.unwrap()
                            .then((res: UpdateAccountResponse.AsObject) => {
                                if (res) {
                                    if (props?.onSuccess) {
                                        props?.onSuccess({
                                            ...props.renderObject,
                                            name: values.name,
                                            code: values.code,
                                            extraFields: values.extraFields
                                        });
                                    }
                                }
                                actions.setSubmitting(false)
                            })
                            .catch((error: ApiMessage) => {
                                actions.setSubmitting(false)
                            })
                    }
                }}

            >
                {formkikProps => (
                    <Form >
                        <Stack tokens={{ childrenGap: props.childrenGap, maxWidth: props.maxWidth, padding: props.padding, maxHeight: props.maxHeight }}    >
                            {state.message != undefined ? (state.message.data != 401) ? <Message
                                body={state.message.body}
                                title={state.message.title}
                                data={state.message.data}
                                onDismiss={() => { dispatch(dismissMessage()) }}
                                type={state.message.type}
                            /> :
                                <SessionExpiredDialog />
                                : null
                            }
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }}>
                                <Stack.Item grow>
                                    <Field name="name" label={t("accountName")} placeholder={t("accountNameHint")} component={InputField} disabled={state.isLoading} autoFocus required maxLength={50} />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Field name="code" label={t("accountCode")} placeholder={"ACC-01"} component={InputField} disabled={state.isLoading} maxLength={25} />
                                </Stack.Item>

                            </Stack>

                            {props.type == FormType.ADD ? <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("accountType")}</Label>
                                <List
                                    inputProps={{ placeholder: t("accountType"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('accountTypes')}
                                    isLoading={state.accountTypes.items.length == 0 ? state.accountTypes.isFetching : false}
                                    isSearching={state.accountTypes.items.length > 0 ? state.accountTypes.isFetching : false}
                                    moreSuggestionsAvailable={state.accountTypes.hasMore && !state.accountTypes.isFetching}
                                    suggestions={state.accountTypes.items.length > 0 ? state.accountTypes.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.accountTypes.items.length > 0 && !state.accountTypes.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.accountTypes.items.at(state.accountTypes.items.length - 1).id);
                                            accountTypesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.accountTypes.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedAccountTypeItems([state.accountTypes.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={false}
                                    selectedItems={state.accountTypes.selected.length > 0 ? state.accountTypes.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedAccountTypeItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetAccountTypes())
                                        accountTypesReq.setSearch(undefined)
                                        accountTypesReq.setNextto(undefined)
                                        dispatch(setIsFilteredAccountTypesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getAccountTypes({ body: accountTypesReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onAccountTypesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(102)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}

                            {props.type == FormType.ADD ? <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("currency")}</Label>
                                <List
                                    inputProps={{ placeholder: t("currency"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('currencies')}
                                    isLoading={state.currencies.items.length == 0 ? state.currencies.isFetching : false}
                                    isSearching={state.currencies.items.length > 0 ? state.currencies.isFetching : false}
                                    moreSuggestionsAvailable={state.currencies.hasMore && !state.currencies.isFetching}
                                    suggestions={state.currencies.items.length > 0 ? state.currencies.items.map(e => { return { item: { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.currencies.items.length > 0 && !state.currencies.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.currencies.items.at(state.currencies.items.length - 1).id);
                                            currenciesReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.currencies.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedCurrencyItems([state.currencies.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={true}
                                    selectedItems={state.currencies.selected.length > 0 ? state.currencies.selected.map(e => { return { key: e.id, text: e.name, imageInitials: e?.symbol, secondaryText: e?.code, imageUrl: e?.icon } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedCurrencyItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetCurrencies())
                                        currenciesReq.setSearch(undefined)
                                        currenciesReq.setNextto(undefined)
                                        dispatch(setIsFilteredCurrenciesSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getCurrencies({ body: currenciesReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onCurrenciesFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(103)

                                            }
                                        }]}
                                />
                            </Stack> : undefined}

                            {props.type == FormType.ADD ? <Stack>

                                <Label required
                                    disabled={state.isLoading}>{t("owner")}</Label>
                                <List
                                    isPeoplePicker={true}
                                    suggestionsHeaderText={t('clients')}
                                    isLoading={state.owners.items.length == 0 ? state.owners.isFetching : false}
                                    isSearching={state.owners.items.length > 0 ? state.owners.isFetching : false}
                                    moreSuggestionsAvailable={state.owners.hasMore && !state.owners.isFetching}
                                    suggestions={state.owners.items.length > 0 ? state.owners.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.owners.items.length > 0 && !state.owners.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.owners.items.at(state.owners.items.length - 1).internalId);
                                            clintsReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.owners.items.findIndex(e => e.internalId == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedUserItems([state.owners.items.at(u)]))
                                        }

                                    }}
                                    inputProps={{ placeholder: t("owner"), required: true }}

                                    disabled={state.isLoading}
                                    selectedItems={state.owners.selected.length > 0 ? state.owners.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } as IPersonaProps }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedUserItems([]))
                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetUsers())
                                        clintsReq.setSearch(undefined)
                                        clintsReq.setNextto(undefined)
                                        dispatch(setIsFilteredOwnersSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                        return []

                                    }}
                                    onFilterChanged={onOwnersFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(100)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}
                            {props.type == FormType.ADD ? <Stack>
                                <Label required
                                    disabled={state.isLoading}>{t("limitation")}</Label>
                                <List
                                    inputProps={{ placeholder: t("limitation"), required: true }}
                                    disabled={state.isLoading}
                                    suggestionsHeaderText={t('limitations')}
                                    isLoading={state.limitations.items.length == 0 ? state.limitations.isFetching : false}
                                    isSearching={state.limitations.items.length > 0 ? state.limitations.isFetching : false}
                                    moreSuggestionsAvailable={state.limitations.hasMore && !state.limitations.isFetching}
                                    suggestions={state.limitations.items.length > 0 ? state.limitations.items.map(e => { return { item: { name: e.name, key: e.id } } as ISuggestionModel<ITag> }) : []}
                                    onGetMoreResults={() => {
                                        if (state.limitations.items.length > 0 && !state.limitations.isFetching) {
                                            const wrapper = new StringValue();
                                            wrapper.setValue(state.limitations.items.at(state.limitations.items.length - 1).id);
                                            limitationReq.setNextto(wrapper)
                                            getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
                                        }
                                    }}
                                    onSuggestionClick={(ev?, item?: any, index?: number) => {
                                        var u = state.limitations.items.findIndex(e => e.id == item.key)
                                        if (u >= 0) {
                                            dispatch(setSelectedLimitationItems([state.limitations.items.at(u)]))
                                        }
                                    }}
                                    isPeoplePicker={false}
                                    selectedItems={state.limitations.selected.length > 0 ? state.limitations.selected.map(e => { return { name: e.name, key: e.id } as ITag }) : []}
                                    onChange={(e) => {
                                        dispatch(setSelectedLimitationItems([]))

                                    }}
                                    onEmptyInputFocus={() => {
                                        dispatch(resetLimitations())
                                        limitationReq.setSearch(undefined)
                                        limitationReq.setNextto(undefined)
                                        dispatch(setIsFilteredLimitationsSet(false))
                                        getFilterPromise?.abort()
                                        getFilterPromise = dispatch(getLimitations({ body: limitationReq, headers: getHeaders() }))
                                        return []
                                    }}
                                    onFilterChanged={onLimitationsFilterChanged}
                                    endButtons={[
                                        {
                                            title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                            onClick: () => {
                                                setCurrenctAction(101)

                                            }
                                        }]}
                                />

                            </Stack> : undefined}


                            <Label disabled={state.isLoading}>{
                                <div>
                                    {t("extraFields") + ' '}
                                    <TooltipHost content={t("extraInformationDescription")}>
                                        <Icon iconName="Info" aria-label={t("extraFields")} />
                                    </TooltipHost>
                                </div>
                            }</Label>
                            <FieldArray
                                name="extraFields"
                                validateOnChange={false}

                            >
                                {arrayHelpers => {
                                    let r = [] as React.ReactNode[];
                                    if (formkikProps.values.extraFields && formkikProps.values.extraFields.length > 0) {
                                        r = formkikProps.values.extraFields.map((o, index) => {
                                            if (o.type == 1) {
                                                return (<Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: props.childrenGap }} key={index}>
                                                    {o.label != undefined ? <Stack.Item grow> <DynamicInputField name={`extraFields[${index}].label`} placeholder={t("extraFieldLabel")} disabled={state.isLoading}
                                                        maxLength={50} autoFocus={(o as any)?.autoFocus} /> </Stack.Item> : null}
                                                    <Stack.Item grow>  <DynamicInputField name={`extraFields[${index}].value`} placeholder={t("extraFieldValue")} disabled={state.isLoading}
                                                        maxLength={50}
                                                    /></Stack.Item>

                                                    <IconButton disabled={state.isLoading}
                                                        iconProps={{ iconName: "remove" }} onClick={() => {
                                                            arrayHelpers.remove(index)
                                                        }} />
                                                </Stack>)
                                            }
                                        }
                                        )
                                    }
                                    if (formkikProps.values.extraFields.length <= 4) {
                                        r.push(< IconButton disabled={state.isLoading}
                                            key={"addButton"} iconProps={{ iconName: "add" }} onClick={() => {
                                                arrayHelpers.push({ key: (formkikProps.values.extraFields.length + 1) + "", label: "", type: 1, value: "", autoFocus: true })
                                            }} />)
                                    }
                                    return r;
                                }
                                }

                            </FieldArray>


                        </Stack>
                        <DialogFooter>
                            <PrimaryButton disabled={state.isLoading} text={state.isLoading ? undefined : props.type == FormType.ADD ? t("add") : t("edit")} type="submit" >

                                <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isLoading ? "block" : "none") } }} />

                            </PrimaryButton>
                            <DefaultButton disabled={state.isLoading} text={props.type == FormType.ADD ? t("discard") : t("cancel")} onClick={() => {
                                if (props.onCancel) {
                                    formkikProps.resetForm()
                                    props.onCancel()
                                }
                            }} />
                        </DialogFooter>



                    </Form>
                )}
            </Formik>
        </>
    );

}

