import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DialogFooter, DatePicker, DayOfWeek, defaultDatePickerStrings, Label, CheckboxVisibility, Separator, ConstrainMode } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { reset, dismissMessage, getItems, resetSet, getAccounts, resetAccountsSet, setMessage, setIsFilteredAccountSet, setSelectedAccountItems, exportReport } from "./AccountBalanceReportPageSlice"
import { ApiMessage, getHeaders } from "../../../../app/Api"
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../../app/Helpers"
import { TableState } from "../../../common/Table/TableSate"
import { Message } from "../../../common/Message/Message"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../common/Popup/Popup"
import { GetCurrenciesRequest } from "../../../../repository/Accountant/currency_pb"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../PageProps"
import { List } from "../../../common/List/List"
import { ExportAccountBalanceReportReplay, ExportAccountBalanceReportRequest, GetAccountBalanceReportRequest, GetTreasuryBalanceReportRequest } from "../../../../repository/Accountant/report_pb"
import report from "../../../../repository/Accountant/report_pb"

import { GetTreasuriesRequest } from "../../../../repository/Accountant/treasury_pb"
import { TreasuriesPage } from "../../Accountant/Treasuries/TreasuriesPage"
import { CurrenciesPage } from "../../Accountant/Currencies/CurrenciesPage"
import moment from "moment"
import { Form, Formik } from "formik"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import { AccountStatus, TreasuryStatus } from "../../../../app/Enums"
import { inputs } from "../../../../app/Validation"
import * as Yup from 'yup';
import { GetAccountsRequest } from "../../../../repository/Accountant/account_pb"
import { AccountsPage } from "../../Accountant/Accounts/AccountsPage"
import { TextView } from "../../../common/DataView/TextView"

let req: GetAccountBalanceReportRequest;
let getAccountsReq: GetAccountsRequest;

let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;
let exportReq: ExportAccountBalanceReportRequest;

const key = "reports/accountBalanceReport"
export const AccountBalanceReportPage: React.FunctionComponent<PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        accounts: TableState,
        isChangeStateLoading: boolean
        totals: any

    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.accountBalanceReportPage.isChangeStateLoading,
            set: state.accountBalanceReportPage.set,
            message: state.accountBalanceReportPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            accounts: state.accountBalanceReportPage.accounts,
            totals: state.accountBalanceReportPage.totals

        }
    })

    const [currentAction, setCurrenctAction] = useState(0)
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 1);
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});


    useEffect(() => {
        req = new GetAccountBalanceReportRequest();
        getAccountsReq = new GetAccountsRequest();

        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                getAccountsReq.setOrganization(wrapper)

            }
        }

        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }

        const boolv = new BoolValue();
        boolv.setValue(true);

        const statt = new Int32Value();
        statt.setValue(AccountStatus.ACTIVE);

        getAccountsReq.setNumofresults(state.accounts.numberOfResults)
        getAccountsReq.setOrder(state.accounts.isDescending)
        getAccountsReq.setStatus(statt)



        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const c: IColumn[] = [


        {
            key: 'date',
            name: t("date"),
            minWidth: 50,
            maxWidth: 140,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.date != undefined)
                    return <Text>{(item?.date)}</Text>; //{item.createdDate}
            },
        },

        {
            key: 'openningBalance',
            name: t("openningBalance"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.openningBalance != undefined)
                    return <Text>{currencyFormatter(item?.openningBalance, { significantDigits: item?.currency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;
            },
        },
        {
            key: 'totalCredit',
            name: t("totalCredit"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalCredit != undefined)
                    return <Text>{currencyFormatter(item?.totalCredit, { significantDigits: item?.currency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;
            },
        },

        {
            key: 'totalDebit',
            name: t("totalDebit"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalDebit != undefined)
                    return <Text>{currencyFormatter(item?.totalDebit, { significantDigits: item?.currency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;

            },
        },
        {
            key: 'closingBalance',
            name: t("closingBalance"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.closingBalance != undefined)
                    return <Text>{currencyFormatter(item?.closingBalance, { significantDigits: item?.currency?.decimalPlaces ?? 9, symbol: item?.currency?.symbol })}</Text>;
            },
        },

        {
            key: 'totalNumberOfCreditOperations',
            name: t("totalNumberOfCreditOperations"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalNumberOfCreditOperations != undefined)
                    return <Text>{item?.totalNumberOfCreditOperations}</Text>;
            },
        },

        {
            key: 'totalNumberOfDebitOperations',
            name: t("totalNumberOfDebitOperations"),
            minWidth: 50,
            maxWidth: 110,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.totalNumberOfDebitOperations != undefined)
                    return <Text>{item?.totalNumberOfDebitOperations}</Text>;
            },
        },


    ];







    const onAccountsFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetAccountsSet())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        getAccountsReq.setSearch(wrapper)
        getAccountsReq.setNextto(undefined)

        dispatch(setIsFilteredAccountSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
        return [];

    }

    return (
        <Stack >

            <Popup isOpen={(currentAction == 1000)} title={t("accounts")} onDismiss={() => { setCurrenctAction(0) }} >

                <AccountsPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedAccountItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>

            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }


            <Formik
                enableReinitialize={true}
                initialValues={{
                    from: moment(fromDate, 'YYYY-MM-DD').startOf('day').toDate(),
                    to: moment(new Date(), 'YYYY-MM-DD').endOf('day').toDate(),
                    group: { key: 0, text: t("daily") },


                }}


                onSubmit={(values, actions) => {

                    let account = state.accounts.selected.at(0);

                    req.setAccount(account.internalId);
                    const wrapper = new Timestamp();
                    wrapper.fromDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate())
                    req.setFrom(wrapper)

                    const wrapperTo = new Timestamp();
                    wrapperTo.fromDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate())
                    req.setTo(wrapperTo)
                    req.setGroupby(Number(values.group.key))

                    exportReq = new ExportAccountBalanceReportRequest();

                    exportReq.setAccount(req.getAccount());
                    exportReq.setFrom(req.getFrom())
                    exportReq.setTo(req.getTo())
                    exportReq.setGroupby(req.getGroupby())


                    const labels: report.Label[] = [];
                    let r = new report.Label();
                    let value = new StringValue();
                    value.setValue(formatDate(moment(values.from, 'YYYY-MM-DD').startOf('day').toDate()))
                    r.setLabelvalue(t("from"));
                    r.setValue(value)
                    r.setPropartyname("from")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(formatDate(moment(values.to, 'YYYY-MM-DD').endOf('day').toDate()))
                    r.setLabelvalue(t("to"));
                    r.setValue(value)
                    r.setPropartyname("to")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(values.group.text)
                    r.setLabelvalue(t("grouping"));
                    r.setValue(value)
                    r.setPropartyname("groupby")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue(account.name + " - " + account.id)
                    r.setLabelvalue(t("account"));
                    r.setValue(value)
                    r.setPropartyname("account")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("generatedDate"));
                    r.setPropartyname("generateddate")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("generatedBy"));
                    r.setPropartyname("generatedby")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("date"));
                    r.setPropartyname("date")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("openningBalance"));
                    r.setPropartyname("openningBalance")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("totalCredit"));
                    r.setPropartyname("TotalCredit")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("totalDebit"));
                    r.setPropartyname("TotalDebit")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("closingBalance"));
                    r.setPropartyname("ClosingBalance")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("totalNumberOfCreditOperations"));
                    r.setPropartyname("TotalNumberOfCreditOperations")
                    labels.push(r)

                    r = new report.Label();
                    r.setLabelvalue(t("totalNumberOfDebitOperations"));
                    r.setPropartyname("TotalNumberOfDebitOperations")
                    labels.push(r)

                    r = new report.Label();
                    value = new StringValue();
                    value.setValue((((new Date()).getTimezoneOffset()) * -1)+"")
                    r.setValue(value);
                    r.setPropartyname("timezone")
                    labels.push(r)

                    exportReq.setLabelsList(labels)
                    getItemsPromise?.abort()
                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))


                }}

            >
                {formkikProps => (
                    <Form >

                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>
                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>
                                <Stack.Item grow>
                                    <DatePicker
                                        isRequired
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        label={t("from")}
                                        placeholder={t("from")}
                                        disabled={state.set.isFetching}
                                        value={formkikProps.values.from}
                                        ariaLabel={t("from")}
                                        formatDate={(e) => {
                                            return formatDate(e, 'YYYY-MM-DD')
                                        }}
                                        parseDateFromString={(date) => {
                                            return moment(date, 'YYYY-MM-DD').startOf('day').toDate();
                                        }}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        onSelectDate={(date: Date | null | undefined) => {

                                            formkikProps.setFieldValue("from", date)
                                        }}

                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <DatePicker
                                        isRequired
                                        firstDayOfWeek={DayOfWeek.Sunday}
                                        label={t("to")}
                                        placeholder={t("to")}
                                        disabled={state.set.isFetching}
                                        value={formkikProps.values.to}
                                        ariaLabel={t("to")}
                                        formatDate={(e) => {
                                            return formatDate(e, 'YYYY-MM-DD')
                                        }}
                                        parseDateFromString={(date) => {
                                            return moment(date, 'YYYY-MM-DD').endOf('day').toDate();
                                        }}
                                        // DatePicker uses English strings by default. For localized apps, you must override this prop.
                                        strings={defaultDatePickerStrings}
                                        onSelectDate={(date: Date | null | undefined) => {
                                            if (date != undefined)
                                                if (moment(date).isSameOrAfter(formkikProps.values.from)) {
                                                    formkikProps.setFieldValue("to", date)
                                                } else {
                                                    formkikProps.setFieldValue("to", new Date())
                                                }
                                        }}

                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <ComboBox
                                        label={t("grouping")}
                                        required
                                        allowFreeform={true}
                                        autoComplete={'on'}
                                        disabled={state.set.isFetching}
                                        options={[{ key: 3, text: t("hourly") }, { key: 0, text: t("daily") }, { key: 1, text: t("monthly") }, { key: 2, text: t("yearly") },]}
                                        selectedKey={formkikProps.values.group.key}
                                        onChange={(e, o) => {
                                            formkikProps.setFieldValue("group", { key: o?.key, text: o?.text })
                                        }} />
                                </Stack.Item>
                            </Stack>


                            <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: 10 }}>

                                <Stack.Item grow>
                                    <Label required disabled={state.set.isFetching}>{t("account")}</Label>
                                    <List
                                        isPeoplePicker={true}
                                        suggestionsHeaderText={t('accounts')}
                                        isLoading={state.accounts.items.length == 0 ? state.accounts.isFetching : false}
                                        isSearching={state.accounts.items.length > 0 ? state.accounts.isFetching : false}
                                        moreSuggestionsAvailable={state.accounts.hasMore && !state.accounts.isFetching}
                                        suggestions={state.accounts.items.length > 0 ? state.accounts.items.map(e => { return { item: { key: e.internalId, text: e.name+ " (#" + e.id +")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } } as ISuggestionModel<IPersonaProps> }) : []}
                                        onGetMoreResults={() => {
                                            if (state.accounts.items.length > 0 && !state.accounts.isFetching) {
                                                const wrapper = new StringValue();
                                                wrapper.setValue(state.accounts.items.at(state.accounts.items.length - 1).internalId);
                                                getAccountsReq.setNextto(wrapper)
                                                getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))
                                            }
                                        }}
                                        onSuggestionClick={(ev?, item?: any, index?: number) => {
                                            var u = state.accounts.items.findIndex(e => e.internalId == item.key)
                                            if (u >= 0) {
                                                dispatch(setSelectedAccountItems([state.accounts.items.at(u)]))
                                            }

                                        }}
                                        inputProps={{ placeholder: t("account"), required: true }}

                                        disabled={state.set.isFetching}
                                        selectedItems={state.accounts.selected.length > 0 ? state.accounts.selected.map(e => { return { key: e.internalId, text: e.name+ " (#" + e.id +")", secondaryText: e.ownerName + " | " + e.accountTypeName, imageInitials: e.currencySymbol ?? e.currencyCode } as IPersonaProps }) : []}
                                        onChange={(e) => {
                                            dispatch(setSelectedAccountItems([]))
                                        }}
                                        onEmptyInputFocus={() => {
                                            dispatch(resetAccountsSet())
                                            getAccountsReq.setSearch(undefined)
                                            getAccountsReq.setNextto(undefined)
                                            dispatch(setIsFilteredAccountSet(false))
                                            getFilterPromise?.abort()
                                            getFilterPromise = dispatch(getAccounts({ body: getAccountsReq, headers: getHeaders() }))

                                        }}
                                        onFilterChanged={onAccountsFilterChanged}
                                        endButtons={[
                                            {
                                                title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                                onClick: () => {
                                                    setCurrenctAction(1000);

                                                }
                                            }]}
                                    />

                                </Stack.Item>

                            </Stack>


                            <DialogFooter>
                                <PrimaryButton disabled={state.set.isFetching} text={state.set.isFetching ? undefined : t("generateReport")} type="submit" >

                                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                                </PrimaryButton>

                                <DefaultButton disabled={state.set.isFetching} text={t("clearFilter")} onClick={() => {
                                    formkikProps.resetForm()
                                    req.setAccount("")
                                    req.setGroupby(0)
                                    req.setFrom(undefined)
                                    req.setTo(undefined)
                                    dispatch(setSelectedAccountItems([]))
                                    dispatch(resetSet())
                                }} />
                            </DialogFooter>
                        </Stack>
                        {state.set.items.length > 0 ? <CommandBar
                            items={[
                                {
                                    key: 'export',
                                    text: t("export"),
                                    iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },
                                    title: t("export"),
                                    disabled: state.set.isFetching,
                                    commandBarButtonAs: (e) => {
                                        return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                                            <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                                        </CommandBarButton>;
                                    },
                                    subMenuProps: {
                                        items: [{
                                            key: 'exportToExcel',
                                            text: t('exportToExcel'),
                                            title: t("exportToExcel"),
                                            iconProps: { iconName: 'ExcelDocument' },

                                            onClick: () => {
                                                if (!state.isChangeStateLoading && exportReq) {
                                                    exportReq.setExporttype(1);
                                                    setCurrenctAction(1)
                                                    getItemsPromise = dispatch(exportReport({
                                                        body: exportReq, headers: getHeaders()
                                                    }))

                                                }

                                            }
                                        },
                                        {
                                            key: 'exportToCsv',
                                            text: t("exportToCsv"),
                                            title: t("exportToCsv"),

                                            iconProps: { iconName: 'ReportDocument' },
                                            onClick: () => {
                                                if (!state.isChangeStateLoading && exportReq) {
                                                    exportReq.setExporttype(2);
                                                    setCurrenctAction(1)
                                                    getItemsPromise = dispatch(exportReport({
                                                        body: exportReq, headers: getHeaders()
                                                    }))

                                                }

                                            }
                                        },

                                        ]
                                    },


                                },
                                {
                                    key: 'print',
                                    text: t("print"),
                                    iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
                                    title: t("print"),
                                    onClick: () => {
                                        handlePrint(reactToPrintContent);
                                    }
                                },

                            ]}

                        /> : undefined}

                    </Form>
                )}


            </Formik>

            <Table
                setKey="accountBalanceReport"
                items={state.set.items}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                columns={c}
                selectionMode={SelectionMode.none}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                constrainMode={ConstrainMode.unconstrained}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.fixedColumns}
                compRef={ref}
                height={state.message == undefined ? "calc(100vh - 435px)" : "calc(100vh - 484px)"}

            />
            {state.totals ? <>
                <Separator alignContent="start"><b>{t("totalPeriod")}</b></Separator>

                <Stack tokens={{ childrenGap: 10 }} horizontal>

                    <TextView label={t("totalCredit")} value={state.totals?.totalCredit != undefined? currencyFormatter(state.totals?.totalCredit, { significantDigits: state.totals?.currency?.decimalPlaces ?? 9, symbol: state.totals?.currency?.symbol }) : ""} />

                    <TextView label={t("totalDebit")} value={state.totals?.totalDebit != undefined? currencyFormatter(state.totals?.totalDebit, { significantDigits: state.totals?.currency?.decimalPlaces ?? 9, symbol: state.totals?.currency?.symbol }) : ""} />

                    <TextView label={t("totalNumberOfCreditOperations")} value={state.totals?.totalNumberOfCreditOperations != undefined? state.totals?.totalNumberOfCreditOperations + "" : ""} />
                   
                    <TextView label={t("totalNumberOfDebitOperations")} value={state.totals?.totalNumberOfDebitOperations != undefined? state.totals?.totalNumberOfDebitOperations + "" : ""} />

                </Stack>
            </> : undefined
            }

        </Stack >



    );
}




