import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, IPeoplePickerItemSelectedProps, PeoplePickerItem, ValidationState, NormalPeoplePicker, IPersonaProps, IPickerItemProps, DatePicker, DayOfWeek, defaultDatePickerStrings, DialogFooter, CheckboxVisibility } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../../app/Hooks"
import { getPage } from "../../../../../../app/Pages"
import { setPage } from "../../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../../common/Table/Table"
import { reset, dismissMessage, setSort, setNumberOfResults, resetSet, setSelectedItems, setSearchText, setIsFilteredSet, addItem, updateItem, getItems, getClinetUsers, resetUsers, setIsFilteredOwnersSet, setFromFilterOption, setSelectedUserItems, setToFilterOption, setMessage } from "./OrderTransactionsPageSlice"
import { ApiMessage, CLIENT_PROFILE_LOGO_URL, getHeaders, getSession } from "../../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../../app/Themes"
import { SearchField } from "../../../../../common/SearchField/SearchField"
import { currencyFormatter, formatDate, printComponent, normalizeKey } from "../../../../../../app/Helpers"
import { TableState } from "../../../../../common/Table/TableSate"
import { Message } from "../../../../../common/Message/Message"
import { ShareBox } from "../../../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../../../common/Popup/Popup"
import { FormType } from "../../../../../forms/FormProps"
import { TreasuryStatus, LimitationType, Operations, Natures, OrderAction } from "../../../../../../app/Enums"
import { GetStatementRequest } from "../../../../../../repository/Accountant/account_operation_pb"
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb"
import moment from "moment"
import { GetAccountsRequest } from "../../../../../../repository/Accountant/account_pb"
import { GetTreasuriesRequest, GetTreasuriesResponse } from "../../../../../../repository/Accountant/treasury_pb";
import { GetOrderTransactionsRequest } from "../../../../../../repository/Trading/order_pb"
import { GetUsersRequest } from "../../../../../../repository/UserManagement/Customers/user_pb"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../../../PageProps"
import { List } from "../../../../../common/List/List"
import { UsersPage } from "../../../../Customers/Users/UsersPage"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let req: GetOrderTransactionsRequest;
let clintsReq: GetUsersRequest;
let org: number = -1;

let getItemsPromise: any;
let getFilterPromise: any;
let actionPromise: any;

interface OrderTransactionsPageProps {
    order: any
}
export const OrderTransactionsPage: React.FunctionComponent<OrderTransactionsPageProps & PageProps> = (props) => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        set: TableState,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        searchText: string | undefined,
        fromFilterOption: { value: string, text: string }
        filters: any[],
        toFilterOption: { value: string, text: string }
        clients: TableState,

    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.orderTransactionsPage.isChangeStateLoading,
            set: state.orderTransactionsPage.set,
            message: state.orderTransactionsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            searchText: state.orderTransactionsPage.searchText,
            fromFilterOption: state.orderTransactionsPage.fromFilterOption,
            filters: state.orderTransactionsPage.filters,
            toFilterOption: state.orderTransactionsPage.toFilterOption,
            clients: state.orderTransactionsPage.clients

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const closeTreasuryActionConfirmationcalloutTargetId = useId('close-treasury-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});

    const getActionId = (action: number): string => {
        if (action == 1) {
            return closeTreasuryActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedItems(_selection.getSelection()));
            } else {
                dispatch(setSelectedItems([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedUserItems([]))
        req.setClient(undefined)
        dispatch(setFromFilterOption({ value: "", text: "" }))
        req.setFrom(undefined)
        dispatch(setToFilterOption({ value: "", text: "" }))
        req.setTo(undefined)
    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }

        if (state.clients.selected.length > 0) {
            const wrapper = new StringValue();
            wrapper.setValue((state.clients.selected.at(0)?.internalId));
            req.setClient(wrapper)
            isFiltered = true;
        } else {
            req.setClient(undefined)
        }


        if (state.fromFilterOption && state.fromFilterOption.value.trim().length > 0) {
            const wrapper = new Timestamp();

            wrapper.fromDate(moment(state.fromFilterOption.value, 'YYYY-MM-DD').startOf('day').toDate())
            req.setFrom(wrapper)
            isFiltered = true;

        } else {
            req.setFrom(undefined)
        }

        if (state.toFilterOption && state.toFilterOption.value.trim().length > 0) {
            const wrapper = new Timestamp();
            wrapper.fromDate(moment(state.toFilterOption.value, 'YYYY-MM-DD').endOf('day').toDate())
            req.setTo(wrapper)
            isFiltered = true;

        } else {
            req.setTo(undefined)
        }

        req.setNextto(undefined)
        dispatch(resetSet())
        dispatch(setIsFilteredSet(isFiltered))
        getItemsPromise?.abort()
        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetOrderTransactionsRequest();
        clintsReq = new GetUsersRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)
                clintsReq.setOrganization(wrapper)

            }
        }

        req.setNextto(undefined)
        req.setNumofresults(state.set.numberOfResults)
        req.setOrder(state.set.isDescending)
        var r = new StringValue();
        r.setValue(props.order?.id);
        req.setOrderid(r)


        clintsReq.setNumofresults(state.clients.numberOfResults)
        clintsReq.setOrder(state.clients.isDescending)

        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

        // dispatch(setMessage({ body: t("statementAccountDesc", { account: props.account?.name }), type: 4, title: "", data: 1 }))

        return () => { //clean up
            getItemsPromise?.abort();
            getFilterPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])
    /*
    
          public long Id { get; set; }
                public string InternalId { get; set; }
                public string Name { get; set; }
                public string Code { get; set; }
                public decimal MirrorBalance { get; set; }
                public CurrencyResponse Currency { get; set; }
                public int Status { get; set; }
                public AccountTypeResponse AccountType { get; set; }
                public OwnerResponse Owner { get; set; }
                public ProfileResponse CreatedBy { get; set; }
                public DateTime CreatedDate { get; set; }
    */

    const c: IColumn[] = [
        {
            key: 'id',
            name: t("id"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/orderTransactions/" + item?.id + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/orderTransactions/" + item?.id + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.id}</Link >;
            },
        },

        {
            key: 'referenceTransactionId',
            name: t("transactionNumber"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.id != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/account-transactions/" + item?.referenceTransactionInternalId + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/account-transactions/" + item?.referenceTransactionInternalId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.referenceTransactionId}</Link >;
            },
        },

        {
            key: 'amount',
            name: t("amount"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.amount != undefined)
                    return <Text>{currencyFormatter(item?.amount, { significantDigits: item?.currencyDecimalPlaces ?? 9, symbol: item?.currencySymbol })}</Text>;


            },
        },



        {
            key: 'clientName',
            name: t("client"),
            minWidth: 20,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e && !props.isSelection) {
                        e.preventDefault();
                        navigate("/customers/users/" + item?.clientId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/users/" + item?.clientId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}><Persona
                        text={item?.clientName == "" ? item?.clientId : item?.clientName}
                        imageUrl={CLIENT_PROFILE_LOGO_URL  + "/" + getSession()?.organization?.id + "/" +  item?.clientId + "/profile.jpg"}

                        size={PersonaSize.size40}
                    /></Link>

            },
        },
        {
            key: 'referenceOrderId',
            name: props.order?.tradeAction == OrderAction.SELL ? t("buyOrder") : t("sellOrder"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item, i, c) => {

                if (item?.id != undefined)
                    return <Link onClick={(e) => {
                        if (e && !props.isSelection) {
                            e.preventDefault();
                            navigate("/orders/" + item?.referenceOrderId + ((org == -1) ? "" : "?org=" + org));
                            window.location.reload(); 
                        }
                    }
                    } href={"/orders/" + item?.referenceOrderId + ((org == -1) ? "" : "?org=" + org)} target={props.isSelection ? "_blank" : undefined}> {item?.referenceOrderId}</Link >;
            },
        },
        {
            key: 'createdDate',
            name: t("date"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },



    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.set.selected.at(0)?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },


    ];

    const mainOptions: ICommandBarItemProps[] = [

        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.set.isFetching,
            onClick: () => {
                dispatch(resetSet())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t('sort'),
            iconProps: { iconName: 'sort' },
            disabled: state.set.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t('ascendingDate'),
                    canCheck: true,
                    checked: !state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetSet())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t('descendingDate'),
                    canCheck: true,
                    checked: state.set.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetSet())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.set.numberOfResults,
            title: t('results') + ': ' + state.set.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.set.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];


    const ownersSuggestionsProps: ISuggestionsProps<IPersonaProps> = {
        suggestionsHeaderText: t('clients'),
        noResultsFoundText: t('noResults'),
        loadingText: t('loading'),
        searchForMoreText: t('loadMore'),
        searchingText: t('pleaseWait'),
        searchForMoreIcon: { iconName: "more" },
        moreSuggestionsAvailable: state.clients.hasMore && !state.clients.isFetching,
        onRenderSuggestion: (props: IPersonaProps) => {


            return <Persona {...props} size={PersonaSize.size32} showSecondaryText={true} styles={{ root: { margin: 8 } }} />;
        },

        onSuggestionClick: (ev?, item?: any, index?: number) => {
            var u = state.clients.items.findIndex(e => e.internalId == item.key)
            if (u >= 0) {
                dispatch(setSelectedUserItems([state.clients.items.at(u)]))
            }

        },

        onGetMoreResults: () => {
            if (state.clients.items.length > 0 && !state.clients.isFetching) {
                const wrapper = new StringValue();
                wrapper.setValue(state.clients.items.at(state.clients.items.length - 1).internalId);
                clintsReq.setNextto(wrapper)
                getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
            }
        },
        suggestions: state.clients.items.length > 0 ? state.clients.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : [],
        isLoading: state.clients.items.length == 0 ? state.clients.isFetching : false,
        isSearching: state.clients.items.length > 0 ? state.clients.isFetching : false,


    };


    const onOwnersFilterChanged = (filterText: string, selectedItems?: IPersonaProps[]): IPersonaProps[] => {

        dispatch(resetUsers())
        const wrapper = new StringValue();
        wrapper.setValue(filterText);
        clintsReq.setSearch(wrapper)
        clintsReq.setNextto(undefined)
        dispatch(setIsFilteredOwnersSet(true))
        getFilterPromise?.abort();
        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
        return [];

    }


    return (
        <Stack>


            <Popup isOpen={(currentAction == 1000)} title={t("clients")} onDismiss={() => { setCurrenctAction(0) }} >

                <UsersPage isSelection={true} onItemSelected={(e) => {
                    dispatch(setSelectedUserItems([e]))
                    setCurrenctAction(0)
                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.set.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />



            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareOrderTransaction")} value={`https://${window.location.host}/orderTransactions/${state.set.selected.at(0).id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}



            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label >{t("client")}</Label>
                            <List
                                isPeoplePicker={true}
                                suggestionsHeaderText={t('clients')}
                                styles={{ root: { width: 268 } }}
                                isLoading={state.clients.items.length == 0 ? state.clients.isFetching : false}
                                isSearching={state.clients.items.length > 0 ? state.clients.isFetching : false}
                                moreSuggestionsAvailable={state.clients.hasMore && !state.clients.isFetching}
                                suggestions={state.clients.items.length > 0 ? state.clients.items.map(e => { return { item: { key: e.internalId, text: e.name, secondaryText: e.username, imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined } } as ISuggestionModel<IPersonaProps> }) : []}
                                onGetMoreResults={() => {
                                    if (state.clients.items.length > 0 && !state.clients.isFetching) {
                                        const wrapper = new StringValue();
                                        wrapper.setValue(state.clients.items.at(state.clients.items.length - 1).internalId);
                                        clintsReq.setNextto(wrapper)
                                        getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                    }
                                }}
                                onSuggestionClick={(ev?, item?: any, index?: number) => {
                                    var u = state.clients.items.findIndex(e => e.internalId == item.key)
                                    if (u >= 0) {
                                        dispatch(setSelectedUserItems([state.clients.items.at(u)]))
                                    }

                                }}
                                inputProps={{ placeholder: t("client") }}

                                selectedItems={state.clients.selected.length > 0 ? state.clients.selected.map(e => { return { key: e.internalId, text: e.name, secondaryText: e.username , imageUrl: e?.imageSource && e?.imagePath ? e?.imageSource +""+ e?.imagePath : undefined} as IPersonaProps }) : []}
                                onChange={(e) => {
                                    dispatch(setSelectedUserItems([]))
                                }}
                                onEmptyInputFocus={() => {
                                    dispatch(resetUsers())
                                    clintsReq.setSearch(undefined)
                                    clintsReq.setNextto(undefined)
                                    dispatch(setIsFilteredOwnersSet(false))
                                    getFilterPromise?.abort()
                                    getFilterPromise = dispatch(getClinetUsers({ body: clintsReq, headers: getHeaders() }))
                                    return []


                                }}
                                onFilterChanged={onOwnersFilterChanged}
                                endButtons={[
                                    {
                                        title: t("advancedOptions"), checked: true, iconProps: { iconName: "ShowResults" },
                                        onClick: () => {
                                            setCurrenctAction(1000)

                                        }
                                    }]}
                            />



                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("from")}</Label>
                            <DatePicker

                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder={t("from")}
                                value={state.fromFilterOption.value != "" ? moment(state.fromFilterOption.value, 'YYYY-MM-DD').toDate() : undefined}
                                styles={{ root: { width: 300 } }}
                                ariaLabel={t("from")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').toDate();
                                }}
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date)
                                        dispatch(setFromFilterOption({ value: formatDate(date, 'YYYY-MM-DD'), text: t("from") + ":" + formatDate(date, 'YYYY-MM-DD') }))

                                }}

                            />

                        </Stack>
                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("to")}</Label>
                            <DatePicker

                                firstDayOfWeek={DayOfWeek.Sunday}
                                placeholder={t("to")}
                                value={state.toFilterOption.value != "" ? moment(state.toFilterOption.value, 'YYYY-MM-DD').toDate() : undefined}
                                styles={{ root: { width: 300 } }}
                                ariaLabel={t("to")}
                                formatDate={(e) => {
                                    return formatDate(e, 'YYYY-MM-DD')
                                }}
                                parseDateFromString={(date) => {
                                    return moment(date, 'YYYY-MM-DD').toDate();
                                }}
                                strings={defaultDatePickerStrings}
                                onSelectDate={(date: Date | null | undefined) => {
                                    if (date)
                                        dispatch(setToFilterOption({ value: formatDate(date, 'YYYY-MM-DD'), text: t("to") + ":" + formatDate(date, 'YYYY-MM-DD') }))

                                }}

                            />

                        </Stack>
                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null
            }

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {



                                if (e.key == 2) {
                                    dispatch(setSelectedUserItems([]))
                                    req.setClient(undefined)
                                }


                                if (e.key == 6) {
                                    dispatch(setFromFilterOption({ value: "", text: "" }))
                                    req.setFrom(undefined)
                                }
                                if (e.key == 7) {
                                    dispatch(setToFilterOption({ value: "", text: "" }))
                                    req.setTo(undefined)
                                }
                            });
                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.set.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="orderTransactions"
                items={state.set.items}
                columns={c}
                selection={_selection}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.set.isFetching && state.set.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                        req.setNextto(wrapper)
                        getItemsPromise?.abort();
                        getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))

                    }
                }}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 225px)" : "calc(100vh - 273px)"}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.set.items.length == 0 && state.set.isFetching}
                isColumnSortingEnabled={state.set.selected.length == 0}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.set.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.set.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.set.items.length > 0 && !state.set.isFetching && state.set.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.set.items.at(state.set.items.length - 11)?.id);
                                    req.setNextto(wrapper)
                                    getItemsPromise?.abort();
                                    getItemsPromise = dispatch(getItems({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />


            {
                props.isSelection ? <DialogFooter>
                    <PrimaryButton disabled={state.set.isFetching || state.set.selected.length == 0} text={state.set.isFetching ? undefined : t("select")} onClick={() => {
                        if (props.onItemSelected && state.set.selected.length > 0) {
                            props.onItemSelected(state.set.selected.at(0))
                        }
                    }} >

                        <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.set.isFetching ? "block" : "none") } }} />

                    </PrimaryButton>
                    <DefaultButton disabled={state.set.isFetching} text={t("discard")} onClick={() => {
                        if (props.onCancel) {
                            props.onCancel()
                        }
                    }} />
                </DialogFooter> : undefined
            }
        </Stack >



    );
}




