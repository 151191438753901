import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator, TooltipHost } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks"
import { getPage } from "../../../../../app/Pages"
import { setPage } from "../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../common/Table/Table"
import { ApiMessage, CLIENT_PROFILE_LOGO_URL, getHeaders, getSession, PROFILE_LOGO_URL } from "../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../../common/Message/Message"
import { ShareBox } from "../../../../common/ShareBox/ShareBox"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { cancelOrder, dismissMessage, exportItem, get, reset, resetItem, updateItem } from "./OrderDetailsPageSlice"
import { Popup } from "../../../../common/Popup/Popup"
import { FormType } from "../../../../forms/FormProps"
import { DynamicField } from "../../../../common/DynamicFields/DynamicFields"
import { DeleteCurrencyRequest, GetCurrencyRequest } from "../../../../../repository/Accountant/currency_pb"
import { CurrenciesForm } from "../../../../forms/Accountant/Currencies/CurrenciesForm"
import { CurrencyForm, OrderAction, OrderStatus, OrderType } from "../../../../../app/Enums"
import { TextView } from "../../../../common/DataView/TextView"
import { ProfileView } from "../../../../common/DataView/ProfileView"
import { DeleteCurrencyPairRequest, GetCurrencyPairRequest, UpdateCurrencyPairActivityRequest } from "../../../../../repository/Trading/currency_pair_pb"
import { LinkView } from "../../../../common/DataView/LinkView"
import { currencyFormatter, printComponent, normalizeKey, formatDate, getCurrentLanguage, printOrder } from "../../../../../app/Helpers"
import { CurrencyPairsForm } from "../../../../forms/Trading/CurrencyPairs/CurrencyPairsForm"
import { CancelRequest, ExportOrderRequest, GetOrderRequest } from "../../../../../repository/Trading/order_pb"
import { OrderTransactionsPage } from "./OrderTransactionsPage/OrderTransactionsPage"
import { useReactToPrint } from "react-to-print"
import statement from "../../../../../repository/Trading/order_pb";
import { StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { PlaceOrderForm } from "../../../../forms/Trading/Orders/PlaceOrder/PlaceOrderForm"


const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};

let exportReq: ExportOrderRequest;
let req: GetOrderRequest;

let org: number = -1;

let getPromise: any;
let actionPromise: any;


const key = "trading/orders/details"
export const OrderDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();
    const { hash } = useLocation();
    const [selectedKey, setSelectedKey] = useState("0");

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.orderDetailsPage.isChangeStateLoading,
            message: state.orderDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.orderDetailsPage.isLoading,
            item: state.orderDetailsPage.item,

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)

    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});



    const printReceipt = () => {
        return printOrder(state.item, t)
    };

    const handlePrint2 = useReactToPrint({});
    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetOrderRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            getPromise.unwrap().then((e: any) => {
                if (hash == "#tradeHistory") {
                    setSelectedKey("1");
                } else {
                    setSelectedKey("0");
                }
            });




        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItemsOpenOrder: ICommandBarItemProps[] = [
      
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'trade',
            text:  state.item?.tradeAction == OrderAction.SELL ? t("buy") : t("sell"),
            iconProps: { iconName: 'Remote' },
            title: state.item?.tradeAction == OrderAction.SELL ? t("buy") : t("sell"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(102)
                }
            }
        },

        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'status',
            text: t("cancelOrder"),
            iconProps: { id: activityActionConfirmationcalloutTargetId, iconName: "Cancel", styles: { root: { fontWeight: "bold" } } },
            title: t("cancelOrder"),
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 2 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 2 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 2 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(2)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },

        {
            key: 'export',
            text: t("export"),
            iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },
            title: t("export"),
            disabled: state.isChangeStateLoading,
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    exportReq = new ExportOrderRequest();
                    exportReq.setId(req.getId())
                    const labels: statement.Label[] = [];
                    let r = new statement.Label();
                    let value = new StringValue();

                    value.setValue((state.item?.currencyPairName))
                    r.setLabelvalue(t("pair"));
                    r.setValue(value)
                    r.setPropartyname("pair")
                    labels.push(r)


                    r = new statement.Label();
                    value = new StringValue();
                    value.setValue((state.item?.orderType == OrderType.MARKET_ORDER ? t("marketOrder") : state.item?.orderType == OrderType.LIMIT_ORDER ? t("limitOrderInternal") : state.item?.orderType == OrderType.LIMIT_ORDER_External ? t("limitOrderExternal") : state.item?.orderType == OrderType.ALL_OR_NONE_ORDER ? t("AON") : ""))
                    r.setLabelvalue(t("orderType"));
                    r.setValue(value)
                    r.setPropartyname("type")
                    labels.push(r)

                    value = new StringValue();
                    value.setValue((state.item?.tradeAction == OrderAction.BUY ? t("buy") : t("sell")))
                    r = new statement.Label();
                    r.setLabelvalue(t("side"));
                    r.setValue(value)
                    r.setPropartyname("side")
                    labels.push(r)

                    value = new StringValue();
                    value.setValue(state.item?.status == OrderStatus.OPEN ? t("open") : state.item?.status == OrderStatus.FILLD ? t("filled") : state.item?.status == OrderStatus.NEW ? t("new") : state.item?.status == OrderStatus.CANCELED ? t("cancelled") : state.item?.status == OrderStatus.NEW ? t("new") : "")
                    r = new statement.Label();
                    r.setLabelvalue(t("status"));
                    r.setValue(value)
                    r.setPropartyname("status")
                    labels.push(r)





                    r = new statement.Label();
                    r.setLabelvalue(t("id"));
                    r.setPropartyname("id")
                    labels.push(r)


                    r = new statement.Label();
                    r.setLabelvalue(t("price"));
                    r.setPropartyname("Price")
                    labels.push(r)

                    r = new statement.Label();
                    r.setLabelvalue(t("amount"));
                    r.setPropartyname("Amount")
                    labels.push(r)

                    r = new statement.Label();
                    r.setLabelvalue(t("total"));
                    r.setPropartyname("Total")
                    labels.push(r)

                    r = new statement.Label();
                    r.setLabelvalue(t("filledAmount"));
                    r.setPropartyname("Filled")
                    labels.push(r)

                    r = new statement.Label();
                    r.setLabelvalue(t("orderTitle"));
                    r.setPropartyname("ordertitle")
                    labels.push(r)
                    r = new statement.Label();
                    r.setLabelvalue(t("date"));
                    r.setPropartyname("date")
                    labels.push(r)


                    r = new statement.Label();
                    r.setLabelvalue(t("information"));
                    r.setPropartyname("basicinfo")
                    labels.push(r)

                    r = new statement.Label();
                    value = new StringValue();
                    value.setValue((((new Date()).getTimezoneOffset()) * -1) + "")
                    r.setValue(value);
                    r.setPropartyname("timezone")
                    labels.push(r)


                    exportReq.setLabelsList(labels)
                    exportReq.setExporttype(1)
                    setCurrenctAction(1)
                    actionPromise = dispatch(exportItem({
                        body: exportReq, headers: getHeaders()
                    }))

                }

            }



        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            subMenuProps: {
                items: [{
                    key: 'editInfo',
                    text: t('printReceipt'),
                    title: t("printReceipt"),
                    iconProps: { iconName: 'M365InvoicingLogo' },

                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            handlePrint2(printReceipt)
                        }

                    }
                },
                {
                    key: 'printPage',
                    text: t("printPage"),
                    title: t("printPage"),
                    iconProps: { iconName: "Page", styles: { root: { fontWeight: "bold" } } },
                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            handlePrint(reactToPrintContent);
                        }
                    }
                },

                ]
            },

        },
    ];

    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            }
        },


        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/logs?resourceId=" + state.item?.id + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },

        {
            key: 'export',
            text: t("export"),
            iconProps: { iconName: "DownloadDocument", styles: { root: { fontWeight: "bold" } } },
            title: t("export"),
            disabled: state.isChangeStateLoading,
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    exportReq = new ExportOrderRequest();
                    exportReq.setId(req.getId())
                    const labels: statement.Label[] = [];
                    let r = new statement.Label();
                    let value = new StringValue();

                    value.setValue((state.item?.currencyPairName))
                    r.setLabelvalue(t("pair"));
                    r.setValue(value)
                    r.setPropartyname("pair")
                    labels.push(r)


                    r = new statement.Label();
                    value = new StringValue();
                    value.setValue((state.item?.orderType == OrderType.MARKET_ORDER ? t("marketOrder") : state.item?.orderType == OrderType.LIMIT_ORDER ? t("limitOrderInternal") : state.item?.orderType == OrderType.LIMIT_ORDER_External ? t("limitOrderExternal") : state.item?.orderType == OrderType.ALL_OR_NONE_ORDER ? t("AON") : ""))
                    r.setLabelvalue(t("orderType"));
                    r.setValue(value)
                    r.setPropartyname("type")
                    labels.push(r)

                    value = new StringValue();
                    value.setValue((state.item?.tradeAction == OrderAction.BUY ? t("buy") : t("sell")))
                    r = new statement.Label();
                    r.setLabelvalue(t("side"));
                    r.setValue(value)
                    r.setPropartyname("side")
                    labels.push(r)

                    value = new StringValue();
                    value.setValue(state.item?.status == OrderStatus.OPEN ? t("open") : state.item?.status == OrderStatus.FILLD ? t("filled") : state.item?.status == OrderStatus.NEW ? t("new") : state.item?.status == OrderStatus.CANCELED ? t("cancelled") : state.item?.status == OrderStatus.NEW ? t("new") : "")
                    r = new statement.Label();
                    r.setLabelvalue(t("status"));
                    r.setValue(value)
                    r.setPropartyname("status")
                    labels.push(r)





                    r = new statement.Label();
                    r.setLabelvalue(t("id"));
                    r.setPropartyname("id")
                    labels.push(r)


                    r = new statement.Label();
                    r.setLabelvalue(t("price"));
                    r.setPropartyname("Price")
                    labels.push(r)

                    r = new statement.Label();
                    r.setLabelvalue(t("amount"));
                    r.setPropartyname("Amount")
                    labels.push(r)

                    r = new statement.Label();
                    r.setLabelvalue(t("total"));
                    r.setPropartyname("Total")
                    labels.push(r)

                    r = new statement.Label();
                    r.setLabelvalue(t("filledAmount"));
                    r.setPropartyname("Filled")
                    labels.push(r)

                    r = new statement.Label();
                    r.setLabelvalue(t("orderTitle"));
                    r.setPropartyname("ordertitle")
                    labels.push(r)
                    r = new statement.Label();
                    r.setLabelvalue(t("date"));
                    r.setPropartyname("date")
                    labels.push(r)


                    r = new statement.Label();
                    r.setLabelvalue(t("information"));
                    r.setPropartyname("basicinfo")
                    labels.push(r)

                    r = new statement.Label();
                    value = new StringValue();
                    value.setValue((((new Date()).getTimezoneOffset()) * -1) + "")
                    r.setValue(value);
                    r.setPropartyname("timezone")
                    labels.push(r)


                    exportReq.setLabelsList(labels)
                    exportReq.setExporttype(1)
                    setCurrenctAction(1)
                    actionPromise = dispatch(exportItem({
                        body: exportReq, headers: getHeaders()
                    }))

                }

            }



        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            subMenuProps: {
                items: [{
                    key: 'editInfo',
                    text: t('printReceipt'),
                    title: t("printReceipt"),
                    iconProps: { iconName: 'M365InvoicingLogo' },

                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            handlePrint2(printReceipt)
                        }

                    }
                },
                {
                    key: 'printPage',
                    text: t("printPage"),
                    title: t("printPage"),
                    iconProps: { iconName: "Page", styles: { root: { fontWeight: "bold" } } },
                    onClick: () => {
                        if (!state.isChangeStateLoading) {
                            handlePrint(reactToPrintContent);
                        }
                    }
                },

                ]
            },

        },
    ];
 

    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }

            <Popup isOpen={(currentAction == 102)} title={t("placeOrder")} isBlocking onDismiss={() => { setCurrenctAction(0) }} >
                <PlaceOrderForm childrenGap={10} maxWidth={700} renderObject={{ matchOrder: state.item }} type={FormType.ADD} onSuccess={(e) => {
                    if (e.matchOrder) {
                        /*
                             quantityFilled: val.quantityfilled?.value,
                             remainingFilled: val.remainingfilled?.value,
                             remainingQuantityFilled: val.remainingquantityfilled?.value,
                             filled: val.filled?.value ?? undefined,
     
                        */
                        dispatch(updateItem(e.matchOrder))

                    }

                    setCurrenctAction(0)

                }} onCancel={() => { setCurrenctAction(0) }} />

            </Popup>


            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareOrder")} value={`https://${window.location.host}/orders/${state.item?.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}

            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 2 ? t("cancelOrder") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 2 ? t("cancelOrderDesc") : "")}
                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.item) {
                                        if (currentAction == 2) {
                                            let req = new CancelRequest();
                                            req.setId(state.item?.id)

                                            actionPromise?.abort();
                                            actionPromise = dispatch(cancelOrder({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                setCurrenctAction(0)
                                            })



                                        }
                                    }
                                }} text={(currentAction == 2 ? t("cancelOrder") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}



            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <Pivot selectedKey={String(selectedKey)} onLinkClick={(i) => {
                        if (i?.props.itemKey == "1")
                            // navigate("#rules")
                            window.history.replaceState(null, "", "#tradeHistory")

                        if (i?.props.itemKey == "0")
                            window.history.replaceState(null, "", "#info")
                        //navigate("#info")

                        setSelectedKey(i?.props?.itemKey ?? "0")
                    }} >
                        <PivotItem headerText={t("info")} itemKey="0"   >
                            <div ref={ref}>
                                <CommandBar
                                    items={state.item?.status == OrderStatus.OPEN ? _selectionItemsOpenOrder : _selectionItems}
                                />
                                <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>

                                        <TextView label={t("id")} value={state.item?.id} />


                                        <TextView label={t("createdDate")} value={state.item?.createdDate} />

                                        {state.item?.addedBy ? <ProfileView label={t("addedBy")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                            text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                            imageUrl={PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + state.item?.addedById + "/profile.jpg"}

                                            size={PersonaSize.size40} />

                                            : undefined}

                                    </Stack>

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>

                                        <ProfileView label={t("pair")} value={state.item?.currencyPairName}
                                            url={"/currency-pairs/" + state.item?.currencyPairId + ((org == -1) ? "" : "?org=" + org)}
                                            text={state.item?.currencyPairName}
                                            secondaryText={(state.item?.currencyPairBaseCurrencyCode == "" ? state.item?.currencyPairBaseCurrencyId : state.item?.currencyPairBaseCurrencyCode) + "-" + (state.item?.currencyPairQuoteCurrencyCode == "" ? state.item?.currencyPairQuoteCurrencyId : state.item?.currencyPairQuoteCurrencyCode)}
                                            imageInitials={state.item?.currencyPairBaseCurrencySymbol + "/" + state.item?.currencyPairQuoteCurrencySymbol}
                                            size={PersonaSize.size40}

                                        />

                                        <TextView label={t("orderType")} value={state.item?.orderType == OrderType.MARKET_ORDER ? t("marketOrder") : state.item?.orderType == OrderType.LIMIT_ORDER ? t("limitOrderInternal") : state.item?.orderType == OrderType.LIMIT_ORDER_External ? t("limitOrderExternal") : state.item?.orderType == OrderType.ALL_OR_NONE_ORDER ? t("AON") : ""} />

                                        <TextView styles={{ root: { color: state.item?.tradeAction == OrderAction.BUY ? "#008000" : "#FF0000" } }} label={t("side")} value={state.item?.tradeAction == OrderAction.BUY ? t("buy") : t("sell")} />

                                        <TextView label={t("status")} value={state.item?.status == OrderStatus.OPEN ? t("open") : state.item?.status == OrderStatus.FILLD ? t("filled") : state.item?.status == OrderStatus.NEW ? t("new") : state.item?.status == OrderStatus.CANCELED ? t("cancelled") : state.item?.status == OrderStatus.NEW ? t("new") : ""} />


                                    </Stack>

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <TextView label={t("price")} value={currencyFormatter(state.item?.itemPrice, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol })} />

                                        <TextView label={t("amount")} value={currencyFormatter(state.item?.itemQuantity, { significantDigits: state.item?.currencyPairBaseCurrencyDecimalPlaces ?? 9, symbol: state.item?.currencyPairBaseCurrencySymbol })} />

                                        <TextView label={t("total")} value={currencyFormatter(state.item?.total, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol })} />

                                        <TextView label={t("filledAmount")} value={currencyFormatter(state.item?.filled, { significantDigits: state.item?.currencyDecimalPlaces ?? 9, symbol: state.item?.currencySymbol }) + " / " + currencyFormatter(state.item?.quantityFilled, { significantDigits: state.item?.currencyPairBaseCurrencyDecimalPlaces ?? 9, symbol: state.item?.currencyPairBaseCurrencySymbol }) + "  / %" + ((state.item?.filled * 100) / state.item?.total).toFixed(2)} />

                                    </Stack>


                                    <Stack horizontal tokens={{ childrenGap: 10 }}>

                                        {state.item?.clientId ? <ProfileView label={t("client")} value={state.item?.clientName == "" ? state.item?.clientId : state.item?.clientName}
                                            url={"/customers/users/" + state.item?.clientId + ((org == -1) ? "" : "?org=" + org)}
                                            text={state.item?.clientName == "" ? state.item?.clientId : state.item?.clientName}
                                            imageUrl={CLIENT_PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + state.item?.clientId + "/profile.jpg"}

                                            size={PersonaSize.size40} />

                                            : undefined}

                                        <LinkView label={state.item?.tradeAction == OrderAction.SELL ?
                                            (t("sellerFrom", { currency: state.item.currencyPairBaseCurrencyCode })) : (t("buyerFrom", { currency: state.item.currencyPairQuoteCurrencyCode }))} value={state.item?.fromAccountName == "" ? state.item?.fromAccountId : state.item?.fromAccountName}
                                            url={"/accounts/" + state.item?.fromAccountInternalId + ((org == -1) ? "" : "?org=" + org)} />


                                        <LinkView label={state.item?.tradeAction == OrderAction.SELL ?
                                            (t("sellerTo", { currency: state.item.currencyPairQuoteCurrencyCode })) : (t("buyerTo", { currency: state.item.currencyPairBaseCurrencyCode }))} value={state.item?.toAccountName == "" ? state.item?.toAccountId : state.item?.toAccountName}
                                            url={"/accounts/" + state.item?.toAccountInternalId + ((org == -1) ? "" : "?org=" + org)} />


                                    </Stack>

                                </Stack>
                            </div>
                        </PivotItem>

                        <PivotItem headerText={t("orderTransactions")} itemKey="1"  >
                            <OrderTransactionsPage order={state.item} />
                        </PivotItem>
                    </Pivot>

            }


        </Stack>



    );
}




