import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ProgressIndicator, Pivot, PivotItem, Separator } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/Hooks"
import { getPage } from "../../../../app/Pages"
import { setPage } from "../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../common/Table/Table"
import { ApiMessage, getHeaders, getSession, PROFILE_LOGO_URL } from "../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import { Message } from "../../../common/Message/Message"
import { ShareBox } from "../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { dismissMessage, get, reset, resetItem, updateItem } from "./LogDetailsPageSlice"
import { Popup } from "../../../common/Popup/Popup"
import { FormType } from "../../../forms/FormProps"
import { DynamicField } from "../../../common/DynamicFields/DynamicFields"
import { TextView } from "../../../common/DataView/TextView"
import { DeleteLimitationRequest, GetLimitationRequest, UpdateLimitationActivityRequest } from "../../../../repository/Accountant/limitation_pb"
import { LimitationsForm } from "../../../forms/Accountant/Limitations/LimitationsFrom"
import { ProfileView } from "../../../common/DataView/ProfileView"
import { getOperations, printComponent, normalizeKey } from "../../../../app/Helpers"
import { GetLogRequest } from "../../../../repository/Trading/log_pb"
import { LinkView } from "../../../common/DataView/LinkView"
import { LogType } from "../../../../app/Enums"
import { useReactToPrint } from "react-to-print"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetLogRequest;

let org: number = -1;

let getPromise: any;
let actionPromise: any;


const key = "logs/details"
export const LogDetailsPage: React.FunctionComponent = () => {

    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    let { id } = useParams();

    const state: {
        isChangeStateLoading: boolean,
        message: ApiMessage | undefined,
        appTheme: string, isDarkModeEnabled: boolean,
        isLoading: boolean,
        item: any
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.logDetailsPage.isChangeStateLoading,
            message: state.logDetailsPage.message,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled,
            isLoading: state.logDetailsPage.isLoading,
            item: state.logDetailsPage.item,

        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');
    const activityActionConfirmationcalloutTargetId = useId('activity-action-callout-target');
    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
    const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});

    const getActionId = (action: number): string => {
        if (action == 1) {
            return deleteActionConfirmationcalloutTargetId;
        } else if (action == 2) {
            return activityActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }


    useEffect(() => {
        req = new GetLimitationRequest();
        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {

            }
        }
        const page = getPage(key)
        dispatch(setTitle(t(normalizeKey(page?.title))));
        dispatch(setPage(page));

        if (id) {
            req.setId(id)
            getPromise = dispatch(get({ body: req, headers: getHeaders() }))
        } else {
            navigate(-1)
        }


        return () => { //clean up
            getPromise?.abort();
            actionPromise?.abort();
            dispatch(reset());
        }
    }, [])



    const _selectionItems: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: t('back'),
            title: t('back'),
            iconProps: { iconName: 'back' },
            disabled: state.isLoading,
            onClick: () => {
                navigate(-1)
            }
        },
        {
            key: 'refresh',
            text: t('refresh'),
            title: t('refresh'),
            iconProps: { iconName: 'refresh' },
            disabled: state.isLoading,
            onClick: () => {
                dispatch(resetItem())
                getPromise = dispatch(get({ body: req, headers: getHeaders() }))
            }
        },

        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];



    return (
        <Stack >
            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }


            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareLog")} value={`https://${window.location.host}/logs/${state.item.id}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}


            {state.isLoading ? <ProgressIndicator /> :

                !state.item ? null :
                    <div ref={ref}>
                        <CommandBar
                            items={_selectionItems}
                        />
                        <Stack tokens={{ childrenGap: 10, padding: "0px 33% 10px 3%" }}>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>

                                <TextView label={t("id")} value={state.item?.id} />


                                <TextView label={t("executedDate")} value={state.item?.createdDate} />

                                {state.item?.addedBy ? <ProfileView label={t("executer")} value={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    url={"/management/users/" + state.item?.addedById + ((org == -1) ? "" : "?org=" + org)}
                                    text={state.item?.addedBy == "" ? state.item?.addedById : state.item?.addedBy}
                                    imageUrl={PROFILE_LOGO_URL + "/" + getSession()?.organization?.id + "/" + state.item?.addedById + "/profile.jpg"}

                                    size={PersonaSize.size40} />

                                    : undefined}

                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <LinkView label={t("privilege")} value={state.item?.privilegeName == "" ? state.item?.currencyId : state.item?.privilegeName}
                                    url={"/management/privileges/" + state.item?.privilegeId + ((org == -1) ? "" : "?org=" + org)} />

                                <TextView label={t("endpoint")} value={state.item?.privilegeEndpoint} />

                                <TextView label={t("class")} value={state.item?.privilegeClass} />

                                <TextView label={t("resourceId")} value={state.item?.objectid} />

                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <TextView label={t("type")} value={state.item?.type != undefined ? state.item?.type == LogType.SUCCESS_OP ? t("success_op") : state.item?.type == LogType.FATAL_EXCEPTION ? t("fatel_exception") : state.item?.type == LogType.APP_EXCEPTION ? t("app_exception") : "" : ""} />

                                <TextView label={t("startExecutionDate")} value={state.item?.startExecutionDate} />

                                <TextView label={t("endExecutionDate")} value={state.item?.endExecutionDate} />

                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <TextView label={t("ip")} value={state.item?.clientIP} />

                                <TextView label={t("userAgent")} value={state.item?.userAgent} />

                            </Stack>
                            <TextView label={t("inputs")} value={state.item?.inputs} />
                            <TextView label={t("outputs")} value={state.item?.outputs} />
                            <TextView label={t("exception")} value={state.item?.exception} />

                            <TextView label={t("description")} value={state.item?.description} />


                        </Stack>


                    </div>


            }


        </Stack >



    );
}




