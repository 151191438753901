// source: profile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var Protos_common_pb = require('../common_pb.js');
goog.object.extend(proto, Protos_common_pb);
goog.exportSymbol('proto.managers_profile_endpoint.AccountSecurityInfo', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.GetProfileReplay', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.GetProfileRequest', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.GetProfileResponse', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.GetProfileResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.Profile', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.Role', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateAccountSecurityReplay', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateAccountSecurityRequest', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateAccountSecurityResponse', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateAccountSecurityResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdatePasswordReplay', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdatePasswordResponse', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdatePasswordResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateProfilePasswordRequest', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateProfileReplay', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateProfileRequest', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateProfileResponse', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateProfileResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateUsernameReplay', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateUsernameRequest', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateUsernameResponse', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UpdateUsernameResponse.ResultCase', null, global);
goog.exportSymbol('proto.managers_profile_endpoint.UserType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.GetProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.GetProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.GetProfileRequest.displayName = 'proto.managers_profile_endpoint.GetProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.managers_profile_endpoint.UpdateProfileRequest.repeatedFields_, null);
};
goog.inherits(proto.managers_profile_endpoint.UpdateProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateProfileRequest.displayName = 'proto.managers_profile_endpoint.UpdateProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.UpdateProfilePasswordRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateProfilePasswordRequest.displayName = 'proto.managers_profile_endpoint.UpdateProfilePasswordRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.UpdateAccountSecurityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateAccountSecurityRequest.displayName = 'proto.managers_profile_endpoint.UpdateAccountSecurityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateUsernameRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.UpdateUsernameRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateUsernameRequest.displayName = 'proto.managers_profile_endpoint.UpdateUsernameRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.UpdateAccountSecurityReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateAccountSecurityReplay.displayName = 'proto.managers_profile_endpoint.UpdateAccountSecurityReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateUsernameReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.UpdateUsernameReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateUsernameReplay.displayName = 'proto.managers_profile_endpoint.UpdateUsernameReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.Role.displayName = 'proto.managers_profile_endpoint.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UserType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.UserType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UserType.displayName = 'proto.managers_profile_endpoint.UserType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.managers_profile_endpoint.Profile.repeatedFields_, null);
};
goog.inherits(proto.managers_profile_endpoint.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.Profile.displayName = 'proto.managers_profile_endpoint.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.GetProfileReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.GetProfileReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.GetProfileReplay.displayName = 'proto.managers_profile_endpoint.GetProfileReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.AccountSecurityInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.AccountSecurityInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.AccountSecurityInfo.displayName = 'proto.managers_profile_endpoint.AccountSecurityInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateProfileReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.UpdateProfileReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateProfileReplay.displayName = 'proto.managers_profile_endpoint.UpdateProfileReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdatePasswordReplay = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.managers_profile_endpoint.UpdatePasswordReplay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdatePasswordReplay.displayName = 'proto.managers_profile_endpoint.UpdatePasswordReplay';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.GetProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_profile_endpoint.GetProfileResponse.oneofGroups_);
};
goog.inherits(proto.managers_profile_endpoint.GetProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.GetProfileResponse.displayName = 'proto.managers_profile_endpoint.GetProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateProfileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_profile_endpoint.UpdateProfileResponse.oneofGroups_);
};
goog.inherits(proto.managers_profile_endpoint.UpdateProfileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateProfileResponse.displayName = 'proto.managers_profile_endpoint.UpdateProfileResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdatePasswordResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_profile_endpoint.UpdatePasswordResponse.oneofGroups_);
};
goog.inherits(proto.managers_profile_endpoint.UpdatePasswordResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdatePasswordResponse.displayName = 'proto.managers_profile_endpoint.UpdatePasswordResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_profile_endpoint.UpdateAccountSecurityResponse.oneofGroups_);
};
goog.inherits(proto.managers_profile_endpoint.UpdateAccountSecurityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateAccountSecurityResponse.displayName = 'proto.managers_profile_endpoint.UpdateAccountSecurityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.managers_profile_endpoint.UpdateUsernameResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.managers_profile_endpoint.UpdateUsernameResponse.oneofGroups_);
};
goog.inherits(proto.managers_profile_endpoint.UpdateUsernameResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.managers_profile_endpoint.UpdateUsernameResponse.displayName = 'proto.managers_profile_endpoint.UpdateUsernameResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.GetProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.GetProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.GetProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.GetProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.GetProfileRequest}
 */
proto.managers_profile_endpoint.GetProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.GetProfileRequest;
  return proto.managers_profile_endpoint.GetProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.GetProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.GetProfileRequest}
 */
proto.managers_profile_endpoint.GetProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.GetProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.GetProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.GetProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.GetProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.managers_profile_endpoint.UpdateProfileRequest.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    middlename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    emailaddressesList: jspb.Message.toObjectList(msg.getEmailaddressesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    primaryemail: (f = msg.getPrimaryemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    primaryphonenumber: (f = msg.getPrimaryphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateProfileRequest;
  return proto.managers_profile_endpoint.UpdateProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMiddlename(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addEmailaddresses(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryemail(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPrimaryphonenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMiddlename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailaddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryemail();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPrimaryphonenumber();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string firstName = 1;
 * @return {string}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string middleName = 2;
 * @return {string}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.getMiddlename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.setMiddlename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lastName = 3;
 * @return {string}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 4;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
*/
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated google.protobuf.StringValue emailAddresses = 5;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.getEmailaddressesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
*/
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.setEmailaddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.addEmailaddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.clearEmailaddressesList = function() {
  return this.setEmailaddressesList([]);
};


/**
 * optional google.protobuf.StringValue primaryEmail = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.getPrimaryemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
*/
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.setPrimaryemail = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.clearPrimaryemail = function() {
  return this.setPrimaryemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.hasPrimaryemail = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue primaryPhoneNumber = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.getPrimaryphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
*/
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.setPrimaryphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateProfileRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.clearPrimaryphonenumber = function() {
  return this.setPrimaryphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateProfileRequest.prototype.hasPrimaryphonenumber = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateProfilePasswordRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateProfilePasswordRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldpassword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newpassword: jspb.Message.getFieldWithDefault(msg, 2, ""),
    renewpassword: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateProfilePasswordRequest}
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateProfilePasswordRequest;
  return proto.managers_profile_endpoint.UpdateProfilePasswordRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateProfilePasswordRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateProfilePasswordRequest}
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldpassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewpassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRenewpassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateProfilePasswordRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateProfilePasswordRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldpassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewpassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRenewpassword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string oldPassword = 1;
 * @return {string}
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.prototype.getOldpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_profile_endpoint.UpdateProfilePasswordRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.prototype.setOldpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string newPassword = 2;
 * @return {string}
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.prototype.getNewpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_profile_endpoint.UpdateProfilePasswordRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.prototype.setNewpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string reNewPassword = 3;
 * @return {string}
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.prototype.getRenewpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_profile_endpoint.UpdateProfilePasswordRequest} returns this
 */
proto.managers_profile_endpoint.UpdateProfilePasswordRequest.prototype.setRenewpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateAccountSecurityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateAccountSecurityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isemailtfenabled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    tfemail: (f = msg.getTfemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityRequest}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateAccountSecurityRequest;
  return proto.managers_profile_endpoint.UpdateAccountSecurityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateAccountSecurityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityRequest}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsemailtfenabled(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfemail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateAccountSecurityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateAccountSecurityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsemailtfenabled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getTfemail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool isEmailTFEnabled = 1;
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.prototype.getIsemailtfenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityRequest} returns this
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.prototype.setIsemailtfenabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue TFEmail = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.prototype.getTfemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityRequest} returns this
*/
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.prototype.setTfemail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityRequest} returns this
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.prototype.clearTfemail = function() {
  return this.setTfemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityRequest.prototype.hasTfemail = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateUsernameRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateUsernameRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateUsernameRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateUsernameRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateUsernameRequest}
 */
proto.managers_profile_endpoint.UpdateUsernameRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateUsernameRequest;
  return proto.managers_profile_endpoint.UpdateUsernameRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateUsernameRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateUsernameRequest}
 */
proto.managers_profile_endpoint.UpdateUsernameRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateUsernameRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateUsernameRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateUsernameRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateUsernameRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.managers_profile_endpoint.UpdateUsernameRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_profile_endpoint.UpdateUsernameRequest} returns this
 */
proto.managers_profile_endpoint.UpdateUsernameRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateAccountSecurityReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateAccountSecurityReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityReplay}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateAccountSecurityReplay;
  return proto.managers_profile_endpoint.UpdateAccountSecurityReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateAccountSecurityReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityReplay}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateAccountSecurityReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateAccountSecurityReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityReplay} returns this
*/
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityReplay} returns this
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityReplay} returns this
*/
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityReplay} returns this
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateUsernameReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateUsernameReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateUsernameReplay}
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateUsernameReplay;
  return proto.managers_profile_endpoint.UpdateUsernameReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateUsernameReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateUsernameReplay}
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateUsernameReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateUsernameReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateUsernameReplay} returns this
*/
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateUsernameReplay} returns this
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateUsernameReplay} returns this
*/
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateUsernameReplay} returns this
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateUsernameReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.Role}
 */
proto.managers_profile_endpoint.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.Role;
  return proto.managers_profile_endpoint.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.Role}
 */
proto.managers_profile_endpoint.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_profile_endpoint.Role.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_profile_endpoint.Role} returns this
 */
proto.managers_profile_endpoint.Role.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.Role.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.Role} returns this
*/
proto.managers_profile_endpoint.Role.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Role} returns this
 */
proto.managers_profile_endpoint.Role.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Role.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UserType.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UserType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UserType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UserType.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UserType}
 */
proto.managers_profile_endpoint.UserType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UserType;
  return proto.managers_profile_endpoint.UserType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UserType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UserType}
 */
proto.managers_profile_endpoint.UserType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UserType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UserType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UserType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UserType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.managers_profile_endpoint.UserType.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_profile_endpoint.UserType} returns this
 */
proto.managers_profile_endpoint.UserType.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.UserType.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.UserType} returns this
*/
proto.managers_profile_endpoint.UserType.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UserType} returns this
 */
proto.managers_profile_endpoint.UserType.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UserType.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.managers_profile_endpoint.Profile.repeatedFields_ = [6,7,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstname: (f = msg.getFirstname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middlename: (f = msg.getMiddlename()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastname: (f = msg.getLastname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    createddate: (f = msg.getCreateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    phonenumbersList: jspb.Message.toObjectList(msg.getPhonenumbersList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    emailaddressesList: jspb.Message.toObjectList(msg.getEmailaddressesList(),
    google_protobuf_wrappers_pb.StringValue.toObject, includeInstance),
    usertype: (f = msg.getUsertype()) && proto.managers_profile_endpoint.UserType.toObject(includeInstance, f),
    role: (f = msg.getRole()) && proto.managers_profile_endpoint.Role.toObject(includeInstance, f),
    addedby: (f = msg.getAddedby()) && Protos_common_pb.ProfileResponse.toObject(includeInstance, f),
    address: (f = msg.getAddress()) && Protos_common_pb.Address.toObject(includeInstance, f),
    internalid: jspb.Message.getFieldWithDefault(msg, 12, ""),
    extrafieldsList: jspb.Message.toObjectList(msg.getExtrafieldsList(),
    Protos_common_pb.ExtraField.toObject, includeInstance),
    image: (f = msg.getImage()) && Protos_common_pb.File.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.Profile}
 */
proto.managers_profile_endpoint.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.Profile;
  return proto.managers_profile_endpoint.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.Profile}
 */
proto.managers_profile_endpoint.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstname(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddlename(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastname(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateddate(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addPhonenumbers(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.addEmailaddresses(value);
      break;
    case 8:
      var value = new proto.managers_profile_endpoint.UserType;
      reader.readMessage(value,proto.managers_profile_endpoint.UserType.deserializeBinaryFromReader);
      msg.setUsertype(value);
      break;
    case 9:
      var value = new proto.managers_profile_endpoint.Role;
      reader.readMessage(value,proto.managers_profile_endpoint.Role.deserializeBinaryFromReader);
      msg.setRole(value);
      break;
    case 10:
      var value = new Protos_common_pb.ProfileResponse;
      reader.readMessage(value,Protos_common_pb.ProfileResponse.deserializeBinaryFromReader);
      msg.setAddedby(value);
      break;
    case 11:
      var value = new Protos_common_pb.Address;
      reader.readMessage(value,Protos_common_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInternalid(value);
      break;
    case 13:
      var value = new Protos_common_pb.ExtraField;
      reader.readMessage(value,Protos_common_pb.ExtraField.deserializeBinaryFromReader);
      msg.addExtrafields(value);
      break;
    case 14:
      var value = new Protos_common_pb.File;
      reader.readMessage(value,Protos_common_pb.File.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddlename();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastname();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCreateddate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhonenumbersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmailaddressesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUsertype();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.managers_profile_endpoint.UserType.serializeBinaryToWriter
    );
  }
  f = message.getRole();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.managers_profile_endpoint.Role.serializeBinaryToWriter
    );
  }
  f = message.getAddedby();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      Protos_common_pb.ProfileResponse.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Protos_common_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getInternalid();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getExtrafieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      Protos_common_pb.ExtraField.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      Protos_common_pb.File.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.managers_profile_endpoint.Profile.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue firstName = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.Profile.prototype.getFirstname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setFirstname = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearFirstname = function() {
  return this.setFirstname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Profile.prototype.hasFirstname = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue middleName = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.Profile.prototype.getMiddlename = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setMiddlename = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearMiddlename = function() {
  return this.setMiddlename(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Profile.prototype.hasMiddlename = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue lastName = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.Profile.prototype.getLastname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setLastname = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearLastname = function() {
  return this.setLastname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Profile.prototype.hasLastname = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp createdDate = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_profile_endpoint.Profile.prototype.getCreateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setCreateddate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearCreateddate = function() {
  return this.setCreateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Profile.prototype.hasCreateddate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated google.protobuf.StringValue phoneNumbers = 6;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.managers_profile_endpoint.Profile.prototype.getPhonenumbersList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setPhonenumbersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.Profile.prototype.addPhonenumbers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearPhonenumbersList = function() {
  return this.setPhonenumbersList([]);
};


/**
 * repeated google.protobuf.StringValue emailAddresses = 7;
 * @return {!Array<!proto.google.protobuf.StringValue>}
 */
proto.managers_profile_endpoint.Profile.prototype.getEmailaddressesList = function() {
  return /** @type{!Array<!proto.google.protobuf.StringValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {!Array<!proto.google.protobuf.StringValue>} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setEmailaddressesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.google.protobuf.StringValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.Profile.prototype.addEmailaddresses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.google.protobuf.StringValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearEmailaddressesList = function() {
  return this.setEmailaddressesList([]);
};


/**
 * optional UserType userType = 8;
 * @return {?proto.managers_profile_endpoint.UserType}
 */
proto.managers_profile_endpoint.Profile.prototype.getUsertype = function() {
  return /** @type{?proto.managers_profile_endpoint.UserType} */ (
    jspb.Message.getWrapperField(this, proto.managers_profile_endpoint.UserType, 8));
};


/**
 * @param {?proto.managers_profile_endpoint.UserType|undefined} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setUsertype = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearUsertype = function() {
  return this.setUsertype(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Profile.prototype.hasUsertype = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Role role = 9;
 * @return {?proto.managers_profile_endpoint.Role}
 */
proto.managers_profile_endpoint.Profile.prototype.getRole = function() {
  return /** @type{?proto.managers_profile_endpoint.Role} */ (
    jspb.Message.getWrapperField(this, proto.managers_profile_endpoint.Role, 9));
};


/**
 * @param {?proto.managers_profile_endpoint.Role|undefined} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setRole = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearRole = function() {
  return this.setRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Profile.prototype.hasRole = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional common.ProfileResponse addedBy = 10;
 * @return {?proto.common.ProfileResponse}
 */
proto.managers_profile_endpoint.Profile.prototype.getAddedby = function() {
  return /** @type{?proto.common.ProfileResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.ProfileResponse, 10));
};


/**
 * @param {?proto.common.ProfileResponse|undefined} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setAddedby = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearAddedby = function() {
  return this.setAddedby(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Profile.prototype.hasAddedby = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional common.Address address = 11;
 * @return {?proto.common.Address}
 */
proto.managers_profile_endpoint.Profile.prototype.getAddress = function() {
  return /** @type{?proto.common.Address} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.Address, 11));
};


/**
 * @param {?proto.common.Address|undefined} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Profile.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string internalId = 12;
 * @return {string}
 */
proto.managers_profile_endpoint.Profile.prototype.getInternalid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.setInternalid = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated common.ExtraField extraFields = 13;
 * @return {!Array<!proto.common.ExtraField>}
 */
proto.managers_profile_endpoint.Profile.prototype.getExtrafieldsList = function() {
  return /** @type{!Array<!proto.common.ExtraField>} */ (
    jspb.Message.getRepeatedWrapperField(this, Protos_common_pb.ExtraField, 13));
};


/**
 * @param {!Array<!proto.common.ExtraField>} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setExtrafieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.common.ExtraField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.ExtraField}
 */
proto.managers_profile_endpoint.Profile.prototype.addExtrafields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.common.ExtraField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearExtrafieldsList = function() {
  return this.setExtrafieldsList([]);
};


/**
 * optional common.File image = 14;
 * @return {?proto.common.File}
 */
proto.managers_profile_endpoint.Profile.prototype.getImage = function() {
  return /** @type{?proto.common.File} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.File, 14));
};


/**
 * @param {?proto.common.File|undefined} value
 * @return {!proto.managers_profile_endpoint.Profile} returns this
*/
proto.managers_profile_endpoint.Profile.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.Profile} returns this
 */
proto.managers_profile_endpoint.Profile.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.Profile.prototype.hasImage = function() {
  return jspb.Message.getField(this, 14) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.GetProfileReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.GetProfileReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.GetProfileReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: (f = msg.getUsername()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountemail: (f = msg.getAccountemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    accountphonenumber: (f = msg.getAccountphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isactive: (f = msg.getIsactive()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isconfirmed: (f = msg.getIsconfirmed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    lastlogin: (f = msg.getLastlogin()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    accountsecurity: (f = msg.getAccountsecurity()) && proto.managers_profile_endpoint.AccountSecurityInfo.toObject(includeInstance, f),
    profile: (f = msg.getProfile()) && proto.managers_profile_endpoint.Profile.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay}
 */
proto.managers_profile_endpoint.GetProfileReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.GetProfileReplay;
  return proto.managers_profile_endpoint.GetProfileReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.GetProfileReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay}
 */
proto.managers_profile_endpoint.GetProfileReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccountid(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUsername(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountemail(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAccountphonenumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsactive(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsconfirmed(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastlogin(value);
      break;
    case 8:
      var value = new proto.managers_profile_endpoint.AccountSecurityInfo;
      reader.readMessage(value,proto.managers_profile_endpoint.AccountSecurityInfo.deserializeBinaryFromReader);
      msg.setAccountsecurity(value);
      break;
    case 9:
      var value = new proto.managers_profile_endpoint.Profile;
      reader.readMessage(value,proto.managers_profile_endpoint.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.GetProfileReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.GetProfileReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.GetProfileReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountemail();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAccountphonenumber();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsactive();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsconfirmed();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLastlogin();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAccountsecurity();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.managers_profile_endpoint.AccountSecurityInfo.serializeBinaryToWriter
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.managers_profile_endpoint.Profile.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 accountId = 1;
 * @return {number}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.getAccountid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue username = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.getUsername = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
*/
proto.managers_profile_endpoint.GetProfileReplay.prototype.setUsername = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.clearUsername = function() {
  return this.setUsername(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue accountEmail = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.getAccountemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
*/
proto.managers_profile_endpoint.GetProfileReplay.prototype.setAccountemail = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.clearAccountemail = function() {
  return this.setAccountemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.hasAccountemail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue accountPhoneNumber = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.getAccountphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
*/
proto.managers_profile_endpoint.GetProfileReplay.prototype.setAccountphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.clearAccountphonenumber = function() {
  return this.setAccountphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.hasAccountphonenumber = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue isActive = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.getIsactive = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
*/
proto.managers_profile_endpoint.GetProfileReplay.prototype.setIsactive = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.clearIsactive = function() {
  return this.setIsactive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.hasIsactive = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue isConfirmed = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.getIsconfirmed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
*/
proto.managers_profile_endpoint.GetProfileReplay.prototype.setIsconfirmed = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.clearIsconfirmed = function() {
  return this.setIsconfirmed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.hasIsconfirmed = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp lastLogin = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.getLastlogin = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
*/
proto.managers_profile_endpoint.GetProfileReplay.prototype.setLastlogin = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.clearLastlogin = function() {
  return this.setLastlogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.hasLastlogin = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AccountSecurityInfo accountSecurity = 8;
 * @return {?proto.managers_profile_endpoint.AccountSecurityInfo}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.getAccountsecurity = function() {
  return /** @type{?proto.managers_profile_endpoint.AccountSecurityInfo} */ (
    jspb.Message.getWrapperField(this, proto.managers_profile_endpoint.AccountSecurityInfo, 8));
};


/**
 * @param {?proto.managers_profile_endpoint.AccountSecurityInfo|undefined} value
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
*/
proto.managers_profile_endpoint.GetProfileReplay.prototype.setAccountsecurity = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.clearAccountsecurity = function() {
  return this.setAccountsecurity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.hasAccountsecurity = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Profile profile = 9;
 * @return {?proto.managers_profile_endpoint.Profile}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.getProfile = function() {
  return /** @type{?proto.managers_profile_endpoint.Profile} */ (
    jspb.Message.getWrapperField(this, proto.managers_profile_endpoint.Profile, 9));
};


/**
 * @param {?proto.managers_profile_endpoint.Profile|undefined} value
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
*/
proto.managers_profile_endpoint.GetProfileReplay.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.GetProfileReplay} returns this
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.GetProfileReplay.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.AccountSecurityInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.AccountSecurityInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.AccountSecurityInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    isemailtfenabled: (f = msg.getIsemailtfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isphonetfenabled: (f = msg.getIsphonetfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isauthapptfenabled: (f = msg.getIsauthapptfenabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    tfemail: (f = msg.getTfemail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    tfphonenumber: (f = msg.getTfphonenumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.AccountSecurityInfo;
  return proto.managers_profile_endpoint.AccountSecurityInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.AccountSecurityInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsemailtfenabled(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsphonetfenabled(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsauthapptfenabled(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfemail(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTfphonenumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.AccountSecurityInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.AccountSecurityInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.AccountSecurityInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsemailtfenabled();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsphonetfenabled();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsauthapptfenabled();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getTfemail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTfphonenumber();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue isEmailTFEnabled = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.getIsemailtfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
*/
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.setIsemailtfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.clearIsemailtfenabled = function() {
  return this.setIsemailtfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.hasIsemailtfenabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue isPhoneTFEnabled = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.getIsphonetfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
*/
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.setIsphonetfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.clearIsphonetfenabled = function() {
  return this.setIsphonetfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.hasIsphonetfenabled = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue isAuthAppTFEnabled = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.getIsauthapptfenabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
*/
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.setIsauthapptfenabled = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.clearIsauthapptfenabled = function() {
  return this.setIsauthapptfenabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.hasIsauthapptfenabled = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue TFEmail = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.getTfemail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
*/
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.setTfemail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.clearTfemail = function() {
  return this.setTfemail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.hasTfemail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue TFPhoneNumber = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.getTfphonenumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
*/
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.setTfphonenumber = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.AccountSecurityInfo} returns this
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.clearTfphonenumber = function() {
  return this.setTfphonenumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.AccountSecurityInfo.prototype.hasTfphonenumber = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateProfileReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateProfileReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateProfileReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateProfileReplay}
 */
proto.managers_profile_endpoint.UpdateProfileReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateProfileReplay;
  return proto.managers_profile_endpoint.UpdateProfileReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateProfileReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateProfileReplay}
 */
proto.managers_profile_endpoint.UpdateProfileReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateProfileReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateProfileReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateProfileReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileReplay} returns this
*/
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateProfileReplay} returns this
 */
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileReplay} returns this
*/
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateProfileReplay} returns this
 */
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateProfileReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdatePasswordReplay.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdatePasswordReplay} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.toObject = function(includeInstance, msg) {
  var f, obj = {
    updateddate: (f = msg.getUpdateddate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    message: (f = msg.getMessage()) && Protos_common_pb.MessageResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdatePasswordReplay}
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdatePasswordReplay;
  return proto.managers_profile_endpoint.UpdatePasswordReplay.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdatePasswordReplay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdatePasswordReplay}
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdateddate(value);
      break;
    case 2:
      var value = new Protos_common_pb.MessageResponse;
      reader.readMessage(value,Protos_common_pb.MessageResponse.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdatePasswordReplay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdatePasswordReplay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdateddate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Protos_common_pb.MessageResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp updatedDate = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.getUpdateddate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdatePasswordReplay} returns this
*/
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.setUpdateddate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdatePasswordReplay} returns this
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.clearUpdateddate = function() {
  return this.setUpdateddate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.hasUpdateddate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.MessageResponse message = 2;
 * @return {?proto.common.MessageResponse}
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.getMessage = function() {
  return /** @type{?proto.common.MessageResponse} */ (
    jspb.Message.getWrapperField(this, Protos_common_pb.MessageResponse, 2));
};


/**
 * @param {?proto.common.MessageResponse|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdatePasswordReplay} returns this
*/
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdatePasswordReplay} returns this
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdatePasswordReplay.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_profile_endpoint.GetProfileResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_profile_endpoint.GetProfileResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_profile_endpoint.GetProfileResponse.ResultCase}
 */
proto.managers_profile_endpoint.GetProfileResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_profile_endpoint.GetProfileResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_profile_endpoint.GetProfileResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.GetProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.GetProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.GetProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.GetProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_profile_endpoint.GetProfileReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.GetProfileResponse}
 */
proto.managers_profile_endpoint.GetProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.GetProfileResponse;
  return proto.managers_profile_endpoint.GetProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.GetProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.GetProfileResponse}
 */
proto.managers_profile_endpoint.GetProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_profile_endpoint.GetProfileReplay;
      reader.readMessage(value,proto.managers_profile_endpoint.GetProfileReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.GetProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.GetProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.GetProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.GetProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_profile_endpoint.GetProfileReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetProfileReplay success = 1;
 * @return {?proto.managers_profile_endpoint.GetProfileReplay}
 */
proto.managers_profile_endpoint.GetProfileResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_profile_endpoint.GetProfileReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_profile_endpoint.GetProfileReplay, 1));
};


/**
 * @param {?proto.managers_profile_endpoint.GetProfileReplay|undefined} value
 * @return {!proto.managers_profile_endpoint.GetProfileResponse} returns this
*/
proto.managers_profile_endpoint.GetProfileResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_profile_endpoint.GetProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.GetProfileResponse} returns this
 */
proto.managers_profile_endpoint.GetProfileResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.GetProfileResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_profile_endpoint.UpdateProfileResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_profile_endpoint.UpdateProfileResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_profile_endpoint.UpdateProfileResponse.ResultCase}
 */
proto.managers_profile_endpoint.UpdateProfileResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_profile_endpoint.UpdateProfileResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_profile_endpoint.UpdateProfileResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateProfileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateProfileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateProfileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateProfileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_profile_endpoint.UpdateProfileReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateProfileResponse}
 */
proto.managers_profile_endpoint.UpdateProfileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateProfileResponse;
  return proto.managers_profile_endpoint.UpdateProfileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateProfileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateProfileResponse}
 */
proto.managers_profile_endpoint.UpdateProfileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_profile_endpoint.UpdateProfileReplay;
      reader.readMessage(value,proto.managers_profile_endpoint.UpdateProfileReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateProfileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateProfileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateProfileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateProfileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_profile_endpoint.UpdateProfileReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateProfileReplay success = 1;
 * @return {?proto.managers_profile_endpoint.UpdateProfileReplay}
 */
proto.managers_profile_endpoint.UpdateProfileResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_profile_endpoint.UpdateProfileReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_profile_endpoint.UpdateProfileReplay, 1));
};


/**
 * @param {?proto.managers_profile_endpoint.UpdateProfileReplay|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateProfileResponse} returns this
*/
proto.managers_profile_endpoint.UpdateProfileResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_profile_endpoint.UpdateProfileResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateProfileResponse} returns this
 */
proto.managers_profile_endpoint.UpdateProfileResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateProfileResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_profile_endpoint.UpdatePasswordResponse.ResultCase}
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_profile_endpoint.UpdatePasswordResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_profile_endpoint.UpdatePasswordResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdatePasswordResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdatePasswordResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_profile_endpoint.UpdatePasswordReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdatePasswordResponse}
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdatePasswordResponse;
  return proto.managers_profile_endpoint.UpdatePasswordResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdatePasswordResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdatePasswordResponse}
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_profile_endpoint.UpdatePasswordReplay;
      reader.readMessage(value,proto.managers_profile_endpoint.UpdatePasswordReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdatePasswordResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdatePasswordResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_profile_endpoint.UpdatePasswordReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdatePasswordReplay success = 1;
 * @return {?proto.managers_profile_endpoint.UpdatePasswordReplay}
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_profile_endpoint.UpdatePasswordReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_profile_endpoint.UpdatePasswordReplay, 1));
};


/**
 * @param {?proto.managers_profile_endpoint.UpdatePasswordReplay|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdatePasswordResponse} returns this
*/
proto.managers_profile_endpoint.UpdatePasswordResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_profile_endpoint.UpdatePasswordResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdatePasswordResponse} returns this
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdatePasswordResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_profile_endpoint.UpdateAccountSecurityResponse.ResultCase}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_profile_endpoint.UpdateAccountSecurityResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_profile_endpoint.UpdateAccountSecurityResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateAccountSecurityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateAccountSecurityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_profile_endpoint.UpdateAccountSecurityReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityResponse}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateAccountSecurityResponse;
  return proto.managers_profile_endpoint.UpdateAccountSecurityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateAccountSecurityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityResponse}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_profile_endpoint.UpdateAccountSecurityReplay;
      reader.readMessage(value,proto.managers_profile_endpoint.UpdateAccountSecurityReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateAccountSecurityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateAccountSecurityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_profile_endpoint.UpdateAccountSecurityReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateAccountSecurityReplay success = 1;
 * @return {?proto.managers_profile_endpoint.UpdateAccountSecurityReplay}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_profile_endpoint.UpdateAccountSecurityReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_profile_endpoint.UpdateAccountSecurityReplay, 1));
};


/**
 * @param {?proto.managers_profile_endpoint.UpdateAccountSecurityReplay|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityResponse} returns this
*/
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_profile_endpoint.UpdateAccountSecurityResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateAccountSecurityResponse} returns this
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateAccountSecurityResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.ResultCase = {
  RESULT_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.managers_profile_endpoint.UpdateUsernameResponse.ResultCase}
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.prototype.getResultCase = function() {
  return /** @type {proto.managers_profile_endpoint.UpdateUsernameResponse.ResultCase} */(jspb.Message.computeOneofCase(this, proto.managers_profile_endpoint.UpdateUsernameResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.managers_profile_endpoint.UpdateUsernameResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.managers_profile_endpoint.UpdateUsernameResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.managers_profile_endpoint.UpdateUsernameReplay.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.managers_profile_endpoint.UpdateUsernameResponse}
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.managers_profile_endpoint.UpdateUsernameResponse;
  return proto.managers_profile_endpoint.UpdateUsernameResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.managers_profile_endpoint.UpdateUsernameResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.managers_profile_endpoint.UpdateUsernameResponse}
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.managers_profile_endpoint.UpdateUsernameReplay;
      reader.readMessage(value,proto.managers_profile_endpoint.UpdateUsernameReplay.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.managers_profile_endpoint.UpdateUsernameResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.managers_profile_endpoint.UpdateUsernameResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.managers_profile_endpoint.UpdateUsernameReplay.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateUsernameReplay success = 1;
 * @return {?proto.managers_profile_endpoint.UpdateUsernameReplay}
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.prototype.getSuccess = function() {
  return /** @type{?proto.managers_profile_endpoint.UpdateUsernameReplay} */ (
    jspb.Message.getWrapperField(this, proto.managers_profile_endpoint.UpdateUsernameReplay, 1));
};


/**
 * @param {?proto.managers_profile_endpoint.UpdateUsernameReplay|undefined} value
 * @return {!proto.managers_profile_endpoint.UpdateUsernameResponse} returns this
*/
proto.managers_profile_endpoint.UpdateUsernameResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.managers_profile_endpoint.UpdateUsernameResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.managers_profile_endpoint.UpdateUsernameResponse} returns this
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.managers_profile_endpoint.UpdateUsernameResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.managers_profile_endpoint);
